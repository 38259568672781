import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const EqualWidth = ({ children = [] }) => {
  return children.map((c, i) => <Wrapper key={`eq-${i}`}>{c}</Wrapper>);
};

EqualWidth.propTypes = {
  children: PropTypes.node.isRequired,
};

const Wrapper = styled.div`
  flex: 1;
`;

export default EqualWidth;
