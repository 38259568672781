import React from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import bg from '../../assets/images/banner-bg.svg';
import gotoImage from '../../assets/images/goto.svg';
import { navigationParents } from '../../functionalities/navigation';
import { useMessageGetter } from 'react-message-context';
import { Card } from '../card';
import { h3LeftNegative, labelLeftNegative } from '../../styles/text';

const TourBanner = ({ fullWidth }) => {
  const text = useMessageGetter('components.banner.tour');
  const theme = useTheme();
  return (
    <Link to={navigationParents.tour.url}>
      <Card bgGradient={theme.tourBanner.bgGradient} bgImage={bg} spacing="dense">
        <Content fullWidth={fullWidth}>
          <Texts>
            <Title>{text('title')}</Title>
            <Subtitle>{text('subtitle')}</Subtitle>
          </Texts>
          <Image src={gotoImage} alt="" width="114" height="85" />
        </Content>
      </Card>
    </Link>
  );
};

TourBanner.propTypes = {
  fullWidth: PropTypes.bool,
};

export default TourBanner;

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 150px;
  ${({ fullWidth }) =>
    fullWidth &&
    `
    height: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    > img {
      position: inherit;
    }
  `}
`;

const Texts = styled.div``;

const Title = styled.h1`
  ${h3LeftNegative};
  color: ${({ theme }) => theme.tourBanner.color};
  margin: 0;
`;

const Subtitle = styled.span`
  ${labelLeftNegative};
  color: ${({ theme }) => theme.tourBanner.color};
  margin-top: 5px;
`;

const Image = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
`;
