import styled from 'styled-components';

const FlexibleWizardFooter = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 15px 30px 40px 30px;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-image: ${props => props.theme.wizard.bgFooter};
  position: absolute;
  bottom: 0;
  height: 190px;
`;

export default FlexibleWizardFooter;
