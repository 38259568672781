import React from 'react';
import PropTypes from 'prop-types';
import SmallButton from '../../button/SmallButton';
import Center from '../../container/Center';

const WizardContentButton = React.forwardRef(
  ({ onClick, loading = false, disabled = false, text }, ref) => (
    <Center style={{ marginTop: '20px' }}>
      <SmallButton
        primary
        onClick={onClick}
        loading={loading}
        disabled={disabled}
        text={text}
        ref={ref}
      />
    </Center>
  ),
);

WizardContentButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

export default WizardContentButton;
