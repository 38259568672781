import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ProfilePicture, { SIZES, SHAPES } from '../picture/ProfilePicture';
import { labelFormElementPositive, pWeak, pPositive } from '../../styles/text';

const urlRegex = /(https?:\/\/[^\s]+)/g;

const Message = ({ from, message, sendDate, read = true, footerTexts = [] }) => {
  return (
    <Container>
      {!read && <NewMessageIndicator />}
      <Picture userId={from.id} size={SIZES.SMALL} shape={SHAPES.SQUARE} flat read={read} />
      <MessageContent>
        <MessageHeader>
          <MessageSender read={read}>{from.name}</MessageSender>
          <MessageSent read={read}>{sendDate}</MessageSent>
        </MessageHeader>
        <MessageText read={read}>{shortenUrl(message)}</MessageText>
        <MessageFooter>
          {footerTexts.map((text, i) => (
            <Fragment key={`text-${i}`}>
              <span>{text}</span>
              {i < footerTexts.length - 1 ? <Divider /> : null}
            </Fragment>
          ))}
        </MessageFooter>
      </MessageContent>
    </Container>
  );
};

function shortenUrl(message) {
  return message.replace(urlRegex, url => `${url.substring(0, 15)}...`);
}

Message.propTypes = {
  from: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    userId: PropTypes.string,
  }).isRequired,
  read: PropTypes.bool,
  message: PropTypes.string.isRequired,
  sendDate: PropTypes.string.isRequired,
  footerTexts: PropTypes.arrayOf(PropTypes.string),
};

export default Message;

const Picture = styled(ProfilePicture).attrs(({ read }) => ({
  style: {
    opacity: read ? 0.6 : 1,
  },
}))``;

const Container = styled.div`
  padding: 20px 35px 15px;
  display: flex;
  flex-direction: row;
  position: relative;
`;

const MessageContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 15px;
`;

const MessageHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  width: 100%;
`;

const MessageFooter = styled.div`
  ${pWeak};
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: 100%;
  align-items: center;
`;

const MessageSender = styled.div.attrs(({ read, theme }) => ({
  ...(read && {
    style: { color: theme.message.read },
  }),
}))`
  ${labelFormElementPositive};
  text-align: left;
  flex: 1;
`;

const MessageSent = styled.div`
  ${pWeak};
  text-align: right;
`;

const MessageText = styled.div.attrs(({ read, theme }) => ({
  ...(read && {
    style: { color: theme.message.read },
  }),
}))`
  ${pPositive};
`;

export const NewMessageIndicator = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: ${props => props.theme.message.newIndicator};
  margin-right: 8px;
  position: absolute;
  left: 20px;
  top: 36px;
`;

const Divider = styled.div`
  width: 2px;
  height: 2px;
  background-color: ${props => props.theme.message.divider};
  margin-left: 8px;
  margin-right: 8px;
`;
