import React, { useEffect } from 'react';
import { useApp } from '../../../context/appContext';
import { loadGroup } from '../../../context/actions';
import ModalFullscreen from '../../../components/modal/ModalFullscreen';
import { HistoryShape, LocationShape, MatchShapeOfParams } from '../../../util/shapes';
import GroupSettingsPageMember from './GroupSettingsPageMember';
import GroupSettingsPageCaptain from './GroupSettingsPageCaptain';
import { theme } from '../../../theme';

const GroupSettingsPage = ({ location, history, match }) => {
  const [app, dispatch] = useApp();
  const { groupId } = match.params;
  const group = app[groupId];

  useEffect(() => {
    if (!group) {
      loadGroup(dispatch, groupId);
    }
  }, [dispatch, groupId, group]);

  if (!group) {
    return (
      <ModalFullscreen title={''} onClose={history.goBack} theme={theme('white')}>
        {null}
      </ModalFullscreen>
    );
  }

  const { user } = app;
  const isCaptain = user.isLoggedIn && user.sub === group.captain.userId;
  if (isCaptain) {
    return <GroupSettingsPageCaptain location={location} match={match} history={history} />;
  } else {
    return <GroupSettingsPageMember location={location} match={match} history={history} />;
  }
};

GroupSettingsPage.propTypes = {
  history: HistoryShape.isRequired,
  location: LocationShape.isRequired,
  match: MatchShapeOfParams(['challengeId', 'groupId']),
};

export default GroupSettingsPage;
