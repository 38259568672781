import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Box from '../../container/Box';
import { pNegative } from '../../../styles/text';
import { getChallengeIconSrc } from '../../../assets/images';

/**
 * Shows text with icon on side. Used with calculations of the wizards.
 *
 * @param {props} properties
 */
const WizardIconText = ({ icon, text }) => (
  <Box row margin="0 30px">
    <Icon src={getChallengeIconSrc(icon)} />
    <Text>{text}</Text>
  </Box>
);

WizardIconText.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
};

export default WizardIconText;

const Icon = styled.img`
  width: 34px;
  height: 34px;
  min-width: 34px;
  min-height: 34px;
`;

const Text = styled.div`
  ${pNegative};
  margin-left: 15px;
  margin-top: 10px;
  margin-bottom: 18px;
`;
