import { Groups } from 'isomorphic';
import { mapPersonalDailyAnswersToCumulativeActualProgress } from '../components/data-cards/ProgressCard';

export function mapProfileGroupToGroupCard(group) {
  const data = {
    id: group.id,
    challengeId: group.challenge.id.replace('challenge_', ''),
    csn: group.challenge.short_name,
    captain: group.captain,
    startDate: group.startDate,
    durationDays: group.durationDays,
    chartData: {
      valuesAmount: group.durationDays,
      refStartValue: 0.0,
      refEndValue: (group.personal.initialCo2Kg / 365) * group.durationDays,
      values: mapPersonalDailyAnswersToCumulativeActualProgress(group.personal.dailyAnswers),
    },
    name: group.name,
    memberCount: Object.keys(group.members).filter(
      userId => userId !== `user_${group.captain.userId}`,
    ).length,
    theme: group.theme,
    groupReductionKg: Groups.getGroupTotalReductionKg(group.progress),
    groupYearlyReductionTargetKg: Groups.getYearlyReductionTargetKg(group),
    personalReductionKg: Groups.getTotalReductionKg(group.personal.dailyAnswers),
    groupReductionTargetPrc: group.goal.totalTargetCo2Kg / group.goal.totalInitialCo2Kg,
  };
  return data;
}
