import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Row from '../container/Row';
import { h3LeftNegative, labelLeftNegative, pNegative } from '../../styles/text';

const DataGrid = ({ data = [] }) => {
  return (
    <Row>
      {data.map((item, idx) => (
        <DataGridValue key={`v-${idx}`} {...item} />
      ))}
    </Row>
  );
};

DataGrid.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      unit: PropTypes.string,
      description: PropTypes.string.isRequired,
    }),
  ),
};

export default DataGrid;

const DataGridValue = ({ value, unit, description }) => (
  <DataGridValueContainer>
    <Value>
      {value < 0 ? `+${Math.abs(value)}` : value}
      {unit && <UnitText>{unit}</UnitText>}
    </Value>
    <Description>{description}</Description>
  </DataGridValueContainer>
);

DataGridValue.propTypes = {
  value: PropTypes.number.isRequired,
  unit: PropTypes.string,
  description: PropTypes.string.isRequired,
};

const DataGridValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 37px;
  border-left: 1px solid rgba(255, 255, 255, 0.15);
  padding-left: 10px;
  padding-right: 30px;
`;

const Value = styled.div`
  ${h3LeftNegative};
`;

const UnitText = styled.span`
  ${labelLeftNegative};
  margin-left: 3px;
`;

const Description = styled.div`
  ${pNegative};
  white-space: nowrap;
`;
