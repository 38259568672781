import React, { useEffect } from 'react';
import styled from 'styled-components';

import NavigationBarLink from './NavigationBarLink';
import { navigationParents } from '../../functionalities/navigation';
import ProfilePicture, { SIZES, SHAPES } from '../../components/picture/ProfilePicture';
import { useApp } from '../../context/appContext';
import SmallButton from '../../components/button/SmallButton';
import elements from '../../styles/elements';
import { ReactComponent as LogoColor } from '../../assets/images/logo-color.svg';
import { Link, useLocation } from 'react-router-dom';
import media from '../../styles/media';
import { loadNotifications, loadReminders, loadUserInfo } from '../../context/actions';

// If the user is logged in, we set the link to profile page, otherwise we redirect to auth page
function getProfileLink(userUrl, authUrl, isLoggedIn = false) {
  return isLoggedIn ? userUrl : { pathname: authUrl, state: { from: window.location.pathname } };
}

/**
 * Figure out whether to show indication for new messages.
 *
 * - Always show indication if there are new challenge reminders
 * - Show indication if there are notifications that are created user has latest visited messages
 *   page
 * - If user is currently viewing Messages page, do not show indication unless there are new
 *   challenge reminders. This means that if user is viewing Messages page and there is a new
 *   notification, the indicator is not shown.
 */
function showIndicationForNewMessages(state, location) {
  const userHasNewChallengeReminders = state.userMessages.some(({ readAt }) => readAt == null);
  if (userHasNewChallengeReminders) return true;
  const userIsOnMessagesPage = location.pathname === navigationParents.messages.url;
  if (userIsOnMessagesPage) return false;
  const userHasNewNotifications = state.notifications.some(
    ({ createdAt }) => createdAt >= state.userInfo.lastVisitedMessages,
  );
  return userHasNewNotifications;
}

const NavigationBar = () => {
  const location = useLocation();
  const [state, dispatch] = useApp();

  useEffect(() => {
    if (state.user.isLoggedIn) {
      loadReminders(dispatch);
      loadNotifications(dispatch);
      loadUserInfo(dispatch);
    }
  }, [dispatch, state.user.isLoggedIn]);

  const profileLink = getProfileLink(
    navigationParents.user.url,
    navigationParents.auth.url,
    state.user.isLoggedIn,
  );
  const { user } = state;
  return (
    <OuterContainer>
      <Container>
        <Link to={navigationParents.home.url}>
          <Logo alt="Home" />
        </Link>
        <Links>
          <TextLinks>
            <NavigationBarLink
              href={navigationParents.home.url}
              active={location.pathname === navigationParents.home.url}
            >
              Home
            </NavigationBarLink>
            <NavigationBarLink
              href={navigationParents.challenges.url}
              active={location.pathname.startsWith(navigationParents.challenges.url)}
            >
              Challenges
            </NavigationBarLink>
            {user.isLoggedIn && (
              <NavigationBarLink
                href={navigationParents.messages.url}
                active={location.pathname.startsWith(navigationParents.messages.url)}
              >
                Messages
                {showIndicationForNewMessages(state, location) && <NotificationForNewMessages />}
              </NavigationBarLink>
            )}
          </TextLinks>
          <NavigationBarLink href={profileLink} last>
            {user.isLoggedIn ? (
              <ProfilePicture userId={user.sub} size={SIZES.MINI} shape={SHAPES.ROUND} flat />
            ) : (
              <SmallButton text="Get in" primary onClick={() => {}} />
            )}
          </NavigationBarLink>
        </Links>
      </Container>
    </OuterContainer>
  );
};

export default NavigationBar;

const OuterContainer = styled.div`
  display: flex;
  justify-content: center;

  ${media.desktop`
    box-shadow: 0 2px 12px 0 rgba(87, 87, 87, 0.15);
  `}
`;

const Container = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 100;
  width: 100%;
  max-width: ${elements.containerMaxWidthPx}px;
  height: ${elements.navBarHeightPx}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${props => props.theme.colors.dull7};
  padding: ${elements.appSecondaryPaddingPx}px;
  padding-left: ${elements.appPaddingPx}px;
  padding-right: ${elements.appPaddingPx}px;
  color: ${props => props.theme.colors.seachangeStandout};
  box-shadow: 0 0 12px 0 rgba(87, 87, 87, 0.15);

  ${media.desktop`
    position: static;
    box-shadow: none;
    justify-content: space-between;
  `}
`;

const NotificationForNewMessages = styled.div`
  position: absolute;
  right: 10px;
  top: 5px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: ${props => props.theme.colors.brand4Hue1};
`;

const Logo = styled(LogoColor)`
  display: none;
  ${media.desktop`
    display: block;
    width: auto;
    max-height: 20px;
  `}
`;

const Links = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${media.desktop`
    justify-content: flex-end;
  `}
`;

const TextLinks = styled.div`
  display: flex;
`;
