import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import elements from '../../styles/elements';
import { useDocumentHeight, useParentHeight } from '../../hooks/measuring';
import media from '../../styles/media';

const ResponsiveStickyContainer = ({ children, className, insideModal = false }) => {
  const documentHeight = useDocumentHeight();
  const [parentHeight, ref] = useParentHeight();
  return (
    <Container className={className} height={insideModal ? parentHeight : documentHeight} ref={ref}>
      <Sticky>{children}</Sticky>
    </Container>
  );
};

ResponsiveStickyContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  insideModal: PropTypes.bool,
};

export default ResponsiveStickyContainer;

const Container = styled.div`
  position: absolute;
  top: 0;
  height: ${({ height }) => height}px;
  ${media.desktop`
    height: ${({ height }) => height - elements.navBarHeightPx}px;
  `}
  pointer-events: none;
`;

const Sticky = styled.div`
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 199;
  padding: ${elements.appSecondaryPaddingPx}px;
  pointer-events: all;
`;
