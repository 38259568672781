import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { useMessageGetter } from 'react-message-context';
import PropTypes from 'prop-types';
import { FullscreenWizard, WizardStep, WizardCountrySelection } from '../../../components/wizard';
import ModalConfirm from '../../../components/modal/ModalConfirm';
import { theme } from '../../../theme';
import { getAuthVariant } from '../../../functionalities/misc';
import { postCreateSignUp, postInitSignUp } from '../../../functionalities/apis';
import { session } from '../../../util/session';
import { HistoryShape, LocationShape } from '../../../util/shapes';
import WelcomeStep from './steps/WelcomeStep';
import AuthStep from './steps/AuthStep';
import VerifyStep from './steps/VerifyStep';
import ProfileInfoStep from './steps/ProfileInfoStep';
import Auth from '@aws-amplify/auth';
import { useAppDispatch } from '../../../context/appContext';
import { EVENTS } from '../../../context/actions';
import { composePhoneNumber } from '../../../util/user';
import AccountCreatedStep from './steps/AccountCreatedStep';
import VerifiedStep from './steps/VerifiedStep';
import { storage } from '../../../util/storage';
import { runDeferredAction } from '../../../util/actionHelper';
import { useTrackPageView } from '../../../hooks/useTrackPageView';

const AuthWizard = ({ location, history }) => {
  useTrackPageView();
  const variant = getAuthVariant(location.hash);
  const [state, setState] = useState({
    x: 0,
    type: 'phoneNumber',
    phoneValue: {
      countryCallCode: '358',
      countryCode: 'FI',
      phoneNumber: '',
    },
    email: '',
    secret: '',
    givenName: '',
    familyName: '',
    showExitConfirm: false,
    showErrorModal: false,
    showCallCodeSelect: false,
    errorText: undefined,
    counter: 0,
    initCounter: 0,
    // Load initial state from session storage if we exited form to see ie. terms
    ...(session.get('signupState') || {}),
  });

  useEffect(() => {
    let intervalId;
    if (state.initCounter > 0) {
      intervalId = setInterval(() => {
        setState(state => ({
          ...state,
          counter: state.counter + 1,
        }));
      }, 1000);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [state.initCounter]);

  const dispatch = useAppDispatch();

  const onClose = () => {
    try {
      switch (variant) {
        case 'createGroup':
          if (history.length <= 3) {
            history.push('/');
          } else {
            history.go(-3);
          }
          return;
        case 'joinGroup':
        case 'joinLockedGroup':
        case 'answer':
          if (history.length <= 3) {
            history.push('/');
          } else {
            history.go(-2);
          }
          return;
        default:
          history.goBack();
      }
    } catch (err) {
      history.push('/');
    }
  };

  const onExit = () => {
    session.remove('signupState');
    onClose();
  };

  const onCloseExitDialog = () =>
    setState(state => ({
      ...state,
      showExitConfirm: false,
    }));

  const confirmClose = () =>
    setState(state => ({
      ...state,
      showExitConfirm: true,
    }));

  const onContinue = () => {
    setState(state => ({
      ...state,
      x: state.x + 1,
    }));
  };

  const onChange = evt => {
    const { name, value } = evt.target;
    setState(state => ({
      ...state,
      [name]: value,
    }));
  };

  const onChangeAuthType = () => {
    setState(state => ({
      ...state,
      x: 1,
      type: state.type === 'phoneNumber' ? 'email' : 'phoneNumber',
      secret: '',
    }));
  };

  const onGotoVerifyStep = authToken => {
    setState(state => ({
      ...state,
      x: state.x + 1,
      authToken,
      counter: 0,
      initCounter: state.initCounter + 1,
    }));
  };

  const onRequestNewCode = async () => {
    setState(state => ({
      ...state,
      counter: 0,
      initCounter: state.initCounter + 1,
    }));

    const { authToken } = await postInitSignUp({
      type,
      value:
        type === 'phoneNumber'
          ? composePhoneNumber(state.phoneValue.countryCallCode, state.phoneValue.phoneNumber)
          : state.email,
    });

    setState(state => ({
      ...state,
      authToken,
    }));
  };

  const onShowCallCodeSelect = () => {
    setState(state => ({
      ...state,
      showCallCodeSelect: true,
    }));
  };

  const { x, type, showExitConfirm, phoneValue, email, showCallCodeSelect } = state;

  // Values for auth step depend on which type is selected
  const authValue = type === 'phoneNumber' ? phoneValue : email;
  const login =
    type === 'phoneNumber'
      ? composePhoneNumber(phoneValue.countryCallCode, phoneValue.phoneNumber)
      : email;
  const onAuthChange = evt => {
    const value = evt.target.value;
    if (type === 'phoneNumber') {
      setState(state => ({
        ...state,
        phoneValue: {
          ...state.phoneValue,
          phoneNumber: value,
        },
      }));
    } else {
      setState(state => ({
        ...state,
        email: value,
      }));
    }
  };

  const doLogin = async oneTimeSecret => {
    const cognitoUser = await Auth.signIn(login);
    await Auth.sendCustomChallengeAnswer(cognitoUser, oneTimeSecret);
    const user = await Auth.currentAuthenticatedUser({
      bypassCache: false,
    });

    dispatch([
      EVENTS.LOGIN,
      {
        ...user.attributes,
        userId: user.attributes.sub,
      },
    ]);
  };

  const onFlowSuccess = async () => {
    try {
      session.remove('signupState');
    } catch (err) {
      // Is ok
    }

    try {
      switch (variant) {
        case 'joinGroup':
          const action = storage.get('deferredAction');
          await runDeferredAction(action, history);
          return;
        case 'createGroup':
        case 'joinLockedGroup':
        case 'answer':
          history.goBack();
          return;
        default:
          if (x > 4) {
            // Go to profile page if this was sign up
            history.replace('/user');
          } else {
            // Try going back to where we came from based on state
            history.replace(location?.state?.from || '/user');
          }
      }
    } catch (err) {
      history.push('/');
    }
  };

  // Decide if we log in or move to sign up
  const onVerifySuccess = async response => {
    if (response && response.oneTimeSecret) {
      await doLogin(response.oneTimeSecret);
      await onFlowSuccess();
    } else {
      setState(state => ({
        ...state,
        x: state.x + 1,
      }));
    }
  };

  const storeForm = () => {
    session.add('signupState', {
      authToken: state.authToken || '',
      email: state.email || '',
      givenName: state.givenName || '',
      familyName: state.familyName || '',
      phoneValue: state.phoneValue || '',
      x: state.x || 0,
      type: state.type,
    });
  };

  const onCreateAccount = async () => {
    const { givenName, familyName, authToken } = state;
    try {
      // Create the actual account
      const { oneTimeSecret } = await postCreateSignUp({
        givenName,
        familyName,
        authToken,
      });

      await doLogin(oneTimeSecret);

      setState(state => ({ ...state, x: 5 }));
    } catch (err) {
      console.log('Error signing user up', err);
      setState(
        Object.assign({}, state, {
          showErrorModal: 'unknown',
          errorText: 'Very unknown error',
        }),
      );
    }
  };

  return (
    <ThemeProvider theme={theme('white')}>
      <OnExitDialog
        show={showExitConfirm}
        onCancel={onCloseExitDialog}
        onExit={onExit}
        variant={variant}
      />
      <FullscreenWizard x={x} y={0} maxX={4} maxY={1}>
        <WizardStep x={0} y={0} maxY={1} onClose={confirmClose}>
          <WelcomeStep
            show={x === 0}
            variant={variant}
            type={type}
            onChangeType={type => setState(state => ({ ...state, type }))}
            onContinue={onContinue}
          />
        </WizardStep>
        <WizardStep x={1} y={0} maxY={1} onClose={confirmClose}>
          <AuthStep
            show={x === 1}
            type={type}
            value={authValue}
            disabled={state.initCounter > 0 && state.counter < 20}
            onChange={onAuthChange}
            onChangeAuthType={onChangeAuthType}
            onInitSignUp={postInitSignUp}
            onForward={onGotoVerifyStep}
            onShowCallCodeSelect={onShowCallCodeSelect}
          />
        </WizardStep>
        <WizardStep x={2} y={0} maxY={1} onClose={confirmClose}>
          {x === 2 && (
            <VerifyStep
              type={state.type}
              authToken={state.authToken}
              authValue={login}
              secret={state.secret}
              counter={state.counter}
              onBack={() => setState(state => ({ ...state, x: 1, secret: '' }))}
              onChangeAuthType={onChangeAuthType}
              onRequestNewCode={onRequestNewCode}
              onChange={secret => setState(state => ({ ...state, secret }))}
              onSuccess={onVerifySuccess}
            />
          )}
        </WizardStep>
        <WizardStep x={3} y={0} maxY={1} onClose={confirmClose}>
          {x === 3 && (
            <VerifiedStep
              authValue={login}
              type={type}
              onExit={confirmClose}
              onContinue={onContinue}
            />
          )}
        </WizardStep>
        <WizardStep x={4} y={0} maxY={1} onClose={confirmClose}>
          {x === 4 && (
            <ProfileInfoStep
              familyName={state.familyName}
              givenName={state.givenName}
              onChange={onChange}
              onCreateAccount={onCreateAccount}
              onLeaveForm={storeForm}
            />
          )}
        </WizardStep>
        <WizardStep x={5} y={0} maxY={0} onClose={confirmClose}>
          {x === 5 && (
            <AccountCreatedStep
              givenName={state.givenName}
              onContinue={onFlowSuccess}
              variant={variant}
            />
          )}
        </WizardStep>
      </FullscreenWizard>
      <WizardCountrySelection
        show={showCallCodeSelect}
        onClose={() => setState(state => ({ ...state, showCallCodeSelect: false }))}
        onChange={(countryCode, countryCallCode) =>
          setState(state => ({
            ...state,
            phoneValue: {
              ...state.phoneValue,
              countryCode,
              countryCallCode,
            },
            showCallCodeSelect: false,
          }))
        }
      />
    </ThemeProvider>
  );
};

AuthWizard.propTypes = {
  location: LocationShape.isRequired,
  history: HistoryShape,
};

export default AuthWizard;

const OnExitDialog = ({ variant = 'default', show, onCancel, onExit }) => {
  const messages = useMessageGetter(`pages.wizards.auth.${variant}.exit`);
  if (!show) {
    return null;
  } else {
    return (
      <ModalConfirm
        x={0}
        y={0}
        title={messages('title')}
        description={messages('description')}
        onCancel={onCancel}
        options={[
          {
            label: messages('confirm'),
            key: 'yes',
            onClick: onExit,
          },
          {
            label: messages('cancel'),
            key: 'no',
            onClick: onCancel,
          },
        ]}
      />
    );
  }
};

OnExitDialog.propTypes = {
  variant: PropTypes.string,
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onExit: PropTypes.func.isRequired,
};
