import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ProfilePicture, {
  SIZES,
  SHAPES,
  PictureContainer,
  PictureWrapper,
  VARIANT,
} from '../../picture/ProfilePicture';
import { pWeak } from '../../../styles/text';
import { useMessage } from 'react-message-context';
import { badgeCaptain, badgeIsMember } from '../../../assets/images';
import { getName } from '../../../util/user';
import { UserShape } from '../../../util/shapes';

const ProspectiveProfilePicture = styled.div`
  ${pWeak};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border: 1px dotted ${props => props.theme.colors.brand2Hue3};
  border-radius: 34px;
`;

const RoundMemberList = ({
  captain,
  members,
  limit = 5,
  mini = false,
  isUserMember = false,
  isShowProspect = true,
  showBadges = true,
  style,
  userId,
  variant = VARIANT.NEGATIVE,
}) => {
  const membersWithoutCaptain = members.filter(member => member.userId !== captain.userId);
  membersWithoutCaptain.sort((m1, m2) => {
    if (m1.userId === userId) return -1;
    else if (m2.userId === userId) return 1;
    else return 0;
  });

  const limitAdjustment = members.length === membersWithoutCaptain.length ? 0 : -1;
  limit += limitAdjustment;
  const items = [captain, ...membersWithoutCaptain.filter((_, i) => i < limit)];

  const notShownMembersAmount = membersWithoutCaptain.length - limit;
  const prospectText = useMessage('components.memberlist.prospect')();

  return (
    <Container style={style}>
      <ClipPath />
      <MembersRow mini={mini}>
        {items.map((member, i) => (
          <ProfilePicture
            key={`member-${i}`}
            badge={
              showBadges &&
              ((member.userId === captain.userId && <CaptainBadge />) ||
                (member.userId === userId && <UserBadge />))
            }
            alt={getName(member)}
            size={!mini ? SIZES.MINI2 : SIZES.MICRO}
            shape={SHAPES.ROUND}
            variant={variant}
            flat={true}
            userId={member.userId}
          />
        ))}
        {notShownMembersAmount > 0 && (
          <ProfilePicture
            size={SIZES.MINI2}
            shape={SHAPES.ROUND}
            flat={true}
            userId={undefined}
            overlayText={`+${notShownMembersAmount}`}
          />
        )}
        {!isUserMember && isShowProspect && (
          <ProspectiveProfilePicture>{prospectText}</ProspectiveProfilePicture>
        )}
      </MembersRow>
    </Container>
  );
};

RoundMemberList.propTypes = {
  captain: UserShape.isRequired,
  isUserMember: PropTypes.bool,
  isShowProspect: PropTypes.bool,
  limit: PropTypes.number,
  members: PropTypes.arrayOf(UserShape).isRequired,
  mini: PropTypes.bool,
  showBadges: PropTypes.bool,
  style: PropTypes.object,
  userId: PropTypes.string,
  userProfilePictureUpdated: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(VARIANT)),
};

export default RoundMemberList;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

function profilePictureTransforms(mini) {
  let css = '';
  const firstTranslateX = mini ? -3 : -4;
  for (let i = 2; i <= 10; i++) {
    css += `&:nth-child(${i}) { transform: translateX(${firstTranslateX * (i - 1)}px); }`;
  }
  return css;
}

const MembersRow = styled.div`
  display: flex;
  flex-direction: row;

  > ${PictureWrapper}:not(:first-child) > ${PictureContainer}, > ${ProspectiveProfilePicture} {
    clip-path: url(#pacman);
  }

  > ${PictureWrapper}, > ${ProspectiveProfilePicture} {
    ${({ mini }) => profilePictureTransforms(mini)}
  }
`;

const clipPath = `
M${17.2579418 / 34},
0 C${26.6467825 / 34},
0 34,
${7.61115925 / 34} 34,
${17 / 34}
C34,
${26.3888407 / 34} ${26.6467825 / 34},
34 ${17.2579418 / 34},
34 C${11.8971065 / 34},
34 ${7.11582658 / 34},
${31.5186285 / 34} ${4 / 34},
${27.6417834 / 34}
C${6.05678811 / 34},
${24.6056629 / 34} ${7.2579418 / 34},
${20.9431037 / 34} ${7.2579418 / 34},
${17 / 34} C${7.2579418 / 34},
${13.0568963 / 34} ${6.05678811 / 34},
${9.39433712 / 34} ${4.00041762 / 34},
${6.35825926 / 34}
C${7.11582658 / 34},
${2.48137151 / 34} ${11.8971065 / 34},
0 ${17.2579418 / 34},
0 Z`;

const ClipPath = () => (
  <svg height="0" width="0">
    <defs>
      <clipPath id="pacman" clipPathUnits="objectBoundingBox">
        <path d={clipPath} />
      </clipPath>
    </defs>
  </svg>
);

const Badge = styled.img`
  width: 15px;
  height: 15px;
`;

const CaptainBadge = styled(Badge).attrs(() => ({
  src: badgeCaptain,
}))``;

const UserBadge = styled(Badge).attrs(() => ({
  src: badgeIsMember,
}))``;
