import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { Link } from 'react-router-dom';
import { Card } from '../components/card';
import { h1LeftNegative, h2LeftNegative } from '../styles/text';
import { theme } from '../theme';
import elements from '../styles/elements';

const BlogArticleCard = ({ article }) => {
  const articleTheme = theme(article.theme ? article.theme : 'gray');
  return (
    <ThemeProvider theme={articleTheme}>
      <Link to={`/blog/${article.slug}`}>
        <Card
          bgImage={article.image + '?width=' + elements.containerMaxWidthPx}
          bgSize="cover"
          smallShadow
        >
          <CardContents>
            <Row>
              <Title>{article.title}</Title>
            </Row>
            <Row>
              <Category>{article.category}</Category>
            </Row>
          </CardContents>
        </Card>
      </Link>
    </ThemeProvider>
  );
};

BlogArticleCard.propTypes = {
  article: PropTypes.object,
};

export default BlogArticleCard;

const Row = styled.div``;

const Title = styled.h1`
  ${h1LeftNegative};
  margin: 0;
`;

const Category = styled.p`
  ${h2LeftNegative};
  margin-top: ${elements.appPaddingPx}px;
`;

const CardContents = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 260px;
`;
