import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import bg from '../../assets/images/wizard-bg-white-bot.svg';
import { h1LeftPositive } from '../../styles/text';
import Button from '../button/Button';

const Banner = ({ title, href, action }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Link to={href}>
        <Button primary>{action}</Button>
      </Link>
    </Container>
  );
};

Banner.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
};

export default Banner;

const Container = styled.div`
  padding: 40px 15px;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-position: bottom center;
  text-align: center;
`;

const Title = styled.h1`
  ${h1LeftPositive};
  text-align: center;
  margin: 0px 0 20px 0;
`;
