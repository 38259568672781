import React, { useRef, useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useMessageGetter } from 'react-message-context';
import { WizardHeading, WizardInputSecretCode } from '../../../../components/wizard';
import {
  AbsoluteWizardContentContainer,
  AbsoluteWizardHeaderContainer,
  RelativeWizardStepContent,
} from '../../../../components/wizard/container/PositionedWizardContainers';
import { ModalContent } from '../../../../components/modal/ModalFullscreen';
import WizardContentButton from '../../../../components/wizard/form/WizardContentButton';
import Center from '../../../../components/container/Center';
import { postVerifySignUp } from '../../../../functionalities/apis';
import P from '../../../../components/texts/paragraphs/P';
import TextButton from '../../../../components/button/TextButton';
import Appear from '../../../../components/transition/Appear';
import Box from '../../../../components/container/Box';
import { interpolate } from '../../../../util/text';
import BoldSpan from '../../../../components/texts/BoldSpan';
import ModalSelect from '../../../../components/modal/ModalSelect';

const VerifyStep = ({
  authToken,
  authValue,
  counter,
  onBack,
  onChange,
  onRequestNewCode,
  onChangeAuthType,
  onSuccess,
  secret,
  type,
}) => {
  const messages = useMessageGetter(`pages.wizards.auth.step_3_verify.${type}`);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showRequestNewCodeSelect, setShowRequestNewCodeSelect] = useState(false);
  const submitButtonRef = useRef(null);
  const secretInputRef = useRef(null);

  const onVerify = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await postVerifySignUp({ authToken, secret });
      onSuccess(response);
    } catch (err) {
      setLoading(false);
      console.log('Error verifying', err);
      const errorCode = err?.response?.data?.code || 'UNKNOWN';
      switch (errorCode) {
        case 'INCORRECT_SECRET':
          setError({
            code: errorCode,
            text: messages('error.incorrect_secret'),
          });
          break;
        case 'TOO_MANY_TRIES':
          setError({
            code: errorCode,
            text: messages('error.too_many_tries'),
          });
          break;
        case 'NO_SIGN_UP':
        case 'INVALID_TOKEN':
          setError({
            code: errorCode,
            text: messages('error.reset_auth'),
          });
          break;
        default:
          setError({
            code: errorCode,
            text: messages('error.unknown'),
          });
      }
    }
  };

  // Auto submit when secret length is sufficient
  useEffect(() => {
    if (secret.length >= 4 && submitButtonRef.current) {
      submitButtonRef.current.click();
    }
  }, [secret, submitButtonRef]);

  const reset = () => {
    onChange('');
    if (secretInputRef.current) {
      secretInputRef.current.focus();
    }
  };

  const secondsToRequestNewCode = 20 - counter;

  const requestNewCodeOptions = [
    {
      value: 'resend',
      name: messages('new_code.resend', { value: authValue }),
      disabled: false,
    },
    {
      value: 'phoneNumber',
      name: messages('new_code.phoneNumber'),
      disabled: false,
    },
    {
      value: 'email',
      name: messages('new_code.email'),
      disabled: false,
    },
  ];

  const doRequestNewCode = async () => {
    try {
      reset();
      await onRequestNewCode();
    } catch (err) {
      setError({
        text: messages('error.unknown'),
      });
    }
  };

  const onModalSelect = ({ value }) => {
    if (value === type) {
      onBack();
    } else if (value === 'resend') {
      doRequestNewCode();
    } else {
      onChangeAuthType();
    }
    setShowRequestNewCodeSelect(false);
  };

  return (
    <Fragment>
      {showRequestNewCodeSelect && (
        <ModalSelect
          x={2}
          title={messages('new_code.title')}
          options={requestNewCodeOptions}
          onChange={onModalSelect}
          onCancel={() => setShowRequestNewCodeSelect(false)}
        />
      )}

      <RelativeWizardStepContent>
        <AbsoluteWizardHeaderContainer>
          <WizardHeading
            title={messages('title')}
            subtitle={[
              messages('subtitle', { value: authValue }),
              <TextButton onClick={onBack}>{messages('back')}</TextButton>,
            ]}
          />
        </AbsoluteWizardHeaderContainer>
        <AbsoluteWizardContentContainer>
          <ModalContent>
            <Center>
              <WizardInputSecretCode
                value={secret}
                onChange={onChange}
                disabled={loading}
                ref={secretInputRef}
              />
            </Center>
            <WizardContentButton
              ref={submitButtonRef}
              onClick={onVerify}
              loading={loading}
              text={messages('continue')}
            />
            {error && error.code === 'INCORRECT_SECRET' && (
              <P isCenter>
                {error.text}{' '}
                {<TextButton onClick={reset}>{messages('error.try_again')}</TextButton>}
              </P>
            )}
            {error && error.code !== 'INCORRECT_SECRET' && (
              <P isCenter textColor="fatal">
                {error.text}
              </P>
            )}
            {counter >= 5 && counter < 20 && (
              <Appear>
                <P isCenter textColor="weak">
                  {interpolate(messages('request_timeout'), {
                    $1: <BoldSpan>{secondsToRequestNewCode}</BoldSpan>,
                  })}
                </P>
              </Appear>
            )}
            {counter >= 20 && (
              <Appear>
                <Box margin="15px 0 0 0">
                  <Center>
                    <TextButton onClick={() => setShowRequestNewCodeSelect(true)}>
                      {messages('request_new')}
                    </TextButton>
                  </Center>
                </Box>
              </Appear>
            )}
          </ModalContent>
        </AbsoluteWizardContentContainer>
      </RelativeWizardStepContent>
    </Fragment>
  );
};

VerifyStep.propTypes = {
  authValue: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
  counter: PropTypes.number.isRequired,
  onBack: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeAuthType: PropTypes.func.isRequired,
  onRequestNewCode: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  secret: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['phoneNumber', 'email']).isRequired,
};

export default VerifyStep;
