import React from 'react';
import Icon from './Icon';

// eslint-disable-next-line max-len
const path =
  'M17 15.94l5.721-5.72a.75.75 0 0 1 1.061 1.06L18.062 17l5.72 5.721a.75.75 0 0 1-1.06 1.061L17 18.062l-5.72 5.72a.75.75 0 0 1-1.061-1.06L15.94 17l-5.72-5.72a.75.75 0 0 1 1.06-1.061L17 15.94z';

const CloseIcon = () => <Icon path={path} />;

export default CloseIcon;
