import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import elements from '../../../styles/elements';
import { StyledButton } from '../../button/Button';

const FixedWizardFooter = ({ align = 'center', children = [] }) => (
  <Outer>
    <FixedWizardFooterContainer align={align}>
      <FullPageWrapper childrenLength={children.length}>{children}</FullPageWrapper>
    </FixedWizardFooterContainer>
  </Outer>
);

FixedWizardFooter.propTypes = {
  align: PropTypes.oneOf(['flex-start', 'flex-end', 'space-between', 'space-around', 'center']),
  children: PropTypes.node,
};

export default FixedWizardFooter;

const Outer = styled.div`
  display: flex;
  justify-content: center;
`;

const FixedWizardFooterContainer = styled.div`
  box-sizing: border-box;
  min-height: ${elements.wizardFooterHeightPx}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  background-image: ${props => props.theme.wizard.bgFooter};
  background-color: ${props => props.theme.wizard.bgFooter};
  position: fixed;
  z-index: 250;
  bottom: 0;
`;

const FullPageWrapper = styled.div.attrs(props => ({
  style: {
    justifyContent: props.childrenLength > 1 ? 'space-between' : 'center',
  },
}))`
  max-width: ${elements.containerMaxWidthPx}px;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0 15px;

  ${props =>
    props.childrenLength > 1 &&
    `
      & ${StyledButton} {
        width: auto;
        padding: 0 15px;
      }
    `}
`;
