import styled from 'styled-components';
import elements from '../../../styles/elements';

const WizardStepContent = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  box-sizing: border-box;
  padding-bottom: ${elements.wizardFooterHeightPx}px;
`;

export default WizardStepContent;
