const KEYS = ['joinGroupQuestionnaire', 'deferredAction', 'hasSeenTour', 'invites'];

class Storage {
  add(key, data, expiry) {
    const item = { data, expiry };
    if (!KEYS.includes(key)) {
      console.error('Invalid key provided for storage, when adding: ', key);
    }
    window.localStorage[key] = JSON.stringify(item);
  }
  remove(key) {
    if (!KEYS.includes(key)) {
      console.error('Invalid key provided for storage, when removing: ', key);
    }
    delete window.localStorage[key];
  }
  get(key) {
    if (!KEYS.includes(key)) {
      console.error('Invalid key provided for storage, when getting: ', key);
    }
    const item = window.localStorage[key] ? JSON.parse(window.localStorage[key]) : null;

    if (!item) {
      return null;
    } else {
      if (item.expiry) {
        const expDate = new Date(item.expiry);
        if (new Date() > expDate) {
          return null;
        }
      }

      return item.data;
    }
  }
}

export const storage = new Storage();
