import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import API from '@aws-amplify/api-rest';
import { pCenter } from '../../styles/text';
import config from '../../config';

// Get an upload presigned POST info from lambda
// Image is uploaded to S3
// S3 has an event which clears all resized images when object is created at /uploads folder (original image is updated)
// Poll when one resized image has been updated (created again from the new uploaded file)
const FileUploader = ({
  children,
  label,
  onUploaded,
  onStartUpload = () => {},
  uploadId,
  type,
}) => {
  const onChange = async e => {
    onStartUpload();
    const file = e.target.files[0];
    const init = {
      body: {
        name: file.name,
        groupId: type === 'group' ? uploadId : undefined,
        type,
      },
    };
    const uploadInfo = await API.post('apigateway', '/profile/pictureUploadUrl', init);
    try {
      // original time of one resized image
      const bucketUrl = config[process.env.REACT_APP_ENV].profileBucket.url;
      const imageReadyUrl = `${bucketUrl}/140x140/${uploadId}`;
      const currentPictureLastModified = await loadPictureLastModified(imageReadyUrl);

      await uploadFile(uploadInfo, file);

      try {
        // poll new time of resized image
        const isImageUpdated = async lastModified => {
          const newModified = await loadPictureLastModified(imageReadyUrl);
          return lastModified !== newModified;
        };
        await poll(async () => await isImageUpdated(currentPictureLastModified), 5000, 500);
      } catch (err) {
        console.debug('Error while polling image updated', err);
      }

      onUploaded();
    } catch (err) {
      console.error('Error uploading file', err);
    }
  };

  const fileInputRef = React.createRef();
  const selectFile = () => {
    fileInputRef.current.click();
  };
  const trySelectFile = e => {
    if (e.key === ' ' || e.key === 'Enter') {
      selectFile();
    }
  };

  return (
    <div
      style={{ marginTop: '10px', marginBottom: '30px', cursor: 'pointer' }}
      onClick={selectFile}
      onKeyDown={trySelectFile}
      role="button"
      tabIndex="0"
    >
      <form>
        {children}
        <UploadLabel htmlFor="profilepic">{label}</UploadLabel>
        <input
          ref={fileInputRef}
          type="file"
          name="profilepic"
          accept="image/png, image/jpeg"
          style={{ display: 'none' }}
          onChange={onChange}
        />
      </form>
    </div>
  );
};

FileUploader.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string.isRequired,
  onUploaded: PropTypes.func.isRequired,
  onStartUpload: PropTypes.func.isRequired,
  uploadId: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['group', 'user']),
};

export default FileUploader;

const UploadLabel = styled.label`
  ${pCenter};
`;

const uploadFile = (presignedPostData, file) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    Object.keys(presignedPostData.fields).forEach(key => {
      formData.append(key, presignedPostData.fields[key]);
    });

    // Actual file has to be appended last.
    formData.append('file', file);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', presignedPostData.url, true);
    xhr.send(formData);
    xhr.onload = function () {
      this.status === 204 ? resolve() : reject(this.responseText);
    };
  });
};

function loadPictureLastModified(src) {
  return new Promise((resolve, reject) => {
    const client = new XMLHttpRequest();
    client.open('GET', src, true);
    client.send();
    client.onreadystatechange = function () {
      if (this.readyState === this.HEADERS_RECEIVED) {
        const lastModified = client.getResponseHeader('Last-Modified');
        client.abort();
        resolve(lastModified);
      }
    };
    client.onerror = error => {
      resolve();
    };
  });
}

function poll(fn, timeout, interval) {
  const endTime = Number(new Date()) + (timeout || 2000);
  interval = interval || 100;

  const checkCondition = function (resolve, reject) {
    const result = fn();
    result.then(function (response) {
      // If the condition is met, we're done!
      if (response) {
        resolve(response);
      }
      // If the condition isn't met but the timeout hasn't elapsed, go again
      else if (Number(new Date()) < endTime) {
        setTimeout(checkCondition, interval, resolve, reject);
      }
      // Didn't match and too much time, reject!
      else {
        reject(new Error('timed out for ' + fn + ': ' + arguments));
      }
    });
  };

  return new Promise(checkCondition);
}
