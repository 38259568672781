import React from 'react';
import PropTypes from 'prop-types';
import { FeedItemContainer, Left, Main } from './FeedItemPrimitives';
import { useMessageGetter } from 'react-message-context';
import ProfilePicture, { SIZES } from '../picture/ProfilePicture';
import Post, { POST_TYPE } from './Post';
import { UserShape } from '../../util/shapes';

const SimpleFeedItem = ({ user, text, placeholder, onClick }) => {
  const messages = useMessageGetter();
  const isPlaceholder = !text;
  return (
    <FeedItemContainer onClick={onClick}>
      <Left>
        <ProfilePicture userId={user.userId} size={SIZES.MINI} flat />
      </Left>
      <Main>
        <Post
          type={isPlaceholder ? POST_TYPE.PLACEHOLDER : POST_TYPE.POST}
          author={user.givenName}
          authorTitle={messages('components.feed.comments.captain')}
          text={text || placeholder}
          iconType={onClick && 'arrowRightPositive'}
        />
      </Main>
    </FeedItemContainer>
  );
};

SimpleFeedItem.propTypes = {
  user: UserShape.isRequired,
  text: PropTypes.string,
  placeholder: PropTypes.string,
  onClick: PropTypes.func,
};

export default SimpleFeedItem;
