export const RestrictedCallCodes = [
  "+1",
  "+90",
];

export function canSendSMS(phoneNumber) {
  if (!phoneNumber) {
    return false;
  }

  for (let callCode of RestrictedCallCodes) {
    if (phoneNumber.startsWith(callCode)) {
      return false;
    }
  }

  return true;
}
