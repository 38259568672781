import { useEffect } from 'react';

/**
 * Add listener for key events
 */
export const useKeyPress = (key, onKeyPress) => {
  useEffect(() => {
    const eventListener = e => {
      if (e.key === key) {
        onKeyPress();
      }
    };
    window.addEventListener('keydown', eventListener);
    return () => {
      window.removeEventListener('keydown', eventListener);
    };
  }, [key, onKeyPress]);
};
