import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMessageGetter } from 'react-message-context';
import { WizardHeading, WizardInputText } from '../../../../components/wizard';
import {
  AbsoluteWizardContentContainer,
  AbsoluteWizardHeaderContainer,
  RelativeWizardStepContent,
} from '../../../../components/wizard/container/PositionedWizardContainers';
import { ModalContent } from '../../../../components/modal/ModalFullscreen';
import WizardContentButton from '../../../../components/wizard/form/WizardContentButton';
import FlagIcon from '../../../../components/icons/FlagIcon';
import { composePhoneNumber } from '../../../../util/user';
import P from '../../../../components/texts/paragraphs/P';
import { Auth } from 'isomorphic';
import { interpolate } from '../../../../util/text';
import TextButton from '../../../../components/button/TextButton';

const AuthStep = ({
  disabled,
  show,
  type,
  value,
  onChange,
  onChangeAuthType,
  onInitSignUp,
  onShowCallCodeSelect,
  onForward,
}) => {
  const messages = useMessageGetter(`pages.wizards.auth.step_2_auth.${type}`);
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState(undefined);
  const [unknownErrorText, setUnknownErrorText] = useState(undefined);
  const [validate, setValidate] = useState(false);

  useEffect(() => {
    if (type === 'phoneNumber') {
      if (!Auth.canSendSMS(`+${value.countryCallCode}`)) {
        setErrorText(messages('error.restricted'));
      }
    }
  }, [type, value?.countryCallCode, messages]);

  // Reset error if type is changed
  useEffect(() => {
    setErrorText(undefined);
  }, [type]);

  if (!show) {
    return null;
  }

  const sendVerification = async () => {
    if ((type === 'phoneNumber' && !value.phoneNumber) || (type === 'email' && !value)) {
      setValidate(true);
      return;
    }
    setLoading(true);
    try {
      const { authToken } = await onInitSignUp({
        type,
        value:
          type === 'phoneNumber'
            ? composePhoneNumber(value.countryCallCode, value.phoneNumber)
            : value,
      });
      setLoading(false);
      onForward(authToken);
    } catch (err) {
      setLoading(false);
      if (err?.response?.data?.code === 'INVALID_NUMBER') {
        setErrorText(messages('error.invalid_number'));
      } else if (err?.response?.data?.code === 'INVALID_EMAIL') {
        setErrorText(messages('error.invalid_email'));
      } else {
        setUnknownErrorText(messages('error.unknown'));
      }
    }
  };

  const restricted = type === 'phoneNumber' && !Auth.canSendSMS('+' + value.countryCallCode);

  return (
    <RelativeWizardStepContent>
      <AbsoluteWizardHeaderContainer>
        <WizardHeading title={messages('title')} subtitle={messages('subtitle')} />
      </AbsoluteWizardHeaderContainer>
      <AbsoluteWizardContentContainer>
        <ModalContent>
          {type === 'phoneNumber' && (
            <WizardInputText
              icon={<FlagIcon countryCode={value.countryCode} />}
              iconText={`+${value.countryCallCode}`}
              iconAction={onShowCallCodeSelect}
              name="phonenumber"
              type="tel"
              label={messages('input.label')}
              placeholder={messages('input.placeholder')}
              value={value.phoneNumber}
              onChange={onChange}
              onSubmit={sendVerification}
              required={validate}
              errorText={errorText}
            />
          )}
          {type === 'email' && (
            <WizardInputText
              name="email"
              type="email"
              label={messages('input.label')}
              placeholder={messages('input.placeholder')}
              value={value}
              onChange={onChange}
              onSubmit={sendVerification}
              required={validate}
              errorText={errorText}
            />
          )}
          <WizardContentButton
            onClick={sendVerification}
            loading={loading}
            disabled={disabled || restricted}
            text={messages('forward')}
          />
          {unknownErrorText && (
            <P isCenter textColor="fatal">
              {unknownErrorText}
            </P>
          )}
          {restricted && (
            <P isCenter>
              {interpolate(messages('restricted.info'), {
                $1: (
                  <TextButton onClick={onChangeAuthType}>{messages('restricted.link')}</TextButton>
                ),
              })}
            </P>
          )}
        </ModalContent>
      </AbsoluteWizardContentContainer>
    </RelativeWizardStepContent>
  );
};

AuthStep.propTypes = {
  disabled: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['phoneNumber', 'email']).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      countryCode: PropTypes.string,
      countryCallCode: PropTypes.string,
      phoneNumber: PropTypes.string,
    }),
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeAuthType: PropTypes.func.isRequired,
  onInitSignUp: PropTypes.func.isRequired,
  onShowCallCodeSelect: PropTypes.func.isRequired,
  onForward: PropTypes.func.isRequired,
};

export default AuthStep;
