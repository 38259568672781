import styled from 'styled-components';

const Box = styled.div.attrs(props => ({
  style: {
    ...(props.margin ? { margin: props.margin } : {}),
    ...(props.row ? RowStyles : {}),
    ...(props.centered && props.row ? { alignItems: 'center' } : {}),
    ...(props.hidden ? { display: 'none' } : {}),
    ...(props.style || {}),
  },
}))``;

export default Box;

const RowStyles = {
  display: 'flex',
  flexDirection: 'row',
};
