import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const Appear = ({ y = 5, children }) => <AppearContainer y={y}>{children}</AppearContainer>;

Appear.propTypes = {
  y: PropTypes.number,
  children: PropTypes.node,
};

export default Appear;

const AppearKeyframes = y => keyframes`
  from {
    transform: translateY(${y}px);
    opacity: 0;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
`;

const AppearContainer = styled.div`
  animation-duration: 300ms;
  animation-name: ${props => AppearKeyframes(props.y)};
  animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;
