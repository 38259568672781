import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import GroupCard from '../components/data-cards/GroupCard';
import navigation from '../functionalities/navigation';
import Middle from '../components/middle/Middle';
import PathBar from '../components/top/pathBar/PathBar';
import { useMessageGetter } from 'react-message-context';
import { ThemeProvider } from 'styled-components';
import { theme } from '../theme';
import BlogArticleCard from '../blog/BlogArticleCard';
import moment from 'moment';
import { useApp } from '../context/appContext';
import { loadChallenges, loadProfileData, loadStats } from '../context/actions';
import Section from '../components/container/Section';
import ChallengeCard from '../components/data-cards/ChallengeCard';
import { mapProfileGroupToGroupCard } from '../context/mappers';
import TourBanner from '../components/banner/TourBanner';
import { getName } from '../util/user';
import { compareString } from '../util/common';
import { useTrackPageView } from '../hooks/useTrackPageView';
import CenteredContent from '../components/container/CenteredContent';
import Box from '../components/container/Box';
import { sortArticles } from '../blog/queries';
import Footer from '../fragments/layout/Footer';
import WaveHeader from '../components/headers/WaveHeader';
import { Card, CardValuesRow } from '../components/card';
import CardItem from '../components/card/CardItem';
import { Groups } from 'isomorphic';
import LoginBanner from '../components/banner/LoginBanner';
import Add2HSBanner from '../components/banner/Add2HSBanner';
import { getPendingInvitations } from '../functionalities/invitations';
import SmallGroupInviteCard from '../components/data-cards/SmallGroupInviteCard';
import { getCMSArticles } from '../functionalities/apis';

const thisPage = 'home';
const RANDOM_MSG_AMOUNT = 7;

const HomePage = ({ exiting, wrapper: Wrapper }) => {
  if (!exiting) navigation.setPage(thisPage);
  const locale = 'en-US';
  const messages = useMessageGetter();
  const [state, dispatch] = useApp();
  const [articlesData, setArticlesData] = useState(null);

  useEffect(() => {
    loadStats(dispatch);
  }, [dispatch]);
  useEffect(() => {
    loadChallenges(dispatch);
  }, [dispatch]);
  useEffect(() => {
    loadProfileData(dispatch);
  }, [dispatch]);
  useTrackPageView();

  useEffect(() => {
    async function load() {
      const response = await getCMSArticles(locale);
      setArticlesData(response.data);
    }

    load();
  }, [setArticlesData, locale]);

  const { user, isLoadedStats, isLoadedChallenges, isLoadedProfile } = state;
  //const { data: articlesData } = useQuery(GET_ARTICLES, { variables: { locale } });
  const contentReady = isLoadedStats && isLoadedChallenges && isLoadedProfile;

  if (!contentReady) return <Wrapper>{null}</Wrapper>;

  const [title, subtitle] = getTitles(messages, user);
  const groups = Object.values(state.profile.groups);
  const challenges = state.challenges
    .filter(challenge => challenge.active)
    // No challenges which user has attended
    .filter(challenge => !groups.some(group => group.challenge.id === challenge.id));

  const hasActiveGroups = groups.some(Groups.isGroupActive);
  const canAdd2HS = !state.isStandalone && state.beforeInstallEvent !== null;
  const isLoggedIn = user && user.isLoggedIn;
  const onAdd2HS = canAdd2HS ? state.beforeInstallEvent.prompt : () => {};

  // highlight blog article
  const article =
    articlesData &&
    articlesData.listBlogPosts.data
      .filter(a => a.appHighlight)
      .sort(sortArticles)
      .find(_ => _);

  const invitations = getPendingInvitations(groups);

  return (
    <Wrapper>
      <ThemeProvider theme={theme('white')}>
        <PathBar showLogo pageTitle="" betaStamp hideOnWideScreen />
        <Middle>
          <WaveHeader title={title} subtitle={subtitle} />
          <CenteredContent>
            <Box margin="20px 0 0 0">
              <Section title={messages('pages.home.stats.title')} show={true}>
                <Card spacing={'dense'} flat={false}>
                  <CardValuesRow>
                    <CardItem
                      label={messages('pages.home.stats.reductionKg24h.title')}
                      value={state.stats.reductionKg24h.getScaledValue().value}
                      valueUnit={state.stats.reductionKg24h.getScaledValue().unitAbbr}
                      description={messages('pages.home.stats.reductionKg24h.description')}
                    />
                    <CardItem
                      label={messages('pages.home.stats.reductionKgAlltime.title')}
                      value={state.stats.totalReductionKg.getScaledValue().value}
                      valueUnit={state.stats.reductionKg24h.getScaledValue().unitAbbr}
                      description={messages('pages.home.stats.reductionKgAlltime.description')}
                    />
                  </CardValuesRow>
                </Card>
              </Section>
            </Box>

            {/* Group invitations */}
            <Box margin="70px 0 0 0" hidden={invitations.length === 0}>
              <Section title={messages('pages.home.section.invitations.title')} show>
                {invitations.map(group => (
                  <ThemeProvider theme={theme(group.theme)} key={group.id}>
                    <SmallGroupInviteCard group={group} />
                  </ThemeProvider>
                ))}
              </Section>
            </Box>

            {/* Actions: user not logged in */}
            <Box margin="70px 0 0 0" hidden={isLoggedIn} row>
              <Box margin="0 8px 0 0" style={{ flex: 1 }}>
                <LoginBanner />
              </Box>
              <Box margin="0 0 0 8px" style={{ flex: 1 }}>
                <TourBanner />
              </Box>
            </Box>

            {/* Actions: user logged in, can add to homescreen but has not yet done so */}
            <Box margin="70px 0 0 0" hidden={!(isLoggedIn && canAdd2HS)} row>
              <Box margin="0 8px 0 0" style={{ flex: 1 }}>
                <Add2HSBanner onClick={onAdd2HS} />
              </Box>
              <Box margin="0 0 0 8px" style={{ flex: 1 }}>
                <TourBanner />
              </Box>
            </Box>

            {/* Actions: user logged in and also added to homescreen */}
            <Box margin="70px 0 0 0" hidden={!(isLoggedIn && !canAdd2HS)}>
              <TourBanner fullWidth />
            </Box>

            <Box margin="70px 0 0 0" hidden={!hasActiveGroups}>
              <Section
                title={messages('pages.home.section.activeGroups.title')}
                show={hasActiveGroups}
              >
                {getGroups(state.profile).map(group => (
                  <ThemeProvider theme={theme(group.theme)} key={group.id}>
                    <Link to={`/challenges/${group.challengeId}/group/${group.id.substring(6)}`}>
                      <GroupCard
                        groupId={group.id}
                        groupName={
                          group.name ||
                          messages('data.group.defaultName', {
                            captainName: getName(group.captain),
                          })
                        }
                        captain={group.captain}
                        challengeId={group.challengeId}
                        challengeName={messages('general.challenge.csn', { csn: group.csn })}
                        chartData={group.chartData}
                        durationDays={group.durationDays}
                        groupReductionKg={group.groupReductionKg}
                        groupYearlyReductionTargetKg={group.groupYearlyReductionTargetKg}
                        memberCount={group.memberCount}
                        personalReductionKg={group.personalReductionKg}
                        startDate={group.startDate}
                      />
                    </Link>
                  </ThemeProvider>
                ))}
              </Section>
            </Box>

            <Box margin="70px 0 0 0" hidden={challenges.length === 0}>
              <Section
                title={
                  user && user.isLoggedIn
                    ? messages('pages.home.challenges.loggedIn.title')
                    : messages('pages.home.challenges.loggedOut.title')
                }
                show={state.isLoadedChallenges && state.isLoadedProfile && challenges.length > 0}
              >
                {challenges.map((challenge, i) => (
                  <Box margin="0 0 12px 0" key={`challenge-${i}`}>
                    <ChallengeCard challenge={challenge} showStats={false} showInfo={false} />
                  </Box>
                ))}
              </Section>
            </Box>

            <Box margin="70px 0 0 0" hidden={!article}>
              <Section title={messages('pages.home.blog.title')} show={!!article}>
                <BlogArticleCard article={article} />
              </Section>
            </Box>
          </CenteredContent>
        </Middle>
        <Footer />
      </ThemeProvider>
    </Wrapper>
  );
};

HomePage.propTypes = {
  exiting: PropTypes.bool,
  wrapper: PropTypes.func,
};

export default HomePage;

function getTitles(messages, user) {
  if (!user.isLoaded) {
    return [null, null];
  } else if (user.isLoggedIn) {
    const dayOfYear = moment().dayOfYear();
    return [
      messages('pages.home.header.loggedIn.title', { givenName: user.given_name }),
      messages(`pages.home.header.loggedIn.${dayOfYear % RANDOM_MSG_AMOUNT}`, {
        givenName: user.given_name,
      }),
    ];
  } else {
    return [messages('pages.home.header.title'), messages('pages.home.header.desc')];
  }
}

function getGroups(profile) {
  const groups = Object.values(profile.groups)
    .filter(Groups.isGroupActive)
    .map(mapProfileGroupToGroupCard);
  groups.sort((g1, g2) => compareString(g2.startDate, g1.startDate));
  return groups;
}
