import headerBackground from '../assets/images/header-background.svg';
import {
  orbitBg,
  lightBgHeader,
  blueBg,
  generalLightBgTop,
  generalLightBgBottom,
} from '../assets/images';
import { hotWaterBg, dailyCommuteBg } from '../assets/images/illustrations';

const colors = {
  seachangeStandout: '#ffffff',
  seachangeStandoutAlpha30: 'rgba(255, 255, 255, 0.3)',
  seachangeTextAlpha30: 'rgba(87, 87, 87, 0.3)',
  seachangeTextAlpha15: 'rgba(87, 87, 87, 0.15)',
  seachangeTextAlpha5: 'rgba(87, 87, 87, 0.05)',
  brand1Hue1: '#24345a',
  brand1Hue1RGB: '36, 52, 90',
  brand1Hue2: '#262f3f',
  brand1Gradient1: '#1e4982',
  brand1Gradient2: '#141e35',
  brand1Gradient3: '#242a5a',
  brand1Gradient4: '#1c4b83',
  brand2Tint3: '#164a7d',
  brand2Tint3RGB: '22, 74, 125',
  brand2Hue1: '#279de1',
  brand2Hue2: '#0d6ab7',
  brand2Hue3: '#768399',
  brand2Hue4: '#26cdcb',
  brand2Hue4RGB: '38, 205, 203',
  brand2Gradient1: '#187fc9',
  brand2Gradient2: '#08589a',
  brand2Gradient3: '#b1def4',
  brand2Gradient4: '#d6edec',
  brand2Gradient6: '#7ce9c4',
  brand3Hue1: '#36e08b',
  brand3Hue2: '#18c465',
  brand4Hue1: '#ff7761',
  brand4Hue1RGB: '255, 119, 97',
  brand4Hue2: '#ff5e4d',
  brand5Gradient1: '#ff9472',
  brand5Gradient2: '#f2709c',
  theme2Gradient1: '#6862d3',
  theme2Gradient2: '#6143b3',
  theme2Hue2: '#5c38b5',
  theme2Hue2RGB: '92, 56, 181',
  theme2Hue5: '#281757',
  dull1: '#141414',
  dull2: '#333333',
  dull3: '#575757',
  dull3_15: 'rgba(87, 87, 87, 0.15)',
  dull4: '#999999',
  dull5: '#cccccc',
  dull6: '#eeeeee',
  dull7: '#ffffff',
  dull7_15: 'rgba(255, 255, 255, 0.15)',
  dull7_30: 'rgba(255, 255, 255, 0.3)',
  dull8: '#000000',
  soft1: '#e8f2f7',
  soft1_20: 'rgba(232, 242, 247, 0.2)',
  soft2: '#e9f7f0',
  soft3: '#ffe3df',
  soft4: '#fafcfd',
};

const extensions = {
  blue: {
    button: {
      bg: `linear-gradient(110deg, ${colors.brand4Hue1}, ${colors.brand4Hue2})`,
      bgPrimary: `linear-gradient(110deg, ${colors.brand4Hue1}, ${colors.brand4Hue2})`,
      bgSecondary: 'transparent',
      bgDisabled: colors.seachangeStandoutAlpha30,
      text: colors.dull7,
      textPrimary: colors.dull7,
      textSecondary: colors.dull7,
      textFatal: `linear-gradient(110deg, ${colors.brand4Hue1}, ${colors.brand4Hue2})`,
      shadow: 'none',
      shadowDisabled: 'none',
    },
    buttonIcon: {
      bg: colors.brand2Tint3,
      bgRGB: a => `rgba(${colors.brand2Tint3RGB}, ${a})`,
      fill: colors.dull7,
    },
    card: {
      bg: colors.dull7,
      bgGradient: `linear-gradient(213deg, ${colors.brand1Gradient1}, ${colors.brand1Hue1})`,
      bgImage: hotWaterBg,
      shadow: '0 15px 40px -15px rgba(87, 87, 87, 0.35)',
      shadowSmall: `0 3px 15px -2px ${colors.seachangeTextAlpha30}`,
      textPrimary: colors.dull7,
      textSecondary: colors.dull7,
      textTertiary: colors.dull7,
      value: {
        decrease: colors.dull7,
        increase: colors.dull7,
      },
    },
    challengeCard: {
      bgGradient: `linear-gradient(to bottom, ${colors.brand1Gradient1}, ${colors.brand1Gradient2})`,
    },
    header: {
      bgGradient: `linear-gradient(206deg, ${colors.brand1Gradient1}, ${colors.brand1Hue1})`,
      text: colors.dull7,
      bgImage: hotWaterBg,
      bgColor: colors.brand1Hue1,
    },
    inputSecret: {
      errorColor: colors.dull7,
      placeholderColor: colors.dull5,
    },
    lineChart: {
      refLegendColor: colors.dull7,
      lineColor: colors.dull7,
      lineColorIncrease: colors.dull7,
    },
    linkCard: {
      bgImage: blueBg,
      bgGradient: `linear-gradient(to bottom, ${colors.brand1Gradient1}, ${colors.brand1Gradient2})`,
    },
    wizard: {
      bg: `linear-gradient(198deg, ${colors.brand1Gradient1}, ${colors.brand1Hue1})`,
      bgFooter: `rgba(${colors.brand1Hue1RGB}, 0.8)`,
      stepHeaderTextColor: colors.dull7,
      divider: colors.dull7_15,
      bgOpacity: 0.8,
    },
    challenge: {
      bg: `linear-gradient(206deg, ${colors.brand1Gradient1}, ${colors.brand1Hue1})`,
      infoTxtFade: `linear-gradient(to bottom, rgba(255, 255, 255, 0.2), ${colors.dull7})`,
      groupListingBg: `linear-gradient(247deg, ${colors.brand1Gradient1}, ${colors.brand1Hue1})`,
      factAndMathBg: `linear-gradient(256deg, ${colors.brand1Gradient1}, ${colors.brand1Hue1})`,
      factAndMathTableHdr: `linear-gradient(83deg, ${colors.brand2Gradient3}, ${colors.brand2Gradient4})`,
    },
    slider: {
      bgTrack: colors.brand3Hue1,
    },
    select: {
      color: colors.dull7,
      selectedColor: colors.brand3Hue1,
      bg: 'none',
      bgSelected: colors.dull7,
      bgRadioSelected: colors.brand3Hue2,
      bgRadio: 'none',
      colorRadioSelected: colors.dull7,
      shadowSelected: '0 25px 25px -8px rgba(87, 87, 87, 0.3)',
      radioBorder: colors.brand3Hue2,
    },
    groupInfo: {
      bgColor: '#09234c',
      bgPosition: 'left top',
    },
  },
  purple: {
    button: {
      bg: `linear-gradient(110deg, ${colors.brand4Hue1}, ${colors.brand4Hue2})`,
      bgPrimary: `linear-gradient(110deg, ${colors.brand4Hue1}, ${colors.brand4Hue2})`,
      bgSecondary: 'transparent',
      bgDisabled: colors.seachangeStandoutAlpha30,
      text: colors.dull7,
      textPrimary: colors.dull7,
      textSecondary: colors.dull7,
      textFatal: `linear-gradient(110deg, ${colors.brand4Hue1}, ${colors.brand4Hue2})`,
      shadow: 'none',
      shadowDisabled: 'none',
    },
    buttonIcon: {
      bg: colors.theme2Hue2,
      bgRGB: a => `rgba(${colors.theme2Hue2RGB}, ${a})`,
      fill: colors.dull7,
    },
    card: {
      bg: colors.dull7,
      bgGradient: `linear-gradient(245deg, ${colors.theme2Gradient1}, ${colors.theme2Gradient2})`,
      bgImage: dailyCommuteBg,
      shadow: '0 3px 15px -2px rgba(87, 87, 87, 0.3)',
      shadowSmall: `0 3px 15px -2px ${colors.seachangeTextAlpha30}`,
      textPrimary: colors.dull7,
      textSecondary: colors.dull7,
      textTertiary: colors.dull7,
      value: {
        decrease: colors.dull7,
        increase: colors.dull7,
      },
    },
    challenge: {
      bg: `linear-gradient(to bottom, ${colors.theme2Gradient1}, ${colors.theme2Hue5} 100%)`,
      infoTxtFade: `linear-gradient(to bottom, rgba(255, 255, 255, 0.2), ${colors.dull7})`,
      groupListingBg: `linear-gradient(245deg, ${colors.theme2Gradient1}, ${colors.theme2Gradient2})`,
      factAndMathBg: `linear-gradient(256deg, ${colors.brand1Gradient1}, ${colors.brand1Hue1})`,
      factAndMathTableHdr: `linear-gradient(83deg, ${colors.brand2Gradient3}, ${colors.brand2Gradient4})`,
    },
    challengeCard: {
      bgGradient: `linear-gradient(227deg, ${colors.theme2Gradient1} 9%, ${colors.theme2Gradient2} 69%)`,
    },
    groupInfo: {
      bgColor: colors.theme2Hue5,
      bgPosition: 'left top',
    },
    header: {
      bgGradient: `linear-gradient(209deg, ${colors.theme2Gradient2} 5%, ${colors.theme2Gradient1} 69%)`,
      text: colors.dull7,
      bgImage: dailyCommuteBg,
      bgColor: colors.theme2Gradient1,
    },
    inputSecret: {
      errorColor: colors.dull7,
      placeholderColor: colors.dull5,
    },
    lineChart: {
      refLegendColor: colors.dull7,
      lineColor: colors.dull7,
      lineColorIncrease: colors.dull7,
    },
    linkCard: {
      bgImage: dailyCommuteBg,
      bgGradient: `linear-gradient(to bottom, ${colors.theme2Gradient1}, ${colors.theme2Hue5} 100%)`,
    },
    modal: {
      bgImage: `url(${dailyCommuteBg}),
        linear-gradient(201deg, ${colors.theme2Gradient1} 3%, ${colors.theme2Gradient2} 69%)`,
      bgPosition: 'left top, left top',
    },
    select: {
      color: colors.dull7,
      selectedColor: colors.brand3Hue1,
      bg: 'none',
      bgSelected: colors.dull7,
      bgRadioSelected: colors.brand3Hue2,
      bgRadio: 'none',
      colorRadioSelected: colors.dull7,
      shadowSelected: '0 25px 25px -8px rgba(87, 87, 87, 0.3)',
      radioBorder: colors.brand3Hue2,
    },
    smallGroupCard: {
      bgImage: dailyCommuteBg,
      bgGradient: `linear-gradient(to bottom, ${colors.theme2Gradient1}, ${colors.theme2Hue5} 100%)`,
    },
    wizard: {
      bg: `linear-gradient(201deg, ${colors.theme2Gradient1} 3%, ${colors.theme2Gradient2} 69%)`,
      bgFooter: `rgba(${colors.theme2Hue2RGB}, 0.8)`,
      stepHeaderTextColor: `${colors.dull7}`,
      divider: colors.dull7_15,
      bgOpacity: 0.8,
    },
  },
  gray: {
    card: {
      bg: colors.dull3_15,
      bgGradient: `none`,
      shadow: '0 15px 40px -15px rgba(87, 87, 87, 0.35)',
      textPrimary: colors.brand2Hue3,
      textSecondary: colors.brand2Hue3,
      textTertiary: colors.brand2Hue3,
    },
  },
  white: {
    linkCard: {
      bgImage: generalLightBgTop,
      bgSize: 'cover',
    },
    header: {
      bgGradient: `linear-gradient(224deg, ${colors.brand2Hue1}, ${colors.brand2Gradient6})`,
      bgImage: lightBgHeader,
      text: colors.dull7,
    },
    wizard: {
      bg: colors.dull7,
      bgColor: colors.dull7,
      bgFooter: `rgba(255, 255, 255, 0.8)`,
      stepHeaderTextColor: colors.dull2,
      divider: colors.dull3_15,
      bgOpacity: 0.2,
    },
    button: {
      bg: 'transparent',
      bgSecondary: colors.dull7,
      bgDisabled: `rgba(${colors.brand4Hue1RGB}, 0.15)`,
      bgPrimary: colors.brand4Hue1,
      text: colors.dull7,
      textPrimary: colors.dull7,
      textSecondary: colors.dull2,
      textFatal: colors.brand4Hue1,
      shadow: 'none',
      shadowDisabled: 'none',
    },
    select: {
      color: colors.brand1Hue2,
      selectedColor: colors.brand3Hue1,
      bg: 'none',
      bgSelected: 'none',
      bgRadioSelected: colors.brand3Hue2,
      bgRadio: colors.soft1,
      colorRadioSelected: colors.dull7,
      shadowSelected: 'none',
      radioBorder: colors.brand3Hue2,
    },
    text: {
      negative: colors.brand1Hue2,
      positive: colors.brand1Hue2,
      weak: colors.brand2Hue3,
      standout: colors.brand3Hue1,
      placeholder: colors.dull5,
      error: colors.brand4Hue1,
      link: colors.brand2Hue1,
    },
    smallButton: {
      bgColor: colors.dull7,
      bg: {
        primary: `linear-gradient(132deg, ${colors.brand4Hue1}, ${colors.brand4Hue2} 84%)`,
        secondary: `linear-gradient(238deg, ${colors.brand1Gradient1} 105%, ${colors.brand1Hue1} -6%)`,
        positive: 'none',
        disabled: colors.dull3_15,
      },
      color: {
        primary: colors.dull7,
        secondary: colors.dull7,
        positive: colors.brand1Hue2,
      },
    },
  },
  lightBlue: {
    wizard: {
      bg: `linear-gradient(224deg, ${colors.brand2Hue1}, ${colors.brand2Gradient6})`,
      bgFooter: `rgba(${colors.brand2Hue4RGB}, 0.8)`,
      stepHeaderTextColor: `${colors.dull7}`,
      bgOpacity: 0.2,
    },
    buttonIcon: {
      bgRGB: () => 'transparent',
      fill: colors.dull7,
    },
  },
};

const theme = {
  colors,
  fontFamily: 'Barlow, sans-serif',
  primaryFont: 'Barlow, sans-serif',
  secondaryFont: 'Karla, sans-serif',
  button: {
    bg: 'none',
    bgPrimary: `linear-gradient(110deg, ${colors.brand4Hue1}, ${colors.brand4Hue2})`,
    bgSecondary: 'transparent',
    bgDisabled: colors.seachangeStandoutAlpha30,
    textPrimary: colors.dull7,
    text: colors.dull7,
    textFatal: `linear-gradient(110deg, ${colors.brand4Hue1}, ${colors.brand4Hue2})`,
  },
  buttonIcon: {
    bg: colors.soft1,
    bgRGB: () => colors.soft1,
    fill: colors.brand1Hue2,
  },
  footer: {
    bg: colors.brand2Hue3,
    text: colors.dull7,
  },
  loadingDots: {
    negative: colors.dull7,
    positive: colors.dull2,
  },
  smallButton: {
    bgColor: colors.dull7,
    bg: {
      primary: `linear-gradient(132deg, ${colors.brand4Hue1}, ${colors.brand4Hue2} 84%)`,
      secondary: `linear-gradient(238deg, ${colors.brand1Gradient1} 105%, ${colors.brand1Hue1} -6%)`,
      positive: 'none',
      disabled: colors.dull7_30,
    },
    color: {
      primary: colors.dull7,
      secondary: colors.dull7,
      positive: colors.brand1Hue2,
    },
  },
  textButton: {
    color: colors.brand2Hue1,
  },
  card: {
    bg: colors.dull7,
    bgGradient: 'none',
    bgImage: 'none',
    shadow: '0 15px 40px -15px rgba(87, 87, 87, 0.35)',
    shadowSmall: `0 3px 15px -2px ${colors.seachangeTextAlpha30}`,
    textPrimary: colors.brand3Hue1,
    textSecondary: colors.dull2,
    textTertiary: colors.dull4,
    value: {
      decrease: colors.brand3Hue1,
      increase: colors.brand4Hue1,
    },
  },
  tourBanner: {
    bgGradient: `linear-gradient(250deg, ${colors.brand2Hue1} 1%, ${colors.brand2Gradient6})`,
    color: colors.dull7,
  },
  loginBanner: {
    color: colors.brand1Hue2,
  },
  challengeCard: {},
  elements: {
    boxShadow: '0px 10px 20px 0px rgba(168,168,168,1)',
    background_water: headerBackground,
    pathBarBackgroundImage: `linear-gradient(${colors.brand1Hue1},
      ${colors.brand1Hue1},
      ${colors.seachangeTextAlpha15})`,
  },
  header: {
    bgImage: hotWaterBg,
    bgColor: colors.brand1Hue1,
  },
  input: {
    checkbox: {
      bgColor: colors.soft2,
      bgColorSelected: colors.brand3Hue2,
    },
    text: {
      bgColor: colors.dull7,
      borderColor: colors.soft1,
      bgColorDisabled: colors.dull6,
      bgColorSecondary: colors.soft4,
      color: colors.brand1Hue2,
      colorDisabled: colors.dull5,
    },
  },
  inputSecret: {
    errorColor: colors.brand4Hue1,
    placeholderColor: colors.dull5,
  },
  lineChart: {
    refLegendColor: colors.dull4,
    lineColor: colors.brand3Hue1,
    lineColorIncrease: colors.brand4Hue1,
  },
  linkCard: {},
  text: {
    negative: colors.dull7,
    positive: colors.brand1Hue2,
    weak: colors.brand2Hue3,
    standout: colors.brand3Hue1,
    error: colors.brand4Hue1,
    link: colors.brand2Hue1,
  },
  message: {
    read: colors.brand2Hue3,
    divider: colors.dull4,
    newIndicator: colors.brand4Hue1,
  },
  modal: {
    bgImage: `url(${generalLightBgTop}), url(${generalLightBgBottom})`,
    bgPosition: `top right, bottom left`,
    select: {
      option: {
        bgColor: colors.dull7,
        color: colors.brand1Hue2,
        cancelColor: colors.brand4Hue1,
        disabledColor: colors.dull5,
        borderColor: colors.seachangeTextAlpha15,
      },
    },
  },
  successIndicator: {
    full: {
      border: colors.brand1Hue1,
      fill: colors.brand1Hue1,
    },
    half: {
      border: colors.brand1Hue1,
      fill: colors.dull7,
      halfFill: colors.brand2Hue1,
    },
    none: {
      border: colors.brand1Hue1,
      fill: colors.dull7,
    },
    unknown: {
      border: colors.dull5,
      fill: colors.dull7,
    },
    active: {
      outerBg: 'rgba(13, 105, 181, 0.15)',
      innerBg: 'rgba(13, 105, 181, 0.3)',
      innerShadow: `0 0 1px 0 ${colors.dull7}`,
    },
  },
  contentWithSpinner: {
    loadingDotsBgImage: `linear-gradient(224deg, ${colors.brand2Hue1}, ${colors.brand2Gradient6})`,
  },
  smallGroupCard: {
    bgImage: blueBg,
    bgGradient: `linear-gradient(33deg, ${colors.brand1Hue1} -17%, ${colors.brand1Gradient1} 116%)`,
  },
  notification: {
    bg: {
      read: colors.dull7,
      unread: colors.soft1,
    },
    border: colors.soft1,
    textSecondary: colors.brand2Hue3,
  },
  infoBox: {
    bgColor: colors.soft1,
  },
  waveHeader: {
    bgGradient: `linear-gradient(43deg, ${colors.brand1Gradient3} 2%, ${colors.brand1Gradient4} 99%)`,
    bgImage: orbitBg,
    bgPosition: 'left -425px, left top',
    bgRepeat: 'no-repeat',
    text: colors.dull7,
  },
};

const getTheme = type => {
  switch (type) {
    case 'blue':
      return {
        ...theme,
        ...extensions.blue,
      };
    case 'gray':
      return {
        ...theme,
        ...extensions.gray,
      };
    case 'white':
      return {
        ...theme,
        ...extensions.white,
      };
    case 'purple':
      return {
        ...theme,
        ...extensions.purple,
      };
    case 'lightBlue':
      return {
        ...theme,
        ...extensions.blue,
        ...extensions.lightBlue,
      };
    default:
      return theme;
  }
};

export default getTheme;
