import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import PathBar from '../../components/top/pathBar/PathBar';
import Middle from '../../components/middle/Middle';
import navigation, { navigationParents } from '../../functionalities/navigation';
import { theme } from '../../theme';
import { useMessageGetter } from 'react-message-context';
import ChallengeCard from '../../components/data-cards/ChallengeCard';
import { useApp } from '../../context/appContext';
import { loadChallenges } from '../../context/actions';
import Box from '../../components/container/Box';
import { useTrackPageView } from '../../hooks/useTrackPageView';
import CenteredContent from '../../components/container/CenteredContent';
import P from '../../components/texts/paragraphs/P';

const thisPage = navigationParents.challenges.page;

const ChallengesPage = ({ exiting, wrapper: Wrapper }) => {
  if (!exiting) navigation.setPage(thisPage);

  const [state, dispatch] = useApp();

  useTrackPageView();

  useEffect(() => {
    loadChallenges(dispatch);
  }, [dispatch]);

  const messages = useMessageGetter('pages.challenges');
  const { challenges } = state;

  if (challenges.length === 0) {
    return <Wrapper>{null}</Wrapper>;
  }

  challenges.sort((c1, c2) => {
    if (c1.active !== c2.active) {
      if (c1.active) {
        return -1;
      } else {
        return 1;
      }
    }

    return c1.id > c2.id ? 1 : -1;
  });

  return (
    <Wrapper>
      <ThemeProvider theme={theme('white')}>
        <CenteredContent>
          <PathBar pageTitle={messages('title')} level={0} bwHeader={true} posAbsolute={false} />
          <Middle>
            {challenges.map((challenge, i) => (
              <Box margin="15px 0 0 0" key={`challenge-${i}`}>
                <ChallengeCard challenge={challenge} />
              </Box>
            ))}
            <Box margin="19px 45px">
              <P isCenter textColor="weak">
                {messages('willBeMore')}
              </P>
            </Box>
          </Middle>
        </CenteredContent>
      </ThemeProvider>
    </Wrapper>
  );
};

ChallengesPage.propTypes = {
  exiting: PropTypes.bool,
  wrapper: PropTypes.func,
};

export default ChallengesPage;
