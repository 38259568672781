import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { pLeft } from '../../../../styles/text';
import { largeCheckmark } from '../../../../assets/images';

const WizardInputCheckbox = ({ value, label, onChange }) => {
  const onKeyPress = () => onChange(!value);
  return (
    <Container
      onClick={() => onChange(!value)}
      role="checkbox"
      aria-checked={value}
      onKeyPress={onKeyPress}
      tabIndex="0"
    >
      <Checkbox checked={value}>{value && <Checkmark />}</Checkbox>
      <Label>{label}</Label>
    </Container>
  );
};

WizardInputCheckbox.propTypes = {
  value: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default WizardInputCheckbox;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  width: 100%;
  max-width: 300px;
  margin-bottom: 30px;
  align-items: center;
  &:hover,
  & > label:hover {
    cursor: pointer;
  }
`;

const Checkbox = styled.div.attrs(props => ({
  style: {
    backgroundColor: props.checked
      ? props.theme.input.checkbox.bgColorSelected
      : props.theme.input.checkbox.bgColor,
  },
}))`
  width: 15px;
  height: 15px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Checkmark = styled.img.attrs(props => ({
  src: largeCheckmark,
}))`
  width: 6px;
  height: 6px;
  object-fit: contain;
`;

const Label = styled.label`
  ${pLeft};
  margin-left: 10px;
`;
