import styled from 'styled-components';
import { CardItemContainer } from './CardItem';

/**
 * Row with equal width children
 */
const CardValuesRow = styled.div`
  display: flex;
  flex-direction: row;
  > div {
    flex-grow: 1;
    flex-basis: 0;
  }

  &:not(:first-of-type) {
    margin-top: 30px;
  }

  > ${CardItemContainer}:first-child {
    margin-right: 15px;
  }

  > ${CardItemContainer}:last-child {
    margin-left: 15px;
  }
`;

export default CardValuesRow;
