const elements = {
  boxShadow: '0px 10px 20px 0px rgba(168,168,168,1)',
  boxShadow2: '0 7px 10px -2px rgba(87, 87, 87, 0.3)',
  topBarHeightPx: 60,
  navBarHeightPx: 60,
  appPaddingPx: 15,
  appSecondaryPaddingPx: 15,
  wizardFooterHeightPx: 90,
  headerHeightPx: 217,
  elementMaxWidth: 330,
  containerMaxWidthPx: 500,
};

export default elements;
