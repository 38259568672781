import styled from 'styled-components';
import { pNegative } from '../../../styles/text';

const WizardParagraph = styled.p.attrs(props => ({
  style: {
    ...(props.centered ? { textAlign: 'center' } : {}),
  },
}))`
  ${pNegative};
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 0 15px 0;
`;

export default WizardParagraph;
