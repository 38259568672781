import styled from 'styled-components';
import { pWizardTitle } from '../../../styles/text';

const BigParagraph = styled.p`
  ${pWizardTitle};
`;

export default BigParagraph;

export const BigParagraphIcon = styled.img`
  width: 23px;
  height: 23px;
  vertical-align: top;
`;
