import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import Scroller from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import LoadingDotsIndicator from './indicator/LoadingDotsIndicator';
import { labelLeftWeak } from '../styles/text';
import elements from '../styles/elements';
import { routingModalSlideDuration } from '../styles/durations';
import { FEED_PAGE_SIZE } from '../util/constants';

const chunkArray = (array, chunkSize) => {
  const tempArray = [];

  for (let index = 0; index < array.length; index += chunkSize) {
    tempArray.push(array.slice(index, index + chunkSize));
  }

  return tempArray;
};

const InfiniteScroll = props => {
  const theme = useContext(ThemeContext);
  return (
    <Scroller
      dataLength={props.dataLength}
      next={props.loadMore}
      hasMore={props.hasMore}
      scrollableTarget={props.scrollableTarget}
      loader={
        <Loader>
          <LoadingDotsIndicator
            round
            size="8px"
            bgImage={theme.contentWithSpinner.loadingDotsBgImage}
          />
        </Loader>
      }
      endMessage={props.dataLength > FEED_PAGE_SIZE && <EndMessage>{props.endMessage}</EndMessage>}
    >
      <TransitionGroup>
        {chunkArray(props.children, 10).map((page, index) => (
          <CSSTransition
            key={`animation-page-${index}`}
            timeout={routingModalSlideDuration}
            classNames="appear"
          >
            <div>{page}</div>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </Scroller>
  );
};

const Loader = styled.div`
  max-width: ${elements.containerMaxWidthPx}px;
  width: 100%;
  padding: 100px 0 400px;
  display: flex;
  justify-content: center;
`;

const EndMessage = styled.div`
  ${labelLeftWeak};
  max-width: ${elements.containerMaxWidthPx}px;
  width: 100%;
  font-weight: normal;
  text-align: center;
  padding: 100px 45px 400px;
`;

InfiniteScroll.propTypes = {
  dataLength: PropTypes.number.isRequired,
  loadMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  endMessage: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  scrollableTarget: PropTypes.string,
};

export default InfiniteScroll;
