import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { h2LeftPositive } from '../../styles/text';

const CardLabel = styled.h2`
  ${h2LeftPositive};
  margin-top: 0;
  margin-bottom: 14px;
`;

export const CardLabelWithIcon = ({ children, icon }) => (
  <Container>
    {icon && <IconContainer>{icon}</IconContainer>}
    {children}
  </Container>
);

CardLabelWithIcon.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.element.isRequired,
};

const Container = styled(CardLabel)`
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  height: 15px;
  width: 15px;
  margin-right: 10px;
`;

export default CardLabel;
