import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { OptionDayRadiobutton } from './option/Option';
import { h2LeftNegative, pWizardTitle } from '../../../../styles/text';
import { convertLineBreaks } from '../../../../util/text';

function prevValueOfArray(array, index) {
  if (index - 1 >= 0) {
    return array[index - 1];
  } else {
    return array[array.length - 1];
  }
}

function nextValueOfArray(array, index) {
  if (index + 1 <= array.length - 1) {
    return array[index + 1];
  } else {
    return array[0];
  }
}

const WizardRadioInline = ({ title, subtitle, onSelect, options = [], value, name }) => {
  // If radio group is focused, change selections with arrows
  const onKeyUp = e => {
    if (e.keyCode >= 37 && e.keyCode <= 40) {
      e.preventDefault();
      e.stopPropagation();
      const valueIndex = options.findIndex(option => option.value === value);
      if (valueIndex >= 0) {
        if (e.keyCode <= 38) {
          // Prev from left and up arrows
          const prevOption = prevValueOfArray(options, valueIndex);
          onSelect(prevOption.value, name);
        } else {
          // Next from right and down arrows
          const nextOption = nextValueOfArray(options, valueIndex);
          onSelect(nextOption.value, name);
        }
      } else if (options.length > 0) {
        onSelect(options[0].value, name);
      }
    }
  };

  return (
    <Fragment>
      {title && <Title>{title}</Title>}
      <Container onKeyUp={onKeyUp}>
        {options.map((option, index) => (
          <OptionDayRadiobutton
            key={option.key}
            tabIndex={value === option.value || (!value && index === 0) ? 0 : -1}
            onClick={onSelect}
            name={name}
            value={option.value}
            selected={value === option.value}
            title={option.title}
            subtitle={option.subtitle}
            icon={option.icon}
          />
        ))}
      </Container>
      {subtitle && (
        <Center>
          <Subtitle>{convertLineBreaks(subtitle)}</Subtitle>
        </Center>
      )}
    </Fragment>
  );
};

WizardRadioInline.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      subtitle: PropTypes.string,
    }),
  ),
  name: PropTypes.string,
  value: PropTypes.any,
};

export default WizardRadioInline;

const Container = styled.div.attrs(() => ({
  role: 'radiogroup',
}))`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 300px;
  align-self: center;
  flex: 0 1 auto;
  justify-content: center;
`;

const Title = styled.h2`
  ${h2LeftNegative};
  text-align: center;
  margin-bottom: 15px;
  margin-top: 0;
`;

const Subtitle = styled.p`
  ${pWizardTitle};
  max-width: 300px;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 35px;
`;
