import messages from './localizations/messages';

export default function(locale, key, params) {
  key = key.split('.');
  let leaf = messages[locale];
  for (let i = 0; i < key.length; i++) {
    leaf = leaf[key[i]];
    if (leaf && typeof leaf === 'function') {
      return leaf.call(this, params);
    }
  }
  console.log('Unrecognized key', key);
  return '';
}

