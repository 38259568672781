import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from '../../theme';
import ModalFullscreen from '../../components/modal/ModalFullscreen';
import { HistoryShape } from '../../util/shapes';
import RichText from '../../components/cms/RichText';
import Divider from '../../components/wizard/divider';
import { useTrackPageView } from '../../hooks/useTrackPageView';
import { getCMSTermsOfService } from '../../functionalities/apis';

const TermsOfServicePage = ({ history }) => {
  useTrackPageView();
  const [state, setState] = useState({
    loading: true,
    error: null,
    document: null,
  });

  useEffect(() => {
    async function load() {
      const response = await getCMSTermsOfService('en-US');
      if (response.data) {
        setState(state => ({
          ...state,
          loading: false,
          document: response.data.getSimpleDocument.data,
        }));
      } else {
        setState(state => ({
          ...state,
          loading: false,
          error: true,
        }));
      }
    }
    load();
  }, [setState]);

  const { loading, error, document } = state;
  if (loading) {
    return (
      <ThemeProvider theme={theme('white')}>
        <ModalFullscreen onClose={history.goBack} />
      </ThemeProvider>
    );
  } else if (error || (!loading && !document)) {
    const errorText = 'Oops! There was error loading terms of service!';
    return (
      <ThemeProvider theme={theme('white')}>
        <ModalFullscreen onClose={history.goBack}>
          <RichText type="description" children={[{ text: errorText }]} />
        </ModalFullscreen>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme('white')}>
      <ModalFullscreen title={document.header} onClose={history.goBack}>
        {document.heading.map(({ type, children }, i) => (
          <RichText key={i} type={type} children={children} />
        ))}
        <Divider style={{ margin: 0 }} />
        {document.content.map(({ type, children }, i) => (
          <RichText key={i} type={type} children={children} />
        ))}
      </ModalFullscreen>
    </ThemeProvider>
  );
};

TermsOfServicePage.propTypes = {
  history: HistoryShape.isRequired,
};

export default TermsOfServicePage;
