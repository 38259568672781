import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardValue, CardDataSheet, Split } from '../../components/card';

import { DotCalendar } from '../../components/chart';
import { SuccessStates } from '../../components/indicator/DailySuccessIndicator';
import { getISODay, parseISO } from 'date-fns';
import { useMessageGetter } from 'react-message-context';
import { Groups } from 'isomorphic';
import { ReactComponent as PersonSvg } from '../../assets/images/person.svg';
import { CardLabelWithIcon } from '../card/CardLabel';
import { SpacingShape } from '../../util/shapes';

const SuccessCalendarCard = ({ startDate, daysAmount, dailyAnswers, spacing, flat }) => {
  const currentDayNumber = Groups.getGroupDay(startDate);
  const messages = useMessageGetter('components.success_calendar_card');
  const successDays = dailyAnswers.filter(day => day.successValue === SuccessStates.success).length;
  const partlySuccessDays = dailyAnswers.filter(day => day.successValue === SuccessStates.partly)
    .length;
  const successRatePrc = Math.round((successDays / Math.max(dailyAnswers.length, 1)) * 100);
  const calendarSheetData = [
    {
      type: 'even',
      valueText: `${successDays}`,
      desc: messages('successDescription'),
      icon: SuccessStates.success,
    },
    {
      type: 'even',
      valueText: `${partlySuccessDays}`,
      desc: messages('partlyDescription'),
      icon: SuccessStates.partly,
    },
  ];
  const maxDay = dailyAnswers.map(answer => answer.day).reduce((acc, val) => Math.max(acc, val), 1);
  const daysData = new Array(maxDay - 1);
  dailyAnswers.forEach(answer => (daysData[answer.day - 1] = answer.successValue));

  return (
    <Card spacing={spacing} flat={flat}>
      <CardLabelWithIcon icon={<PersonSvg />}>{messages('label')}</CardLabelWithIcon>
      <Split>
        <div>
          <CardValue value={successRatePrc} valueUnit="%" desc={messages('rateDescription')} />
          <CardDataSheet columns={1} data={calendarSheetData} />
        </div>
        <DotCalendar
          startWeekDay={getISODay(parseISO(startDate)) - 1}
          daysAmount={daysAmount}
          days={daysData}
          activeIndex={currentDayNumber - 1}
        />
      </Split>
    </Card>
  );
};

SuccessCalendarCard.propTypes = {
  dailyAnswers: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.number.isRequired,
      successValue: PropTypes.oneOf([
        SuccessStates.success,
        SuccessStates.partly,
        SuccessStates.fail,
      ]),
    }),
  ),
  daysAmount: PropTypes.number.isRequired,
  startDate: PropTypes.string.isRequired,
  spacing: SpacingShape,
  flat: PropTypes.bool,
};

export default SuccessCalendarCard;
