import styled from 'styled-components';
import { Container } from './WizardInputCheckbox';

const InputCheckboxGroup = styled.div`
  padding-bottom: 20px;

  > ${Container} {
    margin-bottom: 10px;
  }
`;

export default InputCheckboxGroup;
