import React, { useCallback } from 'react';
import { getChallengeIntroConfig } from '../../../functionalities/apis';
import { useTrackPageView } from '../../../hooks/useTrackPageView';
import { HistoryShape, MatchShapeOfParams } from '../../../util/shapes';
import Wizard from '../../wizards/Wizard';

const ChallengeIntroWizard = ({ match, history }) => {
  useTrackPageView();

  const { challengeId } = match.params;
  const loadConfig = useCallback(async () => await getChallengeIntroConfig(challengeId), [
    challengeId,
  ]);
  return (
    <Wizard
      history={history}
      match={match}
      loadConfig={loadConfig}
      onClose={() => history.push(`/challenges/${challengeId}`)}
    />
  );
};

ChallengeIntroWizard.propTypes = {
  match: MatchShapeOfParams(['challengeId']),
  history: HistoryShape.isRequired,
};

export default ChallengeIntroWizard;
