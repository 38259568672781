import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMessageGetter } from 'react-message-context';
import { imgSignup, imgSignupGroup } from '../../../../assets/images';
import Button from '../../../../components/button/Button';
import Box from '../../../../components/container/Box';
import Center from '../../../../components/container/Center';
import ModalSelect from '../../../../components/modal/ModalSelect';
import {
  FixedWizardFooter,
  HEADING_SIZES,
  WizardHeading,
  WizardStepContent,
} from '../../../../components/wizard';
import WizardIllustration from '../../../../components/wizard/illustration/WizardIllustration';

const WelcomeStep = ({ variant, type, onContinue, onChangeType }) => {
  const [showTypeSelect, setShowTypeSelect] = useState(false);
  const messages = useMessageGetter(`pages.wizards.auth.step_1_welcome`);
  const illustrationSrc = variant === 'default' ? imgSignup : imgSignupGroup;
  const authTypeOptions = [
    {
      value: 'phoneNumber',
      name: messages('type.phoneNumber'),
      disabled: false,
    },
    {
      value: 'email',
      name: messages('type.email'),
      disabled: false,
    },
  ];
  return (
    <WizardStepContent>
      {showTypeSelect && (
        <ModalSelect
          title={messages('change_type_title')}
          options={authTypeOptions}
          onChange={({ value }) => {
            onChangeType(value);
            setShowTypeSelect(false);
            onContinue();
          }}
          onCancel={() => setShowTypeSelect(false)}
        />
      )}
      <Box margin="0 0 20px 0" style={{ display: 'block', textAlign: 'center' }}>
        <WizardIllustration src={illustrationSrc} />
      </Box>
      <WizardHeading
        title={messages('title')}
        subtitle={messages(`subtitle.${variant}`)}
        type={HEADING_SIZES.HUGE}
      />
      <Center>
        <Button onClick={onContinue} primary>
          {messages(`continue.${type}`)}
        </Button>
      </Center>
      <FixedWizardFooter>
        <Button onClick={() => setShowTypeSelect(true)} secondary>
          {messages('change_type')}
        </Button>
      </FixedWizardFooter>
    </WizardStepContent>
  );
};

WelcomeStep.propTypes = {
  variant: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['phoneNumber', 'email']).isRequired,
  onContinue: PropTypes.func.isRequired,
  onChangeType: PropTypes.func.isRequired,
};

export default WelcomeStep;
