import React, { Fragment } from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { useMessageGetter } from 'react-message-context';
import { InputContainer, InputContainedLabel, Input, Description } from './InputPrimitives';
import ArrowRightIcon from '../../../icons/ArrowRightIcon';

const WizardFullscreenInputLink = ({
  name,
  type = 'text',
  label,
  required,
  value,
  description,
  onChange,
  onClick,
  disabled = false,
  size = 'big',
  errorText,
  children,
  secondary = false,
}) => {
  const messages = useMessageGetter();
  const onKeyDown = e => {
    if (e.key === 'Enter' && !disabled && onClick) {
      onClick();
    }
  };

  const getErrorText = () => {
    if (errorText) {
      return errorText;
    }

    if (required && value.length === 0) {
      return messages('components.wizards.input.required', { requiredLabel: label });
    }
  };

  return (
    <Fragment>
      <Container disabled={disabled}>
        <InputContainer
          onClick={!disabled ? onClick : undefined}
          size={size}
          disabled={disabled}
          secondary={secondary}
        >
          <InputContainedLabel name={name} error={getErrorText()} label={label} />
          <Row>
            <Input
              name={name}
              type={type}
              value={value}
              onChange={onChange}
              readOnly={!onChange}
              onKeyDown={onKeyDown}
              disabled={disabled}
              size={size}
            />
            <ArrowRightContainer>
              <ArrowRightIcon />
            </ArrowRightContainer>
          </Row>
        </InputContainer>
        {description && <Description>{description}</Description>}
      </Container>
      {children}
    </Fragment>
  );
};

WizardFullscreenInputLink.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  description: PropTypes.string,
  value: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['big', 'small']),
  errorText: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  secondary: PropTypes.bool,
  children: PropTypes.node,
};

export default WizardFullscreenInputLink;

const Container = styled.div`
  align-self: center;
  width: 100%;
  margin: auto;
  margin-bottom: 15px;
  > ${InputContainer} {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    * {
      cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    }
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  padding-right: 40px;
`;

const ArrowRightContainer = styled.div`
  position: absolute;
  right: 0;
  top: -3px;
`;
