import React from 'react';
import styled from 'styled-components';

const TextDotSeparator = () => (
  <Container>
    <DotSeparator />
  </Container>
);

export default TextDotSeparator;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 100%;
  width: 14px;
`;

const DotSeparator = styled.div`
  width: 2px;
  height: 2px;
  background-color: ${props => props.theme.colors.dull7};
  border-radius: 50vh;
`;
