import styled from 'styled-components';
import elements from '../../styles/elements';

export const FullScreen = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 200;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background: white;
`;

export const CloseContainer = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 100;
  padding: ${elements.appSecondaryPaddingPx}px;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 12px 30px 12px 30px;
`;

export const Left = styled.div`
  width: 33px;
  min-width: 33px;
`;
