import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card } from '../../components/card';
import { h1LeftNegative, labelLeftNegative, pNegative, h2LeftNegative } from '../../styles/text';
import { useMessageGetter } from 'react-message-context';
import { theme } from '../../theme';
import ChallengeTeaserCard from './ChallengeTeaserCard';
import { Constants, Groups } from 'isomorphic';
import { hexToRGBA } from '../../util/common';
import DataGrid from './DataGrid';
import Box from '../container/Box';
import Co2 from '../../functionalities/Co2';
import { ChallengeShape } from '../../util/shapes';

const images = require.context('../../assets/images', true);

const ChallengeCard = ({ challenge, showInfo = true, showStats = true }) => {
  const messages = useMessageGetter();
  const id = challenge.id.substring(10);
  const groups = Object.values(challenge.groups);
  const challengeGroups = groups.filter(
    g => Groups.getGroupStatus(g.startDate, g.durationDays) !== Constants.GroupStates.alumni,
  );
  const challengeGroupMembers = challengeGroups
    .map(g => g.memberCount)
    .reduce((acc, val) => acc + val + 1, 0);
  const challengeReductionKgScaled = new Co2(challenge.reduction).getScaledValue();
  const isActive = challenge.active || challenge.groups.length > 0;

  if (!isActive) {
    return <ChallengeTeaserCard challenge={challenge} showInfo={showInfo} />;
  }

  const challengeTheme = theme(isActive ? challenge.theme : 'gray');
  const data = [
    {
      value: challengeGroups.length,
      description: 'groups',
    },
    {
      value: challengeGroupMembers,
      description: 'Tidealists',
    },
    {
      value: challengeReductionKgScaled.value,
      unit: `${challengeReductionKgScaled.unitAbbr} CO2e`,
      description: 'all-time reduction',
    },
  ];

  return (
    <ThemeProvider theme={challengeTheme}>
      <Link to={`/challenges/${id}`}>
        <Card
          bgImage={images(`./${id}-bg.svg`)}
          bgGradient={challengeTheme.challengeCard.bgGradient}
          spacing="dense"
        >
          <Header>{messages(`data.challenge.${id}.title`)}</Header>
          {showInfo && <Info>{messages(`data.challenge.${id}.info`)}</Info>}
          {showStats && (
            <Box margin="25px 0 2px 0">
              <DataGrid data={data} />
            </Box>
          )}
          <Box margin="20px 0 0 0">
            <Row>
              <Value>
                {Intl.NumberFormat('en', {
                  style: 'percent',
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                }).format(challenge.co2FootprintPros / 100)}
              </Value>
              <ValueDesc>{messages('components.challenge_card.valueDesc')}</ValueDesc>
            </Row>
          </Box>
        </Card>
      </Link>
    </ThemeProvider>
  );
};

ChallengeCard.propTypes = {
  challenge: ChallengeShape.isRequired,
  showStats: PropTypes.bool,
  showInfo: PropTypes.bool,
};

export default ChallengeCard;

const Header = styled.h1`
  ${h1LeftNegative};
  display: block;
  flex: 1;
  color: ${props => props.theme.card.textSecondary};
  margin: 0;
`;

const Info = styled.p`
  ${labelLeftNegative};
  margin-top: 15px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Value = styled.div`
  ${h2LeftNegative};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => hexToRGBA(props.theme.colors.dull8, '0.15')};
  border-radius: 3px;
  width: 60px;
  height: 32px;
`;

const ValueDesc = styled.div`
  ${pNegative};
  align-self: center;
  margin-left: 8px;
`;
