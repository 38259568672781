import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { largeCheckmark } from '../../../../../assets/images';
import {
  OptionContainerRadiobutton,
  RoundOptionContainerRadiobutton,
  OptionContainerCheckbox,
} from './option-container/OptionContainer';
import { pNegative, dayNumberElement } from '../../../../../styles/text';

export const OptionRadiobutton = ({
  onClick,
  value,
  selected = false,
  title,
  subtitle,
  icon,
  iconText,
  tabIndex,
  name,
}) => {
  const optionRef = useRef(null);
  useEffect(() => {
    if (tabIndex === 0 && selected) {
      optionRef.current.focus();
    }
  }, [tabIndex, selected]);

  return (
    <OptionContainerRadiobutton
      selected={selected}
      onClick={() => onClick(value, name)}
      role="radio"
      ref={optionRef}
      tabIndex={tabIndex}
      aria-checked={selected}
    >
      <Left>
        {icon && <IconRadiobutton src={icon} selected={selected} />}
        {iconText && (
          <IconText className="radio">
            <Center>
              <DayTitle selected={selected}>{iconText}</DayTitle>
            </Center>
          </IconText>
        )}
        {!icon && !iconText && <Radio selected={selected} />}
      </Left>
      <RightSide title={title} subtitle={subtitle} />
    </OptionContainerRadiobutton>
  );
};

OptionRadiobutton.propTypes = {
  onClick: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  selected: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  subtitle: PropTypes.string,
  tabIndex: PropTypes.number,
  icon: PropTypes.string,
  iconText: PropTypes.string,
  name: PropTypes.string,
};

export const OptionDayRadiobutton = ({
  onClick,
  value,
  selected = false,
  title,
  subtitle,
  tabIndex,
  name,
}) => {
  const optionRef = useRef(null);
  useEffect(() => {
    if (tabIndex === 0 && selected) {
      optionRef.current.focus();
    }
  }, [tabIndex, selected]);

  return (
    <RoundOptionContainerRadiobutton
      selected={selected}
      onClick={() => onClick(value, name)}
      role="radio"
      ref={optionRef}
      tabIndex={tabIndex}
      aria-checked={selected}
    >
      <Center>
        <DayTitle>{title}</DayTitle>
        {subtitle && <DaySubtitle>{subtitle}</DaySubtitle>}
      </Center>
    </RoundOptionContainerRadiobutton>
  );
};

OptionDayRadiobutton.propTypes = {
  onClick: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  selected: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  subtitle: PropTypes.string,
  tabIndex: PropTypes.number,
  name: PropTypes.string,
};

export const OptionCheckbox = ({
  onClick,
  value,
  title,
  subtitle,
  index = 0,
  selected = false,
  nextSelected = false,
  previousSelected = false,
  name,
}) => (
  <OptionContainerCheckbox
    role="checkbox"
    tabIndex="0"
    onKeyUp={onKeySelect(() => onClick(index, value, name))}
    aria-checked={selected}
    selected={selected}
    onClick={() => onClick(index, value, name)}
    nextSelected={nextSelected}
    previousSelected={previousSelected}
  >
    <Left>
      <IconCheckbox checked={selected}>
        <CheckedImage src={largeCheckmark} alt="checked" />
      </IconCheckbox>
    </Left>
    <RightSide title={title} subtitle={subtitle} />
  </OptionContainerCheckbox>
);

OptionCheckbox.propTypes = {
  onClick: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  subtitle: PropTypes.string,
  index: PropTypes.number,
  selected: PropTypes.bool,
  nextSelected: PropTypes.bool,
  previousSelected: PropTypes.bool,
  name: PropTypes.string,
};

const RightSide = ({ title, subtitle }) => (
  <Right>
    <Title>{title}</Title>
    {subtitle && <Subtitle>{subtitle}</Subtitle>}
  </Right>
);
RightSide.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

const onKeySelect = action => event => {
  if (event.keyCode === 32 || event.keyCode === 13) {
    event.stopPropagation();
    event.preventDefault();
    action();
    return true;
  }
  return false;
};

const CheckedImage = styled.img`
  width: 22px;
  height: 23px;
`;

const Left = styled.div`
  width: 75px;
  min-width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const IconCheckbox = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    display: ${props => (props.checked ? 'block' : 'none')};
  }
`;

const Radio = styled.div.attrs(
  props =>
    props.selected && {
      style: {
        borderWidth: '14px',
        backgroundColor: '#fff',
      },
    },
)`
  width: 45px;
  height: 45px;
  border-radius: 25px;
`;

const IconRadiobutton = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 25px;
`;

const IconText = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 25px;
`;

const Title = styled.label``;

const Subtitle = styled.p`
  margin: 4px 15px 0 0;
`;

const DayTitle = styled.label`
  ${dayNumberElement};
`;

const DaySubtitle = styled.p`
  ${pNegative};
  margin-bottom: 0;
`;
