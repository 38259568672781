import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import navigation, { navigationParents } from '../../functionalities/navigation';
import Middle from '../../components/middle/Middle';
import GroupHeader from '../../components/headers/group-header/GroupHeader';
import GroupInfo from '../../components/groups/group-info/GroupInfo';
import GroupPageCard from '../../components/groups/group-cards/GroupPageCard';
import MiniFeed from '../../components/feeds/mini-feed/MiniFeed';
import { useMessageGetter } from 'react-message-context';
import { PropTypes } from 'prop-types';
import { postComment } from '../../functionalities/apis';
import { theme } from '../../theme';
import { Groups } from 'isomorphic';
import FixedFadingBack from '../../components/top/FixedFadingBack';
import { useApp } from '../../context/appContext';
import { loadGroupFeed, loadGroup } from '../../context/actions';
import PathBar from '../../components/top/pathBar/PathBar';
import { getName } from '../../util/user';
import { useTrackPageView } from '../../hooks/useTrackPageView';
import sortingOptions from './members/sortingOptions';
import CenteredContent from '../../components/container/CenteredContent';
import { useMostRecentValue } from '../../hooks/values';
import { MatchShapeOfParams, HistoryShape } from '../../util/shapes';
import { assureNoPrefix } from 'isomorphic/util';
import { GroupStates } from 'isomorphic/constants';

const thisPage = 'Group';

const GroupPage = ({ match: matchProp, history, exiting, wrapper: Wrapper }) => {
  const [app, dispatch] = useApp();
  const { user, posts } = app;

  if (!exiting) {
    navigation.setParent(navigationParents.challenges);
    navigation.setPage(thisPage);
  }
  const match = useMostRecentValue(matchProp);
  const groupId = match.params.id;

  // XXX: Used to force a refresh when user returns from join wizard to the view
  const isWizardOverlayVisible = !match.isExact && history.location.pathname.endsWith('/join');

  useTrackPageView();

  useEffect(() => {
    loadGroup(dispatch, groupId);
    loadGroupFeed(dispatch, groupId);
  }, [dispatch, groupId, isWizardOverlayVisible]);

  const groupPosts = posts.filter(post => post.id === `group_${groupId}`);
  groupPosts.sort((p1, p2) => p2.createdAt - p1.createdAt);

  const onNewPost = async (groupId, newComment) => {
    const comment = await postComment(groupId, { text: newComment });

    const url = `/challenges/${
      match.params.challengeId
    }/group/${groupId}/posts/${comment.aspect.substring(5)}`;
    history.push(url);
  };

  const onLoadMorePosts = () => {
    if (groupPosts.length <= 0) {
      return;
    }

    const offsetKey = groupPosts[groupPosts.length - 1].aspect;
    loadGroupFeed(dispatch, groupId, offsetKey);
  };

  const messages = useMessageGetter();
  const group = app[groupId];

  if (!group) {
    return <Wrapper>{null}</Wrapper>;
  }

  const {
    captain,
    challenge,
    dataLocale,
    discussionLocale,
    durationDays,
    goal,
    members,
    personal,
    progress,
    startDate,
    access,
  } = group;
  const challengeId = challenge.id;
  const status = Groups.getGroupStatus(startDate, durationDays);
  const isCaptain = user.isLoggedIn && user.sub === captain.userId;
  const isMember = user.isLoggedIn && !!members[`user_${user.sub}`];
  const userId = user.isLoggedIn ? user.sub : '';

  // only show today as unaswered after 5am
  const currentAnswerableDay = Groups.getGroupDay(startDate) + (new Date().getHours() < 5 ? -1 : 0);
  const answerableDays = [
    `${currentAnswerableDay}`,
    `${Math.max(1, currentAnswerableDay - 1)}`,
    `${Math.max(1, currentAnswerableDay - 2)}`,
    `${Math.max(1, currentAnswerableDay - 3)}`,
  ];
  const showDailyAnswerButton =
    personal &&
    currentAnswerableDay > 0 &&
    status === GroupStates.active &&
    !answerableDays.every(v => Object.keys(personal.dailyAnswers).includes(v));

  const groupDay = Groups.getGroupDay(startDate);
  const actions = [];
  if (isCaptain || isMember) {
    actions.push({
      type: 'settings',
      label: messages('header.action.label.settings'),
      onClick: () =>
        history.push(`/challenges/${match.params.challengeId}/group/${groupId}/settings`),
    });
  }

  const membersArray = Object.values(members);
  const reductionLeader = membersArray.sort(sortingOptions(messages).biggestReduction.compareFn)[0];
  const footprintLeader = membersArray.sort(
    sortingOptions(messages).smallestFootprint.compareFn,
  )[0];

  const challengeName = messages(
    `data.challenge.${assureNoPrefix('challenge_', challengeId)}.title`,
  );

  return (
    <Wrapper>
      <ThemeProvider theme={theme(group.theme)}>
        <CenteredContent>
          <FixedFadingBack />
        </CenteredContent>
        <PathBar
          pageTitle={messages(`pages.group.${isMember ? 'title_user_is_member' : 'title'}`)}
          actions={actions}
          level={1}
        />
        <Middle>
          <GroupHeader
            challengeName={challengeName}
            challengeId={challengeId}
            groupName={
              group.name ||
              messages('data.group.defaultName', { captainName: getName(group.captain) })
            }
            captain={captain}
            members={membersArray}
            statusText={messages(`pages.group.header.status.${status}`, {
              groupDay: Math.abs(groupDay),
              daysSince: groupDay - durationDays,
            })}
            groupId={groupId}
            groupStartDate={startDate}
            userId={userId}
            isMember={isMember}
            isCaptain={isCaptain}
            showDailyAnswerButton={showDailyAnswerButton}
            inviteOnly={access === 'invite'}
          />
          <ThemeProvider theme={theme('white')}>
            <CenteredContent>
              <GroupPageCard
                status={status}
                groupStartDate={startDate}
                groupGoalData={goal}
                groupProgressData={progress}
                personalGoalData={personal}
                groupDurationDays={durationDays}
                messages={messages}
                reductionLeader={reductionLeader}
                footprintLeader={footprintLeader}
                fullStatsLink={`/challenges/${challengeId.substring(10)}/group/${groupId}/stats`}
              />
              <MiniFeed
                items={groupPosts}
                isLoggedIn={user.isLoggedIn}
                currentUserId={user.isLoggedIn && user.sub}
                captainId={captain.userId}
                groupId={groupId}
                onNewPost={onNewPost}
                onLoadMorePosts={onLoadMorePosts}
                isLoadingMorePosts={app.isLoadingMorePosts}
                group={group}
                challengeId={assureNoPrefix('challenge_', challengeId)}
                linkToFullFeed
              />
            </CenteredContent>
          </ThemeProvider>
          <GroupInfo
            challengeId={challengeId}
            challengeName={challengeName}
            challengeDesc={challenge.shortDesc || ''}
            status={status}
            startDate={startDate}
            language={discussionLocale}
            region={dataLocale}
            inviteOnly={access === 'invite'}
          />
        </Middle>
      </ThemeProvider>
    </Wrapper>
  );
};

GroupPage.propTypes = {
  match: MatchShapeOfParams(['id']),
  history: HistoryShape.isRequired,
  exiting: PropTypes.bool,
  wrapper: PropTypes.func,
};

export default GroupPage;
