import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { pPositive, pTextFieldLeft, pLeft, h1LeftPositive, weak } from '../../../../styles/text';

export const InputContainer = styled.div.attrs(props => ({
  style: {
    ...(props.secondary ? { backgroundColor: props.theme.input.text.bgColorSecondary } : {}),
    ...(props.disabled ? { backgroundColor: props.theme.input.text.bgColorDisabled } : {}),
  },
}))`
  height: ${props => (props.size === 'big' ? '75px' : '60px')};
  border: 1px solid ${props => props.theme.input.text.borderColor};
  background-color: ${props => props.theme.input.text.bgColor};
  border-radius: 4px;
  padding: ${props => (props.size === 'big' ? '13px 15px 15px 15px' : '10px 12px 12px 18px')};
  position: relative;
`;

export const Input = styled.input.attrs(props => ({
  style: {
    ...(props.disabled
      ? {
          color: props.theme.input.text.colorDisabled,
          WebkitTextFillColor: props.theme.input.text.colorDisabled,
        }
      : {}),
  },
}))`
  ${props => (props.size === 'big' ? h1LeftPositive : pTextFieldLeft)}
  width: 100%;
  border: 0;
  background: transparent;
  padding: 0px;
  margin: 3px 0 0 0;
  color: ${props => props.theme.input.text.color};
  opacity: 1; /* required on iOS */
  :placeholder {
    color: ${({ theme }) => theme.input.text.colorDisabled};
  }
`;

export const InputContainedLabel = ({ name, error, label, children }) => (
  <InputLabelContainer>
    <InputLabel hasError={!!error} htmlFor={name}>
      {error || label}
    </InputLabel>
    {children}
  </InputLabelContainer>
);

InputContainedLabel.propTypes = {
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
};

export const Description = styled.p`
  ${pLeft};
  ${weak};
`;

const InputLabel = styled.label`
  ${pPositive};
  color: ${props => (props.hasError ? props.theme.text.error : pPositive.color)};
  flex: 1;
`;

const InputLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
