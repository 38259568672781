const TYPES = {
  grams: {
    id: 'grams',
    conversion: 1000,
    abbr: 'g',
  },
  kilograms: {
    id: 'kilograms',
    conversion: 1,
    abbr: 'kg',
  },
  tonnes: {
    id: 'tonnes',
    conversion: 0.001,
    abbr: 't',
  },
};
Object.freeze(TYPES);

const SPECIAL_TYPES = {
  finnishCitizens: {
    id: 'finnishCitizens',
    conversion: 1 / 10300,
    abbr: 'Finns',
  },
};
Object.freeze(SPECIAL_TYPES);

const unitConversionRatio = 0.999;

const INFOS = [
  {
    id: 'utils.co2data.averageWorldCitizenCo2',
    co2Kg: 0.1315,
    hasFeedValue: false,
  },
  {
    id: 'utils.co2data.banana',
    co2Kg: 0.07,
    hasFeedValue: true,
  },
  {
    id: 'utils.co2data.showerMinNaturalGas',
    co2Kg: 0.1,
    hasFeedValue: false,
  },
  {
    id: 'utils.co2data.cola',
    co2Kg: 0.17,
    hasFeedValue: true,
  },
  {
    id: 'utils.co2data.smallGasolineCar',
    co2Kg: 0.201,
    hasFeedValue: true,
  },
  {
    id: 'utils.co2data.largeGasolineCar',
    co2Kg: 0.262,
    hasFeedValue: true,
  },
  {
    id: 'utils.co2data.averageFinnCo2',
    co2Kg: 0.2822,
    hasFeedValue: false,
  },
  {
    id: 'utils.co2data.oatmilk',
    co2Kg: 0.29,
    hasFeedValue: true,
  },
  {
    id: 'utils.co2data.avocado',
    co2Kg: 0.4,
    hasFeedValue: true,
  },
  {
    id: 'utils.co2data.beer',
    co2Kg: 0.54,
    hasFeedValue: true,
  },
  {
    id: 'utils.co2data.showerLitresNaturalGas',
    co2Kg: 0.0083,
    hasFeedValue: false,
  },
  {
    id: 'utils.co2data.tomato',
    co2Kg: 3,
    hasFeedValue: true,
  },
  {
    id: 'utils.co2data.chicken',
    co2Kg: 5,
    hasFeedValue: true,
  },
  {
    id: 'utils.co2data.cheese',
    co2Kg: 10,
    hasFeedValue: true,
  },
  {
    id: 'utils.co2data.beef',
    co2Kg: 15,
    hasFeedValue: true,
  },
  {
    id: 'utils.co2data.smartphone',
    co2Kg: 79,
    hasFeedValue: true,
  },
  {
    id: 'utils.co2data.flightHelJfk',
    co2Kg: 1637,
    hasFeedValue: false,
  },
];

export default class Co2 {
  static TYPES = TYPES;
  static SPECIAL_TYPES = SPECIAL_TYPES;

  constructor(co2eKG) {
    this.kg = co2eKG;
  }

  /** < 1000kg as kilograms, otherwise in tonnes. never in grams **/
  getCorrectPhysicalUnit() {
    let correctUnit = TYPES.kilograms;
    if (Math.abs(this.kg * TYPES.tonnes.conversion) > unitConversionRatio) {
      correctUnit = TYPES.tonnes;
    }

    return correctUnit;
  }

  getScaledValue() {
    const unit = this.getCorrectPhysicalUnit();
    return { value: this.convertTo(unit), unitAbbr: unit.abbr };
  }

  getFullScaledText() {
    const { value, unitAbbr } = this.getScaledValue();
    return `${value < 0 ? '+' : ''}${Math.abs(value)} ${unitAbbr}`;
  }

  convertTo(type) {
    let value;
    switch (type) {
      case TYPES.grams:
        value = this.kg * TYPES.grams.conversion;
        break;
      case TYPES.kilograms:
        value = this.kg * TYPES.kilograms.conversion;
        break;
      case TYPES.tonnes:
        value = this.kg * TYPES.tonnes.conversion;
        break;
      case SPECIAL_TYPES.finnishCitizens:
        value = this.kg * SPECIAL_TYPES.finnishCitizens.conversion;
        break;
      default:
        console.error('Type has to be given for convertTo!', type);
        value = this.kg;
    }

    return this.round(value);
  }

  round(value) {
    if (value < -10) {
      return Math.round(value);
    } else if (value < 10) {
      return Math.round(value * 10) / 10;
    } else {
      return Math.round(value);
    }
  }

  getRandomEquivalence(messages, textForFeed = false) {
    // Find all where the internal value is greater than 1 unit of the "thing"
    const things = INFOS.filter(thing => thing.co2Kg <= this.kg).filter(
      thing => !textForFeed || thing.hasFeedValue,
    );

    let thing;
    // If none found, use the first one which is smallest
    if (things.length === 0) {
      thing = INFOS[0];
    } else {
      thing = things[Math.floor(Math.random() * things.length)];
    }

    const amount = this.round(this.kg / thing.co2Kg);
    return textForFeed
      ? messages(`${thing.id}.feed`, { amount })
      : [messages(`${thing.id}.value`, { amount }), messages(`${thing.id}.desc`)];
  }
}
