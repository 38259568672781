import styled from 'styled-components';
import { pNegative } from '../../../styles/text';

const WizardLinkText = styled.a`
  ${pNegative};
  text-decoration: underline;
  padding-left: 30px;
  padding-right: 30px;
`;

export default WizardLinkText;
