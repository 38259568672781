import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { labelVanilla, h3vanilla } from '../../styles/text';

const RichValue = ({ value, unit, negative, end = false }) => (
  <Container $end={end} negative={negative}>
    {negative && <Sign>+</Sign>}
    <Number>{value}</Number>
    <Unit>{unit}</Unit>
  </Container>
);

RichValue.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  unit: PropTypes.string.isRequired,
  negative: PropTypes.bool.isRequired,
  end: PropTypes.bool,
};

export default RichValue;

const Container = styled.div.attrs(({ $end, negative, theme }) => ({
  style: {
    ...($end ? { justifyContent: 'flex-end' } : {}),
    color: negative ? theme.text.error : theme.text.standout,
  },
}))`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
`;

const Number = styled.div`
  ${h3vanilla};
  margin: 0 3px 1px 2px;
`;

const Unit = styled.div`
  ${labelVanilla};
`;

const Sign = styled.div`
  ${labelVanilla};
  align-self: center;
`;
