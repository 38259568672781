import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { Card } from '../../components/card';
import { h1LeftNegative, labelLeftNegative, pNegative, pWeak } from '../../styles/text';
import { useMessageGetter } from 'react-message-context';
import { theme } from '../../theme';
import { lightBg } from '../../assets/images';

const ChallengeTeaserCard = ({ challenge, showInfo = true }) => {
  const messages = useMessageGetter();
  const id = challenge.id.substring(10);
  return (
    <ThemeProvider theme={theme('gray')}>
      <Wrapper>
        <Card bgImage={lightBg}>
          <Strapline>Coming later</Strapline>
          <Header>{messages(`data.challenge.${id}.title`)}</Header>
          {showInfo && <Info>{messages(`data.challenge.${id}.info`)}</Info>}
          <Row>
            <Value>
              {Intl.NumberFormat('en', {
                style: 'percent',
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }).format(challenge.co2FootprintPros / 100)}
            </Value>
            <ValueDesc>{messages('components.challenge_card.valueDesc')}</ValueDesc>
          </Row>
        </Card>
      </Wrapper>
    </ThemeProvider>
  );
};

ChallengeTeaserCard.propTypes = {
  challenge: PropTypes.shape({
    co2FootprintPros: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
  }),
  showInfo: PropTypes.bool,
};

export default ChallengeTeaserCard;

const Wrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
`;

const Strapline = styled.div`
  ${pWeak};
`;

const Header = styled.h1`
  ${h1LeftNegative};
  flex: 1;
  color: ${props => props.theme.card.textSecondary};
  margin: 0;
`;

const Info = styled.p`
  ${labelLeftNegative};
  color: ${props => props.theme.card.textSecondary};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Value = styled.div`
  ${h1LeftNegative};
  align-self: baseline;
  color: ${props => props.theme.card.textSecondary};
`;

const ValueDesc = styled.div`
  ${pNegative};
  color: ${props => props.theme.card.textSecondary};
  align-self: baseline;
  margin-left: 6px;
`;
