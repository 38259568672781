import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import emojiFlags from 'emoji-flags';

const FlagIcon = ({ countryCode }) => {
  return <Flag alt={countryCode}>{emojiFlags.countryCode(countryCode).emoji}</Flag>;
};
const Flag = styled.div`
  display: inline-block;
  font-size: 16px;
`;

FlagIcon.propTypes = {
  countryCode: PropTypes.string.isRequired,
  fixHeight: PropTypes.bool,
};

export default FlagIcon;
