export const WizardComponents = {
  BigValue: 'bigValue',
  Calculation: 'calculation',
  CalculusTable: 'calculusTable',
  Card: 'card',
  Checkbox: 'checkbox',
  Code: 'code',
  Conditional: 'conditional',
  Divider: 'divider',
  GigaSelect: 'gigaSelect',
  Heading: 'heading',
  Input: 'input',
  Link: 'link',
  Paragraph: 'paragraph',
  ProgressCard: 'progressCard',
  Radio: 'radio',
  RadioInline: 'radio-inline',
  Slider: 'slider',
  SmallButton: 'smallButton',
  ValueList: 'valueList',
  Button: 'button',
};

export const StepTypes = {
  Input: 'input',
  Modal: 'modal',
  Repeat: 'repeat',
};

export const Actions = {
  /**
   * Go back in wizard
   */
  Back: 'back',
  CacheValue: 'cacheValue',
  Close: 'close',
  Conditional: 'conditional',
  DoChanges: 'doChanges',
  Down: 'down',
  Forward: 'forward',
  OnChange: 'onChange',
  Post: 'post',
  RecordPickerValue: 'recordPickerValue',
  /**
   * Redirect user to a page
   * {string} redirectTo Name of the page or actual URL
   */
  Redirect: 'redirect',
  RestoreCache: 'restoreCache',
  /**
   * Pick an array from state and save its length to a value in STATE
   * {string} name Name which is used to save in to the state
   * {string} value Name of the array in state
   */
  SetListLengthValue: 'setListLengthValue',
  SetListValue: 'setListValue',
  SetRadioValue: 'setRadioValue',
  SetCodeValue: 'setCodeValue',
  SetValue: 'setValue',
  SetValueFromList: 'setValueFromList',
  SkipStep: 'skipStep',
  ToggleValue: 'toggleValue',
  Up: 'up',
  DisplayCodeError: 'displayCodeError',
};

export const Button = {
  Primary: 'primary',
  Secondary: 'secondary',
  Navigation: 'arrows',
};

export const CardValue = {
  BigValue: 'bigValue',
};
