import { GroupStates } from 'isomorphic/constants';

const sortingOptions = messages => ({
  default: {
    id: 'default',
    name: messages('sorting.default'),
    compareFn: (a, b) => {
      if (a.isCaptain) return -1;
      if (b.isCaptain) return 1;
      if (a.isLoggedIn) return -1;
      if (b.isLoggedIn) return 1;
      return a.joinedAt - b.joinedAt;
    },
  },
  alphabetically: {
    id: 'alphabetically',
    name: messages('sorting.alphabetically'),
    compareFn: (a, b) => {
      if (a.givenName > b.givenName) return 1;
      if (a.givenName < b.givenName) return -1;
      return 0;
    },
  },
  biggestReduction: {
    id: 'biggestReduction',
    name: messages('sorting.biggestReduction'),
    compareFn: (a, b) => b.totalReductionCo2Kg - a.totalReductionCo2Kg,
    disabledForState: GroupStates.hatching,
    disabledWhenLoggedInUserNotInGroup: true,
    showStandings: true,
  },
  smallestFootprint: {
    id: 'smallestFootprint',
    name: messages('sorting.smallestFootprint'),
    compareFn: (a, b) => a.yearlyFootPrintCo2Kg - b.yearlyFootPrintCo2Kg,
    disabledForState: GroupStates.hatching,
    disabledWhenLoggedInUserNotInGroup: true,
    showStandings: true,
  },
});

export default sortingOptions;
