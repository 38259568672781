export const hiddenScrollBarParentCSS = `
  overflow: hidden;
  max-width: 100%;
`;
export const hiddenScrollBarChildCSS = `
  width: 100vw;
  overflow-x: scroll;
  overflow-y: hidden;
`;

export function redirectUnauthenticatedToJoinGroup(groupId) {
  return `#joinGroup=${groupId}`;
}
export function getGroupIdFromHash(hash) {
  const hashMap = hash.slice(1).split('#');
  const groupHash = hashMap.find(hash => hash.includes('joinGroup'));
  const groupId = groupHash && groupHash.split('=')[1];

  return groupId;
}

export function getAuthVariant(hash) {
  const hashMap = hash.slice(1).split('#');
  if (hashMap.includes('joinGroup')) {
    return 'joinGroup';
  } else if (hashMap.includes('createGroup')) {
    return 'createGroup';
  } else if (hashMap.includes('joinLockedGroup')) {
    return 'joinLockedGroup';
  } else if (hashMap.includes('answer')) {
    return 'answer';
  }
  return 'default';
}
