import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getName } from '../../util/user';
import { useMessageGetter } from 'react-message-context';
import { interpolate } from '../../util/text';
import { GroupShape } from '../../util/shapes';
import TextButton from '../button/TextButton';

const ModalGroupSubtitle = ({ group, onClick, inGroup = true, marginBottom }) => {
  const messages = useMessageGetter();

  if (!group) return null;
  return (
    <ModalSubtitle marginBottom={marginBottom}>
      {interpolate(messages(`components.feed.${inGroup ? 'in_' : ''}group`), {
        $1: (
          <TextButton onClick={onClick}>
            {group.name ||
              messages('data.group.defaultName', { captainName: getName(group.captain) })}
          </TextButton>
        ),
      })}
    </ModalSubtitle>
  );
};

ModalGroupSubtitle.propTypes = {
  group: GroupShape,
  onClick: PropTypes.func,
  inGroup: PropTypes.bool,
  marginBottom: PropTypes.string,
};

export default ModalGroupSubtitle;

export const ModalSubtitle = styled.div`
  color: ${props => props.theme.colors.brand2Hue3};
  font-size: 12px;
  text-transform: initial;
  letter-spacing: normal;
  font-weight: normal;
  font-family: ${props => props.theme.secondaryFont};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
`;
