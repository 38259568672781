import React, { useContext } from 'react';
import styled, { css, createGlobalStyle } from 'styled-components';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { Direction, MainRouteContext, Navigating } from './MainRoutes';
import {
  fullscreenModalSlideDuration,
  mainViewTransitionDuration,
  waitBeforeSpinnerDuration,
} from '../../styles/durations';
import ContentWithSpinner, { SpinnerPosition } from '../container/ContentWithSpinner';
import getComponentWithProps from '../../util/getComponentWithProps';

/**
 * A component responsible of rendering and transitioning a main route. Transition behavior depends
 * on whether user is navigating in, out or inside main pages:
 *
 * 1. When navigating from a main page to a main page (navigating === Navigating.Inside), a
 * transition to correct direction is applied on the exiting and entering page
 *
 * 2. When navigating from a main page to non-main page (navigating === Navigating.Out), the exit
 * transition is delayed, so that the page stays in place until the new page (a modal or a wizard)
 * has transitioned on top.
 *
 * 3. When navigating from a non-main page into a main page (navigating === Navigating.In), no
 * transition is applied, so that for example a modal slides away, the page is already under it
 */
export default function MainRoute({ path, component: Component, exact }) {
  const { transitionDirection, navigating } = useContext(MainRouteContext);
  return (
    <Route exact={exact} path={path} key={path}>
      {routerProps =>
        navigating === Navigating.In && routerProps.match != null ? (
          <Component {...routerProps} wrapper={SpinnerWrapper} />
        ) : (
          <CSSTransition
            in={routerProps.match != null}
            timeout={mainViewTransitionDuration}
            unmountOnExit
            classNames={`page-${transitionDirection}`}
          >
            {state => (
              <Container delay={navigating === Navigating.Out}>
                <Component
                  {...routerProps}
                  exiting={state === 'exiting' || state === 'exited'}
                  wrapper={SpinnerWrapperWithDelay}
                />
                {/* Transitioning to the right makes navigation bar disappear on Chrome. Adding these
                global styles prevents that. The downside of this is, that browser scroll restoration
                doesn't work when these styles are present*/}
                {state === 'entering' && transitionDirection === Direction.Right && (
                  <GlobalStylesPreventingNavBarDisappearOnChrome />
                )}
              </Container>
            )}
          </CSSTransition>
        )
      }
    </Route>
  );
}

MainRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
  exact: PropTypes.bool,
};

const SpinnerWrapper = getComponentWithProps(ContentWithSpinner, {
  spinnerPosition: SpinnerPosition.Center,
});

const SpinnerWrapperWithDelay = getComponentWithProps(ContentWithSpinner, {
  spinnerPosition: SpinnerPosition.Center,
  spinnerDelay: mainViewTransitionDuration + waitBeforeSpinnerDuration,
});

const opacityTransitionDuration = mainViewTransitionDuration / 2;
const horizontalShift = 15;

const defaultState = css`
  opacity: 1;
  transform: none;
`;

const transitionActiveState = css`
  position: absolute;
  right: 0;
  left: 0;
`;

const transitionClasses = direction => {
  const enterShift = direction === 'right' ? horizontalShift : -1 * horizontalShift;
  const exitShift = -1 * enterShift;
  return css`
    &-enter {
      opacity: 0;
      transform: translateX(${enterShift}px);
    }

    &-enter-active {
      ${defaultState};
      ${transitionActiveState}
    }

    &-exit {
      ${defaultState};
    }

    &-exit-active {
      ${transitionActiveState};
      opacity: 0;
      transform: translateX(${exitShift}px);
    }
  `;
};

const Container = styled.div`
  transition: opacity ${opacityTransitionDuration}ms cubic-bezier(0, 0, 0.4, 1),
    transform ${mainViewTransitionDuration}ms cubic-bezier(0.4, 0.15, 0.4, 1);
  transition-delay: ${props => (props.delay ? fullscreenModalSlideDuration : 0)}ms;

  &.page-right {
    ${transitionClasses('right')}
  }

  &.page-left {
    ${transitionClasses('left')}
  }
`;

const GlobalStylesPreventingNavBarDisappearOnChrome = createGlobalStyle`
  html, body {
    height: 100%;
    overflow: auto;
  }
`;
