import styled from 'styled-components';
import { h2LeftNegative } from '../../../../styles/text';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: center;
  flex: 1 0 auto;
  justify-content: center;
  padding: 0 30px;
  &:not(:first-of-type) {
    margin-top: 20px;
  }
`;

export const Label = styled.h2`
  ${h2LeftNegative};
  margin: 0;
  margin-bottom: 10px;
`;
