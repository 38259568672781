import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Icon = ({ path }) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
      <Path fill-rule="nonzero" d={path} />
    </Svg>
  );
};

Icon.propTypes = {
  path: PropTypes.string.isRequired,
};

const Svg = styled.svg`
  width: 34px;
  height: 34px;
  min-width: 34px;
  max-width: 34px;
  min-height: 34px;
  max-height: 34px;
`;

const Path = styled.path`
  fill: ${props => props.theme.buttonIcon.fill};
`;

export default Icon;
