import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  bigNumber,
  bigNumberUnit,
  h1,
  labelFormElementPositive,
  pWeak,
  smallNumberUnit,
} from '../../styles/text';
import CardLabel from './CardLabel';

const CardValue = ({ label, value, valueUnit, desc, small = false }) => {
  const Label = small ? SmallLabel : CardLabel;
  const isString = typeof value === 'string';
  const isIncrease = !isString && value < 0;
  let absValue = `${Math.abs(value)}`;
  if (absValue.length === 1) {
    absValue = Math.abs(value).toFixed(1);
  }
  return (
    <ValueContainer>
      {label && <Label>{label}</Label>}
      <TextRow isIncrease={isIncrease}>
        {isIncrease && <SmallPrefix small={small}>+</SmallPrefix>}
        <ValueText small={small}>
          {isString ? value : absValue}
          {valueUnit && <ValueUnit small={small}>{valueUnit}</ValueUnit>}
        </ValueText>
      </TextRow>
      <ValueDesc>{desc}</ValueDesc>
    </ValueContainer>
  );
};

CardValue.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  valueUnit: PropTypes.string,
  desc: PropTypes.string.isRequired,
  small: PropTypes.bool,
};

export default CardValue;

const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextRow = styled.div`
  display: flex;
  flex-direction: row;
  color: ${({ theme, isIncrease }) =>
    isIncrease ? theme.card.value.increase : theme.card.value.decrease};
`;

const ValueText = styled.div`
  ${({ small }) => (small ? h1 : bigNumber)};
`;

const SmallPrefix = styled.div`
  ${({ small }) => (small ? smallNumberUnit : bigNumberUnit)};
  margin-right: 1px;
  display: flex;
  align-items: center;
  padding-top: ${({ small }) => (small ? '0' : '4px')};
`;

const ValueUnit = styled.span`
  ${({ small }) => (small ? smallNumberUnit : bigNumberUnit)};
  margin-left: 3px;
`;

const ValueDesc = styled.p`
  ${pWeak};
  color: ${props => props.theme.card.textTertiary};
  margin: 0;
  margin-top: 3px;
`;

const SmallLabel = styled.div`
  ${labelFormElementPositive}
`;
