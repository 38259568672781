import React from 'react';
import { logoNegative } from '../../../assets/images';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import elements from '../../../styles/elements';
import { h2CenterNegative } from '../../../styles/text';
import ButtonIcon from '../../button-icon/ButtonIcon';
import CenteredContent from '../../container/CenteredContent';
import media from '../../../styles/media';

const PathBar = ({
  pageTitle,
  actions = [],
  bg,
  showLogo = false,
  betaStamp = false,
  bwHeader = false,
  posAbsolute = true,
  hideOnWideScreen = false,
}) => {
  return (
    <Container bg={bg} posAbsolute={posAbsolute} hideOnWideScreen={hideOnWideScreen}>
      <Inner>
        <Flex>
          {showLogo && <Logo src={logoNegative} alt="Tideal" />}
          <Title bwHeader={bwHeader}>
            {pageTitle}
            {betaStamp && <Stamp>beta</Stamp>}
          </Title>
        </Flex>
        <Actions>
          {actions.map((a, i) => (
            <ButtonIcon key={i} bg={false} type={a.type} onClick={a.onClick} label={a.label} />
          ))}
        </Actions>
      </Inner>
    </Container>
  );
};
PathBar.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['settings']),
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func,
    }),
  ),
  level: PropTypes.number,
  bg: PropTypes.string,
  showLogo: PropTypes.bool,
  betaStamp: PropTypes.bool,
  bwHeader: PropTypes.bool,
  posAbsolute: PropTypes.bool,
  hideOnWideScreen: PropTypes.bool,
};

export default PathBar;

const Container = styled.div`
  background: ${props => props.bg};
  position: ${props => (props.posAbsolute ? 'absolute' : 'initial')};
  top: 0;
  z-index: 100;
  width: 100%;
  height: ${elements.topBarHeightPx}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.seachangeStandout};
  padding: ${elements.appSecondaryPaddingPx}px;
  ${media.desktop`
     display: ${({ hideOnWideScreen }) => (hideOnWideScreen ? 'none' : 'flex')};
  `}
`;

const Inner = styled(CenteredContent)`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Flex = styled.div`
  display: flex;
`;

const Logo = styled.img.attrs(() => ({
  width: '73',
  height: '18',
}))`
  width: 73px;
  height: 18px;
`;

const Title = styled.h2`
  ${h2CenterNegative};
  color: ${props => (props.bwHeader ? props.theme.colors.brand1Hue2 : props.theme.header.text)};
  position: relative;
`;

const Stamp = styled.div`
  position: absolute;
  top: -11px;
  right: -16px;
  color: #fff;
  font-family: ${({ theme }) => theme.secondaryFont};
  font-size: 8px;
  letter-spacing: 0px;
  text-transform: lowercase;
`;

const Actions = styled.div`
  position: absolute;
  right: 0px;
  top: 4px;
  ${media.desktop`
     right: 10px;
  `}
`;
