import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { WizardInputText, WizardInputCheckbox } from '../../../components/wizard';
import { theme } from '../../../theme';
import Button from '../../../components/button/Button';
import ProfilePicture, { SIZES, SHAPES, VARIANT } from '../../../components/picture/ProfilePicture';
import { useMessageGetter } from 'react-message-context';
import { useApp } from '../../../context/appContext';
import { EVENTS, saveUser } from '../../../context/actions';
import { intToBoolean, booleanToInt } from '../../../util/user';
import { loadProfileData } from '../../../context/actions';
import { useInput, useBooleanInput } from '../../../hooks/useInput';
import ModalFullscreen, { ModalContent } from '../../../components/modal/ModalFullscreen';
import Center from '../../../components/container/Center';
import FileUploader from '../../../components/picture/FileUploader';
import { Constants, Bitmasking } from 'isomorphic';
import { useTrackPageView } from '../../../hooks/useTrackPageView';
import ModalConfirm from '../../../components/modal/ModalConfirm';
import { HistoryShape } from '../../../util/shapes';
import WizardFullscreenInputLink from '../../../components/wizard/form/input/WizardFullscreenInputLink';
import EmailNotificationSettingsModal from './EmailNotificationSettingsModal';
import SMSNotificationSettingsModal from './SMSNotificationSettingsModal';
import Box from '../../../components/container/Box';

const SMS_OPTOUT_BIT_POS = Constants.OptoutBitmaskPosition.sms_reminder;
const EMAIL_OPTOUT_BIT_POS = Constants.OptoutBitmaskPosition.email_reminder;

const SAVE_STATES = {
  idle: 'idle',
  saving: 'saving',
  saved: 'saved',
  error: 'error',
};

const UserSettingsPage = ({ history }) => {
  const [isUploading, setUploading] = useState(false);
  const [saveState, setSaveState] = useState(SAVE_STATES.idle);
  const messages = useMessageGetter();
  const [{ user, isInputFocused }, dispatch] = useApp();

  useTrackPageView();

  useEffect(() => {
    loadProfileData(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (saveState === SAVE_STATES.saved) {
      setTimeout(() => {
        history.goBack();
      }, 800);
    }
  }, [saveState, history]);

  const [showSMSNotificationSettings, setShowSMSNotificationSettings] = useState(false);
  const [showEmailNotificationSettings, setShowEmailNotificationSettings] = useState(false);
  const initialSmsOptOut =
    Bitmasking.getBooleanFrom(user['custom:optout'], SMS_OPTOUT_BIT_POS) || false;
  const initialEmailOptOut =
    Bitmasking.getBooleanFrom(user['custom:optout'], EMAIL_OPTOUT_BIT_POS) || false;

  const { value: givenName, bind: bindGivenName } = useInput(user.given_name || '');
  const { value: familyName, bind: bindFamilyName } = useInput(user.family_name || '');
  const { value: hideFamilyName, bind: bindHideFamilyName } = useBooleanInput(
    intToBoolean(user['custom:anonymous'] || 0),
  );

  // have to use useState here instead of useBooleanInput because design says this isn't opt-out, but opt-in...
  const [smsOptOut, setSmsOptOut] = useState(initialSmsOptOut);
  const [emailOptOut, setEmailOptOut] = useState(initialEmailOptOut);

  if (!user.isLoaded) {
    return null;
  }

  const onClickSave = async () => {
    setSaveState(SAVE_STATES.saving);
    const profileData = {
      given_name: givenName,
      family_name: familyName,
      'custom:anonymous': `${booleanToInt(hideFamilyName)}`, // 1 or 0
      'custom:optout': `${getOptOutValue()}`,
    };

    try {
      await saveUser(dispatch, profileData);
      setSaveState(SAVE_STATES.saved);
    } catch (err) {
      setSaveState(SAVE_STATES.error);
    }
  };

  const getOptOutValue = () => {
    let optout = user['custom:optout'] || 0;

    // get new optout value with sms optout bit set
    optout = Bitmasking.setBoolean(optout, SMS_OPTOUT_BIT_POS, smsOptOut);
    optout = Bitmasking.setBoolean(optout, EMAIL_OPTOUT_BIT_POS, emailOptOut);

    return `${optout}`;
  };

  const onProfilePictureUploaded = () => {
    setUploading(false);
    dispatch([EVENTS.UI_UPDATE_PROFILE_IMAGE]);
  };

  const actions = [
    <Button
      key="save"
      primary
      style={{ marginTop: '20px' }}
      success={saveState === SAVE_STATES.saved}
      loading={saveState === SAVE_STATES.saving}
      loadingText={messages('wizards.account_settings.saveLoading')}
      onClick={onClickSave}
      disabled={!givenName || !familyName || !(user.phone_number || user.email)}
    >
      {messages('wizards.account_settings.save')}
    </Button>,
  ];

  const closeModal = () => {
    history.goBack();
  };

  return (
    <ThemeProvider theme={theme('white')}>
      <ModalFullscreen
        title={messages('wizards.account_settings.title')}
        onClose={closeModal}
        actions={actions}
        inputFocused={isInputFocused}
      >
        <ModalContent style={isInputFocused ? undefined : { paddingBottom: '130px' }}>
          <Center>
            <FileUploader
              type="user"
              uploadId={user.userId}
              label={messages('wizards.account_settings.file')}
              onStartUpload={() => setUploading(true)}
              onUploaded={onProfilePictureUploaded}
            >
              <ProfilePicture
                userId={user.userId}
                size={SIZES.LARGE}
                shape={SHAPES.SQUARE}
                variant={VARIANT.POSITIVE}
                isUploading={isUploading}
              />
            </FileUploader>
          </Center>

          <WizardInputText
            name="givenName"
            label={messages('wizards.account_settings.given_name')}
            placeholder={messages('wizards.account_settings.given_name')}
            required
            description=""
            size={'small'}
            {...bindGivenName}
          />
          <WizardInputText
            name="familyName"
            label={messages('wizards.account_settings.family_name')}
            placeholder={messages('wizards.account_settings.family_name')}
            required
            description=""
            size={'small'}
            {...bindFamilyName}
          />
          <WizardInputCheckbox
            {...bindHideFamilyName}
            label={messages('wizards.account_settings.hide')}
          />
          <Box margin="0 0 30px 0">
            <WizardInputText
              name="phoneNumber"
              label={messages('wizards.account_settings.phone')}
              placeholder={messages('wizards.account_settings.placeholder.phone_number')}
              description=""
              value={user.phone_number || ''}
              disabled={!!user.phone_number}
              verified={!!user.phone_number}
              addMark={!user.phone_number}
              size={'small'}
              onChange={() => {}}
              onClick={!user.phone_number ? () => history.push('/user/settings/phone') : undefined}
            />
            <WizardInputText
              name="email"
              label={messages('wizards.account_settings.email')}
              placeholder={messages('wizards.account_settings.placeholder.email')}
              description=""
              value={user.email || ''}
              disabled={!!user.email}
              addMark={!user.email}
              size={'small'}
              verified={!!user.email}
              onChange={() => {}}
              onClick={!user.email ? () => history.push('/user/settings/email') : undefined}
            />
          </Box>
          {!!user.phone_number && (
            <WizardFullscreenInputLink
              name="smsOptOut"
              label={messages('wizards.account_settings.sms_opt_out.label')}
              size="small"
              value={messages(`wizards.account_settings.sms_opt_out.${smsOptOut}`)}
              onClick={() => {
                setShowSMSNotificationSettings(true);
              }}
              secondary
              disabled={false}
            >
              <SMSNotificationSettingsModal
                show={showSMSNotificationSettings}
                initialValue={initialSmsOptOut}
                onSave={value => {
                  setSmsOptOut(value);
                  setShowSMSNotificationSettings(false);
                }}
                onClose={() => {
                  setShowSMSNotificationSettings(false);
                }}
              />
            </WizardFullscreenInputLink>
          )}
          {!!user.email && (
            <WizardFullscreenInputLink
              name="emailOptOut"
              label={messages('wizards.account_settings.email_opt_out.label')}
              size="small"
              value={messages(`wizards.account_settings.email_opt_out.${emailOptOut}`)}
              onClick={() => {
                setShowEmailNotificationSettings(true);
              }}
              secondary
              disabled={false}
            >
              <EmailNotificationSettingsModal
                show={showEmailNotificationSettings}
                initialValue={initialEmailOptOut}
                onSave={value => {
                  setEmailOptOut(value);
                  setShowEmailNotificationSettings(false);
                }}
                onClose={() => {
                  setShowEmailNotificationSettings(false);
                }}
              />
            </WizardFullscreenInputLink>
          )}
        </ModalContent>
      </ModalFullscreen>
      {saveState === SAVE_STATES.error && (
        <ModalConfirm
          title={messages('wizards.account_settings.error.title')}
          description={messages('wizards.account_settings.error.description')}
          onCancel={() => setSaveState(SAVE_STATES.idle)}
          options={[
            {
              label: messages('wizards.account_settings.error.close'),
              key: 'ok',
              onClick: () => setSaveState(SAVE_STATES.idle),
            },
          ]}
        />
      )}
    </ThemeProvider>
  );
};

UserSettingsPage.propTypes = {
  history: HistoryShape.isRequired,
};

export default UserSettingsPage;
