import styled from 'styled-components';

const WizardIllustration = styled.img.attrs(props => ({
  alt: '',
}))`
  text-align: center;
  width: 250px;
`;

export default WizardIllustration;
