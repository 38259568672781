import { css } from 'styled-components';

export const positive = css`
  color: ${props => props.theme.text.positive};
`;

export const negative = css`
  color: ${props => props.theme.text.negative};
`;

export const weak = css`
  color: ${props => props.theme.text.weak};
`;

export const fatal = css`
  color: ${props => props.theme.text.error};
`;

const bigNumberBase = css`
  font-family: ${props => props.theme.primaryFont};
  font-size: 60px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
`;

export const bigNumber = css`
  ${bigNumberBase};
`;

export const bigNumberStandout = css`
  ${bigNumberBase};
  color: ${props => props.theme.text.standout};
`;

export const bigNumberUnit = css`
  ${bigNumberBase};
  font-size: 22px;
`;

export const bigNumberUnitStandout = css`
  ${bigNumberUnit};
  color: ${props => props.theme.text.standout};
`;

export const bigNumberUnitNegative = css`
  ${bigNumberBase};
  font-size: 22px;
  color: ${props => props.theme.colors.dull7};
`;

export const smallNumberUnit = css`
  ${bigNumberBase};
  font-size: 19px;
`;

export const bigNumberCenterNegative = css`
  ${bigNumberBase};
  color: ${props => props.theme.colors.dull7};
  text-align: center;
`;

const headerBase = css`
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  font-family: ${props => props.theme.primaryFont};
`;

const h1Base = css`
  ${headerBase}
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
`;

export const h1LeftNegative = css`
  ${h1Base};
  ${negative};
  text-align: left;
`;

export const h1LeftPositive = css`
  ${h1Base};
  ${positive};
  text-align: left;
`;

export const h1CenterNegative = css`
  ${h1Base};
  ${negative};
  text-align: center;
`;
export const h1WizardTitle = css`
  ${h1Base};
  ${negative};
  font-size: 36px;
  line-height: 1.22;
  text-align: center;
`;

export const h1 = css`
  ${h1Base};
`;

export const h1Positive = css`
  ${h1Base};
  ${positive};
`;

const h2Base = css`
  ${headerBase};
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
`;

export const h2Left = css`
  ${h2Base};
  ${positive};
  text-align: left;
`;

export const h2Center = css`
  ${h2Base};
  ${positive};
  text-align: center;
`;

export const h2LeftNegative = css`
  ${h2Base};
  ${negative};
`;

export const h2CenterNegative = css`
  ${h2Base};
  ${negative};
  text-align: center;
`;
export const h2CenterPositive = css`
  ${h2Base}
  ${positive};
  text-align: center;
`;

export const h2LeftPositive = css`
  ${h2Base};
  ${positive};
`;

export const h2RightLink = css`
  ${h2Base};
  color: ${props => props.theme.colors.brand2Hue1};
`;

const h3Base = css`
  ${headerBase};
  font-weight: bold;
  font-size: 19px;
  line-height: 1;
`;

export const h3vanilla = css`
  ${h3Base};
`;

export const h3LeftNegative = css`
  ${h3Base};
  ${negative};
  text-align: left;
`;

export const h3LeftPositive = css`
  ${h3Base};
  ${positive};
  text-align: left;
`;

export const h3Standout = css`
  ${h3Base};
  color: ${props => props.theme.text.standout};
`;

export const h3Alert = css`
  ${h3Base};
  color: ${props => props.theme.text.error};
`;

export const navItemNegative = css`
  font-family: ${props => props.theme.primaryFont};
  ${negative};
  font-weight: 600;
  font-size: 11px;
  line-height: 1.18;
  letter-spacing: 1.5px;
`;

const labelBase = css`
  font-family: ${props => props.theme.secondaryFont};
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: 0px;
`;

const labelBaseMedium = css`
  font-family: ${props => props.theme.secondaryFont};
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.18;
  letter-spacing: 0px;
`;

export const optionLabelLeftPositive = css`
  ${labelBaseMedium};
  ${positive};
`;

export const optionLabelLeftNegative = css`
  ${labelBaseMedium};
  ${negative};
`;

export const labelVanilla = css`
  ${labelBase};
`;

export const labelLeftPositive = css`
  ${labelBase};
  ${positive};
`;

export const labelLeftNegative = css`
  ${labelBase};
  ${negative};
`;

export const labelLeftWeak = css`
  ${labelBase};
  ${weak};
`;

export const labelRightNegative = css`
  ${labelBase};
  ${negative};
  text-align: right;
`;

export const labelCenterPositive = css`
  ${labelBase};
  ${positive};
  text-align: center;
`;

export const labelCenterNegative = css`
  ${labelBase};
  ${negative};
  text-align: center;
`;

export const labelStandout = css`
  ${labelBase};
  color: ${props => props.theme.text.standout};
`;

export const labelAlert = css`
  ${labelBase};
  color: ${props => props.theme.text.error};
`;

const labelFormElementBase = css`
  ${labelBase};
  font-size: 13px;
  line-height: 1.23;
  letter-spacing: normal;
`;

export const labelFormElementNegative = css`
  ${labelFormElementBase};
  ${negative};
`;

export const labelFormElementPositive = css`
  ${labelFormElementBase};
  ${positive};
`;

export const dayNumberElement = css`
  font-family: ${props => props.theme.primaryFont};
  font-size: 18px !important;
  font-weight: 600;
  letter-spacing: 1.5px !important;
  line-height: 0.89 !important;
  text-align: center;
`;

/**
 * Paragraphs,
 * - wizard title
 * - positive, left aligned
 * - positive, center aligned
 * - negative, left aligned
 * - negative, center aligned
 */
export const paragraphBase = css`
  font-family: ${props => props.theme.secondaryFont};
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
`;

export const pWizardTitle = css`
  ${paragraphBase};
  color: ${props => props.theme.text.negative};
  line-height: 1.44;
  font-size: 16px;
  text-align: center;
`;

export const pLeftWizardTitlePositive = css`
  ${paragraphBase};
  color: ${props => props.theme.text.positive};
  line-height: 1.44;
  font-size: 16px;
  text-align: left;
`;

export const pTextFieldLeft = css`
  font-family: ${props => props.theme.fontFamily};
  text-align: left;
  color: ${props => props.theme.text.positive};
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  font-style: normal;
  font-stretch: normal;
`;

export const pWeak = css`
  ${paragraphBase};
  ${weak};
`;

export const pNegative = css`
  ${paragraphBase};
  text-align: left;
  color: ${props => props.theme.text.negative};
`;

export const pPositive = css`
  ${paragraphBase};
  text-align: left;
  color: ${props => props.theme.text.positive};
`;

export const pLeft = css`
  ${paragraphBase};
  text-align: left;
  color: ${props => props.theme.text.positive};
`;

export const pCenter = css`
  ${paragraphBase};
  text-align: center;
  color: ${props => props.theme.text.positive};
`;

export const pCenterNegative = css`
  ${paragraphBase};
  text-align: center;
  color: ${props => props.theme.text.negative};
`;

/**
 * Wizard numbers
 * - negative, center
 */
export const wizardNumberCenterNegative = css`
  font-family: ${props => props.theme.fontFamily};
  font-size: 60px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: ${props => props.theme.colors.seachangeStandout};
`;

export const graphLegendWeak = css`
  font-family: ${props => props.theme.secondaryFont};
  fill: ${props => props.theme.text.weak};
  font-size: 8px;
`;

export const graphLegendStandout = css`
  font-family: ${props => props.theme.secondaryFont};
  fill: ${props => props.theme.text.standout};
  font-size: 8px;
`;

const articleHeaderBase = css`
  ${headerBase};
  ${positive};
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.22;
`;

const articleBase = css`
  ${pLeftWizardTitlePositive};
`;

const articleMargin = css`
  margin: 20px 15px 0 15px;
`;

const articleHeaderMargin = css`
  margin: 60px 15px 0 15px;
`;

export const articleTitle = css`
  ${articleHeaderBase};
  margin: 0 15px;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
`;

export const articleIngress = css`
  ${articleHeaderBase};
  ${weak};
  font-size: 24px;
  text-align: center;
  line-height: 1.42;
  margin: 30px 15px;
`;

export const articlePublished = css`
  ${articleBase};
  ${weak};
  margin: 30px 30px 0 30px;
  font-size: 11px;
  font-weight: bold;
`;

export const articleImageDescription = css`
  ${pCenter};
  ${pWeak};
  margin-top: 10px;
`;

export const articleH1 = css`
  ${articleHeaderBase};
  ${articleHeaderMargin};
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 1.5px;
`;

export const articleH2 = css`
  ${h2Base};
  ${articleHeaderMargin};
`;

export const articleH3 = css`
  ${articleHeaderBase};
  ${articleHeaderMargin};
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 1.5px;
`;

export const articleP = css`
  ${articleBase};
  ${articleMargin};
`;

export const articleOL = css`
  ${articleBase};
  ${articleMargin};
`;

export const articleUL = css`
  ${articleBase};
  ${articleMargin};
`;

export const articleQuote = css`
  ${articleHeaderBase};
  ${articleHeaderMargin};
  ${weak};
  font-size: 24px;
  line-height: 1.42;
  border-left: 5px solid ${props => props.theme.colors.brand2Hue4};
  padding-left: 15px;
  margin-bottom: 60px;
`;
