import React from 'react';
import { useMessageGetter } from 'react-message-context';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { logoNegative } from '../../assets/images';
import media from '../../styles/media';
import { pNegative, labelLeftNegative } from '../../styles/text';
import { interpolate } from '../../util/text';

const Footer = () => {
  const messages = useMessageGetter('fragments.footer');
  return (
    <FooterContainer>
      <Logo src={logoNegative} title="Logo" />
      <Paragraph>{messages('copyright')}</Paragraph>
      <Paragraph>
        {interpolate(messages('legal.text'), {
          $1: <FooterLink to="/privacy-policy">{messages('legal.pp')}</FooterLink>,
          $2: <FooterLink to="/terms-of-service">{messages('legal.tos')}</FooterLink>,
        })}
      </Paragraph>
      <Paragraph>
        {messages('version', { version: process.env.REACT_APP_VERSION || 'local' })}
      </Paragraph>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  background-color: ${({ theme }) => theme.footer.bg};
  margin-top: 70px;
  padding: 40px 30px 100px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.desktop`
    padding: 40px 30px;
  `}
`;

const Logo = styled.img.attrs(() => ({
  width: '73',
  height: '18',
}))`
  height: 18px;
`;

const Paragraph = styled.p`
  ${pNegative};
  color: ${({ theme }) => theme.footer.text};
  margin: 15px 0 0 0;
`;

const FooterLink = styled(Link)`
  ${labelLeftNegative};
  color: ${({ theme }) => theme.footer.text};
`;

export default Footer;
