import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  wizardNumberCenterNegative,
  labelCenterNegative,
  bigNumberUnitNegative,
} from '../../../styles/text';

const WizardValueText = ({ value, valueUnit, valueDescription }) => {
  return (
    <ValueText>
      {value}
      {valueUnit && <ValueUnit>{valueUnit}</ValueUnit>}
      {valueDescription && <ValueDescriptionText>{valueDescription}</ValueDescriptionText>}
    </ValueText>
  );
};

WizardValueText.propTypes = {
  value: PropTypes.any.isRequired,
  valueDescription: PropTypes.string,
  valueUnit: PropTypes.string,
};

const ValueText = styled.div`
  ${wizardNumberCenterNegative};
  margin-bottom: 40px;
`;

const ValueUnit = styled.span`
  ${bigNumberUnitNegative};
`;

const ValueDescriptionText = styled.label`
  ${labelCenterNegative};
  margin-top: 10px;
  display: block;
`;

export default WizardValueText;
