import React, { useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import navigation, { navigationParents } from '../../../functionalities/navigation';
import Middle from '../../../components/middle/Middle';
import PathBar from '../../../components/top/pathBar/PathBar';
import FixedBack from '../../../components/top/FixedBack';
import moment from 'moment';
import Message from '../../../components/messages/Message';
import NewMessage from '../../../components/messages/NewMessage';
import Hr from '../../../components/divider/Hr';
import CenteredContent, {
  CenteredContentFlex,
} from '../../../components/container/CenteredContent';
import { useApp } from '../../../context/appContext';
import { useTrackPageView } from '../../../hooks/useTrackPageView';
import { useMessageGetter } from 'react-message-context';
import { loadReminders } from '../../../context/actions';
import InfiniteScroll from '../../../components/InfiniteScroll';

const thisPage = navigationParents.messages.page;

const ChallengeRemindersPage = ({ exiting, wrapper: Wrapper }) => {
  if (!exiting) navigation.setPage(thisPage);
  const messages = useMessageGetter('components');
  useTrackPageView();

  const [
    { user, userMessages, isLoadingMoreReminders, hasMoreReminders, nextReminderOffset },
    dispatch,
  ] = useApp();

  const loadMoreReminders = useCallback(() => {
    if (!isLoadingMoreReminders) {
      loadReminders(dispatch, nextReminderOffset);
    }
  }, [dispatch, isLoadingMoreReminders, nextReminderOffset]);

  if (!user) {
    // TODO: Show different content if user is not logged in
    console.log('No user. This should do something');
  }

  if (!userMessages.length) return <Wrapper>{null}</Wrapper>;

  return (
    <Wrapper>
      <CenteredContent>
        <FixedBack />
      </CenteredContent>
      <PathBar
        pageTitle={messages('messages.reminders.title')}
        level={1}
        bwHeader={true}
        posAbsolute={false}
      />
      <Middle>
        <Centered>
          <InfiniteScroll
            dataLength={(userMessages || []).length}
            loadMore={loadMoreReminders}
            hasMore={hasMoreReminders}
            endMessage={messages('messages.reminders.allLoaded')}
          >
            {userMessages.map((msg, idx) =>
              msg.readAt !== null ? (
                <Fragment key={`read-${idx}`}>
                  <Message
                    read={true}
                    message={msg.content}
                    sendDate={moment(msg.createdAt).format('MMM D')}
                    from={msg.from}
                    footerTexts={[
                      messages('messages.reminders.day', { number: msg.challengeDay }),
                      messages('messages.reminders.challenge', {
                        csn: msg.csn,
                      }) || msg.challenge,
                    ]}
                  />
                  <Divider key={`hr-${idx}`} />
                </Fragment>
              ) : (
                <NewMessage
                  key={`unread-${idx}`}
                  read={false}
                  message={msg.content}
                  sendDate={moment(msg.createdAt).format('MMM D')}
                  from={msg.from}
                  footerTexts={[
                    messages('messages.reminders.day', { number: msg.challengeDay }),
                    messages('messages.reminders.challenge', {
                      csn: msg.csn,
                    }) || msg.challenge,
                  ]}
                />
              ),
            )}
          </InfiniteScroll>
        </Centered>
      </Middle>
    </Wrapper>
  );
};

ChallengeRemindersPage.propTypes = {
  exiting: PropTypes.bool,
  wrapper: PropTypes.func.isRequired,
};

export default ChallengeRemindersPage;

const Centered = styled(CenteredContentFlex)`
  padding: 15px 0 0;
`;

const Divider = styled(Hr)`
  margin-bottom: 0;
`;
