import React from 'react';
import styled from 'styled-components';
import { useMessageGetter } from 'react-message-context';
import ButtonIcon, { ButtonIconContainer } from '../button-icon/ButtonIcon';
import ResponsiveStickyContainer from './ResponsiveStickyContainer';
import useGoBack from '../../hooks/useGoBack';

const FixedBack = () => {
  const messages = useMessageGetter();
  const goBack = useGoBack();

  return (
    <Container>
      {
        <ButtonIcon
          onClick={goBack}
          type="arrowLeftPositive"
          label={messages('general.button.back')}
        />
      }
    </Container>
  );
};

export default FixedBack;

const Container = styled(ResponsiveStickyContainer)`
  ${ButtonIconContainer} {
    background-color: ${props => props.theme.colors.soft1} !important;
  }
`;
