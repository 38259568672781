import React, { useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';
import { ThemeContext } from 'styled-components';

import LoadingDotsIndicator from '../indicator/LoadingDotsIndicator';
import { waitBeforeSpinnerDuration } from '../../styles/durations';
import elements from '../../styles/elements';

const spinnerTransitionDuration = 300;

export const SpinnerPosition = {
  Top: 'top',
  Center: 'center',
};

/**
 * Component for showing content, which might not be ready yet. If content is
 * not ready yet, pass `null` as children.
 *
 * If content is ready at first render, it is shown as is.
 *
 * If content is ready before `spinnerDelay`, it is animated into place.
 *
 * If content is ready after `spinnerDelay`, a loading indicator is shown. When
 * content appears, spinner will fade away and content is animated into place.
 */
const ContentWithSpinner = ({
  children,
  spinnerDelay = waitBeforeSpinnerDuration,
  spinnerPosition = SpinnerPosition.Top,
}) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [spinnerFadingOut, setSpinnerFadingOut] = useState(false);
  const initialSpinnerTimer = useRef(0);
  const theme = useContext(ThemeContext);

  /** When this component mounts, set a timer for showing the spinner */
  useEffect(() => {
    if (!children) {
      initialSpinnerTimer.current = setTimeout(() => setShowSpinner(true), spinnerDelay);
      return () => clearTimeout(initialSpinnerTimer.current);
    }
  }, [spinnerDelay, children]);

  /**
   * When children appear, start fading out the spinner. If the spinner is not
   * yet visible, then make sure it wont even be shown.
   */
  useEffect(() => {
    if (children) {
      clearTimeout(initialSpinnerTimer.current);
      if (showSpinner) setSpinnerFadingOut(true);
      setShowSpinner(false);
    }
  }, [children, showSpinner]);

  return (
    <>
      <Transition
        in={showSpinner}
        timeout={spinnerTransitionDuration}
        unmountOnExit
        onExited={() => setSpinnerFadingOut(false)}
      >
        {state => (
          <LoadingIndicatorContainer
            show={state === 'entering' || state === 'entered'}
            verticallyCentered={spinnerPosition === SpinnerPosition.Center}
          >
            <LoadingDotsIndicator
              size="8px"
              round
              bgImage={theme.contentWithSpinner.loadingDotsBgImage}
            />
          </LoadingIndicatorContainer>
        )}
      </Transition>

      <ChildrenContainer show={children && !spinnerFadingOut}>{children}</ChildrenContainer>
    </>
  );
};

ContentWithSpinner.propTypes = {
  children: PropTypes.node,
  /** Delay in millisecond before showing spinner when there is no content yet. */
  spinnerDelay: PropTypes.number,
  spinnerPosition: PropTypes.oneOf(Object.values(SpinnerPosition)),
};

export default ContentWithSpinner;

const LoadingIndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: ${p => (p.verticallyCentered ? 'center' : 'flex-start')};
  height: calc(100vh - ${elements.navBarHeightPx}px);
  margin-top: ${p => (p.verticallyCentered ? 0 : 50)}px;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition-property: opacity;
  transition-duration: ${spinnerTransitionDuration}ms;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
`;

const ChildrenContainer = styled.div`
  opacity: ${({ show }) => (show ? 1 : 0)};
  transform: ${({ show }) => (show ? 'none' : 'translateY(50px)')};
  transition: transform 300ms cubic-bezier(0.05, 0.7, 0.1, 1.2),
    opacity 150ms cubic-bezier(0.25, 0.1, 0.25, 1);
`;
