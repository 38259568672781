import styled from 'styled-components';

const Split = styled.div`
  display: flex;
  flex-direction: row;
  & > * {
    width: 50%;
    max-width: 50%;
    min-width: 50%;
  }
`;

export default Split;
