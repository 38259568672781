import React, { useState, useEffect } from 'react';
import { useMessageGetter } from 'react-message-context';
import styled from 'styled-components';
import { Groups } from 'isomorphic';
import { GroupStates } from 'isomorphic/constants';
import ModalFullscreen from '../../../components/modal/ModalFullscreen';
import { useApp } from '../../../context/appContext';
import { pWeak } from '../../../styles/text';
import { theme } from '../../../theme';
import SortingOptionsSelector from '../../../components/sorting/SortingOptionsSelector';
import MemberList from '../../../components/groups/member-list/MemberList';
import { seachangeTextAlpha15 } from '../../../styles/colors';
import getSortingOptions from './sortingOptions';
import Box from '../../../components/container/Box';
import { MatchShapeOfParams, HistoryShape, LocationShape } from '../../../util/shapes';
import ModalGroupSubtitle from '../../../components/modal/ModalGroupSubtitle';
import { loadGroup } from '../../../context/actions';
import { useTrackPageView } from '../../../hooks/useTrackPageView';

const GroupMembersPage = ({ match, history, location }) => {
  useTrackPageView();

  const { challengeId, groupId } = match.params;
  const [{ user, [groupId]: group }, dispatch] = useApp();
  const messages = useMessageGetter('pages.group.members');
  const sortingOptions = getSortingOptions(messages);
  const sortByNavigation = new URLSearchParams(location.search).get('initialSortBy');
  const [selectedSortingOption, setSelectedSortingOption] = useState(
    sortingOptions[sortByNavigation || 'default'],
  );

  useEffect(() => {
    if (!group) loadGroup(dispatch, groupId);
  }, [dispatch, groupId, group]);

  if (!group)
    return (
      <ModalFullscreen
        title={messages('title_unknown_amount')}
        onClose={history.goBack}
        theme={theme('white')}
      >
        {null}
      </ModalFullscreen>
    );
  const groupStatus = Groups.getGroupStatus(group.startDate, group.durationDays);
  const members = Object.values(group.members)
    .map(member => ({
      ...member,
      isCaptain: member.userId === group.captain.userId,
      isLoggedIn: member.userId === user.userId,
    }))
    .sort(selectedSortingOption.compareFn);
  const loggedInUserInGroup = members.some(m => m.isLoggedIn);
  const optionsForSelector = Object.values(sortingOptions).map(option => ({
    ...option,
    disabled:
      option.disabledForState === groupStatus ||
      (!loggedInUserInGroup && option.disabledWhenLoggedInUserNotInGroup),
  }));
  const legendText = messages(
    `legend.${
      groupStatus === GroupStates.hatching
        ? 'hatching'
        : selectedSortingOption.id === 'smallestFootprint'
        ? 'footprint'
        : 'default'
    }`,
  );
  const scoreType =
    groupStatus === GroupStates.hatching
      ? 'initialCo2Kg'
      : selectedSortingOption.id === 'smallestFootprint'
      ? 'yearlyFootPrintCo2Kg'
      : 'totalReductionCo2Kg';

  const gotoGroup = () => history.push(`/challenges/${challengeId}/group/${groupId}`);

  return (
    <ModalFullscreen
      title={messages('title', { amount: members.length })}
      onClose={history.goBack}
      theme={theme('white')}
      subtitle={
        <ModalGroupSubtitle group={group} onClick={gotoGroup} inGroup marginBottom="14px" />
      }
      border={!!group}
    >
      <Header>
        <SortingOptionsSelector
          selectedOption={selectedSortingOption}
          onSelect={setSelectedSortingOption}
          options={optionsForSelector}
        />
        {loggedInUserInGroup && <Legend>{legendText}</Legend>}
      </Header>
      <Box margin="0 30px">
        <MemberList
          members={members}
          showStandings={selectedSortingOption.showStandings}
          scoreType={scoreType}
          groupStatus={groupStatus}
          showScore={loggedInUserInGroup}
        />
      </Box>
    </ModalFullscreen>
  );
};

GroupMembersPage.propTypes = {
  match: MatchShapeOfParams(['challengeId', 'groupId']),
  history: HistoryShape.isRequired,
  location: LocationShape.isRequired,
};

export default GroupMembersPage;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px ${seachangeTextAlpha15};
  padding: 13px 30px 18px;
`;

const Legend = styled.div`
  ${pWeak}
`;
