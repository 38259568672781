import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { pWeak, pLeft, labelLeftPositive } from '../../styles/text';
import ButtonIcon from '../button-icon/ButtonIcon';

export const POST_TYPE = {
  POST: 1,
  PLACEHOLDER: 2,
  ACTION: 3,
};

const Post = ({ author, authorTitle, iconType, text, type }) => {
  return (
    <Container type={type}>
      <Content>
        {(author || authorTitle) && type !== POST_TYPE.PLACEHOLDER && (
          <Author>
            {author && <AuthorName>{author}</AuthorName>}
            {authorTitle && <AuthorTitle>{authorTitle}</AuthorTitle>}
          </Author>
        )}
        {type === POST_TYPE.PLACEHOLDER && <PlaceholderText>{text}</PlaceholderText>}
        {type !== POST_TYPE.PLACEHOLDER && <Text>{text}</Text>}
      </Content>
      {iconType && <ButtonIcon type={iconType} label="" bg={false} />}
    </Container>
  );
};

Post.propTypes = {
  author: PropTypes.string,
  authorTitle: PropTypes.string,
  type: PropTypes.number,
  iconType: PropTypes.string,
  text: PropTypes.string.isRequired,
};

export default Post;

function getPostBg({ type, theme }) {
  switch (type) {
    case POST_TYPE.POST:
      return theme.colors.soft1;
    case POST_TYPE.PLACEHOLDER:
      return theme.colors.soft4;
    default:
      return 'initial';
  }
}

const Container = styled.div`
  border-radius: ${({ type }) => (type !== POST_TYPE.ACTION ? '8px' : 'initial')};
  border-top-left-radius: 0px;
  background-color: ${getPostBg};
  padding: ${({ type }) => (type !== POST_TYPE.ACTION ? '7px 8px 10px 8px' : '0 0 0 8px')};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Content = styled.div`
  flex: 1;
`;

const Author = styled.div`
  display: flex;
  margin-bottom: 2px;
`;

const AuthorTitle = styled.div`
  ${pWeak};
`;

const AuthorName = styled.div`
  ${labelLeftPositive};
  margin-right: 5px;
`;

const Text = styled.div`
  ${pLeft};
`;

const PlaceholderText = styled.div`
  ${pWeak};
`;
