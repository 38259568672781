import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { HeroHeaderContainer } from './HeaderStyle';
import { pWizardTitle, h1WizardTitle } from '../../styles/text';
import CenteredContent from '../container/CenteredContent';

export const HeroHeaderVariant = {
  ROUNDED: 'ROUNDED',
  DEFAULT: 'DEFAULT',
};

const HeroHeader = ({
  img,
  bgImage,
  title,
  subtitle,
  children,
  height,
  variant = HeroHeaderVariant.DEFAULT,
  style = {},
}) => {
  const hasTopPadding = img || title || subtitle;
  return (
    <HeroHeaderContainer
      bgImage={bgImage ? bgImage.default : undefined}
      hasTopPadding={hasTopPadding}
      height={height}
      variant={variant}
      style={style}
    >
      <Centered>
        {img && <Image src={img.default} alt="" />}
        {title && <Title>{title}</Title>}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {children}
      </Centered>
    </HeroHeaderContainer>
  );
};

HeroHeader.propTypes = {
  img: PropTypes.any,
  bgImage: PropTypes.any,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  height: PropTypes.number,
  children: PropTypes.node,
  variant: PropTypes.oneOf(Object.values(HeroHeaderVariant)),
  style: PropTypes.any,
};

export default HeroHeader;

const Centered = styled(CenteredContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.img`
  margin: 45px 0 42px 0;
`;

const Title = styled.h1`
  ${h1WizardTitle};
  color: ${props => props.theme.header.text};
  margin-top: 0;
  margin-bottom: 20px;
`;

const Subtitle = styled.p`
  ${pWizardTitle};
  color: ${props => props.theme.header.text};
  margin: 0 0 30px 0;
`;
