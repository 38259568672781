import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { pLeft, pWeak } from '../../styles/text';
import { getChallengeIconSrc } from '../../assets/images';

const CalculusCell = ({ icon, label, description }) => {
  return (
    <Cell>
      <CellContainer>
        {icon && (
          <Icon>
            <IconImg src={getChallengeIconSrc(icon)} />
          </Icon>
        )}
        <CellContent>
          <CellLabel>{label}</CellLabel>
          {description && <CellDescription>{description}</CellDescription>}
        </CellContent>
      </CellContainer>
    </Cell>
  );
};

CalculusCell.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default CalculusCell;

const Cell = styled.td`
  height: 50px;
`;

const CellContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`;

const Icon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.brand3Hue1};
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconImg = styled.img`
  width: 26px;
  height: 26px;
`;

export const CellContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;

const CellLabel = styled.div`
  ${pLeft};
`;

const CellDescription = styled.div`
  ${pWeak};
`;
