import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import HeroHeader from './HeroHeader';
import { h1WizardTitle, h2CenterNegative, pWizardTitle } from '../../styles/text';
import { theme } from '../../theme';
import { Card, CardValuesRow } from '../card';
import CardItem from '../card/CardItem';

const images = require.context('../../assets/images', true);

const ChallengeHeader = ({ challengeId, title, subtitle, heading, data = [] }) => {
  return (
    <HeroHeader
      bgImage={images(`./${challengeId}-bg.svg`)}
      style={{ marginBottom: '153px', paddingLeft: 0, paddingRight: 0 }}
    >
      <Heading>{heading}</Heading>
      <Illustration src={images(`./${challengeId}-logo.svg`).default} alt="" />
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <ThemeProvider theme={theme('white')}>
        <FactCardContainer>
          <Card spacing={'dense'} flat={false}>
            {data.map((row, i) => (
              <CardValuesRow key={`row-${i}`}>
                {row.map((col, y) => (
                  <CardItem
                    key={`col-${i}-${y}`}
                    label={col.label}
                    value={col.value}
                    valueUnit={col.valueUnit}
                    description={col.description}
                  />
                ))}
              </CardValuesRow>
            ))}
          </Card>
        </FactCardContainer>
      </ThemeProvider>
    </HeroHeader>
  );
};

ChallengeHeader.propTypes = {
  challengeId: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  heading: PropTypes.string,
  data: PropTypes.array,
};

export default ChallengeHeader;

const Heading = styled.header`
  ${h2CenterNegative};
  color: ${props => props.theme.header.text};
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Illustration = styled.img`
  margin: 50px 0 42px 0;
  height: 110px;
`;

const Title = styled.h1`
  ${h1WizardTitle};
  color: ${props => props.theme.header.text};
  margin-top: 0;
  margin-bottom: 20px;
`;

const Subtitle = styled.p`
  ${pWizardTitle};
  color: ${props => props.theme.header.text};
  margin: 0 0 40px 0;
`;

const FactCardContainer = styled.div`
  width: 100%;
  margin-bottom: -153px;
`;
