import React from 'react';
import PropTypes from 'prop-types';
import { useMessageGetter } from 'react-message-context';
import { Link } from 'react-router-dom';
import { Constants, Groups } from 'isomorphic';
import { GroupStates } from 'isomorphic/constants';
import styled from 'styled-components';

import Co2 from '../../../functionalities/Co2';
import Card from '../../card/Card';
import { CardValuesRow } from '../../card';
import SmallLeaders from '../member-list/SmallLeaders';
import {
  GroupGoalShape,
  GroupProgressShape,
  PersonalGoalShape,
  UserShape,
} from '../../../util/shapes';
import SmallButton from '../../button/SmallButton';
import {
  mapGroupProgressDataToCumulativeActualProgress,
  mapPersonalDailyAnswersToCumulativeActualProgress,
} from '../../data-cards/ProgressCard';
import CardItem from '../../card/CardItem';

export default function CombinedProgressCard({
  status,
  groupGoalData,
  groupProgressData,
  personalGoalData,
  groupDurationDays,
  reductionLeader,
  footprintLeader,
  fullStatsLink,
}) {
  const messages = useMessageGetter('components.group_page_card');

  const personalLabel = messages(`subheader.personal.${status}`);
  const personalValue = new Co2(
    status === GroupStates.hatching
      ? personalGoalData.initialCo2Kg - personalGoalData.targetCo2Kg
      : Groups.getTotalReductionKg(personalGoalData.dailyAnswers),
  ).getScaledValue();
  const personalDailyValues = mapPersonalDailyAnswersToCumulativeActualProgress(
    personalGoalData.dailyAnswers,
  );

  const personalChartData =
    personalGoalData.initialCo2Kg > 0
      ? {
          valuesAmount: groupDurationDays,
          values: personalDailyValues,
          refStartValue: 0.0,
          refEndValue: (personalGoalData.initialCo2Kg / 365) * groupDurationDays,
          targetStartValue: 0.0,
          targetEndValue: (personalGoalData.targetCo2Kg / 365) * groupDurationDays,
          currentTotalReduction: personalValue.value,
        }
      : null;

  const groupLabel = messages(`subheader.group.${status}`);
  const groupValue = new Co2(
    status === GroupStates.hatching
      ? groupGoalData.totalInitialCo2Kg - groupGoalData.totalTargetCo2Kg
      : Groups.getGroupTotalReductionKg(groupProgressData),
  ).getScaledValue();
  const groupDailyValues = mapGroupProgressDataToCumulativeActualProgress(groupProgressData);

  const groupChartData =
    groupGoalData.totalInitialCo2Kg > 0
      ? {
          valuesAmount: groupDurationDays,
          values: groupDailyValues,
          refStartValue: 0.0,
          refEndValue: (groupGoalData.totalInitialCo2Kg / 365) * groupDurationDays,
          targetStartValue: 0.0,
          targetEndValue: (groupGoalData.totalTargetCo2Kg / 365) * groupDurationDays,
          currentTotalReduction: groupValue.value,
        }
      : null;

  const showLeaders = status !== GroupStates.hatching;
  const unit = messages(`unit.${status}`);
  return (
    <Link to={fullStatsLink}>
      <Card spacing="dense">
        <CardValuesRow>
          <CardItem
            label={personalLabel}
            value={personalValue.value}
            valueUnit={personalValue.unitAbbr}
            description={unit}
            chartData={personalChartData}
            isIncrease={personalValue.value < 0}
          />
          <CardItem
            label={groupLabel}
            value={groupValue.value}
            valueUnit={groupValue.unitAbbr}
            description={unit}
            chartData={groupChartData}
            isIncrease={groupValue.value < 0}
          />
        </CardValuesRow>
        {showLeaders && (
          <SmallLeaders reductionLeader={reductionLeader} footprintLeader={footprintLeader} />
        )}
        <Action closer={showLeaders}>
          <SmallButton type="positive" text={messages('full_stats_link_text')} />
        </Action>
      </Card>
    </Link>
  );
}

CombinedProgressCard.propTypes = {
  status: PropTypes.oneOf(Object.values(Constants.GroupStates)),
  groupGoalData: GroupGoalShape.isRequired,
  groupProgressData: GroupProgressShape,
  personalGoalData: PersonalGoalShape,
  groupDurationDays: PropTypes.number.isRequired,
  groupStartDate: PropTypes.string.isRequired,
  reductionLeader: UserShape,
  footprintLeader: UserShape,
  fullStatsLink: PropTypes.string,
};

const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ closer }) => (closer ? 19 : 34)}px;
`;
