import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMessageGetter } from 'react-message-context';
import { ModalContent } from '../../../../components/modal/ModalFullscreen';
import { WizardHeading, WizardInputText } from '../../../../components/wizard';
import {
  AbsoluteWizardContentContainer,
  AbsoluteWizardHeaderContainer,
  RelativeWizardStepContent,
} from '../../../../components/wizard/container/PositionedWizardContainers';
import WizardContentButton from '../../../../components/wizard/form/WizardContentButton';
import WizardInlineLink from '../../../../components/wizard/text/WizardInlineLink';
import WizardParagraph from '../../../../components/wizard/text/WizardParagraph';
import { interpolate } from '../../../../util/text';

const ProfileInfoStep = ({ givenName, familyName, onChange, onLeaveForm, onCreateAccount }) => {
  const messages = useMessageGetter('pages.wizards.auth.step_5_profile_info');
  const [loading, setLoading] = useState(false);
  const [validate, setValidate] = useState(false);

  const createAccount = async () => {
    if (!givenName || !familyName) {
      setValidate(true);
      return;
    }

    setLoading(true);
    await onCreateAccount();
    setLoading(false);
  };

  return (
    <RelativeWizardStepContent>
      <AbsoluteWizardHeaderContainer>
        <WizardHeading title={messages('title')} subtitle={messages('subtitle')} />
      </AbsoluteWizardHeaderContainer>
      <AbsoluteWizardContentContainer>
        <ModalContent>
          <WizardInputText
            name="givenName"
            required={validate}
            label={messages('input.givenName.label')}
            placeholder={messages('input.givenName.placeholder')}
            value={givenName}
            onChange={onChange}
          />
          <WizardInputText
            name="familyName"
            required={validate}
            label={messages('input.familyName.label')}
            placeholder={messages('input.familyName.placeholder')}
            value={familyName}
            onChange={onChange}
            description={messages('input.familyName.desc')}
          />
          <WizardParagraph style={{ textAlign: 'center', marginTop: '30px' }}>
            {interpolate(messages('info.legal.text'), {
              $1: (
                <WizardInlineLink to="/auth/terms-of-service" onClick={onLeaveForm}>
                  {messages('info.legal.$1')}
                </WizardInlineLink>
              ),
              $2: (
                <WizardInlineLink to="/auth/privacy-policy" onClick={onLeaveForm}>
                  {messages('info.legal.$2')}
                </WizardInlineLink>
              ),
            })}
          </WizardParagraph>
          <WizardContentButton
            onClick={createAccount}
            loading={loading}
            text={messages('continue')}
          />
        </ModalContent>
      </AbsoluteWizardContentContainer>
    </RelativeWizardStepContent>
  );
};

ProfileInfoStep.propTypes = {
  familyName: PropTypes.string,
  givenName: PropTypes.string,
  onLeaveForm: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onCreateAccount: PropTypes.func.isRequired,
};

export default ProfileInfoStep;
