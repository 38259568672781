import styled from 'styled-components';
import { Constants, Groups } from 'isomorphic';
import Co2 from '../../functionalities/Co2';
import { pNegative, labelLeftNegative } from '../../styles/text';

export const BoldText = styled.span`
  ${labelLeftNegative};
  color: ${props => props.theme.card.textSecondary};
`;

export const PreSpaceBoldText = styled(BoldText)`
  &:before {
    content: ' ';
    white-space: pre;
  }
`;

export const Text = styled.span`
  ${pNegative};
  color: ${props => props.theme.card.textSecondary};
`;

export const getGroupInfo = (
  messages,
  startDate,
  durationDays,
  groupYearlyReductionTargetKg,
  reductionKg,
) => {
  const { GroupStates } = Constants;
  const status = Groups.getGroupStatus(startDate, durationDays);
  let stateLabel, progressLabel, progressKgCo2;
  // eslint-disable-next-line
  switch (status) {
    case GroupStates.hatching:
      stateLabel = messages('state.hatching', { days: Math.abs(Groups.getGroupDay(startDate)) });
      progressLabel = messages('progresslabel.hatching');
      progressKgCo2 = new Co2(groupYearlyReductionTargetKg);
      break;
    case GroupStates.active:
      stateLabel = messages('state.active', { day: Groups.getGroupDay(startDate) });
      progressLabel = messages('progresslabel.active');
      progressKgCo2 = new Co2(reductionKg);
      break;
    case GroupStates.alumni:
      stateLabel = messages('state.alumni');
      progressLabel = messages('progresslabel.alumni');
      progressKgCo2 = new Co2(reductionKg);
      break;
  }

  return [stateLabel, progressLabel, progressKgCo2];
};
