import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getDataPathBezier } from './LineChartHelpers';
import { graphLegendWeak, graphLegendStandout } from '../../styles/text';
import { LineChartShape } from '../../util/shapes';

const LineChart = ({
  data = { values: [] },
  dimensions = { w: 150, h: 90 },
  targetLegend,
  refLegend,
  refType = 'gradient',
  showTarget = true,
  isIncrease = false,
}) => {
  const margin = { top: 10, left: 12, bottom: 12, right: 5 };
  const width = dimensions.w - margin.left - margin.right;
  const height = dimensions.h - margin.top - margin.bottom;
  const { refEndValue = 1.0 } = data;
  const maxValueY = Math.max(
    refEndValue,
    data.values.map(v => v.value).reduce((acc, v) => Math.max(acc, v), 0),
  );

  // Reference path should always go from bottom left to top right corner (unless data values contains bigger numbers)
  const refPath = `M${margin.left},${height + margin.top}
     L${margin.left + width},${margin.top + height - (height * refEndValue) / maxValueY}`;
  const refPathWithBg = `${refPath}
     L${margin.left + width},${margin.top + height}
     L${margin.left},${margin.top + height}
     Z`;

  const { targetStartValue, targetEndValue } = data;
  const targetPath = getTargetPath(
    margin,
    width,
    height,
    targetStartValue,
    targetEndValue / maxValueY,
  );

  const { dataPath, markerCoords } = getDataPathBezier(
    data.values,
    data.valuesAmount,
    dimensions,
    margin,
    maxValueY,
  );
  const dashedStrokeProps = {
    strokeDasharray: '1 3',
    strokeOpacity: '0.4',
    strokeLinecap: 'round',
  };

  return (
    <svg width="100%" height="100%" viewBox={`0 0 ${dimensions.w} ${dimensions.h}`}>
      <defs>
        <linearGradient
          id="bgGradient"
          gradientUnits="userSpaceOnUse"
          x1="28.08%"
          y1="-10.22%"
          x2="71.92%"
          y2="110.22%"
        >
          <stop stopColor="#eeeeee" />
          <stop offset="1" stopColor="#ffffff" />
        </linearGradient>
      </defs>
      {refType === 'gradient' ? (
        <path d={refPathWithBg} fill="url(#bgGradient)" />
      ) : refType === 'dashed' ? (
        <LinePath d={refPath} {...dashedStrokeProps} vectorEffect="non-scaling-stroke" />
      ) : (
        <path d={refPath} stroke="#eeeeee" vectorEffect="non-scaling-stroke" />
      )}
      {showTarget && targetPath && (
        <LinePath d={targetPath} {...dashedStrokeProps} vectorEffect="non-scaling-stroke" />
      )}
      {data.values.length > 0 && (
        <Fragment>
          <LinePath
            d={dataPath}
            strokeWidth="2"
            fill="none"
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            isIncrease={isIncrease}
          />
          <NonScalingCircle
            x={markerCoords.x}
            y={markerCoords.y}
            strokeWidth="14px"
            opacity="0.15"
            isIncrease={isIncrease}
          />
          <NonScalingCircle
            x={markerCoords.x}
            y={markerCoords.y}
            strokeWidth="10px"
            opacity="0.3"
            isIncrease={isIncrease}
          />
          <NonScalingCircle
            x={markerCoords.x}
            y={markerCoords.y}
            strokeWidth="6px"
            isIncrease={isIncrease}
          />
        </Fragment>
      )}
      {refLegend && (
        <RefLegendText
          alignmentBaseline="hanging"
          dominantBaseline="hanging"
          textAnchor="end"
          x={margin.left + width}
          y={margin.top + height - (height * refEndValue) / maxValueY - 10}
        >
          {refLegend.toUpperCase()}
        </RefLegendText>
      )}
      {targetLegend && targetPath && (
        <TargetLegendText
          textAnchor="end"
          x={margin.left + width}
          y={margin.top + height - (height * targetEndValue) / maxValueY - 1}
        >
          {targetLegend.toUpperCase()}
        </TargetLegendText>
      )}
    </svg>
  );
};

export const lineChartDataPropType = PropTypes.shape({
  valuesAmount: PropTypes.number.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number,
      value: PropTypes.number,
    }),
  ),
  targetStartValue: PropTypes.number.isRequired,
  targetEndValue: PropTypes.number.isRequired,
  refStartValue: PropTypes.number,
  refEndValue: PropTypes.number,
});

LineChart.propTypes = {
  data: LineChartShape.isRequired,
  targetLegend: PropTypes.string,
  refLegend: PropTypes.string,
  dimensions: PropTypes.shape({
    w: PropTypes.number,
    h: PropTypes.number,
  }),
  refType: PropTypes.oneOf(['gradient', 'solid', 'dashed']),
  showTarget: PropTypes.bool,
  isIncrease: PropTypes.bool,
};

export default LineChart;

const RefLegendText = styled.text`
  ${graphLegendWeak};
  fill: ${props => props.theme.lineChart.refLegendColor};
`;

const TargetLegendText = styled.text`
  ${graphLegendStandout};
  fill: ${props => props.theme.lineChart.lineColor};
`;

const LinePath = styled.path.attrs(props => ({
  stroke: props.isIncrease
    ? props.theme.lineChart.lineColorIncrease
    : props.theme.lineChart.lineColor,
}))``;

const NonScalingCircle = ({ x, y, value = 0, ...props }) => (
  <LinePath
    d={`M ${x}, ${y} h 0`}
    vectorEffect="non-scaling-stroke"
    strokeLinecap="round"
    {...props}
  />
);

NonScalingCircle.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  value: PropTypes.number,
};

function getTargetPath(margin, width, height, targetStartValue, targetEndValue) {
  if (!targetEndValue) {
    return undefined;
  }
  return `
     M${margin.left},${margin.top + height - height * targetStartValue}
     L${margin.left + width},${margin.top + height - height * targetEndValue}
  `;
}
