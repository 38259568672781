import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { OptionRadiobutton } from './option/Option';
import { h2LeftNegative } from '../../../../styles/text';
import { Container, Label } from './WizardSelectCommon';

function prevValueOfArray(array, index) {
  if (index - 1 >= 0) {
    return array[index - 1];
  } else {
    return array[array.length - 1];
  }
}

function nextValueOfArray(array, index) {
  if (index + 1 <= array.length - 1) {
    return array[index + 1];
  } else {
    return array[0];
  }
}

const WizardSelectOne = ({ title, onSelect, options = [], value, name }) => {
  // If radio group is focused, change selections with arrows
  const onKeyUp = e => {
    if (e.keyCode >= 37 && e.keyCode <= 40) {
      e.preventDefault();
      e.stopPropagation();
      const valueIndex = options.findIndex(option => option.value === value);
      if (valueIndex >= 0) {
        if (e.keyCode <= 38) {
          // Prev from left and up arrows
          const prevOption = prevValueOfArray(options, valueIndex);
          onSelect(prevOption.value, name);
        } else {
          // Next from right and down arrows
          const nextOption = nextValueOfArray(options, valueIndex);
          onSelect(nextOption.value, name);
        }
      } else if (options.length > 0) {
        onSelect(options[0].value, name);
      }
    }
  };

  return (
    <Container role="radiogroup" onKeyUp={onKeyUp}>
      {title && <Label>{title}</Label>}
      {options.map(OptionSelector({ name, value, onSelect }))}
    </Container>
  );
};

WizardSelectOne.propTypes = {
  title: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string,
        icon: PropTypes.string,
      }),
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['subtitle']).isRequired,
      }),
    ]),
  ),
  value: PropTypes.any,
  name: PropTypes.string,
};

export default WizardSelectOne;

const OptionSelector = ({ name, value, onSelect }) => (option, index) => {
  if (option.type === 'subtitle') {
    return <OptionSubtitle key={option.key}>{option.title}</OptionSubtitle>;
  } else {
    return (
      <OptionRadiobutton
        key={option.key}
        name={name}
        tabIndex={value === option.value || (!value && index === 0) ? 0 : -1}
        onClick={onSelect}
        value={option.value}
        selected={value === option.value}
        title={option.title}
        subtitle={option.subtitle}
        iconText={option.iconText}
        icon={option.icon}
      />
    );
  }
};

const OptionSubtitle = styled.h3`
  ${h2LeftNegative};
  margin: 0 0 5px 0;

  &:not(:first-of-type) {
    margin-top: 30px;
  }
`;
