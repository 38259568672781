import { ACTIONS, EVENTS } from './actions';
import { isStandalone } from '../util/common';

export function appReducer(state, [type, payload]) {
  switch (type) {
    case ACTIONS.LOAD_CHALLENGES:
      return {
        ...state,
        isLoadingChallenges: true,
      };
    case ACTIONS.LOAD_GROUP:
      return {
        ...state,
        isLoadingGroup: true,
      };
    case ACTIONS.LOAD_PROFILE:
      return {
        ...state,
        isLoadingProfile: true,
      };
    case ACTIONS.LOAD_STATS:
      return {
        ...state,
        isLoadingStats: true,
      };
    case ACTIONS.LOAD_MORE_POSTS:
      return {
        ...state,
        isLoadingMorePosts: true,
      };
    case ACTIONS.LOAD_MORE_NOTIFICATIONS:
      return {
        ...state,
        isLoadingMoreNotifications: true,
      };
    case ACTIONS.LOAD_MORE_REMINDERS:
      return {
        ...state,
        isLoadingMoreReminders: true,
      };
    case EVENTS.LOADED_CHALLENGES:
      return {
        ...state,
        isLoadedChallenges: true,
        isLoadingChallenges: false,
        challenges: payload,
      };
    case EVENTS.LOADED_GROUP:
      return {
        ...state,
        [payload.groupId]: {
          ...(state[payload.group] || {}),
          ...payload.group,
        },
        isLoadingGroup: false,
      };
    case EVENTS.LOADED_PROFILE:
      return {
        ...state,
        isLoadingProfile: false,
        isLoadedProfile: true,
        profile: {
          ...state.profile,
          ...payload,
        },
      };
    case EVENTS.LOADED_MESSAGES:
      return {
        ...state,
        userMessages: [...state.userMessages, ...payload.items],
        hasMoreReminders: payload.hasMore,
        nextReminderOffset: payload.nextOffset,
        isLoadingMoreReminders: false,
      };
    case EVENTS.MARKING_NOTIFICATIONS_READ:
      return {
        ...state,
        submittingReadNotifications: true,
      };
    case EVENTS.MARKING_NOTIFICATIONS_READ_ENDED:
      return {
        ...state,
        submittingReadNotifications: false,
      };
    case EVENTS.LOADED_NOTIFICATIONS:
      return {
        ...state,
        notifications: [
          ...state.notifications.filter(
            n => !(payload.items || []).find(i => i.aspect === n.aspect),
          ),
          ...(payload.items || []),
        ].sort((a, b) => b.createdAt - a.createdAt),
        nextNotificationOffset: payload.nextOffset,
        hasMoreNotifications: payload.hasMore,
        isLoadingMoreNotifications: false,
      };
    case EVENTS.UPDATED_NOTIFICATION_STATUS:
      return {
        ...state,
        notifications: state.notifications.map(notification => ({
          ...notification,
          read:
            payload === 'ALL' || notification.aspect === `notification_${payload}`
              ? true
              : notification.read,
        })),
        submittingReadNotifications: false,
      };
    case EVENTS.LOADED_STATS:
      return {
        ...state,
        isLoadingStats: false,
        isLoadedStats: true,
        stats: {
          ...state.stats,
          ...payload,
        },
      };
    case EVENTS.LOADED_LATEST_ACTIONS:
      if (!payload) {
        return { ...state, isLoadedLatestActions: true };
      }

      return {
        ...state,
        posts: [
          ...payload.map(post => ({
            ...(state.posts.find(p => p.aspect === post.aspect) || {}),
            ...post,
          })),
          ...state.posts.filter(oldPost => !payload.find(post => post.aspect === oldPost.aspect)),
        ],
        isLoadedLatestActions: true,
      };
    case EVENTS.LOADED_POSTS:
      return {
        ...state,
        isLoadingMorePosts: false,
        hasMorePosts: !!payload.length,
        posts: [
          // Override old posts with new loaded ones
          // Some old posts may have replies intact and we do not want to lose them here
          ...payload.map(post => ({
            ...(state.posts.find(p => p.aspect === post.aspect) || {}),
            ...post,
          })),
          // Filter out old posts that we just received
          ...state.posts.filter(oldPost => !payload.find(post => post.aspect === oldPost.aspect)),
        ],
      };
    case EVENTS.LOADED_POST:
      return {
        ...state,
        posts: [
          ...state.posts.filter(oldPost => oldPost.aspect !== payload.post.aspect),
          {
            ...payload.post,
            replies: payload.replies,
          },
        ],
      };
    case EVENTS.LOADED_USER_INFO:
      return {
        ...state,
        userInfo: payload,
      };
    case EVENTS.POSTED_REPLY:
      const replyToId = `feed_${payload.replyToId}`;
      const replyTo = state.posts.find(post => post.aspect === replyToId);
      return {
        ...state,
        posts: [
          ...state.posts.filter(oldPost => oldPost.aspect !== replyToId),
          {
            ...replyTo,
            replyCount: replyTo.replyCount + 1,
            replies: [...(replyTo.replies || []), payload.reply],
          },
        ],
      };
    case ACTIONS.POST_REACTION:
      if (payload.postId.startsWith('feed_')) {
        return mapReactionToPost(state, payload);
      } else {
        return mapReactionToReply(state, payload);
      }
    case EVENTS.LOGIN:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
          givenName: payload.given_name,
          familyName: payload.family_name,
          isLoggedIn: true,
          isLoaded: true,
        },
      };
    case EVENTS.LOGOUT:
      return {
        ...state,
        user: { isLoggedIn: false, isLoaded: true },
        profile: {
          ...initialState.profile,
        },
        userMessages: initialState.userMessages,
        notifications: initialState.notifications,
      };
    case EVENTS.UI_UPDATE_PROFILE_IMAGE:
      return {
        ...state,
        profilePictureUpdated: `?c=${Date.now()}`,
      };
    case EVENTS.UI_UPDATE_GROUP_IMAGE:
      return {
        ...state,
        groupProfilePictureUpdated: {
          ...state.groupProfilePictureUpdated,
          [payload.groupId]: `?c=${Date.now()}`,
        },
      };
    case EVENTS.SERVICE_WORKER_UPDATE_READY:
      return {
        ...state,
        isLoadedNewServiceWorker: true,
        swRegistration: payload.registration,
      };
    case EVENTS.INPUT_FOCUS_CHANGED:
      return {
        ...state,
        isInputFocused: payload.isInputFocused,
      };
    case EVENTS.SET_MAIN_MODAL_INFO:
      return {
        ...state,
        savedScrollPositions: [...state.savedScrollPositions, payload],
      };
    case EVENTS.SAVE_SCROLL_POSITION:
      return {
        ...state,
        savedScrollPositions: state.savedScrollPositions.map(s =>
          s.address === payload.address ? { ...s, scrollPosition: payload.scrollPosition } : s,
        ),
      };
    case EVENTS.REMOVE_SCROLL_POSITION:
      return {
        ...state,
        savedScrollPositions: state.savedScrollPositions.filter(s => s.address !== payload),
      };
    case EVENTS.BEFORE_INSTALL:
      return {
        ...state,
        beforeInstallEvent: payload.event,
      };
    case EVENTS.APP_INSTALLED:
      return {
        ...state,
        isInstalled: true,
      };
    default:
      console.log('Unknown event', type);
      return state;
  }
}

export const initialState = {
  isLoadedChallenges: false,
  isLoadedProfile: false,
  isLoadedStats: false,
  isLoadingChallenges: false,
  isLoadingProfile: false,
  isLoadingStats: false,
  isLoadingMorePosts: false,
  isLoadingMoreNotifications: false,
  isLoadingMoreReminders: false,
  isLoadedLatestActions: false,
  isLoadedNewServiceWorker: false,
  isInputFocused: false,
  isInstalled: isStandalone(),
  swRegistration: null,
  challenges: [],
  posts: [],
  profile: {
    groups: {},
  },
  profilePictureUpdated: '',
  groupProfilePictureUpdated: {},
  stats: {},
  user: { isLoggedIn: false, isLoaded: false },
  userInfo: { lastVisitedMessages: undefined },
  userMessages: [],
  notifications: [],
  hasMoreNotifications: false,
  submittingReadNotifications: false,
  savedScrollPositions: [],
  beforeInstallEvent: null,
};

function mapReactionToReply(state, { postId, reactionType }) {
  let postAspect = `feed_${postId.match(/_(.*?)_/)[1]}`;
  let reactionToPost = state.posts.find(post => post.aspect === postAspect);
  let reactionToReply = reactionToPost.replies.find(reply => reply.aspect === postId);
  let reactions = reactionToReply.reactions;
  let userId = state.user.sub;
  if (!reactions[reactionType]) {
    reactions = {
      ...reactions,
      [reactionType]: {
        [userId]: {
          familyName: state.user.family_name,
          givenName: state.user.given_name,
          userId,
          created_at: Date.now(),
        },
      },
    };
  } else {
    if (reactions[reactionType][userId]) {
      let { [userId]: value, ...others } = reactions[reactionType];
      reactions = {
        ...reactions,
        [reactionType]: others,
      };
    } else {
      reactions = {
        ...reactions,
        [reactionType]: {
          ...reactions[reactionType],
          [userId]: {
            familyName: state.user.family_name,
            givenName: state.user.given_name,
            userId,
            created_at: Date.now(),
          },
        },
      };
    }
  }

  return {
    ...state,
    posts: [
      ...state.posts.filter(post => post.aspect !== postAspect),
      {
        ...reactionToPost,
        replies: [
          ...reactionToPost.replies.filter(reply => reply.aspect !== postId),
          {
            ...reactionToReply,
            reactions,
          },
        ],
      },
    ],
  };
}

function mapReactionToPost(state, { postId, reactionType }) {
  let reactionToPost = state.posts.find(post => post.aspect === postId);
  let userId = state.user.sub;
  let reactions;
  if (!reactionToPost.reactions[reactionType]) {
    reactions = {
      [reactionType]: {
        [userId]: {
          familyName: state.user.family_name,
          givenName: state.user.given_name,
          userId,
          created_at: Date.now(),
        },
      },
    };
  } else {
    if (reactionToPost.reactions[reactionType][userId]) {
      let { [userId]: value, ...others } = reactionToPost.reactions[reactionType];
      reactions = {
        ...reactionToPost.reactions,
        [reactionType]: others,
      };
    } else {
      reactions = {
        ...reactionToPost.reactions,
        [reactionType]: {
          ...reactionToPost.reactions[reactionType],
          [userId]: {
            familyName: state.user.family_name,
            givenName: state.user.given_name,
            userId,
            created_at: Date.now(),
          },
        },
      };
    }
  }

  return {
    ...state,
    posts: [
      ...state.posts.filter(post => post.aspect !== postId),
      {
        ...reactionToPost,
        reactions,
      },
    ],
  };
}
