import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { labelFormElementNegative, pWeak } from '../../styles/text';
import { CellContent } from './CalculusCell';

const CalculusTable = ({ title, subtitle, children, cellCount }) => {
  const mainHeadingColSpan = subtitle ? Math.round(cellCount / 2) : cellCount;

  return (
    <Table cellSpacing={0} cellPadding={0}>
      <Heading>
        <CalculusTableRow>
          <MainHeading colSpan={mainHeadingColSpan}>{title}</MainHeading>
          {subtitle && (
            <HeadingCell colSpan={cellCount - mainHeadingColSpan}>{subtitle}</HeadingCell>
          )}
        </CalculusTableRow>
      </Heading>
      <TableBody>{children}</TableBody>
    </Table>
  );
};

CalculusTable.propTypes = {
  cellCount: PropTypes.number,
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export default CalculusTable;

export const CalculusTableRow = styled.tr`
  & td:last-child:not(:first-child) ${CellContent} {
    align-items: flex-end;
  }
`;

const Table = styled.table`
  width: 100%;
  &:not(:first-of-type) {
    margin-top: 50px;
  }
  & td {
    border-bottom: 1px solid ${({ theme }) => theme.colors.dull3_15};
  }
`;

const Heading = styled.thead`
  & td {
    padding-bottom: 10px;
  }
`;

const MainHeading = styled.td`
  ${labelFormElementNegative};
`;

const HeadingCell = styled.td`
  ${pWeak};
  text-align: right;
`;

const TableBody = styled.tbody``;
