export const GroupStates = {
  hatching: 'hatching',
  active: 'active',
  alumni: 'alumni',
};

export const DailySuccessValues = {
  success: 'success',
  partly: 'partly',
  fail: 'fail',
};

export const ChallengeTypes = {
  hot_water: 'showering',
  daily_commute: 'commuting'
};

export const Challenges = {
  'hot_water': {
    methods: {
      duration: 'reduce_duration',
      times: 'reduce_times',
    },
    actions: {
      shampoo: 'shampoo',
      daydream: 'daydream',
      personal: 'personal',
    },
    durationCategories: {
      military: 'military',
      efficient: 'efficient',
      relaxed: 'relaxed',
      lengthy: 'lengthy',
      monsoon: 'monsoon',
      manual: 'manual'
    },
    heatingCategories: {
      wood: 'wood',
      geothermal: 'geothermal',
      natural_gas: 'natural_gas',
      oil: 'oil',
      district_heating: 'district_heating',
      electricity: 'electricity',
      unknown: 'unknown'
    }
  },
};

export const ReactionTypes = {
  like: 'like',
};

export const OptoutBitmaskPosition = {
  sms_reminder: 0,
  email_reminder: 1,
  // push_reminder: 2,
  // new_blog_notification: 3,
};
