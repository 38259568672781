import React from 'react';
import styled from 'styled-components';

export const SuccessStates = {
  success: 'success',
  partly: 'partly',
  fail: 'fail',
};

const DailySuccessIndicator = ({ state, active }) => {
  let indicatorComponent;
  switch (state) {
    case SuccessStates.success:
      indicatorComponent = <SuccessDayIndicator />;
      break;
    case SuccessStates.partly:
      indicatorComponent = <PartlyDayIndicator />;
      break;
    case SuccessStates.fail:
      indicatorComponent = <FailDayIndicator />;
      break;
    default:
      indicatorComponent = <DefaultDayIndicator />;
      break;
  }

  if (active) {
    return (
      <ActiveIndicatorOuter>
        <ActiveIndicatorInner>{indicatorComponent}</ActiveIndicatorInner>
      </ActiveIndicatorOuter>
    );
  } else {
    return indicatorComponent;
  }
};

export default DailySuccessIndicator;

const dotSize = 8;

const ActiveIndicatorOuter = styled.div`
  width: ${dotSize * 2}px;
  height: ${dotSize * 2}px;
  border-radius: ${dotSize * 2}px;
  background-color: ${props => props.theme.successIndicator.active.outerBg};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ActiveIndicatorInner = styled.div`
  width: ${dotSize * 1.5}px;
  height: ${dotSize * 1.5}px;
  border-radius: ${dotSize * 1.5}px;
  background-color: ${props => props.theme.successIndicator.active.innerBg};
  box-shadow: ${props => props.theme.successIndicator.active.innerShadow};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DefaultDayIndicator = styled.div`
  width: ${dotSize}px;
  height: ${dotSize}px;
  border: 1px solid ${props => props.theme.successIndicator.unknown.border};
  background-color: ${props => props.theme.successIndicator.unknown.fill};
  border-radius: ${dotSize}px;
`;

const SuccessDayIndicator = styled.div`
  width: ${dotSize}px;
  height: ${dotSize}px;
  border: 1px solid ${props => props.theme.successIndicator.full.border};
  background-color: ${props => props.theme.successIndicator.full.fill};
  border-radius: ${dotSize}px;
`;

const PartlyDayIndicator = () => (
  <PartlyDayIndicatorOuter>
    <PartlyDayIndicatorInner />
  </PartlyDayIndicatorOuter>
);

const PartlyDayIndicatorOuter = styled.div`
  width: ${dotSize}px;
  height: ${dotSize}px;
  border-radius: ${dotSize}px;
  border: 1px solid ${props => props.theme.successIndicator.half.border};
  background-color: ${props => props.theme.successIndicator.half.fill};
  overflow: hidden;
`;

const PartlyDayIndicatorInner = styled.div`
  width: ${dotSize}px;
  height: ${dotSize}px;
  background-color: ${props => props.theme.successIndicator.half.halfFill};
  transform: rotate(-45deg) translateX(-${dotSize / 2}px);
`;

const FailDayIndicator = styled.div`
  width: ${dotSize}px;
  height: ${dotSize}px;
  border: 1px solid ${props => props.theme.successIndicator.none.border};
  background-color: ${props => props.theme.successIndicator.none.fill};
  border-radius: ${dotSize}px;
`;
