import API from '@aws-amplify/api-rest';
import Auth from '@aws-amplify/auth';
import convertChallenges from './Challenges';
import { FEED_PAGE_SIZE } from '../util/constants';
import config from '../config';
import {
  GET_ARTICLES,
  GET_ARTICLE,
  GET_FACT_AND_MATH,
  GET_PRIVACY_POLICY,
  GET_TERMS_OF_SERVICE,
} from '../blog/queries';

const cmsConfig = config[process.env.REACT_APP_ENV].cms;
const cached = {};

export async function getGroupData(groupId) {
  const [publicResponse, authedResponse] = await Promise.all([
    getGroupInfo(groupId),
    getPersonalGroupData(groupId),
  ]);

  return {
    group: publicResponse,
    user: authedResponse,
  };
}

export async function getJoinGroupConfig(challengeId, groupId, rejoin) {
  return await API.get(
    'apigateway',
    `/prefill/join/challenge/${challengeId}/group/${groupId}${rejoin ? '?rejoin=true' : ''}`,
  );
}

export async function getCreateGroupConfig(challengeId) {
  return await API.get('apigateway', `/prefill/create/challenge/${challengeId}`);
}

export async function getBaselineConfig(groupId) {
  return await API.get('apigateway', `/prefill/baseline/group/${groupId}`);
}

export async function getChallengeIntroConfig(challengeId) {
  return await API.get('apigateway', `/prefill/intro/challenge/${challengeId}`);
}

export async function getDailyAnswerConfig(groupId) {
  return await API.get('apigateway', `/prefill/answer/group/${groupId}`);
}

export async function getTourConfig(firstTimer = false) {
  return await API.get('apigateway', `/prefill/tour?first_timer=${firstTimer}`);
}

export async function getGroupInfo(groupId) {
  return await API.get('apigateway', `/group/${groupId}`);
}

export async function getGroupInfoByInviteHash(inviteHash) {
  return await API.get('apigateway', `/invite/${inviteHash}`);
}

export async function getGroupInvite(groupId) {
  return await API.get('apigateway', `/group/${groupId}/invite`);
}

export async function deleteGroup(groupId) {
  return await API.del('apigateway', `/group/${groupId}`);
}

export async function getGroupFeed(groupId, offsetKey, limit = FEED_PAGE_SIZE) {
  const user = await Auth.currentUserInfo();

  if (!user) {
    return [];
  }

  const query = [];
  if (offsetKey) {
    query.push(`offsetKey=${offsetKey}`);
  }

  if (limit) {
    query.push(`limit=${limit}`);
  }

  return await API.get(
    'apigateway',
    `/group/${groupId}/feed${query.length ? `?${query.join('&')}` : ''}`,
  );
}

export async function getPost(postId) {
  return await API.get('apigateway', `/posts/${postId}`);
}

export async function getReplies(groupId, feedId) {
  return await API.get('apigateway', `/group/${groupId}/feed/${feedId}/reply`);
}

export async function getAppStats() {
  return await API.get('apigateway', '/stats');
}

export async function postReaction(groupId, feedId, body) {
  return await API.post('apigateway', `/group/${groupId}/feed/${feedId}/react`, { body });
}

export async function postLeaveGroup(groupId) {
  return await API.post('apigateway', `/group/${groupId}/leave`);
}

export async function postReply(groupId, feedId, body) {
  return await API.post('apigateway', `/group/${groupId}/feed/${feedId}/reply`, { body });
}

export async function postComment(groupId, body) {
  return await API.post('apigateway', `/group/${groupId}/feed`, { body });
}

export async function postCreateSignUp(body) {
  return await API.post('apigateway', '/signup/create', { body });
}

export async function postInitSignUp(body) {
  return await API.post('apigateway', '/signup/init', { body });
}

export async function postVerifySignUp(body) {
  return await API.post('apigateway', '/signup/verify', { body });
}

export async function postInitSecondarySignUp(body) {
  return await API.post('apigateway', '/alias/init', { body });
}

export async function postVerifySecondarySignUp(body) {
  return await API.post('apigateway', '/alias/verify', { body });
}

export async function postSecondarySignUp(body) {
  return await API.post('apigateway', '/alias/attach', { body });
}

export async function getPersonalGroupData(groupId) {
  try {
    const user = await Auth.currentUserInfo();
    return user ? await API.get('apigateway', `/group/${groupId}/personal`) : null;
  } catch (error) {
    console.error('Error loading personal group data', error);
    return null;
  }
}

export async function getProfileData() {
  const user = await Auth.currentUserInfo();
  if (!user) {
    return null;
  }

  return await API.get('apigateway', '/profile');
}

export async function getLatestActions() {
  if (!(await Auth.currentUserInfo())) {
    return null;
  }

  return await API.get('apigateway', `/actions`);
}

export async function postProgress(groupId, data) {
  return await API.post('apigateway', `/group/${groupId}/progress`, data);
}

export async function getChallenges() {
  const response = await API.get('apigateway', `/challenges`);

  cached.challenges = convertChallenges(response);

  return cached.challenges;
}

export async function createNewGroup(data) {
  return await API.post('apigateway', `/group`, { body: data });
}

export async function getMessages(offset, limit = FEED_PAGE_SIZE) {
  const user = await Auth.currentUserInfo();

  if (!user) {
    return null;
  }

  const query = [];
  if (offset) {
    query.push(`offsetKey=${offset}`);
  }

  if (limit) {
    query.push(`limit=${limit}`);
  }

  return await API.get('apigateway', `/messages${query.length ? `?${query.join('&')}` : ''}`);
}

export async function getRemindersSummary() {
  return await API.get('apigateway', '/reminders/summary');
}

export async function updateUnreadMessages() {
  return await API.put('apigateway', '/messages/update');
}

export async function getNotifications(offset, limit = FEED_PAGE_SIZE) {
  const user = await Auth.currentUserInfo();

  if (!user) {
    return null;
  }

  const query = [];
  if (offset) {
    query.push(`offsetKey=${offset}`);
  }

  if (limit) {
    query.push(`limit=${limit}`);
  }

  return await API.get('apigateway', `/notifications${query.length ? `?${query.join('&')}` : ''}`);
}

export async function postNotificationStatus(id) {
  const user = await Auth.currentUserInfo();

  if (!user) {
    return null;
  }

  return await API.post('apigateway', `/notifications`, { body: { id } });
}

// external ip servce to get ther user location
export async function getUserLocation() {
  const response = await fetch('http://ip-api.com/json');
  return await response.json();
}

export async function getUserInfo() {
  return await API.get('apigateway', '/userInfo');
}

export async function putUserInfo(body) {
  return await API.put('apigateway', '/userInfo', { body });
}

export async function getCMSArticles(locale) {
  return await cmsFetch({
    operationName: 'ListPosts',
    query: GET_ARTICLES,
    variables: {
      locale,
    },
  });
}

export async function getCMSArticle(slug, locale) {
  return await cmsFetch({
    operationName: 'GetPost',
    query: GET_ARTICLE,
    variables: {
      locale,
      slug,
    },
  });
}

export async function getCMSFactAndMath(challengeId, locale) {
  return await cmsFetch({
    operationName: 'GetFactAndMath',
    query: GET_FACT_AND_MATH,
    variables: {
      challengeId,
      locale,
    },
  });
}

export async function getCMSPrivacyPolicy(locale) {
  return await cmsFetch({
    operationName: 'GetPrivacyPolicy',
    query: GET_PRIVACY_POLICY,
    variables: {
      locale,
    },
  });
}

export async function getCMSTermsOfService(locale) {
  return await cmsFetch({
    operationName: 'GetPrivacyPolicy',
    query: GET_TERMS_OF_SERVICE,
    variables: {
      locale,
    },
  });
}

async function cmsFetch(body) {
  const response = await fetch(cmsConfig.url, {
    method: 'post',
    headers: {
      Authorization: cmsConfig.accessToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  return await response.json();
}
