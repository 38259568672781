import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { InputContainer, InputContainedLabel, Description } from './InputPrimitives';
import ArrowRightIcon from '../../../icons/ArrowRightIcon';
import { pTextFieldLeft } from '../../../../styles/text';

const WizardInputLink = ({
  name,
  label,
  value,
  description,
  onClick,
  disabled = false,
  secondary = false,
}) => {
  const onKeyDown = e => {
    if (e.key === 'Enter' && !disabled && onClick) {
      onClick();
    }
  };

  if (disabled) {
    onClick = undefined;
  }

  return (
    <Container disabled={disabled}>
      <InputContainer
        role="link"
        tabIndex={0}
        onKeyDown={onKeyDown}
        onClick={onClick}
        disabled={disabled}
        secondary={secondary}
      >
        <InputContainedLabel name={name} label={label} />
        <Content>
          <Text>{value}</Text>
          <ArrowRightContainer>
            <ArrowRightIcon />
          </ArrowRightContainer>
        </Content>
      </InputContainer>
      {description && <Description>{description}</Description>}
    </Container>
  );
};

WizardInputLink.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  secondary: PropTypes.bool,
  description: PropTypes.string,
  value: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['big', 'small']),
  errorText: PropTypes.string,
};

export default WizardInputLink;

const Container = styled.div`
  align-self: center;
  width: 100%;
  margin: auto;
  margin-bottom: 15px;
  > ${InputContainer} {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    * {
      cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const Text = styled.div`
  ${pTextFieldLeft};
  flex: 1;
`;

const ArrowRightContainer = styled.div`
  position: absolute;
  right: 0;
  width: 34px;
  height: 34px;
`;
