import styled, { css } from 'styled-components';
import elements from '../../styles/elements';

const common = css`
  max-width: ${elements.containerMaxWidthPx}px;
  width: 100%;
`;

const CenteredContent = styled.div`
  ${common};
  margin: 0 auto;
  padding: 0 15px;
`;

export const CenteredContentFlex = styled.div`
  ${common};
  display: flex;
  align-self: center;
  flex-direction: column;
`;

export const ModalCenteredContent = styled.div`
  ${common};
  margin: 0 auto;
`;

export default CenteredContent;
