import { useState, useEffect } from 'react';

export const useInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return {
    value,
    setValue,
    reset: () => setValue(''),
    bind: {
      value,
      onChange: event => {
        setValue(event.target.value);
      },
    },
  };
};

export const useBooleanInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return {
    value,
    setValue,
    reset: () => setValue(false),
    bind: {
      value,
      onChange: value => {
        setValue(value);
      },
    },
  };
};

export const useSelectOneInput = initialValue => {
  const [value, setValue] = useState(initialValue);
  const onSelect = (value, name) => {
    setValue(value);
  };

  useEffect(() => {
    onSelect(initialValue);
  }, [initialValue]);

  return {
    value,
    setValue,
    reset: () => setValue(''),
    bind: {
      value,
      onSelect,
    },
  };
};
