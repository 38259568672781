import styled, { css } from 'styled-components';
import {
  optionLabelLeftNegative,
  optionLabelLeftPositive,
  pNegative,
  pPositive,
} from '../../../../../../styles/text';

const baseOptionContainerStyle = css`
  display: flex;
  flex-direction: row;
  height: 75px;
  min-height: 75px;
  align-items: center;
`;

const baseRoundOptionContainerStyle = `
  display: flex;
  flex-direction: row;
  height: 68px;
  width: 68px;
  border-radius: 34px;
  align-items: center;
  border: 1px solid white;
  &:not(:last-child) {
    margin-right: 10px;
  }
`;

export const OptionContainerCheckbox = styled.div`
  ${baseOptionContainerStyle}
  border-radius: 4px;
  ${props => getSelectedStyle(props)};
  & + & {
    border-top: 1px solid ${props => props.theme.colors.dull7_15};
  }
`;
export const OptionContainerRadiobutton = styled.div`
  ${baseOptionContainerStyle}
  ${props => (props.selected ? OptionSelectedStyle : OptionStyle)}
  & + & {
    border-top: 1px solid ${props => props.theme.colors.dull7_15};
  }
`;

export const RoundOptionContainerRadiobutton = styled.div`
  ${baseRoundOptionContainerStyle}
  ${props => (props.selected ? RoundOptionSelectedStyle : OptionStyle)}
`;

const getSelectedStyle = ({ selected, nextSelected, previousSelected }) => {
  if (selected) {
    if (!nextSelected && !previousSelected) {
      return OptionSelectedStyle;
    } else if (nextSelected && previousSelected) {
      return OptionSelectedMiddleStyle;
    } else if (nextSelected) {
      return OptionSelectedTopStyle;
    } else {
      return OptionSelectedBottomStyle;
    }
  } else {
    return OptionStyle;
  }
};

const OptionStyle = css`
  > div > img,
  > div > div {
    border: 1px solid ${props => props.theme.colors.seachangeStandout};
    background-color: ${props => props.theme.select.bgRadio};
  }
  label {
    ${optionLabelLeftNegative};
    color: ${props => props.theme.select.color};
  }
  p {
    ${pNegative};
    line-height: 1.2;
    color: ${props => props.theme.select.color};
  }
`;

const OptionSelectedStyle = css`
  border-radius: 4px;
  background-color: ${props => props.theme.select.bgSelected};
  box-shadow: ${props => props.theme.select.shadowSelected};
  > div > img,
  > div > div {
    border: 1px solid ${props => props.theme.select.radioBorder};
    background-color: ${props => props.theme.select.bgRadioSelected};
  }

  .radio label {
    color: ${props => props.theme.select.colorRadioSelected};
  }

  label {
    ${optionLabelLeftPositive};
    color: ${props => props.theme.select.bgRadioSelected};
  }
  p {
    ${pPositive};
    line-height: 1.2;
    color: ${props => props.theme.select.bgRadioSelected};
  }
`;

const RoundOptionSelectedStyle = css`
  ${OptionSelectedStyle};
  border-radius: 34px;
`;

const OptionSelectedMiddleStyle = css`
  ${OptionSelectedStyle};
  border-radius: 0px;
  box-shadow: none;
`;

const OptionSelectedTopStyle = css`
  ${OptionSelectedStyle};
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  box-shadow: none;
`;

const OptionSelectedBottomStyle = css`
  ${OptionSelectedStyle};
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
`;
