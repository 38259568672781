import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  labelLeftPositive,
  articleH3,
  articleTitle,
  articleIngress,
  articleP,
} from '../../styles/text';

const TYPES = {
  H1: 'h1',
  H2: 'h2',
  Description: 'description',
  H6: 'h6',
  Paragraph: 'paragraph',
};

const RichText = ({ type, children }) => {
  return (
    <Fragment>
      {children.map(({ text }, i) => {
        switch (type) {
          case TYPES.H1:
            return <H1 key={i}>{text}</H1>;
          case TYPES.H2:
            return <H2 key={i}>{text}</H2>;
          case TYPES.Description:
            return <Description key={i}>{text}</Description>;
          case TYPES.H6:
            return <H6 key={i}>{text}</H6>;
          case TYPES.Paragraph:
            return <Paragraph key={i}>{text}</Paragraph>;
          default:
            console.log('Unknown RichText type', type);
            return null;
        }
      })}
    </Fragment>
  );
};

RichText.propTypes = {
  type: PropTypes.oneOf(Object.values(TYPES)),
  children: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
    }),
  ),
};

export default RichText;

const H1 = styled.h1`
  ${articleTitle};
`;

const H2 = styled.h2`
  ${articleH3};
  text-transform: uppercase;
`;

const Description = styled.p`
  ${articleIngress};
`;

const H6 = styled.h6`
  ${labelLeftPositive};
  text-align: center;
`;

const Paragraph = styled.p`
  ${articleP};
`;
