import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { Link } from 'react-router-dom';
import { Card } from '../../components/card';
import { labelLeftNegative, h3LeftNegative } from '../../styles/text';
import { theme } from '../../theme';
import ArrowRightIcon from '../icons/ArrowRightIcon';

const LinkCard = ({ title, description, href, themeName, style = {} }) => {
  const componentTheme = theme(themeName);
  return (
    <ThemeProvider theme={componentTheme}>
      <Wrapper style={style}>
        <Link to={href}>
          <Card
            bgImage={componentTheme.linkCard.bgImage}
            bgGradient={componentTheme.linkCard.bgGradient}
            bgSize={componentTheme.linkCard.bgSize}
          >
            <Row>
              <Header>{title}</Header>
              <ArrowRightIcon />
            </Row>
            <Info>{description}</Info>
          </Card>
        </Link>
      </Wrapper>
    </ThemeProvider>
  );
};

LinkCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  themeName: PropTypes.string.isRequired,
  style: PropTypes.object,
};

export default LinkCard;

const Wrapper = styled.div`
  &:not(:first-of-type) {
    margin-top: 15px;
  }
`;

const Header = styled.h3`
  ${h3LeftNegative};
  flex: 1;
  margin: 0;
`;

const Info = styled.p`
  ${labelLeftNegative};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
