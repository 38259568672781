import moment from 'moment';
import { GroupStates } from './constants';

export const getTotalReductionKg = (dailyAnswers) => {
  return Object.values(dailyAnswers)
    .map(answer => answer.absoluteCo2KgReduction || 0)
    .reduce((acc, val) => acc + val, 0);
};

export const getGroupTotalReductionKg = (progress) => {
  return Object.values(progress.cumulativeCo2KgReduction)
    .reduce((acc, v) => acc + v, 0);
};

export const getYearlyReductionTargetKg = ({goal}) => {
  return goal.totalInitialCo2Kg - goal.totalTargetCo2Kg;
};

/**
 * Baseline answers can be resubmitted at latest on 3rd day of challenge
 * @param {string} startDate 
 */
export const isBaselineUpdateAllowed = (startDate) => {
  return getGroupDay(startDate) <= 3;
}

/** Get group day, which is negative before startDate and positive afterwards.
 *  NB. There is no day 0! So day before startDate is -1, and then startDate is day 1
 */
export const getGroupDay = (startDate) => {
  const now = moment();
  const roundFn = now.isBefore(startDate, 'day') ? Math.floor : Math.ceil;
  const dayDiffExact = now.diff(startDate, 'days', true);
  return dayDiffExact === 0 ? 1 : roundFn(dayDiffExact);
};

/**
 * Get status of a group
 * @param {string} groupStartDate Start date as yyyy-mm-dd
 * @param {number} groupDuration Group duration in days
 * @returns Status, one of [hatching, active, alumni]
 */
export const getGroupStatus = (groupStartDate, groupDuration) => {
  const groupDay = getGroupDay(groupStartDate);
  if (groupDay < 0) {
    return GroupStates.hatching;
  } else if (groupDay <= groupDuration) {
    return GroupStates.active;
  } else {
    return GroupStates.alumni;
  }
}

export const isGroupActive = ({ startDate, durationDays }) => {
  const status = getGroupStatus(startDate, durationDays);
  return  status === GroupStates.active || status === GroupStates.hatching;
}

export const getYearlyFootprintKg = dailyAnswers => {
  const values = Object.values(dailyAnswers);
  return (
    (365 * values.reduce((acc, { co2Kg }) => acc + co2Kg, 0)) / values.length
  );
};
