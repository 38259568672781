import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import {
  copyIconPositive,
  shareApplePositive,
  sharePositive,
  emailPositive,
} from '../../assets/images';
import { h3LeftNegative } from '../../styles/text';

export const ACTION_LIST_BUTTON_TYPES = {
  copy: 'copy',
  email: 'email',
  share: 'share',
  shareApple: 'shareApple',
};

const mapIconToImg = icon => {
  switch (icon) {
    case ACTION_LIST_BUTTON_TYPES.copy:
      return copyIconPositive;
    case ACTION_LIST_BUTTON_TYPES.email:
      return emailPositive;
    case ACTION_LIST_BUTTON_TYPES.shareApple:
      return shareApplePositive;
    case ACTION_LIST_BUTTON_TYPES.share:
      return sharePositive;
    default:
      throw new Error(`Unknown icon ${icon}`);
  }
};

const ActionListButton = ({ children, icon, onClick = () => {}, href }) => {
  if (href) {
    return (
      <Container>
        <Link iconSrc={icon ? mapIconToImg(icon) : undefined} href={href}>
          {children}
        </Link>
      </Container>
    );
  } else {
    return (
      <Container>
        <Button iconSrc={icon ? mapIconToImg(icon) : undefined} onClick={onClick}>
          {children}
        </Button>
      </Container>
    );
  }
};

ActionListButton.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.oneOf(Object.values(ACTION_LIST_BUTTON_TYPES)),
  onClick: PropTypes.func,
};

export default ActionListButton;

const Container = styled.div`
  &:not(:first-of-type) {
    margin-top: 10px;
  }
`;

const elementStyles = css`
  ${h3LeftNegative};
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 3px 15px -2px rgba(87, 87, 87, 0.3);
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-size: 34px 34px;
  background-color: ${props => props.theme.colors.dull7};
  background-image: url(${props => props.iconSrc});
`;

const Button = styled.button.attrs(({ icon }) => ({
  style: {
    padding: !icon ? '0 15px' : '0 49px 0 15px',
  },
}))`
  ${elementStyles};
`;

const Link = styled.a.attrs(({ icon }) => ({
  style: {
    padding: !icon ? '0 15px' : '0 49px 0 15px',
  },
}))`
  ${elementStyles};
`;
