import React from 'react';
import PropTypes from 'prop-types';

/**
 * Use this function to create a component with props. Usage:
 *
 * const Wrapper = getComponentWithProps(SomeComponent, {some: 'props'});
 */
const getComponentWithProps = (Component, props) =>
  Object.assign(({ children }) => <Component {...props}>{children}</Component>, {
    propTypes: {
      children: PropTypes.node,
    },
  });

export default getComponentWithProps;
