import React from 'react';
import ModalConfirm from './ModalConfirm';

export default function SetupErrorModal(onClose, x = 0, y = 0) {
  // eslint-disable-next-line
  return ({ title, description, options }) => (
    <ModalConfirm
      title={title}
      description={description}
      onClose={onClose}
      options={options}
      x={x}
      y={y}
    />
  );
}
