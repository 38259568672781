import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMessageGetter } from 'react-message-context';
import ButtonIcon from '../../button-icon/ButtonIcon';
import SmallButton from '../../button/SmallButton';

export const VISIBILITY = {
  VISIBLE: 'visible',
  HIDDEN: 'hidden',
  NONE: 'none',
};

const WizardActionButtons = ({
  onBack,
  onForth,
  onBackDisabled = false,
  onBackVisibility = VISIBILITY.VISIBLE,
  onBackText = null,
  onForthDisabled = false,
  onForthLoading = false,
  onForthText = null,
  onForthArrow = true,
  arrowLeftBg = false,
}) => {
  const [state, setState] = useState({ navigationTime: new Date().getTime() });

  const onNavigate = action => {
    const { navigationTime } = state;

    // delay navigation for wizard transition duration and delay + 300ms
    // TODO: get transition duration and delay from FullscreenWizard
    if (new Date().getTime() - navigationTime > 1300) {
      setState(Object.assign({}, state, { navigationTime: new Date() }));
      action();
    }
  };

  const messages = useMessageGetter();
  const onBackButton =
    onBackVisibility === VISIBILITY.NONE ? null : onBackText ? (
      <SmallButton
        type={'secondary'}
        loading={false}
        text={onBackText}
        onClick={() => onNavigate(onBack)}
        disabled={onBackDisabled || !onBack}
      />
    ) : (
      <ButtonIcon
        type="arrowLeft"
        hidden={onBackVisibility === VISIBILITY.HIDDEN}
        onClick={() => onNavigate(onBack)}
        label={messages('general.button.back')}
        disabled={onBackDisabled || !onBack}
        bg={arrowLeftBg}
      />
    );
  const onForthButton = onForthText ? (
    <SmallButton
      type={onForthArrow ? 'primary' : undefined}
      loading={onForthLoading}
      text={onForthText}
      onClick={() => onNavigate(onForth)}
      disabled={onForthDisabled || !onForth}
    />
  ) : (
    <ButtonIcon
      type="arrowRight"
      onClick={() => onNavigate(onForth)}
      label={messages('general.button.forward')}
      disabled={onForthDisabled || !onForth}
    />
  );

  return (
    <Container>
      {onBackButton}
      {onForthButton}
    </Container>
  );
};

WizardActionButtons.propTypes = {
  onBack: PropTypes.func,
  onBackDisabled: PropTypes.bool,
  onBackVisibility: PropTypes.oneOf(Object.values(VISIBILITY)),
  onBackText: PropTypes.string,
  onForth: PropTypes.func,
  onForthDisabled: PropTypes.bool,
  onForthLoading: PropTypes.bool,
  onForthText: PropTypes.string,
  onForthArrow: PropTypes.bool,
  arrowLeftBg: PropTypes.bool,
};

export default WizardActionButtons;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 0 15px;
`;
