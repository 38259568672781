import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import { pWizardTitle, h1WizardTitle } from '../../styles/text';
import CenteredContent from '../container/CenteredContent';
import media from '../../styles/media';

const WaveHeader = ({ title, subtitle, children }) => {
  return (
    <Container>
      <Content>
        {title && <Title>{title}</Title>}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {children}
      </Content>
      <Wave />
    </Container>
  );
};

WaveHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
};

export default WaveHeader;

const Container = styled.header`
  height: 390px;
  max-height: 390px;
  display: flex;
  flex-direction: column;
  background-image: ${props => `url(${props.theme.waveHeader.bgImage}),`}
    ${props => props.theme.waveHeader.bgGradient};
  background-position: left -410px, left top;
  background-repeat: no-repeat;
  padding-bottom: 5px;
  box-sizing: border-box;
  ${media.desktop`
    background-position: center -410px, left top;
  `}
`;

const Content = styled(CenteredContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 140px 30px 0 30px;
`;

const Wave = () => (
  <WaveContainer
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 24 150 28"
    preserveAspectRatio="none"
    shape-rendering="auto"
  >
    <defs>
      <path
        id="gentle-wave"
        d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
      />
    </defs>
    <WaveParallax>
      <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
      <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
      <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
    </WaveParallax>
  </WaveContainer>
);

const MoveForever = keyframes`
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
`;

const slownessFactor = 3.5;

const WaveParallax = styled.g`
  & > use {
    animation: ${MoveForever} ${25 * slownessFactor}s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
  }
  & > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: ${7 * slownessFactor}s;
  }
  & > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: ${10 * slownessFactor}s;
  }
  & > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: ${13 * slownessFactor}s;
  }
  & > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: ${20 * slownessFactor}s;
  }
`;

const WaveContainer = styled.svg`
  position: relative;
  width: 100%;
  height: 50px;
  margin-bottom: -5px;
  /*Fix for safari gap*/
  min-height: 50px
  max-height: 50px
`;

const Title = styled.h1`
  ${h1WizardTitle};
  color: ${props => props.theme.waveHeader.text};
  margin-top: 0;
  margin-bottom: 5px;
`;

const Subtitle = styled.p`
  ${pWizardTitle};
  color: ${props => props.theme.waveHeader.text};
  margin: 0 0 30px 0;
`;
