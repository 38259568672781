import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { matchPath, Route, useHistory, useLocation } from 'react-router';
import { CSSTransition } from 'react-transition-group';
import { usePrevious } from '../../hooks/values';
import { routingModalSlideDuration } from '../../styles/durations';
import { HistoryShape, LocationShape, MatchShapeOfParams } from '../../util/shapes';

const ModalRoutes = ({ modals }) => {
  const history = useHistory();
  const location = useLocation();
  const prevLocation = usePrevious(location);

  const newRouteProps = modals.find(r => matchPath(location.pathname, r));
  const prevRouteProps = prevLocation && modals.find(r => matchPath(prevLocation.pathname, r));

  let dir;
  if (!newRouteProps && prevRouteProps) {
    // Going from modal to a non-modal
    dir = 'down';
  } else if (!prevRouteProps && newRouteProps) {
    // Going from non-modal to a modal
    dir = 'up';
  } else if (!prevRouteProps && !newRouteProps) {
    // Page is not modal at all, default to 'up
    dir = 'up';
  } else {
    // Moving from modal to modal
    let layerDiff = newRouteProps.layer - prevRouteProps.layer;
    if (layerDiff >= 0) {
      dir = 'up';
    } else {
      dir = 'down';
    }
  }

  return modals.map(({ path, Component }) => (
    <Route key={path} exact path={path}>
      {({ match, location }) => (
        <TidealCSSTransition
          match={match}
          location={location}
          history={history}
          Component={Component}
          classNames={dir === 'up' ? 'slide-in-sim' : 'slide-out-sim'}
          timeout={routingModalSlideDuration}
        />
      )}
    </Route>
  ));
};

ModalRoutes.propTypes = {
  modals: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      Component: PropTypes.func.isRequired,
      layer: PropTypes.number.isRequired,
    }),
  ),
};

export default ModalRoutes;

const TidealCSSTransition = ({ match, location, history, Component, classNames, timeout }) => {
  // If current match is not null, store it in so that when this route gets unmounted, the match
  // param is still available and page will not throw for the match being null. Also it is necessary
  // that the exiting page does not get altered so it animates out nicely.
  const ref = useRef();
  useEffect(() => {
    if (match != null) {
      ref.current = match;
    }
  }, [match]);

  return (
    <CSSTransition in={match != null} timeout={timeout} classNames={classNames} unmountOnExit>
      <Component match={match || ref.current} location={location} history={history} />
    </CSSTransition>
  );
};

TidealCSSTransition.propTypes = {
  location: LocationShape,
  match: MatchShapeOfParams([]),
  history: HistoryShape,
  Component: PropTypes.func.isRequired,
  classNames: PropTypes.oneOf(['slide-in-sim', 'slide-out-sim']),
  timeout: PropTypes.number.isRequired,
};
