import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { h1CenterNegative, pNegative, labelLeftNegative } from '../../../styles/text';
import { isBefore, parseISO } from 'date-fns';
import HeroHeader, { HeroHeaderVariant } from '../HeroHeader';
import TextDotSeparator from '../../divider/TextDotSeparator';
import RoundMemberList from '../../groups/member-list/RoundMemberList';
import ProfilePicture, { TYPES, VARIANT, SIZES, SHAPES } from '../../picture/ProfilePicture';
import Row from '../../container/Row';
import SmallButton from '../../button/SmallButton';
import Box from '../../container/Box';
import VerticalDivider from '../../divider/VerticalDivider';
import { capitalizeFirstLetter } from '../../../util/text';
import { ReactComponent as ChevronRightSvg } from '../../../assets/images/chevron-right.svg';
import { useMessageGetter } from 'react-message-context';
import { lock, arrowRightSmallNegative, plus } from '../../../assets/images';
import P from '../../texts/paragraphs/P';
import { UserShape } from '../../../util/shapes';

const images = require.context('../../../assets/images', true);

const LinkButton = ({ to, text, iconSrc }) => (
  <Box margin="30px 0 0 0">
    <Link to={to}>
      <SmallButton primary text={text} iconSrc={iconSrc} />
    </Link>
  </Box>
);

LinkButton.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  iconSrc: PropTypes.string,
};

const GroupHeader = ({
  challengeName,
  challengeId,
  groupId,
  statusText,
  groupName,
  groupStartDate,
  isMember = false,
  isCaptain = false,
  members,
  captain,
  userId,
  showDailyAnswerButton,
  inviteOnly,
}) => {
  const messages = useMessageGetter('components.group_header');
  const isShowJoinGroupButton = !isMember && isBefore(new Date(), parseISO(groupStartDate));
  const isShowInviteToGroupButton = isCaptain && isBefore(new Date(), parseISO(groupStartDate));
  const height =
    isShowJoinGroupButton || isShowInviteToGroupButton || showDailyAnswerButton ? 301 : 258; // XXX: Pretty lame
  const challengePath = challengeId.substring(10);

  return (
    <HeroHeader
      bgImage={challengeId && images(`./${challengePath}-bg.svg`)}
      height={height}
      variant={HeroHeaderVariant.ROUNDED}
    >
      <Title>{groupName}</Title>
      <SubheaderRow>
        <StatusText>{statusText}</StatusText>
        <TextDotSeparator />
        <ChallengeLink to={`/challenges/${challengePath}`}>
          {capitalizeFirstLetter(challengeName)}
          <ChevronRightSvg />
        </ChallengeLink>
      </SubheaderRow>
      <MembersRow>
        <ProfilePicture
          userId={`group_${groupId}`}
          type={TYPES.GROUP}
          variant={VARIANT.POSITIVE}
          size={SIZES.MINI2}
          shape={SHAPES.ROUND}
        />
        <VerticalDivider />
        <Link to={`/challenges/${challengePath}/group/${groupId}/members`}>
          <RoundMemberList
            members={members}
            captain={captain}
            isUserMember={isMember}
            isShowProspect={false}
            userId={userId}
            variant={VARIANT.POSITIVE}
          />
        </Link>
      </MembersRow>
      {isShowJoinGroupButton && (
        <>
          <LinkButton
            to={`/challenges/${challengePath}/group/${groupId}/join`}
            text={messages(inviteOnly ? 'join_with_code' : 'join_group')}
            iconSrc={inviteOnly ? lock : arrowRightSmallNegative}
          />
          {inviteOnly && (
            <P textColor="negative" isCenter>
              {messages('join_with_code_description')}
            </P>
          )}
        </>
      )}
      {isShowInviteToGroupButton && (
        <LinkButton
          to={`/challenges/${challengePath}/group/${groupId}/invite`}
          text={messages('invite_friends')}
        />
      )}
      {showDailyAnswerButton && (
        <LinkButton
          to={`/${challengePath}/${groupId}/a`}
          text={messages('daily_records')}
          iconSrc={plus}
        />
      )}
    </HeroHeader>
  );
};

GroupHeader.propTypes = {
  challengeName: PropTypes.string.isRequired,
  challengeId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  statusText: PropTypes.string.isRequired,
  groupStartDate: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  userId: PropTypes.string,
  isCaptain: PropTypes.bool,
  isMember: PropTypes.bool,
  inviteOnly: PropTypes.bool,
  showDailyAnswerButton: PropTypes.bool,
  members: PropTypes.arrayOf(UserShape).isRequired,
  captain: UserShape.isRequired,
};

export default GroupHeader;

const Title = styled.div`
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  ${h1CenterNegative}
`;

const SubheaderRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 7px 0 20px 0;
`;

const MembersRow = styled(Row)``;

const StatusText = styled.span`
  ${pNegative};
`;

const ChallengeLink = styled(Link)`
  ${labelLeftNegative};
  display: flex;
  align-items: center;
`;
