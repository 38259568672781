import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Box from '../container/Box';
import ProfilePicture, { SIZES, SHAPES, TYPES, VARIANT } from '../picture/ProfilePicture';
import { h3LeftNegative } from '../../styles/text';
import { lock } from '../../assets/images';
import { assurePrefix } from 'isomorphic/util';

const GroupCardHeaderRow = ({ groupId, groupName, inviteOnly, subheaderRow }) => {
  return (
    <Box row>
      <ProfilePicture
        userId={assurePrefix('group_', groupId)}
        title={groupName}
        size={SIZES.SMALL}
        shape={SHAPES.SQUARE}
        type={TYPES.GROUP}
        variant={VARIANT.POSITIVE}
      />
      <Column
        style={{ minHeight: '40px', justifyContent: 'space-between', padding: '1px 0 1px 10px' }}
      >
        <Fullwidth row>
          <GroupNameText>{groupName}</GroupNameText>
          {inviteOnly ? (
            <LockIconBg>
              <LockIcon src={lock} />
            </LockIconBg>
          ) : null}
        </Fullwidth>
        <Box row>{subheaderRow}</Box>
      </Column>
    </Box>
  );
};

GroupCardHeaderRow.propTypes = {
  groupId: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  inviteOnly: PropTypes.bool.isRequired,
  subheaderRow: PropTypes.object.isRequired,
};

export default GroupCardHeaderRow;

const Fullwidth = styled(Box)`
  width: 100%;
  justify-content: space-between;
`;

const LockIconBg = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.15);
`;

const LockIcon = styled.img`
  width: 20px;
  height: 20px;
  padding: 2px;
`;

const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const GroupNameText = styled.h3`
  ${h3LeftNegative};
  margin: 0;
`;
