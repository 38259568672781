import styled from 'styled-components';

export const FeedItemContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Left = styled.div`
  width: 33px;
  min-width: 33px;
`;

export const Main = styled.div`
  flex: 1;
`;

export const Right = styled.div`
  width: 45px;
  min-width: 45px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
