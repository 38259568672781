import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMessageGetter } from 'react-message-context';
import { imgHifiveColor } from '../../../../assets/images';
import Button from '../../../../components/button/Button';
import Box from '../../../../components/container/Box';
import Center from '../../../../components/container/Center';
import { HEADING_SIZES, WizardHeading, WizardStepContent } from '../../../../components/wizard';
import WizardIllustration from '../../../../components/wizard/illustration/WizardIllustration';

const AccountCreatedStep = ({ variant, givenName, onContinue }) => {
  const messages = useMessageGetter('pages.wizards.auth.step_6_account_created');
  const [loading, setLoading] = useState(false);

  const onClickContinue = async () => {
    setLoading(true);
    await onContinue();
    setLoading(false);
  };

  return (
    <WizardStepContent>
      <Box margin="0 0 20px 0" style={{ display: 'block', textAlign: 'center' }}>
        <WizardIllustration src={imgHifiveColor} />
      </Box>
      <WizardHeading
        title={messages('title', { givenName })}
        subtitle={messages(`${variant}.subtitle`)}
        type={HEADING_SIZES.HUGE}
      />
      <Center>
        <Button onClick={onClickContinue} primary loading={loading}>
          {messages(`${variant}.continue`)}
        </Button>
      </Center>
    </WizardStepContent>
  );
};

AccountCreatedStep.propTypes = {
  givenName: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  onContinue: PropTypes.func.isRequired,
};

export default AccountCreatedStep;
