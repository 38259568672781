import styled from 'styled-components';
import { pNegative } from '../../styles/text';

const InfoBox = styled.div`
  ${pNegative};
  background-color: ${({ theme }) => theme.infoBox.bgColor};
  padding: 15px 30px;
`;

export default InfoBox;
