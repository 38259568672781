import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardValue, CardDataSheet, CardValuesRow, EqualWidth } from '../../components/card';
import { CardLabelWithIcon } from '../card/CardLabel';
import { LineChart } from '../../components/chart';
import Co2 from '../../functionalities/Co2';

const ProgressCard = ({
  label,
  daysAmount,
  targetReductionPrc,
  totalReductionKg,
  totalReductionDesc,
  dailyAnswers = [],
  sheetData = [],
  refLegend,
  refValue = 1.0,
  targetLegend,
  labelIcon,
  spacing,
  flat,
}) => {
  const chartData = {
    valuesAmount: daysAmount,
    values: dailyAnswers,
    refStartValue: 0.0,
    refEndValue: refValue,
    targetStartValue: 0.0,
    targetEndValue: targetReductionPrc * refValue,
    currentTotalReduction: totalReductionKg || 0,
  };
  const reduction = new Co2(totalReductionKg);
  const { value, unitAbbr } = reduction.getScaledValue();
  return (
    <Card spacing={spacing} flat={flat}>
      <CardLabelWithIcon icon={labelIcon}>{label}</CardLabelWithIcon>
      <CardValuesRow>
        <EqualWidth>
          <CardValue value={value} valueUnit={unitAbbr} desc={totalReductionDesc} />
          {refValue > 0 && (
            <LineChart
              data={chartData}
              targetLegend={targetLegend}
              refLegend={refLegend}
              isIncrease={value < 0}
            />
          )}
        </EqualWidth>
      </CardValuesRow>
      <CardDataSheet columns={1} data={sheetData} />
    </Card>
  );
};

ProgressCard.propTypes = {
  label: PropTypes.string.isRequired,
  daysAmount: PropTypes.number.isRequired,
  dailyAnswers: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ),
  targetReductionPrc: PropTypes.number.isRequired,
  totalReductionKg: PropTypes.number.isRequired,
  totalReductionDesc: PropTypes.string.isRequired,
  sheetData: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['even', 'odd', 'warning']),
      valueText: PropTypes.string.isRequired,
      desc: PropTypes.string.isRequired,
    }),
  ),
  refLegend: PropTypes.string.isRequired,
  refValue: PropTypes.number,
  targetLegend: PropTypes.string.isRequired,
  labelIcon: PropTypes.element,
  flat: PropTypes.bool,
  spacing: PropTypes.string,
};

export default ProgressCard;

/**
 *
 * @param {*} dailyAnswers Daily answers object
 * @param {number} dailyAnswers.co2Kg - Actual emissions in kg
 * @param {number} dailyAnswers.day - Day number in challenge
 */
export const mapPersonalDailyAnswersToCumulativeActualProgress = (dailyAnswers = {}) => {
  const answers = Object.values(dailyAnswers).sort((d1, d2) => d1.day - d2.day);
  const cumulativeArray = [
    {
      index: 0,
      value: 0,
    },
  ];

  let cumulativeKg = 0;
  for (let i = 0; i < answers.length; i++) {
    const answer = answers[i];
    cumulativeKg += answer.co2Kg;
    cumulativeArray.push({
      index: answer.day,
      value: cumulativeKg,
    });
  }

  return cumulativeArray;
};

/**
 * Maps group progress data into cumulative emissions array suitable as values
 * for `ProgressCard` component.
 *
 * @param {*} groupProgress
 */
export const mapGroupProgressDataToCumulativeActualProgress = (
  groupProgress,
  groupDailyDefaultValue,
) => {
  const cumulativeCo2Kgs = groupProgress.co2Kg;
  const days = Object.keys(cumulativeCo2Kgs).sort((k1, k2) => k1 - k2);
  const cumulativeArray = [
    {
      index: 0,
      value: 0,
    },
  ];

  let cumulativeKg = 0;
  for (let i = 0; i < days.length; i++) {
    const dayCo2Kg = cumulativeCo2Kgs[days[i]];
    cumulativeKg += dayCo2Kg;
    cumulativeArray.push({
      index: parseInt(days[i]),
      value: cumulativeKg,
    });
  }

  return cumulativeArray;
};
