import React from 'react';
import styled from 'styled-components';
import { useMessageGetter } from 'react-message-context';

import ProfilePicture, { SHAPES, SIZES } from '../../picture/ProfilePicture';
import StandingNumber from '../../number/StandingNumber';
import { seachangeTextAlpha15 } from '../../../styles/colors';
import { labelFormElementPositive, pLeft, pWeak } from '../../../styles/text';
import { UserShape } from '../../../util/shapes';

export default function SmallLeaders({ reductionLeader, footprintLeader }) {
  const messages = useMessageGetter('components.small_leaders');
  return (
    <Container>
      <Title>{messages('title')}</Title>
      <Leader>
        <ProfilePicture
          userId={reductionLeader.userId}
          size={SIZES.MINI}
          flat
          shape={SHAPES.ROUND}
          badge={<StandingNumber standout>1</StandingNumber>}
        />
        <LeaderInfo>
          <Name>{reductionLeader.givenName}</Name>
          <LeaderCategory>{messages('category.reduction.label')}</LeaderCategory>
        </LeaderInfo>
      </Leader>
      <Leader>
        <ProfilePicture
          userId={footprintLeader.userId}
          size={SIZES.MINI}
          flat
          shape={SHAPES.ROUND}
          badge={<StandingNumber standout>1</StandingNumber>}
        />
        <LeaderInfo>
          <Name>{footprintLeader.givenName}</Name>
          <LeaderCategory>{messages('category.footprint.label')}</LeaderCategory>
        </LeaderInfo>
      </Leader>
    </Container>
  );
}

SmallLeaders.propTypes = {
  reductionLeader: UserShape.isRequired,
  footprintLeader: UserShape.isRequired,
};

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top: solid 1px ${seachangeTextAlpha15};
  border-bottom: solid 1px ${seachangeTextAlpha15};
  padding: 10px 0 8px 0;
  margin-top: 15px;
`;

const Title = styled.div`
  ${labelFormElementPositive};
  max-height: 13px;
  line-height: 0;
`;

const Leader = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;
`;

const LeaderInfo = styled.div`
  margin-left: 5px;
`;

const Name = styled.div`
  ${pLeft}
`;

const LeaderCategory = styled.div`
  ${pWeak}
`;
