import React from 'react';
import styled from 'styled-components';
import { pPositive, pWeak, labelLeftPositive } from '../../../../styles/text';
import { PropTypes } from 'prop-types';
import { useMessageGetter } from 'react-message-context';
import { checkMark, iconPlus } from '../../../../assets/images';
import { InputContainer, InputContainedLabel, Input, Description } from './InputPrimitives';
import { useAppDispatch } from '../../../../context/appContext';
import { changeInputFocus } from '../../../../context/actions';

const WizardInputText = ({
  name,
  type = 'text',
  label,
  icon,
  iconText,
  iconAction,
  required,
  description,
  value,
  placeholder,
  onChange,
  onSubmit,
  onClick,
  disabled,
  hint,
  size = 'big',
  verified,
  addMark,
  errorText,
  readOnly = false,
  secondary = false,
}) => {
  const messages = useMessageGetter();
  const dispatch = useAppDispatch();
  const onFocus = () => changeInputFocus(dispatch, true);
  const onBlur = () => changeInputFocus(dispatch, false);
  const codeInputRef = React.createRef();
  const focusToInput = () => codeInputRef.current.focus();
  const onKeyDown = e => {
    if (e.key === 'Enter' && onSubmit) {
      onSubmit();
    }
  };

  const onKeyDownIcon = evt => {
    if (evt.key === ' ' || evt.key === 'Enter' || evt.key === 'Spacebar') {
      evt.preventDefault();
      if (iconAction) {
        iconAction();
      }
    }
  };

  const getErrorText = () => {
    if (errorText) {
      return errorText;
    }

    if (required && value.length === 0) {
      return messages('components.wizards.input.required', { requiredLabel: label });
    }
  };

  return (
    <Container
      onClick={!!onClick ? onClick : focusToInput}
      clickable={!!onClick}
      disabled={disabled}
    >
      <InputContainer size={size} disabled={disabled} secondary={secondary}>
        <InputContainedLabel name={name} error={getErrorText()} label={label}>
          {verified && (
            <Verified>
              <img src={checkMark} alt="verified" style={{ marginRight: '4px' }} />
              {messages('components.wizards.input.verified')}
            </Verified>
          )}
          {hint && <Hint>{hint}</Hint>}
        </InputContainedLabel>
        <IconInput>
          {icon && (
            <PrefixIcon
              onClick={iconAction}
              onKeyDown={onKeyDownIcon}
              role="button"
              tabIndex="0"
              alt="Select call code"
            >
              {icon}
              <IconText>{iconText}</IconText>
            </PrefixIcon>
          )}
          <Input
            onClick={focusToInput}
            onFocus={onFocus}
            onBlur={onBlur}
            name={name}
            type={type}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            ref={codeInputRef}
            onKeyDown={onKeyDown}
            disabled={disabled}
            readOnly={readOnly}
            size={size}
          />
        </IconInput>

        {addMark && (
          <AddMark>
            <img src={iconPlus} alt="add" />
          </AddMark>
        )}
      </InputContainer>
      <Description>{description}</Description>
    </Container>
  );
};

WizardInputText.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.node,
  iconText: PropTypes.string,
  iconAction: PropTypes.func,
  type: PropTypes.string,
  description: PropTypes.string,
  hint: PropTypes.string,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['big', 'small']),
  errorText: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  verified: PropTypes.bool,
  readOnly: PropTypes.bool,
  secondary: PropTypes.bool,
  addMark: PropTypes.bool,
};

export default WizardInputText;

const Container = styled.div`
  align-self: center;
  width: 100%;
  margin: auto;
  > ${InputContainer} {
    cursor: ${({ clickable, disabled }) => (disabled ? 'default' : clickable ? 'pointer' : 'text')};
    * {
      cursor: ${({ clickable, disabled }) =>
        disabled ? 'default' : clickable ? 'pointer' : 'text'};
    }
  }
`;

const Verified = styled.span`
  ${pPositive};
`;

const AddMark = styled.div`
  position: absolute;
  height: 100%;
  right: 10px;
  top: 0;
  display: flex;
`;

const Hint = styled.span`
  ${pWeak};
`;
const IconText = styled.div`
  ${labelLeftPositive};
  margin: auto;
  margin-left: 4px;
`;

const PrefixIcon = styled.div`
  background: ${props => props.theme.colors.soft1};
  margin: auto;
  border-radius: 2px;
  padding: 5px 8px;
  margin-right: 10px;
  height: 28px;
  display: flex;
  align-items: center;
  :hover,
  *:hover {
    cursor: pointer;
  }
`;

const IconInput = styled.div`
  display: flex;
  flex-direction: row;
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
