import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { allReadIcon } from '../../assets/images';
import { useMessageGetter } from 'react-message-context';
import { h1LeftPositive, pCenter } from '../../styles/text';

const AllReadMessage = ({ messagesEmpty }) => {
  const messages = useMessageGetter('components.messages.notifications');
  return (
    <Fragment>
      <Illustration src={allReadIcon} alt="" width="172" height="110" />
      {messagesEmpty ? (
        <EmptyMessagesContainer>
          <H1>{messages('emptyMessages.title')}</H1>
          <P>{messages('emptyMessages.description1')}</P>
          <P>{messages('emptyMessages.description2')}</P>
        </EmptyMessagesContainer>
      ) : (
        <AllReadContainer>
          <H1>{messages('allRead.title')}</H1>
          <P>{messages('allRead.description')}</P>
        </AllReadContainer>
      )}
    </Fragment>
  );
};

AllReadMessage.propTypes = {
  messagesEmpty: PropTypes.bool,
};

const EmptyMessagesContainer = styled.div`
  margin-bottom: 15px;
`;

const AllReadContainer = styled.div`
  border-bottom: ${props => `1px solid ${props.theme.colors.dull3_15}`};
  padding-bottom: 15px;
  margin-bottom: 15px;
`;

const H1 = styled.h1`
  ${h1LeftPositive};
  text-align: center;
  margin: 15px 0;
`;

const P = styled.p`
  ${pCenter}
  margin: 0 0 15px 0;
  font-size: 16px;
  padding: 0 30px;
`;

const Illustration = styled.img`
  align-self: center;
`;

export default AllReadMessage;
