import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import media from '../../styles/media';

const NavigationBarLink = ({ href, onClick, active = false, last = false, children }) => {
  return (
    <StyledLink to={href} onClick={onClick} active={active} last={last}>
      {children}
    </StyledLink>
  );
};

NavigationBarLink.propTypes = {
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  last: PropTypes.bool,
  children: PropTypes.node,
};

export default NavigationBarLink;

const StyledLink = styled(({ last, active, ...props }) => <Link {...props} />)`
  position: relative;
  display: flex;
  align-items: center;
  height: 34px;
  border-radius: 17px;
  padding: ${props => (!props.last ? '5px 15px' : '5px 0')};
  text-transform: uppercase;
  font-size: 11px;
  font-family: ${props => props.theme.primaryFont};
  font-weight: 600;
  line-height: 1.18;
  letter-spacing: 1.5px;
  ${props =>
    props.active &&
    css`
      background-color: ${props => props.theme.colors.soft1};
    `};

  ${media.desktop`
    padding: ${props => (!props.last ? '5px 15px' : '5px 0 5px 15px')};
  `}

  color: ${props => props.theme.colors.brand1Hue2};
  &:visited,
  &:active,
  &:hover {
    color: ${props => props.theme.colors.brand1Hue2};
  }
`;
