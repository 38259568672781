import React, { useEffect } from 'react';
import navigation, { navigationParents } from '../../../functionalities/navigation';
import Middle from '../../../components/middle/Middle';
import { ThemeProvider } from 'styled-components';
import { PropTypes } from 'prop-types';
import { theme } from '../../../theme';
import { useMessageGetter } from 'react-message-context';
import { Constants, Groups } from 'isomorphic';
import { HistoryShape, MatchShapeOfParams } from '../../../util/shapes';
import FixedFadingBack from '../../../components/top/FixedFadingBack';
import Banner from '../../../components/banner/Banner';
import LinkCard from '../../../components/data-cards/LinkCard';
import { useTrackPageView } from '../../../hooks/useTrackPageView';
import CenteredContent from '../../../components/container/CenteredContent';
import { useMostRecentValue } from '../../../hooks/values';
import { useApp } from '../../../context/appContext';
import { loadChallenges, loadProfileData } from '../../../context/actions';
import ChallengeHeader from '../../../components/headers/ChallengeHeader';
import Section from '../../../components/container/Section';
import SmallGroupCard from '../../../components/data-cards/SmallGroupCard';
import Box from '../../../components/container/Box';

const thisPage = 'Challenge';

const ChallengePage = ({ match: matchProp, exiting, wrapper: Wrapper }) => {
  const match = useMostRecentValue(matchProp);
  const [state, dispatch] = useApp();
  const { isLoadedProfile, isLoadedChallenges, challenges, profile } = state;
  const { challengeId } = match.params;

  useTrackPageView();

  if (!exiting) {
    navigation.setParent(navigationParents.challenges);
    navigation.setPage(thisPage);
  }
  const t = useMessageGetter();
  const messages = useMessageGetter(`data.challenge.${challengeId}`);

  useEffect(() => {
    if (!isLoadedProfile) loadProfileData(dispatch);
    if (!isLoadedChallenges) loadChallenges(dispatch);
  }, [dispatch, isLoadedProfile, isLoadedChallenges]);

  const challenge = challenges.find(challenge => challenge.id === `challenge_${challengeId}`);
  if (!challenge) return <Wrapper>{null}</Wrapper>;

  const hatchingGroups = [];
  const activeGroups = [];

  challenge.groups &&
    Object.values(challenge.groups).forEach(g => {
      g.status = Groups.getGroupStatus(g.startDate, g.durationDays);
      if (g.status === Constants.GroupStates.active) {
        activeGroups.push(g);
      } else if (g.status === Constants.GroupStates.hatching) {
        hatchingGroups.push(g);
      }
    });
  const usersGroups = Object.values(profile.groups).map(group => group.id);

  const {
    co2FootprintPros,
    reduction,
    activeGroupCount,
    activeTidealistCount,
    totalGroupCount,
    totalTidealistCount,
  } = challenge;

  return (
    <Wrapper>
      <ThemeProvider theme={theme(challenge.theme)}>
        <CenteredContent>
          <FixedFadingBack />
        </CenteredContent>
        <Middle>
          <ChallengeHeader
            challengeId={challengeId}
            title={messages('title')}
            subtitle={messages('info')}
            heading={messages('header')}
            data={[
              [
                {
                  label: t('data.challenge.fact.footprint.label'),
                  value: t('data.challenge.fact.footprint.value', { value: co2FootprintPros }),
                  valueUnit: t('data.challenge.fact.footprint.valueUnit'),
                  description: t('data.challenge.fact.footprint.description'),
                },
                {
                  label: t('data.challenge.fact.reduction.label'),
                  value: t('data.challenge.fact.reduction.value', { value: reduction }),
                  valueUnit: t('data.challenge.fact.reduction.valueUnit', { value: reduction }),
                  description: t('data.challenge.fact.reduction.description'),
                },
              ],
              [
                {
                  label: t('data.challenge.fact.groups.label'),
                  value: t('data.challenge.fact.groups.value', { activeGroupCount }),
                  description: t('data.challenge.fact.groups.description', { totalGroupCount }),
                },
                {
                  label: t('data.challenge.fact.members.label'),
                  value: t('data.challenge.fact.members.value', { activeTidealistCount }),
                  description: t('data.challenge.fact.members.description', {
                    totalTidealistCount,
                  }),
                },
              ],
            ]}
          />
          <CenteredContent>
            <Box margin="70px 0 0 0">
              <Section title={t('pages.challenge.more.title')} show>
                <LinkCard
                  title={messages('intro.title')}
                  description={messages('intro.description')}
                  href={`/challenges/${challengeId}/intro`}
                  themeName={challenge.theme || 'white'}
                />
                <LinkCard
                  title={messages('factAndMath.title')}
                  description={messages('factAndMath.short')}
                  href={`/challenges/${challengeId}/fact-and-math`}
                  themeName="white"
                />
              </Section>
            </Box>
            <Box margin="70px 0 0 0" hidden={hatchingGroups.length === 0}>
              <Section
                title={t('pages.challenge.groups.hatching')}
                show={hatchingGroups.length > 0}
              >
                {hatchingGroups.map((g, idx) => (
                  <SmallGroupCard
                    key={`group-${idx}`}
                    group={g}
                    isMember={usersGroups.some(groupId => groupId === `group_${g.id}`)}
                  />
                ))}
              </Section>
            </Box>
            <Box margin="70px 0 0 0" hidden={activeGroups.length === 0}>
              <Section title={t('pages.challenge.groups.active')} show={activeGroups.length > 0}>
                {activeGroups.map((g, idx) => (
                  <SmallGroupCard
                    key={`group-${idx}`}
                    group={g}
                    isMember={usersGroups.some(groupId => groupId === `group_${g.id}`)}
                  />
                ))}
              </Section>
            </Box>
            <Box margin="70px 0 0 0">
              <Banner
                title={t('pages.challenge.captain.banner.title')}
                href={`/challenges/${challengeId}/create`}
                action={t('pages.challenge.captain.banner.action')}
              />
            </Box>
          </CenteredContent>
        </Middle>
      </ThemeProvider>
    </Wrapper>
  );
};

ChallengePage.propTypes = {
  match: MatchShapeOfParams(['challengeId']),
  history: HistoryShape.isRequired,
  exiting: PropTypes.bool,
  wrapper: PropTypes.func,
};

export default ChallengePage;
