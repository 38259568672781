import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';
import { MessageProvider } from 'react-message-context';
import messages from 'isomorphic/localizations/messages';
import AppRouter from './AppRouter';
import { AppStateProvider } from './context/appContext';
import './App.css';
import ServiceWorkerUpdater from './fragments/snackbar/ServiceWorkerUpdater';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <MessageProvider locale="en" fallback="en" messages={messages['en']}>
        <AppStateProvider>
          <AppRouter />
          <ServiceWorkerUpdater />
        </AppStateProvider>
      </MessageProvider>
    </ThemeProvider>
  );
};

export default App;
