import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMessageGetter } from 'react-message-context';
import { Link } from 'react-router-dom';

import Card from '../../card/Card';
import { CardLabelWithIcon } from '../../card/CardLabel';
import { ReactComponent as TrophySvg } from '../../../assets/images/trophy.svg';
import { pWeak } from '../../../styles/text';
import Button from '../../button/Button';
import { SpacingShape } from '../../../util/shapes';

export function HatchingLeaderBoardCard({ groupMembersPath, spacing, flat }) {
  const messages = useMessageGetter('components.leaderboard_card');
  return (
    <Card spacing={spacing} flat={flat}>
      <CardLabelWithIcon icon={<TrophySvg />}>{messages('title')}</CardLabelWithIcon>
      <Text>{messages('hatching.text')}</Text>
      <StyledLink to={groupMembersPath}>
        <Button secondary>{messages('hatching.link_text')}</Button>
      </StyledLink>
    </Card>
  );
}

HatchingLeaderBoardCard.propTypes = {
  groupMembersPath: PropTypes.string.isRequired,
  spacing: SpacingShape,
  flat: PropTypes.bool,
};

const Text = styled.div`
  ${pWeak};
  margin-top: 8px;
  display: inline-block;
`;

const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  padding: 32px 0 2px 0;
`;
