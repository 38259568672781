import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { WizardInputText, WizardSelectOne } from '../../../components/wizard';
import { theme } from '../../../theme';
import Button from '../../../components/button/Button';
import { useMessageGetter } from 'react-message-context';
import { useApp } from '../../../context/appContext';
import { loadGroup, updateGroup, EVENTS } from '../../../context/actions';
import { useInput, useSelectOneInput } from '../../../hooks/useInput';
import moment from 'moment';
import WizardFullscreenInputLink from '../../../components/wizard/form/input/WizardFullscreenInputLink';
import { WizardSubtitle } from '../../../components/wizard/heading/WizardHeadingPrimitives';
import ModalFullscreen, { ModalContent } from '../../../components/modal/ModalFullscreen';
import { Constants, Groups } from 'isomorphic';
import AnimatedModalFullscreen from '../../../components/modal/AnimatedModalFullscreen';
import ModalConfirm from '../../../components/modal/ModalConfirm';
import { deleteGroup } from '../../../functionalities/apis';
import { getName } from '../../../util/user';
import Center from '../../../components/container/Center';
import FileUploader from '../../../components/picture/FileUploader';
import ProfilePicture, {
  SIZES,
  SHAPES,
  TYPES,
  VARIANT,
} from '../../../components/picture/ProfilePicture';
import { useTrackPageView } from '../../../hooks/useTrackPageView';
import { HistoryShape, MatchShapeOfParams } from '../../../util/shapes';
import ModalGroupSubtitle from '../../../components/modal/ModalGroupSubtitle';
import WizardInputLink from '../../../components/wizard/form/input/WizardInputLink';
import ModalDivider from '../../../components/modal/ModalDivider';

const SAVE_STATES = {
  idle: 'idle',
  saving: 'saving',
  saved: 'saved',
};

const GroupSettingsPageCaptain = ({ match, history }) => {
  const [showDiscussionLanguage, setShowDiscussionLanguage] = useState(false);
  const [showAccess, setShowAccess] = useState(false);
  const [showDeleteGroup, setShowDeleteGroup] = useState(false);
  const [showDeleteGroupConfirm, setShowDeleteGroupConfirm] = useState(false);
  const [isUploading, setUploading] = useState(false);
  const [saveState, setSaveState] = useState(SAVE_STATES.idle);
  const [validateClose, setValidateClose] = useState(false);
  const messages = useMessageGetter('pages.group.settings');
  const [app, dispatch] = useApp();
  const { challengeId, groupId } = match.params;
  const group = app[groupId];

  useTrackPageView();

  useEffect(() => {
    if (!group) {
      loadGroup(dispatch, groupId);
    }
  }, [dispatch, groupId, group]);

  useEffect(() => {
    if (saveState === SAVE_STATES.saved) {
      setTimeout(() => {
        history.goBack();
      }, 800);
    }
  }, [saveState, history]);

  const { value: name, bind: bindName } = useInput((group && group.name) || '');
  const { value: languageInput, bind: bindLanguage } = useSelectOneInput(
    (group && group.discussionLocale) || '',
  );
  const { value: language, setValue: setLanguage } = useInput(
    (group && group.discussionLocale) || '',
  );
  const { value: accessInput, bind: bindAccess } = useSelectOneInput(
    (group && group.access) || 'open',
  );
  const { value: access, setValue: setAccess } = useInput((group && group.access) || 'open');

  if (!group) {
    return null;
  }

  const saveSettings = async () => {
    setSaveState(SAVE_STATES.saving);
    await updateGroup(dispatch, groupId, {
      name,
      discussionLocale: language,
      access: access,
    });
    setSaveState(SAVE_STATES.saved);
  };

  const onClickSave = () => {
    if (group.access === 'open' && accessInput === 'invite') {
      setValidateClose(true);
    } else {
      saveSettings();
    }
  };

  const languageOptions = [
    {
      key: 'en',
      name: 'en',
      title: 'English',
      value: 'en',
    },
    {
      key: 'fi',
      name: 'fi',
      title: 'Finnish',
      value: 'fi',
    },
    {
      key: 'sv',
      name: 'sv',
      title: 'Swedish',
      value: 'sv',
    },
  ];

  const accessOptions = [
    {
      key: 'open',
      name: 'open',
      title: messages('input.access.open'),
      value: 'open',
      subtitle: messages('input.access.modal.open.description'),
    },
    {
      key: 'invite',
      name: 'invite',
      title: messages('input.access.invitation'),
      value: 'invite',
      subtitle: messages('input.access.modal.invitation.description'),
    },
  ];

  const isHatching =
    Groups.getGroupStatus(group.startDate, group.durationDays) === Constants.GroupStates.hatching;

  const actions = [
    <Button
      primary
      key={'save'}
      success={saveState === SAVE_STATES.saved}
      loading={saveState === SAVE_STATES.saving}
      loadingText={'Saving'}
      onClick={onClickSave}
      disabled={false}
    >
      {messages('save')}
    </Button>,
  ];

  const discussionLanguageActions = [
    <Button
      primary
      key={'saveLanguage'}
      disabled={false}
      onClick={() => {
        setLanguage(languageInput);
        setShowDiscussionLanguage(false);
      }}
    >
      {messages('input.language.modal.action.set')}
    </Button>,
  ];

  const saveAccessOption = () => {
    setAccess(accessInput);
    setShowAccess(false);
  };

  const accessActions = [
    <Button primary key={'saveAccess'} disabled={false} onClick={saveAccessOption}>
      {messages('input.access.modal.action.set')}
    </Button>,
  ];

  const deleteGroupActions = [
    <Button secondary key={'cancelDeleteGroup'} onClick={() => setShowDeleteGroup(false)}>
      {messages('input.deleteGroup.modal.action.cancel')}
    </Button>,
    <Button primary key={'deleteGroup'} onClick={() => setShowDeleteGroupConfirm(true)}>
      {messages('input.deleteGroup.modal.action.delete')}
    </Button>,
  ];

  const hideDeleteConfirm = () => setShowDeleteGroupConfirm(false);
  const onDeleteGroup = async () => {
    try {
      await deleteGroup(groupId);
      history.push(`/challenges/${match.params.challengeId}`);
    } catch (error) {
      console.log('Error removing group', error);
    }
  };

  const onGroupPictureUploaded = () => {
    setUploading(false);
    dispatch([EVENTS.UI_UPDATE_GROUP_IMAGE, { groupId: group.id }]);
  };

  const closeModal = () => {
    history.goBack();
  };

  const gotoGroup = () => history.push(`/challenges/${challengeId}/group/${groupId}`);
  const gotoBaseline = () => history.push(`/challenges/${challengeId}/group/${groupId}/baseline`);

  return (
    <ThemeProvider theme={theme('white')}>
      <ModalFullscreen
        title={messages('title')}
        onClose={closeModal}
        actions={actions}
        subtitle={<ModalGroupSubtitle group={group} onClick={closeModal} marginBottom="15px" />}
        border={!!group}
      >
        <ModalContent style={{ paddingBottom: '130px' }}>
          <Center>
            <FileUploader
              type="group"
              uploadId={group.id}
              label={messages('file')}
              onStartUpload={() => setUploading(true)}
              onUploaded={onGroupPictureUploaded}
            >
              <ProfilePicture
                userId={group.id}
                size={SIZES.LARGE}
                shape={SHAPES.SQUARE}
                type={TYPES.GROUP}
                variant={VARIANT.POSITIVE}
                isUploading={isUploading}
              />
            </FileUploader>
          </Center>
          <WizardInputText
            name="name"
            label={messages('input.name.label')}
            placeholder={messages('input.name.placeholder', {
              captainName: getName(group.captain),
            })}
            description=""
            size={'small'}
            secondary
            {...bindName}
          />
          <WizardInputText
            name="startDate"
            label={messages('input.startDate.label')}
            placeholder=""
            description={messages('input.startDate.desc')}
            value={moment(group.startDate).format('DD MMMM YYYY')}
            readOnly
            disabled={true}
            secondary
            size={'small'}
          />
          <WizardFullscreenInputLink
            name="language"
            label={messages('input.language.label')}
            size="small"
            value={(languageOptions.find(l => l.value === language) || {}).title || ''}
            onClick={() => setShowDiscussionLanguage(true)}
            secondary
            disabled={false}
          >
            <AnimatedModalFullscreen
              show={showDiscussionLanguage}
              title={messages('input.language.modal.title')}
              onClose={() => {
                setShowDiscussionLanguage(false);
              }}
              actions={discussionLanguageActions}
              subtitle={
                <ModalGroupSubtitle group={group} onClick={gotoGroup} marginBottom="15px" />
              }
              border={!!group}
            >
              <ModalContent style={{ paddingBottom: '130px' }}>
                <WizardSubtitle style={{ marginBottom: '30px' }}>
                  {messages('input.language.modal.desc')}
                </WizardSubtitle>
                <WizardSelectOne
                  value={languageInput}
                  options={languageOptions}
                  {...bindLanguage}
                />
              </ModalContent>
            </AnimatedModalFullscreen>
          </WizardFullscreenInputLink>
          <WizardInputText
            name="dataLocale"
            label={messages('input.locale.label')}
            secondary
            placeholder=""
            readOnly
            description={messages('input.locale.desc')}
            value={messages('input.locale.value')}
            disabled={true}
            size={'small'}
          />
          <WizardFullscreenInputLink
            name="access"
            label={messages('input.access.label')}
            size="small"
            value={(accessOptions.find(l => l.value === access) || {}).title || ''}
            onClick={() => setShowAccess(true)}
            secondary
            disabled={group.access === 'invite'}
            description={group.access === 'invite' ? messages('input.access.disabled') : undefined}
          >
            <AnimatedModalFullscreen
              show={showAccess}
              title={messages('input.access.modal.title')}
              onClose={() => {
                setShowAccess(false);
              }}
              actions={accessActions}
              subtitle={
                <ModalGroupSubtitle group={group} onClick={gotoGroup} marginBottom="14px" />
              }
              border={!!group}
            >
              <ModalContent style={{ paddingBottom: '130px' }}>
                <WizardSubtitle style={{ marginBottom: '30px' }}>
                  {messages('input.access.modal.desc')}
                </WizardSubtitle>
                <WizardSelectOne value={accessInput} options={accessOptions} {...bindAccess} />
              </ModalContent>
            </AnimatedModalFullscreen>
          </WizardFullscreenInputLink>
          <WizardFullscreenInputLink
            name="delete"
            label={messages('input.deleteGroup.label')}
            size="small"
            value={messages('input.deleteGroup.value')}
            onClick={() => setShowDeleteGroup(true)}
            disabled={!isHatching}
            secondary
            description={messages('input.deleteGroup.desc')}
          >
            <AnimatedModalFullscreen
              show={showDeleteGroup}
              title={messages('input.deleteGroup.modal.title')}
              onClose={() => setShowDeleteGroup(false)}
              actions={deleteGroupActions}
              subtitle={
                <ModalGroupSubtitle group={group} onClick={gotoGroup} marginBottom="14px" />
              }
              border={!!group}
            >
              <ModalContent style={{ paddingBottom: '170px' }}>
                <WizardSubtitle>{messages('input.deleteGroup.modal.desc')}</WizardSubtitle>
                {showDeleteGroupConfirm && (
                  <ModalConfirm
                    title={messages('input.deleteGroup.modal.confirm.title')}
                    description={messages('input.deleteGroup.modal.confirm.desc')}
                    onCancel={hideDeleteConfirm}
                    options={[
                      {
                        label: messages('input.deleteGroup.modal.confirm.yes'),
                        key: 'yes',
                        onClick: onDeleteGroup,
                      },
                      {
                        label: messages('input.deleteGroup.modal.confirm.no'),
                        key: 'no',
                        onClick: hideDeleteConfirm,
                      },
                    ]}
                  />
                )}
              </ModalContent>
            </AnimatedModalFullscreen>
          </WizardFullscreenInputLink>
          <ModalDivider />
          <WizardInputLink
            name="gotobaseline"
            onClick={gotoBaseline}
            label={messages('member.baseline.label')}
            value={messages('member.baseline.value')}
            secondary
            description={messages('member.baseline.description')}
          />
        </ModalContent>
        {validateClose && (
          <ModalConfirm
            x={0}
            y={0}
            title={messages('access.are_you_sure.title')}
            description={messages('access.are_you_sure.description')}
            onCancel={() => setValidateClose(false)}
            options={[
              {
                label: messages('access.are_you_sure.ok'),
                key: 'ok',
                onClick: () => {
                  setValidateClose(false);
                  saveSettings();
                },
              },
              {
                label: messages('access.are_you_sure.cancel'),
                key: 'cancel',
                onClick: () => setValidateClose(false),
              },
            ]}
          />
        )}
      </ModalFullscreen>
    </ThemeProvider>
  );
};

GroupSettingsPageCaptain.propTypes = {
  history: HistoryShape.isRequired,
  match: MatchShapeOfParams(['challengeId', 'groupId']),
};

export default GroupSettingsPageCaptain;
