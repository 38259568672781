import React from 'react';
import styled from 'styled-components';
import { useAppState } from '../../context/appContext';
import { useMessageGetter } from 'react-message-context';
import { labelLeftNegative, pNegative } from '../../styles/text';
import TextDotSeparator from '../../components/divider/TextDotSeparator';

const ServiceWorkerUpdater = () => {
  const { isLoadedNewServiceWorker, swRegistration } = useAppState();

  const messages = useMessageGetter('fragments.serviceworker');

  if (!isLoadedNewServiceWorker) {
    return null;
  }

  const reloadContent = evt => {
    evt.preventDefault();
    // In some cases waiting does not exist but refresh will still do the trick
    if (swRegistration.waiting) {
      swRegistration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
    window.location.reload();
  };

  return (
    <Container onClick={reloadContent} role="button" tabIndex="0">
      <Text>{messages('update')}</Text>
      <TextDotSeparator />
      <Action>{messages('action')}</Action>
    </Container>
  );
};

export default ServiceWorkerUpdater;

const Container = styled.div`
  height: 33px;
  background-image: linear-gradient(
    83deg,
    ${({ theme }) => theme.colors.brand5Gradient2} 1%,
    ${({ theme }) => theme.colors.brand5Gradient1} 100%
  );
  width: 100%;
  position: fixed;
  bottom: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }

  @media (min-width: 500px) {
    bottom: 0px;
  }
`;

const Text = styled.span`
  ${pNegative};
`;

const Action = styled.span`
  ${labelLeftNegative};
`;
