import { numberInteger, numberPercent } from 'messageformat-runtime/lib/formatters';
import { number, plural, select } from 'messageformat-runtime';
import { en, fi } from 'messageformat-runtime/lib/cardinals';

const co2kg = function(value) {
  const { Co2 } = require('isomorphic');
  const co2val = new Co2(value);
  return co2val.convertToKg();
};
const date_en_EEEEdMMMM_mdgz41 = (function() {
  var opt = {"weekday":"long","day":"numeric","month":"long"};
  var dtf = new Intl.DateTimeFormat("en", opt);
  return function(value) { return dtf.format(value); }
})();
const split = function(parts, fn, arg = []) {
  if (!parts) {
    return '';
  } else if (parts.length > 2) {
    return `${parts.slice(0, parts.length - 1).join(', ')} ${arg[0] || 'and'} ${
      parts[parts.length - 1]
    }`;
  } else if (parts.length === 2) {
    return `${parts[0]} ${arg[0] || 'and'} ${parts[1]}`;
  } else if (parts.length === 1) {
    return parts[0];
  } else {
    return '';
  }
};
const date_en_MMMM_fbe6bf = (function() {
  var opt = {"month":"long"};
  var dtf = new Intl.DateTimeFormat("en", opt);
  return function(value) { return dtf.format(value); }
})();
const date_en_EEEE_fbjgcb = (function() {
  var opt = {"weekday":"long"};
  var dtf = new Intl.DateTimeFormat("en", opt);
  return function(value) { return dtf.format(value); }
})();
const date_en_d_44fdtt = (function() {
  var opt = {"day":"numeric"};
  var dtf = new Intl.DateTimeFormat("en", opt);
  return function(value) { return dtf.format(value); }
})();
const date_en_MMMd_fbe6as = (function() {
  var opt = {"month":"short","day":"numeric"};
  var dtf = new Intl.DateTimeFormat("en", opt);
  return function(value) { return dtf.format(value); }
})();
const date_en_MMMdd_mekymg = (function() {
  var opt = {"month":"short","day":"2-digit"};
  var dtf = new Intl.DateTimeFormat("en", opt);
  return function(value) { return dtf.format(value); }
})();
const co2e = function(value) {
  const { Co2 } = require('isomorphic');
  return new Co2(value).getScaledValue().value;
};
const co2eUnit = function(value, locale) {
  const { Co2 } = require('isomorphic');
  return new Co2(value).getScaledValue(locale).unitAbbr;
};
const date_en_dLLLL_mr7jy7 = (function() {
  var opt = {"day":"numeric","month":"long"};
  var dtf = new Intl.DateTimeFormat("en", opt);
  return function(value) { return dtf.format(value); }
})();
const co2equi = function(value, locale) {
  const { Co2 } = require('isomorphic');
  return new Co2(value).getPseudoRandomEquivalence(locale, true);
};
const date_en_LLLd_fbeu1v = (function() {
  var opt = {"month":"short","day":"numeric"};
  var dtf = new Intl.DateTimeFormat("en", opt);
  return function(value) { return dtf.format(value); }
})();
export default {
  en: {
    pages: {
      home: {
        header: {
          title: function(d) { return "Welcome, friend"; },
          desc: function(d) { return "Tideal is a community-led and data-inspired climate action platform."; },
          loggedIn: {
            "0": function(d) { return "What greatness will you accomplish today? Go, do it."; },
            "1": function(d) { return "Permanent change requires effort, but always starts with small steps."; },
            "2": function(d) { return "When we act together, we can still save something."; },
            "3": function(d) { return "“If we could change ourselves, the tendencies in the world would also change.”"; },
            "4": function(d) { return "We want sustainable way of living to become the most desired way of living."; },
            "5": function(d) { return "Though everyone can’t do everything, everyone can do something."; },
            "6": function(d) { return "Since there is no planet B, let’s try not to mess things up with planet A."; },
            title: function(d) { return "Hi there, " + d.givenName; },
            random8: function(d) { return "High five, " + d.givenName + "! How are you today?"; }
          }
        },
        stats: {
          title: function(d) { return "Right now on Tideal"; },
          reductionKg24h: {
            title: function(d) { return "Last 24h reduction"; },
            description: function(d) { return "CO2e"; }
          },
          reductionKgAlltime: {
            title: function(d) { return "All-time reduction"; },
            description: function(d) { return "CO2e"; }
          }
        },
        section: {
          invitations: {
            title: function(d) { return "Group invitations"; }
          },
          activeGroups: {
            title: function(d) { return "Your active groups"; }
          }
        },
        blog: {
          title: function(d) { return "From the Tideal blog"; }
        },
        challenges: {
          loggedIn: {
            title: function(d) { return "New challenges for you"; }
          },
          loggedOut: {
            title: function(d) { return "Challenges for you"; }
          }
        },
        actions: {
          title: function(d) { return "Your latest actions"; }
        }
      },
      blog: {
        title: function(d) { return "Tideal stories"; }
      },
      challenges: {
        title: function(d) { return "Challenges"; },
        heading: function(d) { return "Challenges are Tideal’s fundamental building blocks. Right now we have only one of them live, but keep an eye on more coming soon!"; },
        willBeMore: function(d) { return "Right now we have just two challenges live, but work to bring you more of them in the future!"; }
      },
      challenge: {
        title: function(d) { return "Challenge"; },
        groups: {
          hatching: function(d) { return "Groups hatching"; },
          active: function(d) { return "Groups in change"; }
        },
        more: {
          title: function(d) { return "Read more about it"; }
        },
        captain: {
          banner: {
            title: function(d) { return "Create your own group and be a captain in it?"; },
            action: function(d) { return "Create new group"; }
          }
        },
        fact: {
          personalFootprint: {
            label: function(d) { return "Of personal footprint"; },
            value: function(d) { return numberPercent(d.value, "en"); },
            desc: function(d) { return "for average person"; }
          },
          allTimeReduction: {
            label: function(d) { return "All-time reduction"; },
            value: function(d) { return co2kg(d.value, "en"); },
            desc: function(d) { return "CO2e"; }
          }
        }
      },
      error: {
        "500": {
          title: function(d) { return "Argh, something went wrong"; },
          description: function(d) { return "We are terribly sorry, but something went wrong here. This is our bad, not yours.\nWe will now alert our best engineers and have them fix this problem for a future version of Tideal."; },
          button: {
            ok: function(d) { return "Hmm, okay"; }
          }
        },
        signed_404: {
          title: function(d) { return "Page not found or access denied"; },
          description: function(d) { return "Sorry, but this page either doesn’t exist or you don’t have access rights to it."; },
          button: {
            ok: function(d) { return "Okay"; }
          }
        },
        anon_404: {
          title: function(d) { return "Page not found or access denied"; },
          description: function(d) { return "Sorry, but this page either doesn’t exist or you don’t have access rights to it.\nIf you think this page exists and you should have access to it, please sign in, and we’ll take you right to it."; },
          button: {
            ok: function(d) { return "Sign in"; }
          }
        }
      },
      group: {
        title: function(d) { return "Group"; },
        title_user_is_member: function(d) { return "Your Group"; },
        header: {
          label: {
            captain: function(d) { return "Captain"; }
          },
          status: {
            hatching: function(d) { return "Starts " + plural(d.groupDay, 0, en, { "1": "tomorrow", other: "in " + number("en", d.groupDay, 0) + " days" }); },
            active: function(d) { return "Day " + d.groupDay; },
            alumni: function(d) { return "Ended"; }
          }
        },
        info: {
          title: function(d) { return "Group info"; },
          challenge: {
            label: function(d) { return "Challenge"; }
          },
          status: {
            label: function(d) { return "Group status"; },
            hatching: {
              desc: function(d) { return "The captain is now recruiting members who are ready to change their habits! The change period starts " + plural(d.startInDays, 0, en, { "1": "tomorrow", other: "in " + number("en", d.startInDays, 0) + " days" }) + ", on " + d.startDateString + "!"; }
            }
          },
          condensed: {
            status: {
              hatching: {
                desc: function(d) { return "The change period starts on " + d.startDateString + "."; }
              }
            },
            discussion: {
              title: function(d) { return "Discussion language"; },
              label: function(d) { return select(d.language, { en: "English", fi: "Finnish", other: "any language" }) + "."; }
            },
            region: {
              label: function(d) { return select(d.region, { en: "US", fi: "Finland", other: "Unknown" }) + "."; }
            }
          },
          discussion: {
            title: function(d) { return "Discussion"; },
            label: function(d) { return select(d.language, { en: "EN", fi: "FI", other: "ALL" }); },
            desc: function(d) { return "The discussion in this group takes place in " + select(d.language, { en: "English", fi: "Finnish", other: "any language" }) + "."; }
          },
          region: {
            title: function(d) { return "Data locale"; },
            label: function(d) { return select(d.region, { en: "US", fi: "FI", other: "ALL" }); },
            desc: function(d) { return "The CO2e data in this group is based partly on some weighted averages from " + select(d.region, { en: "the US", fi: "Finland", other: "an unknown region" }) + ". For instance, we've taken into account how different energy forms are produced and on what kind of electricity the trains run in that locale. If you're not from " + select(d.region, { en: "the US", fi: "Finland", other: "an unknown region" }) + ", worry not: in this challenge, " + select(d.region, { en: "the US", fi: "Finland", other: "an unknown region" }) + " is a safe choice for everyone."; }
          },
          access: {
            title: function(d) { return "Access"; },
            label: {
              open: function(d) { return "anyone can join"; },
              inviteOnly: function(d) { return "by invitation only"; }
            }
          }
        },
        charts: {
          hatching: {
            group: {
              title: function(d) { return "Group reduction goal"; },
              valueDesc: function(d) { return "CO2e a year"; },
              infoText: function(d) { return "is what the group aims to reduce in CO2e during the change period of " + plural(d.weeks, 0, en, { one: "one week", other: number("en", d.weeks, 0) + " weeks" }); },
              warning: {
                infoText: function(d) { return "is by how much the carbon footprint of the group’s " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " would go up 😱"; },
                yearly: function(d) { return "would be the group’s yearly increase with this plan, so please get back on track!"; }
              }
            },
            personal: {
              title: function(d) { return "Your reduction goal"; },
              valueDesc: function(d) { return "CO2e a year"; },
              infoText: function(d) { return "is what you aim to reduce in CO2e during the change period of " + plural(d.weeks, 0, en, { one: "one week", other: number("en", d.weeks, 0) + " weeks" }); },
              warning: {
                infoText: function(d) { return "is by how much the carbon footprint of your " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " would go up 😱"; },
                yearly: function(d) { return "would be your yearly increase with this pace, so please get back on track!"; }
              }
            }
          },
          active: {
            group: {
              title: function(d) { return "Group reduction"; },
              valueDesc: function(d) { return "CO2e since start"; },
              infoText: function(d) { return "would be the group’s yearly reduction of CO2e with this pace"; },
              warning: {
                infoText: function(d) { return "is by how much the carbon footprint of the group’s " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " has gone up 😱"; },
                yearly: function(d) { return "would be the group’s yearly increase with this pace, so please get back on track!"; }
              }
            },
            personal: {
              title: function(d) { return "Your reduction"; },
              valueDesc: function(d) { return "CO2e since start"; },
              infoText: function(d) { return "would be your yearly reduction of CO2e with this pace."; },
              warning: {
                infoText: function(d) { return "is by how much the carbon footprint of your " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " has gone up 😱"; },
                yearly: function(d) { return "would be your yearly increase with this pace, so please get back on track!"; }
              }
            }
          },
          alumni: {
            group: {
              title: function(d) { return "Group reduction"; },
              valueDesc: function(d) { return "CO2e during change"; },
              infoText: function(d) { return "is how much CO2e group saved when they continued on this path"; },
              warning: {
                infoText: function(d) { return "is by how much the carbon footprint of the group’s " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " did go up 😱"; },
                yearly: function(d) { return "is the group’s yearly increase with this pace, so please get back on track!"; }
              }
            },
            personal: {
              title: function(d) { return "Your reduction"; },
              valueDesc: function(d) { return "CO2e during change"; },
              infoText: function(d) { return "is your reduction of CO2e after you’ve continued on your new path"; },
              warning: {
                infoText: function(d) { return "is by how much the carbon footprint of your " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " did go up 😱"; },
                yearly: function(d) { return "will be your yearly increase with this pace, so please get back on track!"; }
              }
            }
          },
          group: {
            refLegend: function(d) { return "Group before"; },
            targetLegend: function(d) { return "goal"; }
          },
          personal: {
            refLegend: function(d) { return "You before"; },
            targetLegend: function(d) { return "goal"; }
          }
        },
        settings: {
          member: {
            title: function(d) { return "Your group settings"; },
            baseline: {
              label: function(d) { return "Your baseline and goal"; },
              value: function(d) { return "View your starting point"; },
              description: function(d) { return "Your baseline, goal and reduction data are based on the answers you gave when joining the group. If you realize you made a mistake with your answers, you can change them until day number 3 of the group’s change period."; }
            }
          },
          title: function(d) { return "Group settings"; },
          save: function(d) { return "Save changes"; },
          file: function(d) { return "Change the group picture"; },
          input: {
            name: {
              label: function(d) { return "Group name"; },
              placeholder: function(d) { return "Group " + d.captainName; }
            },
            startDate: {
              label: function(d) { return "Group start date"; },
              desc: function(d) { return ""; }
            },
            language: {
              label: function(d) { return "Discussion language"; },
              modal: {
                title: function(d) { return "Discussion language"; },
                desc: function(d) { return "A Tideal group has some discussion features, such as cheering for fellow group members’ progress. What is the preferred discussion language in this group?"; },
                action: {
                  set: function(d) { return "Set language"; }
                }
              }
            },
            access: {
              label: function(d) { return "Group access"; },
              open: function(d) { return "Anyone can join"; },
              invitation: function(d) { return "By invitation only"; },
              disabled: function(d) { return "Once the group access has been set to \"by invitation only\", it cannot be changed back to other options."; },
              modal: {
                title: function(d) { return "Group access"; },
                desc: function(d) { return "It’s possible to switch the group access from \"anyone can join\" to \"by invite only\", but not the other way around."; },
                action: {
                  set: function(d) { return "Set access"; }
                },
                open: {
                  description: function(d) { return "Anyone can join the group, but only group members can see what’s happening in it."; }
                },
                invitation: {
                  description: function(d) { return "Joined by invitation only. Only group members can see what’s happening in it."; }
                }
              }
            },
            locale: {
              label: function(d) { return "Data locale"; },
              desc: function(d) { return "For now, we support Finland as our only data locale."; },
              value: function(d) { return "Finland"; }
            },
            deleteGroup: {
              label: function(d) { return "Group deletion"; },
              value: function(d) { return "Delete group"; },
              desc: function(d) { return "The group can only be deleted during its hatching period."; },
              modal: {
                title: function(d) { return "Delete group"; },
                desc: function(d) { return "As the group captain, you can delete the group during its hatching period. We will shed a tear if you do, but also understand that sometimes this is the best way forward.\nYou should also be aware that right now we don’t have the functionality to let your group members automatically know that the group was deleted."; },
                action: {
                  "delete": function(d) { return "Delete group"; },
                  cancel: function(d) { return "No, don’t delete"; }
                },
                confirm: {
                  title: function(d) { return "One last check – really delete?"; },
                  desc: function(d) { return "We just want to make sure that this doesn’t happen by accident."; },
                  yes: function(d) { return "Yes, delete"; },
                  no: function(d) { return "No, don’t"; }
                }
              }
            }
          },
          access: {
            are_you_sure: {
              title: function(d) { return "Really change the group access?"; },
              description: function(d) { return "Once you set the group access to \"by invitation only\", you can’t switch it back to \"anyone can join\"."; },
              ok: function(d) { return "Yes, change"; },
              cancel: function(d) { return "No, don’t"; }
            }
          }
        },
        invite: {
          heading: function(d) { return "Invite friends"; },
          title: function(d) { return "Tideal is better with friends"; },
          subtitle: function(d) { return "Invite friends and family to the group, help them achieve a more sustainable lifestyle, and make a bigger change together."; },
          message: {
            heading: function(d) { return "Your invitation message"; },
            placeholder: function(d) { return "You can attach a message to the invitation, which people will see when they follow your invitation link. This is optional."; },
            subject: function(d) { return "Join my 4-week challenge group on Tideal"; },
            body: function(d) { return "I invite you to join my 4-week challenge group on Tideal at " + d.url + ". " + select(d.isInviteText, { true: "By following the link you will also find an invitation message that I left for you to read :)", other: "" }) + select(d.isPasscode, { true: "\n\nThe access code for the group is " + d.passcode + ". You will need to enter it when joining the group.", other: "" }) + "\n\nAbout Tideal: it’s a climate action app where people can reduce their climate footprint and see the effects in realtime.\n\nRead more about what the challenge is about and join my group by following the link at the top.\n\nCheers,\n" + d.captainName; },
            edit: {
              title: function(d) { return "Invitation message"; },
              placeholder: function(d) { return "Write your message"; },
              save: function(d) { return "Save"; }
            }
          },
          share: {
            heading: function(d) { return "Invite people to group"; },
            action: function(d) { return "Share invite"; }
          },
          email: {
            action: function(d) { return "Email"; }
          },
          copy: {
            link: {
              action: function(d) { return "Copy invitation link"; },
              label: function(d) { return "Invitation link"; },
              confirm: {
                title: function(d) { return "Link copied!"; },
                description: function(d) { return "You can now just paste it to and send it with the tool of your choice."; }
              }
            },
            passcode: {
              confirm: {
                title: function(d) { return "Access code copied!"; },
                description: function(d) { return "Make sure to share it with the people whom you want to join the group."; }
              }
            },
            confirm: {
              ok: function(d) { return "Okay"; }
            },
            error: {
              title: function(d) { return "Error"; }
            },
            code: {
              title: function(d) { return "Access code"; },
              action: function(d) { return "Copy access code"; },
              description: function(d) { return "Since this group is by invitation only, the people who want to join it need to enter this code. So, make sure to let them know about this."; }
            }
          },
          error: {
            share: function(d) { return "Error sharing link"; },
            copy: {
              code: function(d) { return "Error copying code"; },
              link: function(d) { return "Error copying link"; }
            },
            update: function(d) { return "Error setting welcome message"; }
          }
        },
        members: {
          title: function(d) { return plural(d.amount, 0, en, { one: number("en", d.amount, 0) + " member", other: number("en", d.amount, 0) + " members" }); },
          title_unknown_amount: function(d) { return "members"; },
          legend: {
            hatching: function(d) { return "Yearly baseline footprint"; },
            footprint: function(d) { return "Yearly footprint"; },
            "default": function(d) { return "Reduction since start"; }
          },
          sorting: {
            "default": function(d) { return "Default"; },
            alphabetically: function(d) { return "Alphabetically — first name"; },
            alphabetically_short: function(d) { return "First name"; },
            biggestReduction: function(d) { return "Biggest reduction"; },
            smallestFootprint: function(d) { return "Smallest footprint"; }
          }
        },
        baseline: {
          title: function(d) { return "Your baseline and goal"; }
        },
        fullStats: {
          title: function(d) { return "Full stats"; },
          baseline: {
            description: function(d) { return "The baseline and goal data on are based on the answers you and others gave when joining the group. If you realize you made a mistake with your answers, you can change them until day number 3 of the group’s change period."; },
            link: function(d) { return "See how you answered"; }
          }
        },
        error: {
          no_group: {
            title: function(d) { return "This group doesn’t exist"; },
            description: function(d) { return "How strange... If you feel that this is a mistake on Tideal’s side, please contact us and we’ll see to it."; },
            ok: function(d) { return "Okay"; }
          },
          no_challenge: {
            title: function(d) { return "This challenge doesn’t exist"; },
            description: function(d) { return "How strange... If you feel that this is a mistake on Tideal’s side, please contact us and we’ll see to it."; },
            ok: function(d) { return "Okay"; }
          }
        }
      },
      invite: {
        header: {
          title: function(d) { return d.captainName + " invited you to join a Tideal group"; },
          subtitle: function(d) { return "Tideal is a community-led and data-inspired climate action platform. Take part in groups in which people change their habits in bite-sized challenges."; }
        },
        segment: {
          message: function(d) { return "Message from captain"; }
        },
        actions: {
          join: function(d) { return "Join the group!"; },
          back: function(d) { return "Look around"; }
        }
      },
      messages: {
        title: function(d) { return "Messages"; }
      },
      user: {
        header: {
          title: function(d) { return "Your profile"; },
          memberText: function(d) { return "Tidealist since " + d.since + "."; },
          groups: function(d) { return "Your groups"; }
        },
        no_groups: {
          title: function(d) { return "Not much here yet!"; },
          content: function(d) { return "When you have joined a Tideal group or two, we will list those groups in this space. Here, you can also access your profile settings, by clicking the $1 icon in the top right corner of the page."; }
        },
        actions: {
          signout: {
            title: function(d) { return "Sign out "; }
          },
          login: {
            exit: {
              confirm: {
                title: function(d) { return "Exit from signing in?"; },
                out: function(d) { return "Yes, exit"; },
                stay: function(d) { return "No, stay here"; },
                content: function(d) { return "If you exit now, we won’t sign you in into your Tideal account. Not a big deal, but we just thought to let you know 😊 "; }
              }
            }
          }
        }
      },
      wizards: {
        select: {
          country: {
            title: function(d) { return "Choose country code"; }
          }
        },
        secondary: {
          email: {
            title: function(d) { return "Add an email address to your account"; },
            subtitle: function(d) { return "If you do this, you will then be able to use email as an additional means for signing in. Handy if you ever lose access to your phone number!"; },
            invalidEmail: function(d) { return "Sorry, but that’s not a valid email"; },
            form: {
              email: {
                label: function(d) { return "Email address"; },
                placeholder: function(d) { return "Your email address "; }
              }
            },
            "continue": function(d) { return "Send a verification code"; },
            confirm: {
              title: function(d) { return "Enter the verification code we sent you by email"; },
              subtitle: function(d) { return "The code was sent to " + d.target; },
              "continue": function(d) { return "Code entered"; }
            },
            verified: {
              title: function(d) { return "Verified!"; },
              subtitle: function(d) { return "We can now be certain you own that email address. Not that we ever had any doubts!"; },
              subtitle2: function(d) { return "Now, you can also use this email address to sign in to Tideal."; }
            }
          },
          phoneNumber: {
            title: function(d) { return "Add phone number to your account"; },
            subtitle: function(d) { return "If you do this, we will be able to serve you better by sending you reminders as SMS messages, instead of email. You can always opt out of reminders."; },
            invalidNumber: function(d) { return "Sorry, but that’s not a valid phone number"; },
            form: {
              phoneNumber: {
                label: function(d) { return "Phone number"; },
                placeholder: function(d) { return "phone number "; }
              }
            },
            "continue": function(d) { return "Send a verification code"; },
            confirm: {
              title: function(d) { return "Enter the verification code we sent you by SMS"; },
              subtitle: function(d) { return "The code was sent to " + d.target; },
              placeholder: function(d) { return "∙"; },
              "continue": function(d) { return "Code entered"; }
            },
            verified: {
              title: function(d) { return "Verified!"; },
              subtitle: function(d) { return "We can now be certain you own that phone number. Not that we ever had any doubts!"; },
              subtitle2: function(d) { return "Now, you can also use your phone number to sign in to Tideal. We also turned SMS notifications on for you: you can control those in your profile settings."; }
            }
          },
          confirm: {
            error: function(d) { return "Sorry, but that’s not the code we sent."; },
            try_again: function(d) { return "Try again?"; }
          },
          error: {
            resetAuth: {
              title: function(d) { return "No valid sign up"; },
              content: function(d) { return "Your sign up seems to have timed out and you need to start the process again."; },
              restart: function(d) { return "Retry"; }
            },
            tooManyTries: {
              title: function(d) { return "Too many tries"; },
              content: function(d) { return "That’s one too many wrong attempts. Unfortunately you now need to exit and start the process again."; },
              restart: function(d) { return "Retry"; }
            },
            invalidNumber: {
              title: function(d) { return "The number is not a proper phone number"; },
              content: function(d) { return "We could not use the phone number provided, since we didn’t recognize the number as possible phone number. Could you check the number and try again?"; }
            },
            unknown: {
              title: function(d) { return "Argh, something went wrong"; },
              content: function(d) { return "We are terribly sorry, but something went wrong here. To fix the situation right now, you can try doing what you were about to do once more.\nRest assured that we will send our best engineers down the rabbit hole to look into this problem."; },
              close: function(d) { return "Hmm, okay"; }
            }
          }
        },
        signup: {
          sms: {
            subtitle: function(d) { return "You will later use this number to sign in to your account."; },
            form: {
              sms: {
                label: function(d) { return "Phone number"; },
                placeholder: function(d) { return "+358XXXXXXX"; },
                desc: function(d) { return "Enter your phone number in the international format, such as +3581234567."; }
              }
            },
            "continue": function(d) { return "Send me the code"; },
            loading: function(d) { return "Sending"; },
            error: {
              smsExists: {
                title: function(d) { return "Hello... is it you?"; },
                content: function(d) { return "An account with that phone number has already been created in Tideal. This means that you can simply sign in to that account."; },
                login: function(d) { return "OK, sign me in"; },
                out: function(d) { return "No, I want out"; }
              }
            }
          },
          confirm: {
            sms: {
              title: function(d) { return "Enter the sign-in code which we sent you by SMS."; },
              subtitle: function(d) { return "The code was sent to " + d.phone + "."; },
              error: function(d) { return "Sorry, but that’s not the code we sent. Try again?"; }
            },
            email: {
              title: function(d) { return "Enter the sign-in code which we sent you by email."; },
              subtitle: function(d) { return "The code was sent to " + d.email + "."; }
            },
            "continue": function(d) { return "Code entered"; },
            loading: function(d) { return "Verifying"; }
          },
          email: {
            title: function(d) { return "Where should we send you a verification email?"; },
            subtitle: function(d) { return "You will later use this email to sign in to your account."; },
            form: {
              email: {
                label: function(d) { return "Email address"; },
                placeholder: function(d) { return "Your email address"; },
                desc: function(d) { return "Enter your email address."; }
              }
            },
            "continue": function(d) { return "Send me the code"; }
          },
          info: {
            title: function(d) { return "Almost ready! A few little things we’d like to know about you."; },
            form: {
              givenName: {
                label: function(d) { return "First name"; },
                placeholder: function(d) { return "Your first name"; }
              },
              lastname: {
                label: function(d) { return "Last name"; },
                placeholder: function(d) { return "Your last name"; },
                desc: function(d) { return "You can hide your last name in your account settings."; }
              },
              phone: {
                label: function(d) { return "Phone number"; },
                placeholder: function(d) { return "+358XXXXXXX"; },
                desc: function(d) { return "This will act as a back-up for signing in to your account."; }
              },
              email: {
                label: function(d) { return "Email"; },
                placeholder: function(d) { return "Your email address"; },
                desc: function(d) { return "This will act as a back-up for signing in to your account."; }
              }
            },
            legal: {
              text: function(d) { return "By creating your account, you agree to Tideal’s $1 and $2."; },
              $1: function(d) { return "terms of use"; },
              $2: function(d) { return "privacy policy"; }
            },
            "continue": function(d) { return "Create my account"; },
            loading: function(d) { return "Creating"; }
          },
          exit: {
            title: function(d) { return "Do not create an account?"; },
            description: function(d) { return "If you exit now, your account will not be created."; },
            confirm: function(d) { return "Yes, exit"; },
            cancel: function(d) { return "No, stay here"; }
          },
          welcome: {
            title: function(d) { return "Nice to meet you, " + d.name + "."; },
            subtitle: {
              fromProfile: function(d) { return "Thank you for signing up to Tideal. Here’s the good karma we promised ✨.\nNext up, how about setting a profile picture for yourself in your profile settings?"; },
              fromGroupJoin: function(d) { return "Thank you for signing up to Tideal. Here’s the good karma we promised ✨.\nYou will now also find yourself in the group you were about to join before we stepped in and asked you to sign up for Tideal."; }
            },
            "continue": {
              joinGroup: function(d) { return "See the group"; },
              toProfile: function(d) { return "Take me to my profile"; }
            }
          }
        },
        auth: {
          step_1_welcome: {
            title: function(d) { return "Let’s get you in!"; },
            subtitle: {
              "default": function(d) { return "An account in Tideal gives you access to groups, the ability to track the impact of your lifestyle changes, and unlimited good karma."; },
              joinGroup: function(d) { return "To join this group you need an account. An account in Tideal gives you access to groups, the ability to track the impact of your lifestyle changes, and unlimited good karma."; },
              joinLockedGroup: function(d) { return "Since this group accepts new members by invitation only, we need to first ask you to sign in to your existing account or create a new one."; },
              createGroup: function(d) { return "To create a new group, we need to first ask you to sign in to your existing account or create a new one."; },
              answer: function(d) { return "To answer your daily questions, we need to first ask you to sign in to your account."; }
            },
            "continue": {
              phoneNumber: function(d) { return "Use phone number"; },
              email: function(d) { return "Use email"; }
            },
            change_type: function(d) { return "Other sign-in methods"; },
            change_type_title: function(d) { return "Continue with"; },
            type: {
              phoneNumber: function(d) { return "Phone number"; },
              email: function(d) { return "Email"; }
            }
          },
          step_2_auth: {
            phoneNumber: {
              title: function(d) { return "Continue with phone number"; },
              subtitle: function(d) { return "Next, we will send you a verification code by SMS. If you don’t yet have a Tideal account, we can then set one up for you in no time."; },
              input: {
                label: function(d) { return "Phone number"; },
                placeholder: function(d) { return "phone number"; }
              },
              forward: function(d) { return "Send me the code"; },
              restricted: {
                info: function(d) { return "We’re sorry, but as of now we cannot deliver SMS messages to your country. Instead, please $1 ."; },
                link: function(d) { return "sign in using email"; }
              },
              error: {
                restricted: function(d) { return "We cannot send SMS to your country 😢"; },
                invalid_number: function(d) { return "Sorry, but that’s not a valid phone number"; },
                unknown: function(d) { return "Sorry, but something on our side did not work. Please contact us for help!"; }
              }
            },
            email: {
              title: function(d) { return "Continue with email"; },
              subtitle: function(d) { return "Next, we will send you a verification code by email. If you don’t yet have a Tideal account, we can then set one up for you in no time."; },
              input: {
                label: function(d) { return "Email"; },
                placeholder: function(d) { return "Your email address"; }
              },
              forward: function(d) { return "Send me the code"; },
              error: {
                invalid_email: function(d) { return "Sorry, but that’s not a valid email"; },
                unknown: function(d) { return "Sorry, but something on our side did not work. Please contact us for help!"; }
              }
            }
          },
          step_3_verify: {
            phoneNumber: {
              title: function(d) { return "Enter the sign-in code which we sent you by SMS"; },
              subtitle: function(d) { return "The code was sent to " + d.value + "."; },
              back: function(d) { return "Edit phone number"; },
              input: {
                placeholder: function(d) { return "∙"; }
              },
              error: {
                incorrect_secret: function(d) { return "Sorry, but that’s not the code we sent."; },
                too_many_tries: function(d) { return "That’s one too many wrong attempts. To get in using this phone number, you need to exit and start again."; },
                reset_auth: function(d) { return "Your sign up seems to have timed out and you need to start the process again."; },
                unknown: function(d) { return "This one is on us! There was an unknown issue. Please contact Tideal staff for help with the issue."; },
                try_again: function(d) { return "Re-enter code?"; }
              },
              "continue": function(d) { return "Code entered"; },
              request_timeout: function(d) { return "You can request new code in $1"; },
              request_new: function(d) { return "Request new code"; },
              new_code: {
                title: function(d) { return "Didn’t receive the code? Request a new one."; },
                resend: function(d) { return "Resend it to " + d.value; },
                phoneNumber: function(d) { return "Edit phone number"; },
                email: function(d) { return "Send it by email"; }
              }
            },
            email: {
              title: function(d) { return "Enter the sign-in code which we sent you by email"; },
              subtitle: function(d) { return "The code was sent to " + d.value + "."; },
              back: function(d) { return "Edit email"; },
              input: {
                placeholder: function(d) { return "∙"; }
              },
              error: {
                incorrect_secret: function(d) { return "Sorry, but that’s not the code we sent."; },
                too_many_tries: function(d) { return "That’s one too many wrong attempts. To get in using this email address, you need to exit and start again."; },
                reset_auth: function(d) { return "Your sign up seems to have timed out and you need to start the process again."; },
                unknown: function(d) { return "This one is on us! There was an unknown issue. Please contact Tideal staff for help with the issue."; },
                try_again: function(d) { return "Re-enter code?"; }
              },
              "continue": function(d) { return "Code entered"; },
              request_timeout: function(d) { return "You can request new code in $1"; },
              request_new: function(d) { return "Request new code"; },
              new_code: {
                title: function(d) { return "Didn’t receive the code? Request a new one."; },
                resend: function(d) { return "Resend it to " + d.value; },
                phoneNumber: function(d) { return "Send it by SMS"; },
                email: function(d) { return "Edit email"; }
              }
            }
          },
          step_4_begin_signup: {
            phoneNumber: {
              title: function(d) { return "Welcome, new friend!"; },
              subtitle: function(d) { return "It seems that your phone number " + d.value + " doesn’t yet have a Tideal account registered with it. So let’s set up a new account for you."; },
              "continue": function(d) { return "Yes, continue"; },
              exit: function(d) { return "No, exit"; }
            },
            email: {
              title: function(d) { return "Welcome, new friend!"; },
              subtitle: function(d) { return "It seems that your email address " + d.value + " doesn’t yet have a Tideal account registered with it. So let’s set up a new account for you."; },
              "continue": function(d) { return "Yes, continue"; },
              exit: function(d) { return "No, exit"; }
            }
          },
          step_5_profile_info: {
            title: function(d) { return "A few little things we’d like to know about you."; },
            subtitle: function(d) { return "Your name helps friends identify you in Tideal."; },
            input: {
              givenName: {
                label: function(d) { return "First name"; },
                placeholder: function(d) { return "Your first name"; }
              },
              familyName: {
                label: function(d) { return "Last name"; },
                placeholder: function(d) { return "Your last name"; },
                desc: function(d) { return "You can later hide your last name in your account settings."; }
              }
            },
            info: {
              legal: {
                text: function(d) { return "By creating your account, you agree to Tideal’s $1 and $2."; },
                $1: function(d) { return "terms of use"; },
                $2: function(d) { return "privacy policy"; }
              }
            },
            "continue": function(d) { return "Create my account"; }
          },
          step_6_account_created: {
            title: function(d) { return "Nice to meet you, " + d.givenName + "!"; },
            "default": {
              subtitle: function(d) { return "Thank you for signing up to Tideal. Here’s the good karma we promised ✨\nNext up, how about setting a profile picture for yourself in your profile settings?"; },
              "continue": function(d) { return "Go to your profile"; }
            },
            answer: {
              subtitle: function(d) { return "Thank you for signing up to Tideal. Here’s the good karma we promised ✨\nNext up, how about setting a profile picture for yourself in your profile settings?"; },
              "continue": function(d) { return "Go to your profile"; }
            },
            joinGroup: {
              subtitle: function(d) { return "Thank you for signing up to Tideal. Here’s the good karma we promised ✨\nYou will now also be enrolled to the group."; },
              "continue": function(d) { return "Join the group"; }
            },
            joinLockedGroup: {
              subtitle: function(d) { return "Thank you for signing up to Tideal. Here’s the good karma we promised ✨\nYou can now join the group."; },
              "continue": function(d) { return "Join the group"; }
            },
            createGroup: {
              subtitle: function(d) { return "Thank you for signing up to Tideal. Here’s the good karma we promised ✨\nNow let’s see about creating that group."; },
              "continue": function(d) { return "Create the group"; }
            }
          },
          "default": {
            exit: {
              title: function(d) { return "Exit from getting in?"; },
              description: function(d) { return "If you exit now, you will not be signed in to Tideal."; },
              confirm: function(d) { return "Yes, exit"; },
              cancel: function(d) { return "No, stay here"; }
            }
          },
          joinGroup: {
            exit: {
              title: function(d) { return "Exit from getting in?"; },
              description: function(d) { return "If you exit now, you will not be signed in to Tideal and we will not enrol you in the group you were about to join."; },
              confirm: function(d) { return "Yes, exit"; },
              cancel: function(d) { return "No, stay here"; }
            }
          },
          joinLockedGroup: {
            exit: {
              title: function(d) { return "Exit from getting in?"; },
              description: function(d) { return "If you exit now, you will not be signed in to Tideal and will not be able to join the group you were viewing."; },
              confirm: function(d) { return "Yes, exit"; },
              cancel: function(d) { return "No, stay here"; }
            }
          },
          createGroup: {
            exit: {
              title: function(d) { return "Exit from getting in?"; },
              description: function(d) { return "If you exit now, you will not be signed in to Tideal and will not be able to create a new group."; },
              confirm: function(d) { return "Yes, exit"; },
              cancel: function(d) { return "No, stay here"; }
            }
          },
          answer: {
            exit: {
              title: function(d) { return "Exit from getting in?"; },
              description: function(d) { return "If you exit now, you will not be signed in to Tideal and will not be able to answer your daily questions."; },
              confirm: function(d) { return "Yes, exit"; },
              cancel: function(d) { return "No, stay here"; }
            }
          }
        },
        join: {
          wrongCode: function(d) { return "Sorry, but that code is not right. Try again?"; },
          banned: {
            title: function(d) { return "1 hour penalty!"; },
            description: function(d) { return "You entered the access code wrong 3 times in a row. To make sure you’re not an evil robot trying to hack your way into this group, we need to stop you from more tries for 1 hour."; }
          }
        },
        hot_water: {
          step: {
            join: {
              "continue": function(d) { return "Yes, join the group"; }
            }
          }
        }
      },
      baseline: {
        title: function(d) { return "Your baseline and goal"; },
        actions: {
          gotoedit: function(d) { return "Edit your answers"; }
        },
        table: {
          baseline: {
            title: function(d) { return "Baseline"; },
            subtitle: function(d) { return "You answered, per week"; }
          },
          goal: {
            title: function(d) { return "Goal"; },
            subtitle: function(d) { return "You answered"; },
            initial: {
              label: function(d) { return "Baseline"; },
              value: function(d) { return co2kg(d.initialCo2Kg, "en") + " kg"; },
              description: function(d) { return "CO2e a year"; }
            },
            target: {
              label: function(d) { return "Reduction goal"; },
              value: function(d) { return numberPercent(d.reductionPrc, "en"); }
            },
            calculus: {
              formula: function(d) { return co2kg(d.initialCo2Kg, "en") + " kg CO2e $1 " + numberPercent(d.reductionPrc, "en") + " $2"; },
              value: function(d) { return co2kg(d.targetCo2Kg, "en"); },
              valueUnit: function(d) { return "kg"; },
              description: function(d) { return "CO2e a year"; }
            }
          },
          actions: {
            title: function(d) { return "Concrete actions"; }
          }
        },
        hot_water: {
          showers: {
            label: function(d) { return "Showers"; },
            value: function(d) { return numberInteger(d.showersValue, "en"); }
          },
          duration: {
            label: function(d) { return "Length of a shower"; },
            value: function(d) { return d.durationValue + " min"; }
          },
          heating: {
            label: function(d) { return "Energy source"; },
            wood: function(d) { return "Wood"; },
            geothermal: function(d) { return "Geothermal with mixed sources electricity"; },
            geothermal_zero: function(d) { return "Geothermal with zero emissions electricity"; },
            naturalgas: function(d) { return "Natural gas"; },
            oil: function(d) { return "Oil"; },
            districtheating: function(d) { return "District heating"; },
            electricity: function(d) { return "Electricity, mixed sources"; },
            electricity_zero: function(d) { return "Electricity, zero emissions sources"; },
            dunno: function(d) { return "I don’t know (or other)"; },
            description: function(d) { return d.heatingValue + " g CO2e per minute of shower"; }
          },
          calculus: {
            formula: function(d) { return d.showersValue + " $1 " + d.durationValue + " min $2 " + d.heatingValue + " g CO2e $3 52 weeks in a year $4"; },
            value: function(d) { return co2kg(d.initialCo2Kg, "en"); },
            valueUnit: function(d) { return "kg"; },
            description: function(d) { return "CO2e a year"; }
          },
          action: {
            shampoo: {
              label: function(d) { return "Turn the shower off while applying shampoo and soap"; },
              description: function(d) { return ""; }
            },
            daydream: {
              label: function(d) { return "Don’t daydream in the shower"; },
              description: function(d) { return "Daydreaming is good, just not in the shower"; }
            },
            personal: {
              label: function(d) { return "Your own personal action"; },
              description: function(d) { return "No need to to tell us what it is ☺️"; }
            }
          }
        },
        daily_commute: {
          days: {
            label: function(d) { return "Days of commuting"; },
            value: function(d) { return numberInteger(d.daysValue, "en"); }
          },
          emissions: function(d) { return plural(d.value, 0, en, { "0": "Zero emissions", other: number("en", d.value, 0) + " g CO2e per km" }); },
          weekly_distance: function(d) { return numberInteger(d.value, "en") + " km"; },
          calculus: {
            formula: {
              skeleton: function(d) { return "$1 " + d.types + " $2 $3 48 work weeks in a year $4"; },
              typePart: function(d) { return numberInteger(d.distance, "en") + " km $5 " + numberInteger(d.emissions, "en") + " g CO2e"; }
            },
            value: function(d) { return co2kg(d.initialCo2Kg, "en"); },
            valueUnit: function(d) { return "kg"; },
            description: function(d) { return "CO2e a year"; }
          }
        }
      },
      intro: {
        hot_water: {
          step_1: {
            title: function(d) { return "Feels kind of good!"; },
            description: function(d) { return "Know that feeling of warm water running down on your skin in the morning, straight out of bed and still all sleepy? Or in the evening, washing the day’s worries off of your skin?\nHot long showers sure can feel nice in the moment!"; }
          },
          step_2: {
            title: function(d) { return "It’s a trap!"; },
            description: function(d) { return "Like many things in modern life, turning the hot water on is made so easy that it’s easy not to think about everything that happens behind the scenes.\nWell, a lot happens!"; }
          },
          step_3: {
            title: function(d) { return "The way we produce our energy isn’t yet all clean"; },
            description: function(d) { return "Though the humanity is trying to find ways to move away from high-carbon energy sources, heating water in your home or centrally at a heating facility can still result in plenty of carbon emissions."; }
          },
          step_4: {
            title: function(d) { return "We ran the numbers"; },
            description: function(d) { return "An average Finnish citizen consumes roughly 155 litres of water each day and about 40 % of that in the shower.\nThe water that runs in the shower is typically warm: Tideal’s Department of Hard Science has established a nice showering temperature to be 38-40 °C 🤓"; }
          },
          step_5: {
            title: function(d) { return "Make your shower a wash-up, not a wash-out"; },
            description: function(d) { return "So, reducing hot water use can matter! Cutting down from an 8-minute daily shower to a 4-minute shower five days a week can lower the yearly carbon footprint from 300 kg to 100 kg of CO2e – a reduction equalling 1.8 % of an average Finnish citizen’s entire carbon footprint.\nAnd saving the climate means savings in your pocket too!"; }
          },
          step_6: {
            title: function(d) { return "May we lend you a little helping hand?"; },
            description: function(d) { return "Take part in the showering challenge and watch your footprint shrink with a few simple changes of habit!\nThe change period of a Tideal group takes " + d.durationWeeks + " weeks. During that time the group is there to cheer for you and carry you through hardship."; }
          },
          step_7: {
            title: function(d) { return "Let’s make this happen!"; },
            description: function(d) { return "So, join a hatching group of the showering challenge right now! Or even better, set up your own group, become a captain in it, and invite friends and family into your group!\nGo, and achieve great things together!"; },
            actions: {
              confirm: function(d) { return "Yes!"; }
            }
          }
        },
        daily_commute: {
          step_1: {
            title: function(d) { return "The daily commuting grind"; },
            description: function(d) { return "Commuting isn’t necessarily one of those choices in life where we can decide whether to do it or not: most of us need to get our places of work or study, day in day out.\nThe longer and more stressful the commute is, the more tempting it is to take the easy way out and make it as pleasant as possible."; }
          },
          step_2: {
            title: function(d) { return "Mind the emissions!"; },
            description: function(d) { return "Though many of us can’t avoid the act of commuting entirely, we often still have some room for choice.\nWith some choices, commuting can result in a surprisingly lot of carbon being released into the atmosphere! It all depends on the distance of your commute and your choice on the means of transport for it."; }
          },
          step_3: {
            title: function(d) { return "We ditched muscle power and opted for fossil fuels"; },
            description: function(d) { return "For much of human history, the most popular means of transport was, quite simply, to walk.\nThen, we invented the internal combustion engine and put it in cars, boats, and planes, and started moving around on those. Now, electric motors are gaining ground, but their share on the road is still marginal."; }
          },
          step_4: {
            title: function(d) { return "Here’s some math"; },
            description: function(d) { return "Let’s say an example person, Jane Doe, commutes 48 weeks a year, five days a week. The distance from Jane’s home to her office is 10 kilometres and she makes the trip with her gasoline Nissan Qashqai.\nOver the course of a year, Jane’s commutes add up to 4 800 kilometres! With her Qashqai, those kilometres result in 1 100 kg of CO2e released into the atmosphere. That is about 10.7 % of an average Finnish citizen’s yearly carbon footprint!"; }
          },
          step_5: {
            title: function(d) { return "We can all do something"; },
            description: function(d) { return "What you could do to cut down on the carbon footprint of your commutes depends on your specific situation. We don’t want to preach.\nIf your commute is long and public transport connections cumbersome, maybe on just one day a week you can fight it through the public transport? For shorter distances, bicycle is a climate-friendly and physique-building alternative."; }
          },
          step_6: {
            title: function(d) { return "We’re here to help you"; },
            description: function(d) { return "Take part in the commuting challenge and see how big of a change you can make!\nThe change period of a Tideal group takes " + d.durationWeeks + " weeks. During that time the group is there to cheer for you and carry you through hardship."; }
          },
          step_7: {
            title: function(d) { return "Let’s make this happen!"; },
            description: function(d) { return "So, join a hatching group of the commuting challenge right now! Or even better, set up your own group, become a captain in it, and invite friends and family into your group!\nGo, and achieve great things together!"; },
            actions: {
              confirm: function(d) { return "Yes!"; }
            }
          }
        }
      },
      join: {
        common: {
          access_code: {
            title: function(d) { return "To join this group, you need to enter its access code"; },
            description: function(d) { return "This group is by invitation only. To prove that you have an invitation, we need to ask you to enter the group’s access code."; },
            actions: {
              confirm: function(d) { return "Code entered"; }
            }
          }
        },
        hot_water: {
          error: {
            lateUpdate: {
              title: function(d) { return "This cannot be done anymore"; },
              description: function(d) { return "You tried accessing the joining procedure for the group " + d.groupName + " of the showering challenge. However, you are already in this group and your baseline and goal for it cannot be edited anymore."; },
              ok: function(d) { return "Okay, cool!"; }
            },
            lateJoin: {
              title: function(d) { return "It’s too late to join this group"; },
              description: function(d) { return "You tried accessing the joining procedure for the group " + d.groupName + " of the showering challenge. We are sorry, but the group’s change period has already started so the group can’t allow new members to join anymore."; },
              ok: function(d) { return "Okay, cool!"; }
            }
          },
          dialog: {
            exitJoin: {
              title: function(d) { return "Exit from joining the group?"; },
              description: function(d) { return "If you exit now, the carbon footprint of your current showering will not be saved."; },
              confirm: function(d) { return "Yes, exit"; },
              cancel: function(d) { return "No, stay here"; }
            },
            exitUpdate: {
              title: function(d) { return "Exit from editing your baseline and goal?"; },
              description: function(d) { return "If you exit now, the changes you made here will not be saved."; },
              confirm: function(d) { return "Yes, exit"; },
              cancel: function(d) { return "No, stay here"; }
            }
          },
          step_1_intro: {
            join: {
              title: function(d) { return "Great! Join this group and make a change!"; },
              description: function(d) { return "But first things first: let’s first calculate the carbon footprint of your current showering and then set your goal for reducing it."; },
              confirm: function(d) { return "Ok, let’s do this!"; }
            },
            update: {
              title: function(d) { return "Edit your baseline and goal"; },
              description: function(d) { return "You are about to edit your baseline and goal for the group of the showering challenge. You can do this until day number 3 of the group’s change period."; },
              confirm: function(d) { return "Ok, let’s do this!"; }
            }
          },
          step_2_instructions: {
            title: function(d) { return "Heads up!"; },
            description: function(d) { return "In this challenge, when we ask you about the length of your showers, please give us the time that the water actually runs. So, don’t include the time that you spend shampooing your hair with the water turned off, for instance.\nWe know, it can be hard to estimate this, but your best estimate is better than no information at all."; },
            confirm: function(d) { return "Understood"; },
            cancel: function(d) { return "Wait, go back"; }
          },
          step_3_set_shower_amount: {
            title: function(d) { return "On average, how many times a week do you shower?"; }
          },
          step_4_set_shower_duration: {
            title: function(d) { return "What’s your style of showering like?"; },
            description: function(d) { return "For how long does the water run when you take a shower?"; },
            option: {
              "1": {
                title: function(d) { return "Military"; },
                description: function(d) { return "1 minute"; }
              },
              "2": {
                title: function(d) { return "Efficient"; },
                description: function(d) { return "4 minutes"; }
              },
              "3": {
                title: function(d) { return "Relaxed"; },
                description: function(d) { return "8 minutes"; }
              },
              "4": {
                title: function(d) { return "Lengthy"; },
                description: function(d) { return "12 minutes"; }
              },
              "5": {
                title: function(d) { return "Monsoon"; },
                description: function(d) { return "20 minutes"; }
              },
              "6": {
                title: function(d) { return "Set manually"; },
                description: function(d) { return "Set the time manually"; }
              }
            }
          },
          step_4_set_shower_duration_detail: {
            title: function(d) { return "On average, for how long does the water run when you take a shower?"; },
            confirm: function(d) { return "Set shower length"; }
          },
          step_5_set_heating_value: {
            title: function(d) { return "Which energy source is your shower water heated with?"; },
            description: function(d) { return "The CO2e numbers here are based on weighted averages from Finland, the data locale for this group. From somewhere else? Worry not: in this challenge, these numbers are safe for everyone."; },
            option: {
              wood: {
                title: function(d) { return "Wood or wood pellets"; }
              },
              geothermal: {
                title: function(d) { return "Geothermal with mixed sources electricity"; }
              },
              geothermal_zero: {
                title: function(d) { return "Geothermal with zero emissions electricity"; }
              },
              naturalgas: {
                title: function(d) { return "Natural gas"; }
              },
              oil: {
                title: function(d) { return "Oil"; }
              },
              districtheating: {
                title: function(d) { return "District heating"; }
              },
              electricity: {
                title: function(d) { return "Electricity, mixed sources"; }
              },
              electricity_zero: {
                title: function(d) { return "Electricity, zero emissions sources"; }
              },
              unknown: {
                title: function(d) { return "I don’t know (or other)"; },
                description: function(d) { return d.value + " g CO2e per minute of shower (weighted average for Finland)"; }
              },
              description: function(d) { return plural(d.value, 0, en, { "0": "Zero emissions", other: number("en", d.value, 0) + " g CO2e per minute of shower" }); }
            }
          },
          step_6_co2_footprint_summary: {
            title: function(d) { return "The yearly carbon footprint of your showering is about"; },
            value: function(d) { return co2kg(d.calculatedCo2KgPerYear, "en"); },
            calculation: {
              title: function(d) { return "How was it calculated?"; },
              intro: function(d) { return "You shower:"; },
              durationDescription: function(d) { return plural(d.showerAmount, 0, en, { "1": "1 time", other: number("en", d.showerAmount, 0) + " times" }) + " a week, " + plural(d.showerDuration, 0, en, { "1": "1 minute", other: number("en", d.showerDuration, 0) + " minutes" }) + " per shower"; },
              heatingDescription: function(d) { return "the shower water is heated with: " + d.heatingValueSelectTitle; },
              equation: function(d) { return "So, you shower " + plural(d.showerMinutesWeekly, 0, en, { "1": "1 minute", other: number("en", d.showerMinutesWeekly, 0) + " minutes" }) + " a week, which adds up to " + plural(d.showerHoursYearly, 0, en, { "1": "1 hour", other: number("en", d.showerHoursYearly, 0) + " hours" }) + " of showering a year. When the shower is heated with " + d.heatingValueSelectTitle + ", one minute of showering produces an average of " + d.heatingValue + " g of CO2e emissions."; },
              summary: function(d) { return "This puts the yearly carbon footprint of your showering to about " + co2kg(d.calculatedCo2KgPerYear, "en") + " kg of CO2e."; }
            },
            action: {
              set_manually: function(d) { return "Want to set you current footprint manually?"; },
              forward: function(d) { return "I want to reduce this!"; },
              back: function(d) { return "Go back"; }
            },
            manual: {
              title: function(d) { return "Okay, sometimes we get it wrong. Adjust the footprint of your showering."; },
              reference: function(d) { return "For reference, the original estimate of " + co2kg(d.calculatedCo2KgPerYear, "en") + " kg CO2e was based on " + plural(d.showerMinutesWeekly, 0, en, { "1": "1 minute", other: number("en", d.showerMinutesWeekly, 0) + " minutes" }) + " of showering a week. When the water is heated using " + d.heatingValueSelectTitle + ", one minute of showering produces an average of " + d.heatingValue + " g of CO2e emissions."; },
              action: {
                confirm: function(d) { return "Set my current footprint"; },
                cancel: function(d) { return "Cancel"; }
              }
            }
          },
          step_7_set_target: {
            title: function(d) { return "Set a goal for yourself! How much smaller do you aim to make the footprint?"; },
            description: function(d) { return "The current footprint was " + co2kg(d.calculatedCo2KgPerYear, "en") + " kg of CO2e a year."; }
          },
          step_8_co2_target_summary: {
            title: function(d) { return "If you achieve this goal, the carbon footprint of your showering could be reduced"; },
            card: {
              from: {
                title: function(d) { return "From"; },
                value: function(d) { return co2kg(d.calculatedCo2KgPerYear, "en"); }
              },
              to: {
                title: function(d) { return "To"; },
                value: function(d) { return co2kg(d.calculatedTargetCo2KgPerYear, "en"); }
              },
              reductionPrc: {
                valueName: function(d) { return d.reductionPrc + "%"; },
                description: function(d) { return "reduction to the footprint of your showering 👏"; }
              },
              reductionKg: {
                valueName: function(d) { return co2kg(d.reductionKgInChallenge, "en") + " kg"; },
                description: function(d) { return "is what you aim to reduce during the change period of " + d.durationWeeks + " weeks"; }
              }
            },
            data: {
              methods: {
                title: function(d) { return "Calculation based on:"; },
                amount: {
                  title: function(d) { return "Shower less often"; },
                  description: function(d) { return "Currently " + d.showerAmount + " times a week, reduce to " + d.showerAmountTarget; }
                },
                duration: {
                  title: function(d) { return "Take shorter showers"; },
                  description: function(d) { return "Currently " + d.showerDuration + " minutes, reduce to " + d.showerDurationTarget; }
                }
              },
              actions: {
                title: function(d) { return "Your daily actions are:"; },
                shampoo: {
                  title: function(d) { return "Turn the shower off while applying shampoo and soap"; },
                  description: function(d) { return ""; }
                },
                daydream: {
                  title: function(d) { return "Don’t daydream in the shower"; },
                  description: function(d) { return "Daydreaming is good, just not in shower"; }
                },
                personal: {
                  title: function(d) { return "Your personal action"; },
                  description: function(d) { return "No need to to tell us what it is ☺️"; }
                }
              }
            },
            actions: {
              set_manually: function(d) { return "Want to set your goal manually?"; }
            },
            manual: {
              title: function(d) { return "Adjust your goal for reducing the carbon footprint of your showering."; },
              reference: function(d) { return "For reference, the original estimate of your current carbon footprint of " + co2kg(d.calculatedTargetCo2KgPerYearCopy, "en") + " kg CO2e was based on " + plural(d.showerMinutesWeekly, 0, en, { "1": "1 minute", other: number("en", d.showerMinutesWeekly, 0) + " minutes" }) + " minutes of showering a week. When the water is heated using " + d.heatingValueSelectTitle + ", one minute of showering produces an average of " + d.heatingValue + " g of CO2e emissions."; },
              action: {
                confirm: function(d) { return "Set my goal"; },
                cancel: function(d) { return "Cancel"; }
              }
            }
          },
          step_9_tips: {
            title: function(d) { return "How to succeed?"; },
            description: function(d) { return "The ways of cutting down on carbon footprint of your showering are straightforward: you can either shower less often or reduce the time that the water runs when you do take a shower. Maybe you can even do both!\nOf course, shower water temperature also plays a big role in this, but to keep things simple in this challenge, we will treat the temperature as a constant."; }
          },
          step_10_reduction_actions: {
            title: function(d) { return "Want to pick some concrete actions into your agenda?"; },
            description: function(d) { return "These might help you make your showers shorter or more seldom. If you choose any of these, we’ll later track your success in these, too."; },
            options: {
              shampoo: {
                title: function(d) { return "Turn the shower off while applying shampoo and soap"; },
                description: function(d) { return ""; }
              },
              daydream: {
                title: function(d) { return "Don’t daydream in the shower"; },
                description: function(d) { return "Daydreaming is good, just not in shower"; }
              },
              personal: {
                title: function(d) { return "Your personal action"; },
                description: function(d) { return "No need to to tell us what it is ☺️"; }
              }
            }
          },
          step_11_submit: {
            join: {
              title: function(d) { return "All set, tiger!"; },
              description: function(d) { return "You are now ready to join the group! The group will start its change period in " + d.daysToStart + " days time, on " + date_en_EEEEdMMMM_mdgz41(d.startDate) + ". The change period lasts " + d.durationWeeks + " weeks and the aim is to achieve a permanent change in your showering 💪\nAre you ready to commit to this?"; },
              confirm: function(d) { return "Join the group"; },
              cancel: function(d) { return "Go back"; }
            },
            update: {
              title: function(d) { return "Just about done"; },
              description: function(d) { return "You have now jumped through all the hoops necessary to change your baseline and goal for this group. We thank you for being pedantic on this."; },
              confirm: function(d) { return "Save my answers"; },
              cancel: function(d) { return "Go back"; }
            }
          }
        },
        daily_commute: {
          error: {
            lateUpdate: {
              title: function(d) { return "This cannot be done anymore"; },
              description: function(d) { return "You tried accessing the joining procedure for the group " + d.groupName + " of the commuting challenge. However, you are already in this group and your baseline and goal for it cannot be edited anymore."; },
              ok: function(d) { return "Okay, cool!"; }
            },
            lateJoin: {
              title: function(d) { return "It’s too late to join this group"; },
              description: function(d) { return "You tried accessing the joining procedure for the group " + d.groupName + " of the commuting challenge. We are sorry, but the group’s change period has already started so the group can’t allow new members to join anymore."; },
              ok: function(d) { return "Okay, cool!"; }
            }
          },
          dialog: {
            exitJoin: {
              title: function(d) { return "Exit from joining the group?"; },
              description: function(d) { return "If you exit now, the carbon footprint of your current commuting will not be saved."; },
              confirm: function(d) { return "Yes, exit"; },
              cancel: function(d) { return "No, stay here"; }
            },
            exitUpdate: {
              title: function(d) { return "Exit from editing your baseline and goal?"; },
              description: function(d) { return "If you exit now, the changes you made here will not be saved."; },
              confirm: function(d) { return "Yes, exit"; },
              cancel: function(d) { return "No, stay here"; }
            }
          },
          step_1_intro: {
            title: function(d) { return "Great! Join this group and change your commuting for good!"; },
            description: function(d) { return "Let’s first calculate the carbon footprint of your current commuting and then set your goal for reducing it."; },
            forward: function(d) { return "Ok, let’s do this!"; }
          },
          step_2_instructions: {
            title: function(d) { return "Heads up"; },
            description: function(d) { return "In this challenge, commuting refers to regular trips that you make between your home and place of work or study.\nIt does not include business travel or study-related trips that are out of your ordinary schedule."; },
            forward: function(d) { return "Understood"; },
            back: function(d) { return "Wait, go back"; }
          },
          step_3_set_commute_day_amount: {
            title: function(d) { return "On average, on how many days a week do you commute?"; }
          },
          step_4_set_commute_types: {
            title: function(d) { return "During a typical week, what do you use for your commutes?"; },
            option: {
              car: {
                title: function(d) { return plural(d.commuteTypesAllCarsLength, 0, en, { "0": "Add a car", other: "Edit cars" }); },
                description: function(d) { return plural(d.commuteTypesAllCarsLength, 0, en, { "0": "Select a type of car", "1": "Selected now: " + d.commuteTypesCarTitle, "2": "Selected now: two cars", "3": "Selected now: three cars", other: "Selected now: " + number("en", d.commuteTypesAllCarsLength, 0) + " cars" }); }
              }
            },
            type: {
              muscle_power: function(d) { return "Muscle power"; },
              public_transport: function(d) { return "Public or shared"; },
              two_wheels: function(d) { return "Two-wheelers and microcars"; },
              car: function(d) { return "Car"; }
            },
            set_car: {
              title: function(d) { return "What kind of a car do you drive?"; },
              description: function(d) { return "For the CO2e data here, the official emissions numbers have been multiplied with 1.25 to account for actual driving behaviour and 1.2 for the fuel production emissions."; },
              type: {
                electric: function(d) { return "Fully electric"; },
                hybrid: function(d) { return "Hybrid"; },
                diesel: function(d) { return "Diesel"; },
                gasoline: function(d) { return "Gasoline"; },
                custom: function(d) { return "Set manually"; }
              },
              option: {
                custom_car: {
                  title: function(d) { return select(d.commuteTypesCustom, { optCustom: "Edit your custom emissions car", other: "Select a car with custom emissions" }); },
                  description: function(d) { return select(d.commuteTypesCustom, { optCustom: "Now: official " + d.commuteCustomCarEmissionsCo2GSelect + " g, actual " + d.commuteCustomCarEmissionsCo2G + " g CO2e per km", other: "" }); }
                },
                car_custom: {
                  title: function(d) { return select(d.commuteTypesCustom, { optCustom: "Edit your custom emissions car", other: "Select a car with custom emissions" }); },
                  description: function(d) { return select(d.commuteTypesCustom, { optCustom: "Now: official " + d.commuteCustomCarEmissionsCo2GSelect + " g, actual " + d.commuteCustomCarEmissionsCo2G + " g CO2e per km", other: "" }); }
                }
              },
              action: {
                confirm: function(d) { return "Select these cars"; }
              }
            },
            set_custom_car: {
              title: function(d) { return "What are your custom emission car’s official emissions per one kilometre?"; },
              description: function(d) { return "Suggestion: check it from the registration document."; },
              reference: function(d) { return "To account for actual average driving behaviour and the emissions that result from producing the fuel, we will multiply this number with 1.25 for the driving behaviour and 1.2 for the fuel production."; },
              action: {
                cancel: function(d) { return plural(d.commuteCustomCarEmissionsCo2G, 0, en, { "0": "Cancel", other: "Delete car" }); },
                confirm: function(d) { return "Set emissions"; }
              }
            }
          },
          step_5_set_commute_distances: {
            walk: {
              title: function(d) { return "In a typical week, how many kilometres do you commute by walking?"; },
              description: function(d) { return "Walking"; }
            },
            bicycle: {
              title: function(d) { return "In a typical week, how many kilometres do you commute by bicycle?"; },
              description: function(d) { return "Cycling"; }
            },
            tram: {
              title: function(d) { return "In a typical week, how many kilometres do you commute by tram?"; },
              description: function(d) { return "By Tram"; }
            },
            metro: {
              title: function(d) { return "In a typical week, how many kilometres do you commute by metro?"; },
              description: function(d) { return "By Metro"; }
            },
            train: {
              title: function(d) { return "In a typical week, how many kilometres do you commute by train?"; },
              description: function(d) { return "By Train"; }
            },
            escooter: {
              title: function(d) { return "In a typical week, how many kilometres do you commute by e-scooter?"; },
              description: function(d) { return "By E-scooter (shared)"; }
            },
            ld_bus: {
              title: function(d) { return "In a typical week, how many kilometres do you commute by long-distance bus?"; },
              description: function(d) { return "By long-distance bus"; }
            },
            sd_bus: {
              title: function(d) { return "In a typical week, how many kilometres do you commute by short-distance bus?"; },
              description: function(d) { return "By short-distance bus"; }
            },
            scooter: {
              title: function(d) { return "In a typical week, how many kilometres do you commute by moped or scooter?"; },
              description: function(d) { return "By moped or scooter"; }
            },
            motorcycle: {
              title: function(d) { return "In a typical week, how many kilometres do you commute by motorcycle?"; },
              description: function(d) { return "By motorcycle"; }
            },
            microcar: {
              title: function(d) { return "In a typical week, how many kilometres do you commute by microcar?"; },
              description: function(d) { return "By microcar"; }
            },
            car_electric: {
              title: function(d) { return "In a typical week, how many kilometres do you commute by car?"; },
              description: function(d) { return "Electric, charged with mixed sources electricity"; }
            },
            car_electric_zero: {
              title: function(d) { return "In a typical week, how many kilometres do you commute by car?"; },
              description: function(d) { return "Electric, charged with zero emissions electricity"; }
            },
            car_hybrid: {
              title: function(d) { return "In a typical week, how many kilometres do you commute by car?"; },
              description: function(d) { return "Gasoline-electric hybrid, charged with mixed sources electricity"; }
            },
            car_hybrid_zero: {
              title: function(d) { return "In a typical week, how many kilometres do you commute by car?"; },
              description: function(d) { return "Gasoline-electric hybrid, charged with zero emissions electricity"; }
            },
            car_s_diesel: {
              title: function(d) { return "In a typical week, how many kilometres do you commute by car?"; },
              description: function(d) { return "Small diesel car"; }
            },
            car_m_diesel: {
              title: function(d) { return "In a typical week, how many kilometres do you commute by car?"; },
              description: function(d) { return "Medium-sized diesel car"; }
            },
            car_l_diesel: {
              title: function(d) { return "In a typical week, how many kilometres do you commute by car?"; },
              description: function(d) { return "Large diesel car"; }
            },
            car_s_gasoline: {
              title: function(d) { return "In a typical week, how many kilometres do you commute by car?"; },
              description: function(d) { return "Small gasoline car"; }
            },
            car_m_gasoline: {
              title: function(d) { return "In a typical week, how many kilometres do you commute by car?"; },
              description: function(d) { return "Medium-sized gasoline car"; }
            },
            car_l_gasoline: {
              title: function(d) { return "In a typical week, how many kilometres do you commute by car?"; },
              description: function(d) { return "Large gasoline car"; }
            },
            car_custom: {
              title: function(d) { return "In a typical week, how many kilometres do you commute by car?"; },
              description: function(d) { return "Custom emissions car"; }
            }
          },
          step_6_co2_footprint_summary: {
            title: function(d) { return "The yearly carbon footprint of your commuting is about"; },
            value: function(d) { return co2kg(d.calculatedCo2KgPerYear, "en"); },
            heading_how: function(d) { return "How it was calculated?"; },
            p_in_week: function(d) { return "In a week you commute:"; },
            calculation: {
              walk: function(d) { return d.commuteWalkKm + "km by walking"; },
              bicycle: function(d) { return d.commuteBicycleKm + "km by bicycle"; },
              tram: function(d) { return d.commuteTramKm + "km by tram"; },
              metro: function(d) { return d.commuteMetroKm + "km by metro"; },
              train: function(d) { return d.commuteTrainKm + "km by train"; },
              escooter: function(d) { return d.commuteEScooterKm + "km by e-scooter"; },
              ld_bus: function(d) { return d.commuteLDBusKm + "km by long-distance bus"; },
              sd_bus: function(d) { return d.commuteSDBusKm + "km by short-distance bus"; },
              scooter: function(d) { return d.commuteScooterKm + "km by scooter or moped"; },
              motorcycle: function(d) { return d.commuteMotorcycleKm + "km by motorcycle"; },
              microcar: function(d) { return d.commuteMicrocarKm + "km by microcar"; },
              car_electric: function(d) { return d.commuteElectricCarKm + "km by electric car"; },
              car_electric_zero: function(d) { return d.commuteElectricCarZeroKm + "km by electric car"; },
              car_hybrid: function(d) { return d.commuteHybridCarKm + "km by hybrid car"; },
              car_hybrid_zero: function(d) { return d.commuteHybridCarZeroKm + "km by hybrid car"; },
              car_s_diesel: function(d) { return d.commuteSDieselCarKm + "km by small diesel car"; },
              car_m_diesel: function(d) { return d.commuteMDieselCarKm + "km by medium-sized diesel car"; },
              car_l_diesel: function(d) { return d.commuteLDieselCarKm + "km by large diesel car"; },
              car_s_gasoline: function(d) { return d.commuteSGasolineCarKm + "km by small gasoline car"; },
              car_m_gasoline: function(d) { return d.commuteMGasolineCarKm + "km by medium-sized gasoline car"; },
              car_l_gasoline: function(d) { return d.commuteLGasolineCarKm + "km by large gasoline car"; },
              car_custom: function(d) { return d.commuteCustomCarKm + "km by your custom emissions car"; },
              equation: function(d) { return "In 48 work weeks a year, this amounts to " + split(d.commuteAllYearlyTexts, "en") + "."; },
              equation_result: function(d) { return "This puts the yearly carbon footprint of your commuting to about " + co2kg(d.calculatedCo2KgPerYear, "en") + " kg of CO2e."; }
            },
            action: {
              set_manually: function(d) { return "Want to set you current footprint manually?"; },
              back: function(d) { return "Go back"; },
              forward: function(d) { return "I want to reduce this!"; }
            },
            set_manually: {
              title: function(d) { return "Okay, sometimes we get it wrong. Adjust the footprint of your commuting."; },
              reference: function(d) { return "For reference, the original estimate of " + co2kg(d.calculatedCo2KgPerYear, "en") + " kg CO2e was based on weekly commuting of " + split(d.commuteAllWeeklyTexts, "en") + "."; },
              confirm: function(d) { return "Set my footprint"; }
            }
          },
          step_7_set_target: {
            title: function(d) { return "Set a goal for yourself! How much smaller do you aim to make the footprint?"; },
            description: function(d) { return "The current footprint was " + co2kg(d.calculatedCo2KgPerYear, "en") + " kg of CO2e a year."; }
          },
          step_8_summary: {
            title: function(d) { return "If you achieve this goal, the carbon footprint of your commuting could be reduced"; },
            card: {
              from: {
                title: function(d) { return "From"; },
                value: function(d) { return co2kg(d.calculatedCo2KgPerYear, "en"); }
              },
              to: {
                title: function(d) { return "To"; },
                value: function(d) { return co2kg(d.calculatedTargetCo2KgPerYear, "en"); }
              },
              reductionPrc: {
                valueName: function(d) { return d.reductionPrc + "%"; },
                description: function(d) { return "reduction to the footprint of your commuting 👏"; }
              },
              reductionKg: {
                valueName: function(d) { return co2kg(d.reductionKgInChallenge, "en") + " kg"; },
                description: function(d) { return "is what you aim to reduce during the change period of " + d.durationWeeks + " weeks"; }
              }
            },
            action: {
              set_manually: function(d) { return "Do you want to set your goal by the gram?"; }
            },
            set_manually: {
              title: function(d) { return "Adjust your goal for reducing the carbon footprint of your commuting."; },
              reference: function(d) { return "For reference, the original estimate of " + co2kg(d.calculatedCo2KgPerYear, "en") + " kg CO2e was based on weekly commuting of " + split(d.commuteAllWeeklyTexts, "en") + "."; },
              action: {
                confirm: function(d) { return "Set goal"; }
              }
            }
          },
          step_9_tips: {
            title: function(d) { return "How to succeed?"; },
            description: function(d) { return "The specifics of how you can cut down on the carbon footprint of your commuting depend on your specific situation.\nTry, for example, working or studying from home more often, cycling or walking, or using zero-emissions public transport like the train or metro to get to work or school. Be creative, everything counts!"; }
          },
          step_10_submit: {
            join: {
              title: function(d) { return "Let’s go then!"; },
              description: function(d) { return "You are now ready to join the group! The group will start its change period in " + d.daysToStart + " days time, on " + date_en_EEEEdMMMM_mdgz41(d.startDate) + ". The change period lasts " + d.durationWeeks + " weeks and the aim is to achieve a permanent change in your commuting 💪\nAre you ready to commit to this?"; },
              confirm: function(d) { return "Join the group!"; },
              cancel: function(d) { return "Go back"; }
            },
            update: {
              title: function(d) { return "Just about done"; },
              description: function(d) { return "You have now jumped through all the hoops necessary to change your baseline and goal for this group. We thank you for being pedantic on this."; },
              confirm: function(d) { return "Save my answers"; },
              cancel: function(d) { return "Go back"; }
            }
          }
        }
      },
      create: {
        common: {
          exit: {
            title: function(d) { return "Exit from creating the group?"; },
            description: function(d) { return "If you exit now, your group will not be created and the carbon footprint of your current " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " will not be saved."; },
            confirm: function(d) { return "Yes, exit"; },
            cancel: function(d) { return "No, stay here"; }
          },
          heading: function(d) { return "Create new group"; },
          step_1_intro: {
            title: function(d) { return "Ahoy, captain!"; },
            description: function(d) { return "Thank you for taking a step forward! As a captain of a new " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " group, you can invite friends and family into your group and effect wider change through the people around you!\nBut before we get to that, let’s put some settings in place for your group."; },
            forward: function(d) { return "Okay, onwards!"; }
          },
          step_2_set_name: {
            title: function(d) { return "What should be the group’s name?"; },
            description: function(d) { return "You can give your group a name, which we will then show in relevant places around Tideal. If you don’t, the group will simply show as group " + d.captainName + "."; },
            input: {
              name: {
                title: function(d) { return "Group name"; },
                placeholder: function(d) { return "Group " + d.captainName; }
              }
            }
          },
          step_3_set_start_date: {
            title: function(d) { return "When should the change period in your group start?"; },
            description: function(d) { return "People can join your group until the change period starts. It might make sense to give them at least a couple of days to join. The change period itself will take " + d.durationWeeks + " weeks."; },
            input: {
              startDate: {
                month: function(d) { return date_en_MMMM_fbe6bf(d.date); },
                weekday: function(d) { return date_en_EEEE_fbjgcb(d.date); },
                day: function(d) { return date_en_d_44fdtt(d.date); }
              }
            }
          },
          step_4_set_access: {
            title: function(d) { return "How should the access for the group be set up"; },
            description: function(d) { return "As the group’s captain, you decide if it’s open to anyone or by invitation only."; },
            input: {
              access: {
                option: {
                  open: {
                    title: function(d) { return "Anyone can join"; },
                    description: function(d) { return "Anyone can join the group, but only group members can see what’s happening in it."; }
                  },
                  invite: {
                    title: function(d) { return "By invitation only"; },
                    description: function(d) { return "Joined by invitation only. Only group members can see what’s happening in it."; }
                  }
                }
              }
            }
          },
          step_5_set_language: {
            title: function(d) { return "What is the discussion language?"; },
            description: function(d) { return "A Tideal group has some discussion features, such as cheering for fellow group members’ progress. What is the preferred discussion language in this group?"; },
            input: {
              language: {
                option: {
                  en: function(d) { return "English"; },
                  fi: function(d) { return "Finnish"; },
                  sv: function(d) { return "Swedish"; }
                }
              }
            }
          },
          step_6_set_data_locale: {
            title: function(d) { return "What is the data locale of the group?"; },
            description: function(d) { return "Our CO2e data is based partly on weighted averages in specific locales. For now, we support Finland as our only data locale, but worry not: in this challenge, this is a safe choice for everyone."; },
            input: {
              dataLocale: {
                option: {
                  fi: function(d) { return "Finland"; }
                }
              }
            }
          },
          step_7_outro: {
            title: function(d) { return "Group settings ok!"; },
            description: function(d) { return "...but we’re not all done yet. The captain of a group leads from the front and, besides cheering for the group, is always making a personal change, too.\nSo next up, let’s calculate the carbon footprint of your current " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " and then set your goal for reducing it. Be an inspiration to your group!"; },
            forward: function(d) { return "Let’s do this!"; }
          }
        },
        hot_water: {
          submit: {
            title: function(d) { return "All set, captain!"; },
            description: function(d) { return "Captain " + d.givenName + ", we salute you. You’ve now put the group settings in place and set your personal reduction goal. This means we’re about done here!\nNext, how about inviting some friends and family into your group? You can do that through the invite button on the group page."; },
            actions: {
              cancel: function(d) { return "Go back"; },
              confirm: function(d) { return "Create my group"; }
            }
          }
        },
        daily_commute: {
          submit: {
            title: function(d) { return "Let’s go then!"; },
            description: function(d) { return "Captain " + d.givenName + ", we salute you. You’ve now put the group settings in place and set your personal reduction goal. This means we’re about done here!\nNext, how about inviting some friends and family into your group? You can do that through the invite button on the group page."; },
            actions: {
              cancel: function(d) { return "Go back"; },
              confirm: function(d) { return "Create my group"; }
            }
          }
        }
      },
      answer: {
        common: {
          heading: function(d) { return "Change day " + d.day + " - " + date_en_MMMd_fbe6as(d.changeDay); },
          step_1_set_day: {
            option: {
              value: function(d) { return d.value; },
              date: function(d) { return date_en_MMMdd_mekymg(d.date); },
              day: {
                title: function(d) { return "Select a day"; },
                subtitle: function(d) { return select(d.day, { "0": "", other: "Answer the daily questions for change day " + d.day + ", " + date_en_MMMd_fbe6as(d.changeDay) + "." }); },
                forward: function(d) { return "Onwards!"; }
              }
            },
            error: {
              allAnswered: {
                title: function(d) { return "You are all up to date!"; },
                description: function(d) { return "You tried accessing the daily questions for " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " group you’re in, but at the moment you have filled in the records for all the days available for filling in. Well done!"; },
                ok: function(d) { return "Okay, cool!"; }
              }
            },
            title: function(d) { return "Hello " + d.givenName + ", ready to answer your daily questions?"; }
          }
        },
        hot_water: {
          heading: function(d) { return "Change day " + d.day + " - " + date_en_MMMd_fbe6as(d.changeDay); },
          step_2_set_shower_amount: {
            title: function(d) { return "How many times did you shower on this day?"; }
          },
          step_3_set_shower_duration: {
            title: function(d) { return "How was your style of showering?"; },
            description: function(d) { return "In total, for how long did the water run when you were showering?"; },
            option: {
              "1": {
                title: function(d) { return "Military"; },
                description: function(d) { return plural(d.showerTimes, 0, en, { one: "1 minute", other: "1 minute x " + number("en", d.showerTimes, 0) }); }
              },
              "2": {
                title: function(d) { return "Efficient"; },
                description: function(d) { return plural(d.showerTimes, 0, en, { one: "4 minute", other: "4 minute x " + number("en", d.showerTimes, 0) }); }
              },
              "3": {
                title: function(d) { return "Relaxed"; },
                description: function(d) { return plural(d.showerTimes, 0, en, { one: "8 minute", other: "8 minute x " + number("en", d.showerTimes, 0) }); }
              },
              "4": {
                title: function(d) { return "Lengthy"; },
                description: function(d) { return plural(d.showerTimes, 0, en, { one: "12 minute", other: "12 minute x " + number("en", d.showerTimes, 0) }); }
              },
              "5": {
                title: function(d) { return "Monsoon"; },
                description: function(d) { return plural(d.showerTimes, 0, en, { one: "20 minute", other: "20 minute x " + number("en", d.showerTimes, 0) }); }
              },
              "6": {
                title: function(d) { return "Set manually"; },
                description: function(d) { return "Set the time manually"; }
              }
            },
            set_manually: {
              title: function(d) { return "In total, for how long did the water run when you were showering?"; },
              actions: {
                confirm: function(d) { return "Set shower length"; }
              }
            }
          },
          step_4_check_actions: {
            title: function(d) { return "Did you succeed in your daily actions?"; },
            options: {
              shampoo: {
                title: function(d) { return "Turn the shower off while applying shampoo and soap"; },
                description: function(d) { return ""; }
              },
              daydream: {
                title: function(d) { return "Don’t daydream in the shower"; },
                description: function(d) { return "Daydreaming is good, just not in shower"; }
              },
              personal: {
                title: function(d) { return "Your own personal action"; },
                description: function(d) { return "No need to tell us what it is ☺️"; }
              }
            }
          },
          step_5_feedback: {
            title: function(d) { return select(d.dailySuccess, { noEmissions: "Fantastic! No shower means no emission!", success: "Well done, you did it right!", equal: "Another day, another shower.", other: "Oops! Keep an eye on that drainage." }); },
            description: function(d) { return select(d.dailySuccess, { noEmissions: "High five, " + d.givenName + "! Since you didn’t shower at all, we also marked your daily actions as all done! We’re sure the group is proud of you!", success: "You showered shorter than your average was before starting the challenge, so job well done!", equal: "You showered the same length as you did before joining the group. Challenge yourself, squeeze your showers a tiny bit shorter 💪", other: "You showered longer than your average was before starting the challenge. We know, some days are harder than others, we’re sure you’ll get back on track." }); },
            progress: {
              label: function(d) { return "Your reduction"; },
              ref_legend: function(d) { return "you before"; },
              projected_reduction_desc: function(d) { return "would be your yearly reduction in CO2e with this pace"; },
              total_reduction_desc: function(d) { return "CO2e since start"; },
              sheet: {
                yearly_reduction_desc: function(d) { return "would be your yearly reduction in CO2e with this pace"; },
                yearly_increase_desc: function(d) { return "would be your yearly increase with this pace, so please get back on track!"; },
                increase_desc: function(d) { return "is by how much the carbon footprint of your showering has gone up 😱"; }
              }
            },
            actions: {
              done: function(d) { return "Done for this day"; }
            }
          }
        },
        daily_commute: {
          step_2_set_did_commute: {
            title: function(d) { return "Did you commute on this day?"; },
            option: {
              yes: function(d) { return "Yes"; },
              no: function(d) { return "No"; }
            }
          },
          step_3_set_commute_types: {
            title: function(d) { return "What did you use for your commute on this day?"; },
            options: {
              title: function(d) { return "Shortlist of past choices"; },
              car_custom: {
                title: function(d) { return "Your custom emissions car"; },
                description: function(d) { return "Now: official " + d.commuteCustomCarEmissionsCo2GSelect + " g, actual " + d.commuteCustomCarEmissionsCo2G + " g CO2e per km"; }
              }
            },
            actions: {
              edit: function(d) { return "Edit the options on your shortlist"; }
            },
            edit: {
              title: function(d) { return "Which options should we keep on your shortlist?"; },
              type: {
                muscle_power: function(d) { return "Muscle power"; },
                public_transport: function(d) { return "Public or shared"; },
                two_wheels: function(d) { return "Two-wheelers and microcars"; },
                car: function(d) { return "Car"; }
              },
              cars: {
                title: function(d) { return plural(d.commuteTypesAllCarsLength, 0, en, { "0": "Add a car", other: "Edit cars" }); },
                description: function(d) { return plural(d.commuteTypesAllCarsLength, 0, en, { "0": "Select a type of car", "1": "Selected now: " + d.commuteTypesCarTitle, "2": "Selected now: two cars", "3": "Selected now: three cars", other: "Selected now: " + number("en", d.commuteTypesAllCarsLength, 0) + " cars" }); }
              },
              actions: {
                save: function(d) { return "Keep these"; }
              }
            },
            edit_cars: {
              title: function(d) { return "What kind of a car do you drive?"; },
              description: function(d) { return "For the CO2e data here, the official emissions numbers have been multiplied with 1.25 to account for actual driving behaviour and 1.2 for the fuel production emissions."; },
              type: {
                electric: function(d) { return "Fully electric"; },
                hybrid: function(d) { return "Hybrid"; },
                diesel: function(d) { return "Diesel"; },
                gasoline: function(d) { return "Gasoline"; },
                custom: function(d) { return "Set manually"; }
              },
              custom: {
                title: function(d) { return select(d.commuteTypesCustom, { optCustom: "Edit your custom emissions car", other: "Select a car with custom emissions" }); },
                description: function(d) { return select(d.commuteTypesCustom, { optCustom: "Now: official " + d.commuteCustomCarEmissionsCo2GSelect + " g, actual " + d.commuteCustomCarEmissionsCo2G + " g CO2e per km", other: "" }); }
              },
              actions: {
                save: function(d) { return "Select these cars"; }
              }
            },
            edit_custom: {
              title: function(d) { return "What are your custom emission car’s official emissions per one kilometre?"; },
              description: function(d) { return "Suggestion: check it from the registration document."; },
              info: function(d) { return "To account for actual average driving behaviour and the emissions that result from producing the fuel, we will multiply this number with 1.25 for the driving behaviour and 1.2 for the fuel production."; },
              actions: {
                cancel: function(d) { return plural(d.commuteCustomCarEmissionsCo2G, 0, en, { "0": "Cancel", other: "Delete car" }); },
                confirm: function(d) { return "Set emissions"; }
              }
            }
          },
          step_4_set_commute_distances: {
            walk: {
              title: function(d) { return "How many kilometres did you commute by walking?"; },
              description: function(d) { return "Walking"; }
            },
            bicycle: {
              title: function(d) { return "How many kilometres did you commute by bicycle?"; },
              description: function(d) { return "Cycling"; }
            },
            tram: {
              title: function(d) { return "How many kilometres did you commute by tram?"; },
              description: function(d) { return "Tram"; }
            },
            metro: {
              title: function(d) { return "How many kilometres did you commute by metro?"; },
              description: function(d) { return "Metro"; }
            },
            train: {
              title: function(d) { return "How many kilometres did you commute by train?"; },
              description: function(d) { return "Train"; }
            },
            escooter: {
              title: function(d) { return "How many kilometres did you commute by e-scooter?"; },
              description: function(d) { return "E-Scooter (shared)"; }
            },
            ld_bus: {
              title: function(d) { return "How many kilometres did you commute by long-distance bus?"; },
              description: function(d) { return "Long-distance bus"; }
            },
            sd_bus: {
              title: function(d) { return "How many kilometres did you commute by short-distance bus?"; },
              description: function(d) { return "Short-distance bus"; }
            },
            scooter: {
              title: function(d) { return "How many kilometres did you commute by scooter or moped?"; },
              description: function(d) { return "Scooter or moped"; }
            },
            motorcycle: {
              title: function(d) { return "How many kilometres did you commute by motorcycle?"; },
              description: function(d) { return "Motorcycle"; }
            },
            microcar: {
              title: function(d) { return "How many kilometres did you commute by microcar?"; },
              description: function(d) { return "Microcar or light quadricycle"; }
            },
            car_electric: {
              title: function(d) { return "How many kilometres did you commute by car?"; },
              description: function(d) { return "Electric, charged with mixed sources electricity."; }
            },
            car_electric_zero: {
              title: function(d) { return "How many kilometres did you commute by car?"; },
              description: function(d) { return "Electric, charged with zero emissions electricity."; }
            },
            car_hybrid: {
              title: function(d) { return "How many kilometres did you commute by car?"; },
              description: function(d) { return "Gasoline-electric hybrid, charged with mixed sources electricity."; }
            },
            car_hybrid_zero: {
              title: function(d) { return "How many kilometres did you commute by car?"; },
              description: function(d) { return "Gasoline-electric hybrid, charged with zero emissions electricity."; }
            },
            car_s_diesel: {
              title: function(d) { return "How many kilometres did you commute by car?"; },
              description: function(d) { return "Small diesel car."; }
            },
            car_m_diesel: {
              title: function(d) { return "How many kilometres did you commute by car?"; },
              description: function(d) { return "Medium-sized diesel car."; }
            },
            car_l_diesel: {
              title: function(d) { return "How many kilometres did you commute by car?"; },
              description: function(d) { return "Large diesel car."; }
            },
            car_s_gasoline: {
              title: function(d) { return "How many kilometres did you commute by car?"; },
              description: function(d) { return "Small gasoline car."; }
            },
            car_m_gasoline: {
              title: function(d) { return "How many kilometres did you commute by car?"; },
              description: function(d) { return "Medium-sized gasoline car."; }
            },
            car_l_gasoline: {
              title: function(d) { return "How many kilometres did you commute by car?"; },
              description: function(d) { return "Large gasoline car."; }
            },
            car_custom: {
              title: function(d) { return "How many kilometres did you commute by car?"; },
              description: function(d) { return "Custom emissions car."; }
            }
          },
          step_5_feedback: {
            title: function(d) { return select(d.dailySuccess, { success: "Well done, you did it right!", equal: "Just another day of commuting.", noEmissions: "Fantastic! No commuting means no emissions!", other: "Oops! Keep an eye on those emissions." }); },
            description: function(d) { return select(d.dailySuccess, { success: "The carbon emission from your commuting today were smaller than your average was before starting the challenge, so job well done!", equal: "Today the carbon emissions from your commuting were the same as on an average day before starting the challenge. How about trying something new tomorrow?", noEmissions: "High five, " + d.name + "! You didn’t commute at all! We’re sure the group is proud of you!", other: "The carbon emission from your commuting today were higher than your average was before starting the challenge. We know, some days are harder to organize than others, so don’t worry and try to get back on track." }); },
            progress: {
              label: function(d) { return "Your reduction"; },
              ref_legend: function(d) { return "you before"; },
              projected_reduction_desc: function(d) { return "would be your yearly reduction in CO2e with this pace"; },
              total_reduction_desc: function(d) { return "CO2e since start"; },
              sheet: {
                yearly_reduction_desc: function(d) { return "would be your yearly reduction in CO2e with this pace"; },
                yearly_increase_desc: function(d) { return "would be your yearly increase with this pace, so please get back on track!"; },
                increase_desc: function(d) { return "is by how much the carbon footprint of your commuting has gone up 😱"; }
              }
            },
            actions: {
              done: function(d) { return "Done for this day"; }
            }
          }
        }
      },
      tour: {
        error: {
          title: function(d) { return "This tour doesn’t exist"; },
          description: function(d) { return "How strange... If you feel that this is a mistake on Tideal’s side, please contact us and we’ll see to it."; },
          actions: {
            ok: function(d) { return "Okay"; }
          }
        },
        step_1_visitor: {
          title: function(d) { return "Welcome to Tideal"; },
          description: function(d) { return "We noticed that you might be new here 🤗 Would you like to take a short tour and see what this is all about?"; },
          actions: {
            forward: function(d) { return "Take the tour"; }
          }
        },
        step_1_member: {
          title: function(d) { return "Happy to have you here"; },
          description: function(d) { return "We’ve put together a short tour to explain what Tideal is all about. Want to take a look?"; },
          actions: {
            forward: function(d) { return "Take the tour"; }
          }
        },
        step_2: {
          title: function(d) { return "Cut your impact on the climate, fact-based"; },
          description: function(d) { return "Tideal is the world’s best community-powered and data-inspired climate action platform.\nTake part in a curated and growing set of behaviour change challenges, see the climate effects of the changes you make, and live a life true to your values."; }
        },
        step_3: {
          title: function(d) { return "Tideal is made up of challenges"; },
          description: function(d) { return "A challenge is about a bite-sized section of human life that results in carbon emissions: eating lunch, commuting, showering, and so on.\nWhen you take up a challenge, you see what the emissions of that section of your life are and set yourself up to reduce them."; }
        },
        step_4: {
          title: function(d) { return "Challenges are tackled in groups"; },
          description: function(d) { return "To make it all more fun and to have a bigger impact together, challenges are done in groups. Anyone can set up a group and invite their friends and family to join it.\nThe more the merrier, we say 😌"; }
        },
        step_5: {
          title: function(d) { return "Change your ways together"; },
          description: function(d) { return "The group’s behaviour change period lasts for four weeks. During that time, we ask you simple questions on your progress and show you hard data on the reduction that you – and other people in the group – are achieving.\nCheer for your friends’ successes – and maybe even compete on who can achieve the smallest footprint?"; }
        },
        step_6: {
          title: function(d) { return "We can make a difference"; },
          description: function(d) { return "When you take action, you might also start feeling better still concerned, even scared, but better. Concerned, stubborn optimism is a recipe for positive change!\nWhat’s important to realize is that individual actions, taken by groups of people, do add up! After all, all the world is really made out of is individual actions."; },
          actions: {
            close: function(d) { return "Close"; },
            forward: function(d) { return "See the challenges"; }
          }
        }
      }
    },
    data: {
      challenge: {
        "1": {
          header: function(d) { return "Challenge"; },
          title: function(d) { return "Showering"; },
          info: function(d) { return "Heating up water requires energy – surprisingly lot of it! Take on this challenge and watch your footprint shrink with a few simple changes of habit!"; },
          intro: {
            title: function(d) { return "Challenge introduction"; },
            description: function(d) { return "Know that feeling of warm water running down on your skin in the morning, straight out of bed and still all sleepy? Or in the evening, washing..."; }
          },
          factAndMath: {
            title: function(d) { return "Fact and math"; },
            short: function(d) { return "The effortless supply of hot water is a modern convenience that we’ve become accustomed to. All that effortlessness can be misleading..."; }
          }
        },
        "2": {
          title: function(d) { return "Eating meat"; },
          info: function(d) { return "Compared to eating plenty of meat, a diet rich in plants is one of the biggest climate positive lifestyle choices that an individual can make. So, more greens, anyone?"; }
        },
        "3": {
          header: function(d) { return "Challenge"; },
          title: function(d) { return "The daily commute"; },
          info: function(d) { return "Work and study are what keep the world spinning, but getting there and back can result in some serious carbon emissions. Are you ready to change your commute?"; },
          fact: {
            first: {
              txt: function(d) { return "Share of personal carbon footprint"; },
              scale: function(d) { return "for an average person"; }
            },
            second: {
              txt: function(d) { return "If 100 000 people halve their use"; },
              scale: function(d) { return "reduction of CO2e every year"; }
            }
          },
          intro: {
            title: function(d) { return "Challenge introduction"; },
            description: function(d) { return "Commuting isn’t necessarily one of those choices in life where we can decide whether to do it or not: most of us need to get our places of work or..."; }
          },
          factAndMath: {
            title: function(d) { return "Fact and math"; },
            short: function(d) { return "As part of our day-to-day, many of us need to travel some distance to get to our places of work or study. In fact, this activity is so universal that we..."; }
          }
        },
        daily_commute: {
          commute_types_car_title: function(d) { return select(d.value, { optElectric: "electric car", optElectricZero: "electric car", optHybrid: "hybrid car", optHybridZero: "hybrid car", optSDiesel: "small diesel car", optMDiesel: "medium-sized diesel car", optLDiesel: "large diesel car", optSGasoline: "small gasoline car", optMGasoline: "medium-sized gasoline car", optLGasoline: "large gasoline car", optCustom: "custom emission car", other: "unknown car" }); },
          type: {
            walk: function(d) { return "Walking"; },
            bicycle: function(d) { return "Bicycle"; },
            tram: function(d) { return "Tram"; },
            metro: function(d) { return "Metro"; },
            train: function(d) { return "Train"; },
            escooter: function(d) { return "E-scooter (shared)"; },
            ldbus: function(d) { return "Long-distance bus"; },
            sdbus: function(d) { return "Short-distance bus"; },
            scooter: function(d) { return "Moped or scooter (max 45 km/h)"; },
            motorcycle: function(d) { return "Motorcycle"; },
            microcar: function(d) { return "Microcar or light quadricycle (max 45 km/h)"; },
            car_electric_zero: function(d) { return "Electric, charged with zero emissions electricity"; },
            car_electric: function(d) { return "Electric, charged with mixed sources electricity"; },
            car_hybrid_zero: function(d) { return "Gasoline-electric hybrid, charged with zero emissions electricity"; },
            car_hybrid: function(d) { return "Gasoline-electric hybrid, charged with mixed sources electricity"; },
            car_s_diesel: function(d) { return "Small diesel car"; },
            car_m_diesel: function(d) { return "Medium-sized diesel car"; },
            car_l_diesel: function(d) { return "Large diesel car"; },
            car_s_gasoline: function(d) { return "Small gasoline car"; },
            car_m_gasoline: function(d) { return "Medium-sized gasoline car"; },
            car_l_gasoline: function(d) { return "Large gasoline car"; },
            car_custom: function(d) { return "Your custom emissions car"; },
            description: function(d) { return plural(d.value, 0, en, { "0": "Zero emissions", other: number("en", d.value, 0) + " g CO2e per km" }); },
            name: function(d) { return select(d.type, { walk: "Walking", bicycle: "Bicycling", tram: "Tram", metro: "Metro", train: "Train", sdbus: "Short-distance bus", ldbus: "Long-distance bus", car_electric: "Electric car", car_hybrid: "Gasoline-electric hybrid car", car_s_diesel: "Small diesel car", car_m_diesel: "Medium-sized diesel car", car_l_diesel: "Large diesel car", car_s_gasoline: "Small gasoline car", car_m_gasoline: "Medium-sized gasoline car", car_l_gasoline: "Large gasoline car", car_custom: "Your custom emissions car", other: "Other" }); },
            emissions: function(d) { return plural(d.emissions, 0, en, { "0": "Zero emissions", other: number("en", d.emissions, 0) + " g CO2e per km" }); }
          },
          set_value: {
            walk_per_week: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by walking" }); },
            bicycle_per_week: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by bicycle" }); },
            tram_per_week: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by tram" }); },
            metro_per_week: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by metro" }); },
            train_per_week: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by train" }); },
            escooter_per_week: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by e-scooter" }); },
            ld_bus_per_week: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by long-distance bus" }); },
            sd_bus_per_week: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by short-distance bus" }); },
            scooter_per_week: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by scooter or moped" }); },
            motorcycle_per_week: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by motorcycle" }); },
            microcar_per_week: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by microcar" }); },
            car_electric_per_week: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by electric car" }); },
            car_electric_zero_per_week: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by electric car" }); },
            car_hybrid_per_week: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by hybrid car" }); },
            car_hybrid_zero_per_week: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by hybrid car" }); },
            car_s_diesel_per_week: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by small diesel car" }); },
            car_m_diesel_per_week: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by medium-sized diesel car" }); },
            car_l_diesel_per_week: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by large diesel car" }); },
            car_s_gasoline_per_week: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by small gasoline car" }); },
            car_m_gasoline_per_week: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by medium-sized gasoline car" }); },
            car_l_gasoline_per_week: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by large gasoline car" }); },
            car_custom_per_week: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by your custom emissions car" }); },
            walk_per_year: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by walking" }); },
            bicycle_per_year: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by bicycle" }); },
            tram_per_year: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by tram" }); },
            metro_per_year: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by metro" }); },
            train_per_year: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by train" }); },
            escooter_per_year: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by e-scooter" }); },
            ld_bus_per_year: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by long-distance bus" }); },
            sd_bus_per_year: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by short-distance bus" }); },
            scooter_per_year: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by scooter or moped" }); },
            motorcycle_per_year: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by motorcycle" }); },
            microcar_per_year: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by microcar" }); },
            car_electric_per_year: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by electric car" }); },
            car_electric_zero_per_year: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by electric car" }); },
            car_hybrid_per_year: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by hybrid car" }); },
            car_hybrid_zero_per_year: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by hybrid car" }); },
            car_s_diesel_per_year: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by small diesel car" }); },
            car_m_diesel_per_year: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by medium-sized diesel car" }); },
            car_l_diesel_per_year: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by large diesel car" }); },
            car_s_gasoline_per_year: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by small gasoline car" }); },
            car_m_gasoline_per_year: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by medium-sized gasoline car" }); },
            car_l_gasoline_per_year: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by large gasoline car" }); },
            car_custom_per_year: function(d) { return plural(d.value, 0, en, { "0": "", other: number("en", d.value, 0) + " km by your custom emissions car" }); }
          }
        },
        fact: {
          footprint: {
            label: function(d) { return "Of personal footprint"; },
            value: function(d) { return number("en", d.value, 0); },
            valueUnit: function(d) { return "%"; },
            description: function(d) { return "for an average person"; }
          },
          reduction: {
            label: function(d) { return "All-time reduction"; },
            value: function(d) { return co2e(d.value, "en"); },
            valueUnit: function(d) { return co2eUnit(d.value, "en"); },
            description: function(d) { return "CO2e"; }
          },
          groups: {
            label: function(d) { return "Active groups"; },
            value: function(d) { return number("en", d.activeGroupCount, 0); },
            description: function(d) { return number("en", d.totalGroupCount, 0) + " all-time"; }
          },
          members: {
            label: function(d) { return "Tidealists now"; },
            value: function(d) { return number("en", d.activeTidealistCount, 0); },
            description: function(d) { return number("en", d.totalTidealistCount, 0) + " all-time"; }
          }
        }
      },
      group: {
        defaultName: function(d) { return "Group " + d.captainName; },
        defaultNameUnknown: function(d) { return "Nameless group"; }
      }
    },
    wizards: {
      account_settings: {
        title: function(d) { return "Account settings"; },
        file: function(d) { return "Change my profile picture"; },
        given_name: function(d) { return "First name"; },
        family_name: function(d) { return "Last name"; },
        hide: function(d) { return "Hide my last name"; },
        phone: function(d) { return "Phone number"; },
        sms_opt_out: {
          label: function(d) { return "SMS notifications"; },
          true: function(d) { return "Off"; },
          false: function(d) { return "On"; }
        },
        email: function(d) { return "Email address"; },
        email_opt_out: {
          label: function(d) { return "Email notifications"; },
          true: function(d) { return "Off"; },
          false: function(d) { return "On"; }
        },
        placeholder: {
          email: function(d) { return "Add email address"; },
          phone_number: function(d) { return "Add phone number"; }
        },
        save: function(d) { return "Save changes"; },
        saveLoading: function(d) { return "Saving"; },
        error: {
          title: function(d) { return "Error"; },
          description: function(d) { return "Error saving profile info"; },
          close: function(d) { return "Ok"; }
        },
        email_opt_out_modal: {
          title: function(d) { return "Email notifications"; },
          options: {
            true: function(d) { return "Email notifications off"; },
            false: function(d) { return "Email notifications on"; }
          },
          save: function(d) { return "Set"; }
        },
        sms_opt_out_modal: {
          title: function(d) { return "SMS notifications"; },
          options: {
            true: function(d) { return "SMS notifications off"; },
            false: function(d) { return "SMS notifications on"; }
          },
          save: function(d) { return "Set"; }
        }
      }
    },
    utils: {
      co2data: {
        converter: {
          unit: {
            gram: function(d) { return plural(d.amount, 0, en, { one: "1 gram", other: number("en", d.amount, 0) + " grams" }); },
            kilograms: function(d) { return plural(d.amount, 0, en, { one: "1 kilogram", other: number("en", d.amount, 0) + " kilograms" }); },
            tonnes: function(d) { return plural(d.amount, 0, en, { one: "1 tonne", other: number("en", d.amount, 0) + " tonnes" }); },
            bananas: function(d) { return plural(d.amount, 0, en, { one: "1 banana", other: number("en", d.amount, 0) + " bananas" }); }
          },
          unitAbbr: {
            grams: function(d) { return "g"; },
            kilograms: function(d) { return "kg"; },
            tonnes: function(d) { return "t"; }
          }
        },
        avocado: {
          value: function(d) { return d.amount; },
          desc: function(d) { return "is how many avocados’ carbon footprint this equals 🥑"; },
          feed: function(d) { return "equals the carbon footprint of " + d.amount + " avocados 🥑"; }
        },
        cheese: {
          value: function(d) { return d.amount + " kg"; },
          desc: function(d) { return "of cheese could be produced with this reduction 🧀"; },
          feed: function(d) { return "equals the carbon footprint of " + d.amount + " kg of cheese 🧀"; }
        },
        beef: {
          value: function(d) { return d.amount + " kg"; },
          desc: function(d) { return "of beef could be produced with this amount of CO2e reduction 🐮"; },
          feed: function(d) { return "equals the carbon footprint of " + d.amount + " kg of beef 🐮"; }
        },
        cola: {
          value: function(d) { return d.amount; },
          desc: function(d) { return "cans of Coke could be produced with this reduction"; },
          feed: function(d) { return "equals the carbon footprint of " + d.amount + " cans of Coke"; }
        },
        oatmilk: {
          value: function(d) { return d.amount + " l"; },
          desc: function(d) { return "of oatmilk could be produced with this reduction 🥛"; },
          feed: function(d) { return "equals the carbon footprint of " + d.amount + " litres of oatmilk 🥛"; }
        },
        beer: {
          value: function(d) { return d.amount + " l"; },
          desc: function(d) { return "litres of beer could be produced with these reduced CO2e emissions 🍺"; },
          feed: function(d) { return "equals the carbon footprint of " + d.amount + " litres of beer 🍺"; }
        },
        tomato: {
          value: function(d) { return d.amount + " kg"; },
          desc: function(d) { return "of tomatoes farmed in a glasshouse produce this amount of emissions 🍅"; },
          feed: function(d) { return "equals the carbon footprint of " + d.amount + " tomatoes farmed in a glasshouse 🍅"; }
        },
        smartphone: {
          value: function(d) { return d.amount; },
          desc: function(d) { return "large flagship smartphones could be produced with this reduction 📱"; },
          feed: function(d) { return "equals the carbon footprint of " + d.amount + " large flagship smartphones 📱"; }
        },
        flightHelJfk: {
          value: function(d) { return d.amount; },
          desc: function(d) { return "one-way Helsinki-NYC flights produce the same amount of CO2e per passenger 🛫"; }
        },
        smallGasolineCar: {
          value: function(d) { return d.amount + " km"; },
          desc: function(d) { return "could be driven with a small gasoline car with this reduction 🚗"; },
          feed: function(d) { return "equals the carbon footprint of " + d.amount + " km driven with a small gasoline car 🚗"; }
        },
        largeGasolineCar: {
          value: function(d) { return d.amount + " km"; },
          desc: function(d) { return "driven with a large gasoline car produces this much CO2e 🚙"; },
          feed: function(d) { return "equals the carbon footprint of " + d.amount + " km driven with a large gasoline car 🚙"; }
        },
        showerLitresNaturalGas: {
          value: function(d) { return d.amount + " l"; },
          desc: function(d) { return "of water could be heated to a nice showering temperature using gas with this CO2e"; }
        },
        showerMinNaturalGas: {
          value: function(d) { return d.amount + " min"; },
          desc: function(d) { return "worth of warm shower water heated with gas would produce the same emissions 🚿"; }
        },
        averageFinnCo2: {
          value: function(d) { return d.amount + " %"; },
          desc: function(d) { return "of an average Finnish citizens daily carbon footprint equals this reduction 🇫🇮"; }
        },
        averageWorldCitizenCo2: {
          value: function(d) { return d.amount + " %"; },
          desc: function(d) { return "of an average global citizens daily carbon footprint is what this reduction equals 🌍"; }
        },
        banana: {
          value: function(d) { return d.amount; },
          desc: function(d) { return "bananas could be farmed with this CO2e reduction 🍌"; },
          feed: function(d) { return "equals the carbon footprint of " + d.amount + " bananas 🍌"; }
        },
        chicken: {
          value: function(d) { return d.amount + " kg"; },
          desc: function(d) { return "of chicken meat could be produced with this amount of CO2e reduction 🐔"; },
          feed: function(d) { return "equals the carbon footprint of " + d.amount + " kg chicken meat 🐔"; }
        }
      }
    },
    feed: {
      generic: {
        group: {
          created: {
            "1": function(d) { return d.givenName + " " + d.familyName + " founded the group and is now recruiting members who are ready for change! The change period will start on " + date_en_dLLLL_mr7jy7(d.startDate) + "."; }
          },
          joined: {
            "1": function(d) { return d.givenName + " " + d.familyName + " joined the group!"; },
            "2": function(d) { return d.givenName + " " + d.familyName + " joined the group with a " + d.reductionPct + " % reduction goal to the footprint of their " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + "!"; }
          },
          rejoined: {
            "1": function(d) { return d.givenName + " " + d.familyName + " updated their baseline and reduction goal. The new baseline is " + co2kg(d.yearlyTargetFootprintCo2Kg, "en") + " kg of CO2e a year and the reduction goal " + numberPercent(d.reductionPct, "en") + "."; }
          }
        },
        answer: {
          basic: {
            "1": function(d) { return d.name + "’s day number " + d.changeDay + " is now reported."; },
            "2": function(d) { return d.name + "’s " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " emissions were " + co2kg(d.daysEmissionsCo2Kg, "en") + " kg on day " + d.changeDay + "."; }
          },
          ifEmissions: {
            "1": function(d) { return d.name + "’s " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " emissions were " + co2kg(d.daysEmissionsCo2Kg, "en") + " kg on day " + d.changeDay + ", which " + co2equi(d.daysEmissionsCo2Kg, "en") + "."; }
          },
          ifReduction: {
            "1": function(d) { return d.name + "’s " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " on day number " + d.changeDay + " is a " + co2kg(d.daysEmissionReductionAbsoluteCo2Kg, "en") + " kg reduction compared to " + d.givenName + "’s old " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " habits!"; },
            "2": function(d) { return d.name + "’s " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " emissions on this day were " + numberInteger(d.daysEmissionReductionRelativePros, "en") + "% smaller than " + d.givenName + "’s average day was before!"; },
            "3": function(d) { return d.name + "’s " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " emissions on this day were " + co2kg(d.daysEmissionsCo2Kg, "en") + " kg, which is " + d.daysEmissionReductionRelativePros + " % smaller than " + d.givenName + "’s average day was before!"; }
          },
          ifTotalReduction: {
            "1": function(d) { return d.name + " has so far been able to reduce a total of " + co2kg(d.totalEmissionsReductionCo2Kg, "en") + " kg of CO2e during this challenge. Here’s a bicep emoji for that 💪"; },
            "2": function(d) { return d.name + " has so far shaved " + co2kg(d.totalEmissionsReductionCo2Kg, "en") + " kg of off their " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " footprint. Gracias, " + d.givenName + "!"; },
            "3": function(d) { return d.name + " has so far reduced " + co2kg(d.totalEmissionsReductionCo2Kg, "en") + " kg of off their " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " footprint. This " + co2equi(d.totalEmissionsReductionCo2Kg, "en") + "."; },
            "4": function(d) { return d.name + " has cut " + numberInteger(d.totalEmissionsReductionRelativePros, "en") + "% of off their " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " footprint up to this day. That is definitely something!"; },
            "5": function(d) { return d.name + " reported on day " + d.changeDay + ": up to this point " + d.givenName + " has cut " + numberInteger(d.totalEmissionsReductionRelativePros, "en") + "% of off their " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " footprint 👏"; },
            "6": function(d) { return d.name + "’s day number " + d.changeDay + " is now in the books. With this pace, the yearly carbon emissions of " + d.givenName + "’s " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " would be reduced by " + co2kg(d.projectedYearlyAbsoluteReductionCo2Kg, "en") + " kg."; },
            "7": function(d) { return d.name + "’s current reduction pace will lead to a " + co2kg(d.projectedYearlyAbsoluteReductionCo2Kg, "en") + " kg yearly reduction in their " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " footprint."; },
            "8": function(d) { return d.name + "’s current pace entails a " + co2kg(d.projectedNewFootprintCo2Kg, "en") + " kg yearly " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " footprint – a " + co2kg(d.projectedYearlyAbsoluteReductionCo2Kg, "en") + " kg reduction to " + d.givenName + "’s baseline footprint of " + co2kg(d.yearlyBaselineFootprintCo2Kg, "en") + " kg!"; },
            "9": function(d) { return d.name + " is on a path to make a " + numberInteger(d.projectedYearlyReductionRelative, "en") + "% cut to their " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " footprint: from " + co2kg(d.yearlyBaselineFootprintCo2Kg, "en") + " kg to " + co2kg(d.projectedNewFootprintCo2Kg, "en") + " kg of CO2e a year!"; }
          }
        }
      },
      info: {
        day: function(d) { return "Day " + d.changeDay; },
        date: function(d) { return date_en_LLLd_fbeu1v(d.date); }
      }
    },
    errors: {
      equal_to: function(d) { return "The value must be equal to " + d.count; }
    },
    constants: {
      group_status: function(d) { return select(d.STATUS, { hatching: "hatching", active: "active", alumni: "alumni", other: "unknown" }); }
    },
    general: {
      button: {
        cancel: function(d) { return "cancel"; },
        close: function(d) { return "close"; },
        back: function(d) { return "back"; },
        forward: function(d) { return "forward"; },
        next: function(d) { return "next"; },
        post: function(d) { return "post"; }
      },
      time: {
        now: function(d) { return "Now"; },
        minutes: function(d) { return d.minutes + " min"; },
        hours: function(d) { return d.hours + " h"; },
        days: function(d) { return d.days + " d"; }
      },
      date: {
        yesterday: function(d) { return "Yesterday"; },
        tomorrow: function(d) { return "Tomorrow"; },
        today: function(d) { return "Today"; }
      },
      defaultGroupName: function(d) { return "Group " + d.captainName; },
      label: {
        input: {
          optional: function(d) { return "Optional"; }
        }
      },
      challenge: {
        csn: function(d) { return select(d.csn, { daily_commute: "the daily commute", hot_water: "showering", other: "challenge" }); }
      },
      unit: {
        times: function(d) { return "times"; },
        minutes: function(d) { return "minutes"; },
        kg: function(d) { return "kg"; },
        gram: function(d) { return "g"; },
        km: function(d) { return "km"; },
        percent: function(d) { return "%"; },
        days: function(d) { return "days"; },
        co2e: function(d) { return "CO2e"; }
      },
      unit_description: {
        co2kg_year: function(d) { return "of CO2e year"; },
        co2kg_a_year: function(d) { return "CO2e a year"; },
        co2kg_km: function(d) { return "of CO2e per km"; }
      },
      url: {
        join_group: function(d) { return "/group/" + d.groupId + "/join"; },
        create_group: function(d) { return "/group"; }
      }
    },
    header: {
      action: {
        label: {
          settings: function(d) { return "Settings"; }
        }
      }
    },
    components: {
      member_list: {
        captain: function(d) { return "Captain"; },
        members: function(d) { return "Members (" + d.amount + ")"; },
        others: function(d) { return "+" + plural(d.amount, 0, en, { one: "1 other", other: number("en", d.amount, 0) + " others" }); }
      },
      success_calendar_card: {
        label: function(d) { return "Your concrete actions"; },
        successDescription: function(d) { return "all done"; },
        partlyDescription: function(d) { return "some done"; },
        rateDescription: function(d) { return "success rate"; }
      },
      group_card: {
        state: {
          hatching: function(d) { return "Starts " + plural(d.days, 0, en, { "1": "tomorrow", other: "in " + number("en", d.days, 0) + " days" }); },
          active: function(d) { return "Day " + d.day; },
          alumni: function(d) { return "Ended"; }
        },
        progresslabel: {
          hatching: function(d) { return "Yearly group goal"; },
          active: function(d) { return "Group reduction"; },
          alumni: function(d) { return "Group reduction"; }
        },
        subheader: function(d) { return "Your reduction"; },
        group_total: function(d) { return "Group total"; },
        value_desc: function(d) { return "CO2e since start"; },
        ref_label: function(d) { return "YOU BEFORE"; }
      },
      group_page_card: {
        subheader: {
          personal: {
            hatching: function(d) { return "Your goal"; },
            active: function(d) { return "Your reduction"; },
            alumni: function(d) { return "Your reduction"; }
          },
          group: {
            hatching: function(d) { return "Group goal"; },
            active: function(d) { return "Group reduction"; },
            alumni: function(d) { return "Group reduction"; }
          }
        },
        unit: {
          hatching: function(d) { return "CO2 a year"; },
          active: function(d) { return "CO2e since start"; },
          alumni: function(d) { return "CO2e since start"; }
        },
        full_stats_link_text: function(d) { return "Show full stats"; }
      },
      small_group_invite_card: {
        invited_by: function(d) { return d.captainName + " invited you to this group"; },
        starts_in: function(d) { return "Starts in " + d.days + " days"; }
      },
      small_group_card: {
        you: function(d) { return "you"; },
        rest_members: function(d) { return "and " + plural(d.amount, 0, en, { one: "1 other", other: number("en", d.amount, 0) + " others" }); }
      },
      group_listing_card: {
        title: function(d) { return "Group"; },
        hatching: {
          counter: function(d) { return "Starts " + plural(d.groupDay, 0, en, { "1": "tomorrow", other: "in " + number("en", d.groupDay, 0) + " days" }); },
          goal: function(d) { return "Group goal"; }
        },
        active: {
          counter: function(d) { return "Change — day " + d.groupDay; },
          goal: function(d) { return "Reduction"; }
        }
      },
      group_header: {
        join_group: function(d) { return "Join this group"; },
        join_with_code: function(d) { return "Enter code to join"; },
        invite_friends: function(d) { return "Invite friends"; },
        daily_records: function(d) { return "Daily questions"; },
        join_with_code_description: function(d) { return "To join this group, you need to have its access code."; }
      },
      text_preview: {
        more: function(d) { return "Read more"; }
      },
      challenge_card: {
        valueDesc: function(d) { return "share of average personal carbon footprint."; }
      },
      wizards: {
        input: {
          required: function(d) { return d.requiredLabel + " can’t be empty"; },
          verified: function(d) { return "Verified"; }
        }
      },
      feed: {
        title: function(d) { return "Feed"; },
        load: function(d) { return "Show all"; },
        loading: function(d) { return "Loading"; },
        in_group: function(d) { return "In group $1"; },
        group: function(d) { return "Group $1"; },
        newpost: {
          action: function(d) { return "New post"; },
          title: function(d) { return "New post"; },
          input: {
            placeholder: function(d) { return "Write a new post"; }
          },
          submit: function(d) { return "Post"; },
          exit: {
            confirm: {
              title: function(d) { return "Are you sure you want to exit?"; },
              desc: function(d) { return "If you exit now, your post will not be saved."; },
              yes: function(d) { return "Yes, exit"; },
              no: function(d) { return "No, stay here"; }
            }
          }
        },
        notLoggedIn: function(d) { return "This will be the group’s feed. You’ll be able to view this once you have joined the group and the change period has started."; },
        reactions: {
          title: function(d) { return "Respect to " + d.givenName; },
          alt: function(d) { return "Like"; }
        },
        comments: {
          post_title: function(d) { return "Post"; },
          event_title: function(d) { return "Event"; },
          count: function(d) { return plural(d.replyCount, 0, en, { "0": "", other: number("en", d.replyCount, 0) + " " }) + plural(d.replyCount, 0, en, { "0": "Comment", "1": "comment", other: "comments" }); },
          captain: function(d) { return "Captain"; },
          placeholder: function(d) { return "Post a new comment"; },
          exit: {
            confirm: {
              title: function(d) { return "Are you sure you want to exit?"; },
              desc: function(d) { return "If you exit now, your comment will not be posted."; },
              yes: function(d) { return "Yes, exit"; },
              no: function(d) { return "No, stay here"; }
            }
          }
        },
        allRead: function(d) { return "That’s all! You’ve scrolled all the way down to the group’s birth."; }
      },
      banner: {
        tour: {
          title: function(d) { return "Take the tour"; },
          subtitle: function(d) { return "See what this all about"; }
        },
        login: {
          title: function(d) { return "New here?"; },
          subtitle: function(d) { return "Or already one of us? In any case, get in."; },
          button: function(d) { return "Get in"; }
        },
        add2hs: {
          title: function(d) { return "Add Tideal to your home screen"; }
        }
      },
      memberlist: {
        prospect: function(d) { return "You?"; }
      },
      modal_select: {
        cancel: function(d) { return "Cancel"; }
      },
      sorting_options_selector: {
        label: function(d) { return "Sort by"; },
        select_title: function(d) { return "Sort members by"; }
      },
      actions_card: {
        header: function(d) { return "Your concrete actions"; },
        footer: function(d) { return "When the change period of the group has started, we’ll show your success rate on these actions in this space."; }
      },
      leaderboard_card: {
        title: function(d) { return "Leaderboards"; },
        hatching: {
          text: function(d) { return "When the change period of the group has started, this space will list group members who have achieved the biggest reduction and the smallest footprint in their showering."; },
          link_text: function(d) { return "Show group members"; }
        },
        show_more: function(d) { return "Show more"; },
        reduction_header: function(d) { return "Biggest reduction"; },
        reduction_legend: function(d) { return "Reduction since start"; },
        footprint_header: function(d) { return "Smallest footprint"; },
        footprint_legend: function(d) { return "Yearly footprint"; }
      },
      messages: {
        reminders: {
          title: function(d) { return "Challenge reminders"; },
          unanswered: function(d) { return d.count + " unanswered"; },
          earlier: function(d) { return d.count + " earlier"; },
          show: function(d) { return "Show reminders"; },
          day: function(d) { return "Change - day " + d.number; },
          challenge: function(d) { return select(d.csn, { daily_commute: "Commuting", hot_water: "Showering", other: "" }); },
          allLoaded: function(d) { return "That’s it! You have now seen all challenge reminders that you have ever received."; }
        },
        notifications: {
          title: function(d) { return "Notifications"; },
          allRead: {
            title: function(d) { return "All read!"; },
            description: function(d) { return "You have no new challenge reminders or notifications."; }
          },
          emptyMessages: {
            title: function(d) { return "Nothing here yet!"; },
            description1: function(d) { return "When you are in a group, we will send you reminders to help you stick to your new habits. Also, we will let you know when someone reacts to you actions or posts to the group."; },
            description2: function(d) { return "You will then find that stuff here."; }
          },
          markRead: function(d) { return "Mark all as read"; },
          empty: function(d) { return "There’s nothing here yet! When someone reacts to your actions or posts to a group you’re in, we will let you know here."; },
          commented: {
            group: function(d) { return "posted in"; },
            action: function(d) { return "commented on your action:"; },
            post: function(d) { return "commented on your post:"; },
            thread: function(d) { return "commented on something you had commented on before:"; }
          },
          respected: {
            action: function(d) { return "showed respect to your action:"; },
            post: function(d) { return "showed respect to your post:"; },
            comment: function(d) { return "showed respect to your comment:"; }
          },
          allLoaded: function(d) { return "End of line! Those are all the notifications you have ever received."; }
        }
      },
      small_leaders: {
        title: function(d) { return "Leaders"; },
        category: {
          reduction: {
            label: function(d) { return "Reduction"; }
          },
          footprint: {
            label: function(d) { return "Footprint"; }
          }
        }
      }
    },
    fragments: {
      smartinvitebutton: {
        text: function(d) { return "Invitation pending"; }
      },
      serviceworker: {
        update: function(d) { return "A newer version of Tideal is available"; },
        action: function(d) { return "Update now"; }
      },
      footer: {
        copyright: function(d) { return "© Tideal. All rights reserved."; },
        legal: {
          text: function(d) { return "See our $1 and $2."; },
          pp: function(d) { return "privacy policy"; },
          tos: function(d) { return "terms of service"; }
        },
        version: function(d) { return "Version: " + d.version + "."; }
      }
    },
    challenge: {
      welcome: {
        email: {
          subject: function(d) { return "Today is the day"; },
          content: function(d) { return "Hey " + d.givenName + "!\r\nToday is the first day of your Tideal " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " challenge!\r\nDuring the next " + d.durationWeeks + " weeks, the goal is to achieve a permanent change in your habits. So, starting today, pay attention to your " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + ".\r\nHave a great day!\r\nP.S. Feedback on Tideal? Please send it to info@tideal.app."; },
          signature: function(d) { return "Kind regards,"; }
        },
        sms: function(d) { return "Hey " + d.givenName + ", today is the first day of your Tideal " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " challenge! During the next " + d.durationWeeks + " weeks, the goal is to achieve a permanent change in your habits. So, starting today, pay attention to your " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + ". Have a great day! P.S. Feedback on Tideal? Please send it to info@tideal.app."; }
      },
      prompt: {
        "1": function(d) { return "Hey " + d.givenName + "! How did things go on the first day of your " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " challenge? Let us and the group know at " + d.href; },
        "2": function(d) { return select(d.csn, { daily_commute: "Commuting", hot_water: "Showering", other: "" }) + " can be a significant contributor to one's carbon footprint. Did you manage to keep your's checked today? " + d.href; },
        "3": function(d) { return "Permanent change requires some effort, but starts with small steps. Steps in the right direction in " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " today? " + d.href; },
        "4": function(d) { return "High five, " + d.givenName + "! *" + d.givenName + " high fives back* Let us know how you did in the " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " challenge today: " + d.href; },
        "5": function(d) { return "Small changes in your habits can add up to big reductions in your carbon footprint. How did your " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "challenge" }) + " go today? " + d.href; },
        "6": function(d) { return "It's our favourite time of the day: sending a message, full of love, to you. How did the " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " challenge go today? " + d.href; },
        "7": function(d) { return "Hey " + d.givenName + "! First 7 days in the " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " challenge done! Answer the daily questions at " + d.href + " and see full first week stats."; },
        "8": function(d) { return "When we act together, we can still save something. Tell us how you did in the " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " challenge today: " + d.href; },
        "9": function(d) { return "Hey " + d.givenName + "! How are things coming along with the change in your " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "challenge" }) + "? We are all ears at " + d.href; },
        "10": function(d) { return "Good evening, champ. How was the day in the " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " challenge for you? Let us and the group know at " + d.href; },
        "11": function(d) { return "Hey! Answer the " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "challenge" }) + " questions at " + d.href + " and you'll be done for the day! All the best, Tideal."; },
        "12": function(d) { return "Hello, Tidealist " + d.givenName + "! Have you been rocking it in the " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " challenge today? Let the group know at " + d.href; },
        "13": function(d) { return "We like you. -Your friends at Tideal. Oh, and here's the link to answering your daily " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " questions: " + d.href; },
        "14": function(d) { return "Halfway mark in the " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " challenge! Are you feeling those new habits starting to form? The daily questions await at " + d.href; },
        "15": function(d) { return "Being a Tidealist is a team sport. Let your group know how you did in the " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " challenge today: " + d.href; },
        "16": function(d) { return "\"I wish the carbon footprint of my " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " was higher\", said no one, ever. Did you reduce yours today? " + d.href; },
        "17": function(d) { return d.givenName + ", it's that time of the day! Tell us and the group how you did in the " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " challenge today: " + d.href; },
        "18": function(d) { return "When forming new habits, consistency is the key to success. How did you do in the " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " challenge today? " + d.href; },
        "19": function(d) { return "Tideal aims to be a platform encouraging concrete action. As of your " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "challenge" }) + ", what were your actions today? " + d.href; },
        "20": function(d) { return "Though everyone can't do everything, everyone can do something. How did things go in the " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " challenge today? " + d.href; },
        "21": function(d) { return "Last week in the " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " challenge kicking off tomorrow! As always, answer in the daily questions at " + d.href; },
        "22": function(d) { return "What a great day it's been! Hopefully in your " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " challenge, too? We are all ears at " + d.href; },
        "23": function(d) { return "Since there is no planet B, let's try not to mess things up with planet A. The daily questions for A players await at " + d.href; },
        "24": function(d) { return "What greatness did you accomplish in the " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " challenge today? Brag all you can about it at " + d.href + ". But always be honest!"; },
        "25": function(d) { return "Tideal 101: You can answer the daily questions at " + d.href + ". But of course you knew all that by now, you veteran."; },
        "26": function(d) { return "Every change for the better is a good change. How is the change coming along in your " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " challenge? " + d.href; },
        "27": function(d) { return "The end really is nigh! But hopefully only in your " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "challenge" }) + " group. Let them know how today went at " + d.href; },
        "28": function(d) { return "Last day of the change period in your " + select(d.csn, { daily_commute: "commuting", hot_water: "showering", other: "" }) + " challenge! Look how far you've come! The last day's questions await at " + d.href; },
        subject: function(d) { return "Tideal challenge reminder"; },
        signature: function(d) { return "Kind regards,"; }
      }
    },
    messages: {
      sign_up: {
        new_user: {
          sms: function(d) { return "Your secret login code: " + d.secret; },
          email: {
            subject: function(d) { return "Welcome to Tideal"; },
            body: function(d) { return "Hello there!\r\nYour secret sign-in code to Tideal is " + d.secret + ". Welcome!"; },
            signature: function(d) { return "Kind regards,"; }
          }
        },
        old_user: {
          sms: function(d) { return "Your secret login code: " + d.secret; },
          email: {
            subject: function(d) { return "Sign in to Tideal"; },
            body: function(d) { return "Hey " + d.givenName + "!\r\nYour secret sign-in code to Tideal is " + d.secret + "."; },
            signature: function(d) { return "Kind regards,"; }
          }
        }
      },
      secondary_sign_up: {
        sms: function(d) { return "Your verification code: " + d.secret; },
        email: {
          subject: function(d) { return "Add this email address to your Tideal account"; },
          body: function(d) { return "Hey " + d.givenName + "!\r\nYour verification code to Tideal is " + d.secret + "."; },
          signature: function(d) { return "Kind regards,"; }
        }
      },
      shady_sign_up: {
        sms: function(d) { return d.givenName + ",\nWe are writing to let you know that someone tried adding your " + select(d.type, { phoneNumber: "phone number", email: "email address", other: "username" }) + " to their Tideal account. But panic not: we had our guard up and didn't let that happen.\nIf this was you, please be informed that " + select(d.type, { phoneNumber: "a phone number", email: "an email address", other: "a username" }) + " can be attached to just one Tideal account at a time."; },
        email: {
          subject: function(d) { return "Security notice from Tideal"; },
          body: function(d) { return d.givenName + ",\r\nWe are writing to let you know that someone tried adding your " + select(d.type, { phoneNumber: "phone number", email: "email address", other: "username" }) + " to their Tideal account. But panic not: we had our guard up and didn't let that happen.\r\nIf this was you, please be informed that " + select(d.type, { phoneNumber: "a phone number", email: "an email address", other: "a username" }) + " can be attached to just one Tideal account at a time."; },
          signature: function(d) { return "All the best,"; }
        }
      }
    }
  },
  fi: {
    utils: {
      co2data: {
        converter: {
          unit: {
            gram: function(d) { return plural(d.amount, 0, fi, { one: "1 gram", other: number("fi", d.amount, 0) + " grams" }); },
            kilogram: function(d) { return plural(d.amount, 0, fi, { one: "1 kilogram", other: number("fi", d.amount, 0) + " kilograms" }); },
            tonnes: function(d) { return plural(d.amount, 0, fi, { one: "1 tonne", other: number("fi", d.amount, 0) + " tonnes" }); },
            bananas: function(d) { return plural(d.amount, 0, fi, { one: "1 banana", other: number("fi", d.amount, 0) + " bananas" }); }
          }
        }
      }
    }
  }
}