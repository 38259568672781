import Messages from 'messageformat-runtime/messages';
import msgData from './localizations/messages';

Messages.prototype.t = function(key, props) {
  const msg = this.get(key.split('.'), props, true);

  if (!Array.isArray(msg)) {
    return msg;
  } else {
    return key;
  }
}

export default function (locale) {
  return new Messages(msgData, locale);
};
