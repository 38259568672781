import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ProfilePicture, { SIZES } from '../picture/ProfilePicture';
import { pTextFieldLeft } from '../../styles/text';
import { Left } from './FeedModalComponents';
import TextareaAutosize from 'react-autosize-textarea';
import SmallButton from '../button/SmallButton';

const PostInput = ({
  loading = false,
  onChange,
  onSubmit,
  placeholder = '',
  submitText = '',
  userId,
  value,
}) => {
  const inputRef = React.createRef();

  const focusOnField = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <Container>
      <Row>
        <Left>
          <ProfilePicture userId={userId} size={SIZES.MINI} flat />
        </Left>
        <Main onClick={focusOnField}>
          <CustomTextArea
            maxRows={20}
            maxLength={1000}
            name={'postInput'}
            type={'text'}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            disabled={loading}
            ref={inputRef}
          />
        </Main>
      </Row>
      <Row style={{ justifyContent: 'flex-end', marginTop: '15px' }}>
        <SmallButton
          text={submitText}
          type="secondary"
          onClick={onSubmit}
          disabled={!value}
          loading={loading}
        />
      </Row>
    </Container>
  );
};

PostInput.propTypes = {
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  submitText: PropTypes.string,
  userId: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default PostInput;

const Container = styled.div``;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const CustomTextArea = styled(TextareaAutosize)`
  ${pTextFieldLeft}
  width: 100%;
  resize: none;
  border: none;
  background-color: transparent;
  margin-bottom: 10px;
`;

const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border: solid 1px ${props => props.theme.colors.soft1};
  background-color: ${props => props.theme.colors.soft4};
  border-radius: 8px;
  border-top-left-radius: 0px;
  padding: 7px 10px 8px 10px;
`;
