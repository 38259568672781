import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMessageGetter } from 'react-message-context';
import ButtonIcon from '../button-icon/ButtonIcon';
import ResponsiveStickyContainer from './ResponsiveStickyContainer';
import useGoBack from '../../hooks/useGoBack';

const DEFAULT_TRESHOLD = 255;

const FixedFadingBack = ({ treshold = DEFAULT_TRESHOLD }) => {
  const goBack = useGoBack();
  const messages = useMessageGetter();
  const [alpha, setAlpha] = useState(0.0);

  useLayoutEffect(() => {
    const handleScroll = () => {
      setAlpha(() => Math.max(0.0, Math.min(1.0, window.scrollY / treshold)));
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [treshold]);

  return (
    <ResponsiveStickyContainer>
      {
        <ButtonIcon
          onClick={goBack}
          type="arrowLeft"
          label={messages('general.button.back')}
          bg
          alpha={alpha}
        />
      }
    </ResponsiveStickyContainer>
  );
};

FixedFadingBack.propTypes = {
  treshold: PropTypes.number,
};

export default FixedFadingBack;
