import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMessageGetter } from 'react-message-context';
import { Constants } from 'isomorphic';
import { format, parseISO } from 'date-fns';
import {
  challengeIconPositive,
  hatchingIconPositive,
  lockColour,
  openLockColour,
} from '../../../assets/images';
import { pNegative, labelLeftNegative, labelLeftWeak, pWeak } from '../../../styles/text';
import PageSegment from '../../container/PageSegment';
import { assureNoPrefix } from 'isomorphic/util';

// TODO: Merge common contents with "./GroupInfo.jsx"
const GroupInfoCondensed = ({
  challengeId,
  challengeName,
  status,
  language,
  region,
  startDate,
  inviteOnly,
}) => {
  const messages = useMessageGetter();
  challengeId = assureNoPrefix('challenge_', challengeId);

  return (
    <PageSegment title={messages('pages.group.info.title')}>
      <Item
        iconSrc={challengeIconPositive}
        title={messages('pages.group.info.challenge.label')}
        titleHighlight={challengeName}
        content={messages(`data.challenge.${challengeId}.info`)}
      />
      {status === Constants.GroupStates.hatching && (
        <Item
          iconSrc={hatchingIconPositive}
          title={messages('pages.group.info.status.label')}
          titleHighlight={messages('constants.group_status', {
            STATUS: status,
          })}
          content={messages('pages.group.info.condensed.status.hatching.desc', {
            startDateString: format(parseISO(startDate), 'd MMMM'),
          })}
        />
      )}
      <Item
        iconSrc={inviteOnly ? lockColour : openLockColour}
        title={messages('pages.group.info.access.title')}
        titleHighlight={messages(
          `pages.group.info.access.label.${inviteOnly ? 'inviteOnly' : 'open'}`,
        )}
        content={messages(
          `pages.group.settings.input.access.modal.${
            inviteOnly ? 'invitation' : 'open'
          }.description`,
        )}
      />
      <Item
        weak
        title={messages('pages.group.info.condensed.discussion.title')}
        titleHighlight={messages('pages.group.info.condensed.discussion.label', { language })}
        content={messages('pages.group.info.discussion.desc', { language })}
      />
      <Item
        weak
        title={messages('pages.group.info.region.title')}
        titleHighlight={messages('pages.group.info.condensed.region.label', { region })}
        content={messages('pages.group.info.region.desc', { region })}
      />
    </PageSegment>
  );
};

GroupInfoCondensed.propTypes = {
  challengeId: PropTypes.string.isRequired,
  challengeName: PropTypes.string.isRequired,
  challengeDesc: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  inviteOnly: PropTypes.bool.isRequired,
};

export default GroupInfoCondensed;

const Item = ({ title, titleHighlight, iconSrc, content, weak = false }) => {
  return (
    <ListItem bg={iconSrc}>
      <Title weak={weak}>{`${title} — `}</Title>
      <Label weak={weak}>{titleHighlight}</Label>
      {!weak && <Content>{content}</Content>}
    </ListItem>
  );
};

Item.propTypes = {
  title: PropTypes.string.isRequired,
  titleHighlight: PropTypes.string.isRequired,
  iconSrc: PropTypes.string,
  content: PropTypes.string,
  weak: PropTypes.bool,
};

export const Container = styled.div`
  text-align: left;
`;

const ListItem = styled.div.attrs(props => ({
  style: {
    ...(props.bg ? { backgroundImage: `url(${props.bg})` } : {}),
  },
}))`
  padding-left: 40px;
  position: relative;
  background-repeat: no-repeat;
  background-size: 30px 30px;
  background-position: left top;
`;

const Content = styled.p`
  ${pNegative}
  margin: 5px 0 15px 0;
`;

const Title = styled.span`
  ${props => (!props.weak ? pNegative : pWeak)};
  text-transform: capitalize;
`;

const Label = styled.span`
  ${props => (!props.weak ? labelLeftNegative : labelLeftWeak)};
`;
