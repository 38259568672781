export function intToBoolean(int) {
  const parsedInt = parseInt(int);
  if (parsedInt > 0) {
    return true;
  } else {
    return false;
  }
}

export function booleanToInt(bool) {
  if (bool) {
    return 1;
  } else {
    return 0;
  }
}

export function getName(member) {
  const givenName = member.givenName || '';
  const familyName = member.familyName ? member.familyName.charAt(0) : '';
  return `${givenName} ${familyName}`;
}

export const composePhoneNumber = (countryCallCode, phoneNumber) => {
  const callCode = `+${countryCallCode}`;
  if (phoneNumber.startsWith(callCode)) {
    return phoneNumber;
  } else {
    if (phoneNumber[0] === '0') {
      return `${callCode}${phoneNumber.substr(1)}`;
    } else {
      return `${callCode}${phoneNumber}`;
    }
  }
};
