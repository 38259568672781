import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import elements from '../../styles/elements';
import media from '../../styles/media';

const Middle = ({ children }) => {
  return <Container>{children}</Container>;
};

Middle.propTypes = {
  children: PropTypes.node,
};

export default Middle;

export const Container = styled.div`
  /* this holds the padding for the footer bar. Otherwise content would go under it. */
  padding-bottom: ${elements.navBarHeightPx}px;
  display: flex;
  flex-direction: column;

  ${media.desktop`
    padding-bottom: 0;
  `}
`;
