import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { ThemeProvider } from 'styled-components';
import { useMessageGetter } from 'react-message-context';
import { Link } from 'react-router-dom';
import Auth from '@aws-amplify/auth';
import navigation from '../../functionalities/navigation';
import Middle from '../../components/middle/Middle';
import Button from '../../components/button/Button';
import GroupCard from '../../components/data-cards/GroupCard';
import PathBar from '../../components/top/pathBar/PathBar';
import { PropTypes } from 'prop-types';
import { theme } from '../../theme';
import CenteredContent from '../../components/container/CenteredContent';
import ProfileHeader from '../../components/headers/ProfileHeader';
import Center from '../../components/container/Center';
import H3Centered from '../../components/texts/H3Centered';
import { mapProfileGroupToGroupCard } from '../../context/mappers';
import { EVENTS, loadProfileData, loadUser } from '../../context/actions';
import { useApp } from '../../context/appContext';
import moment from 'moment';
import { compareString } from '../../util/common';
import { getName } from '../../util/user';
import { useTrackPageView } from '../../hooks/useTrackPageView';
import { HistoryShape } from '../../util/shapes';
import Section from '../../components/container/Section';
import { interpolate } from '../../util/text';
import { settingsPositive } from '../../assets/images';
import BigParagraph, { BigParagraphIcon } from '../../components/texts/paragraphs/BigParagraph';
import Box from '../../components/container/Box';

const thisPage = 'user';

const UserPage = ({ history, exiting, wrapper: Wrapper }) => {
  const [state, dispatch] = useApp();

  if (!exiting) navigation.setPage(thisPage);

  useTrackPageView();

  useEffect(() => {
    async function load() {
      const profileData = await loadProfileData(dispatch);
      if (profileData === null) {
        history.replace('/404');
      }

      // Load user data to ensure latest information is present
      loadUser(dispatch, true);
    }
    load();
  }, [dispatch, history]);

  const signOut = async () => {
    try {
      await Auth.signOut();
      dispatch([EVENTS.LOGOUT]);
      history.push('/');
    } catch (error) {
      console.log('Error signing out');
    }
  };

  const messages = useMessageGetter();

  const actions = [
    {
      type: 'settings',
      label: messages('header.action.label.settings'),
      onClick: () => history.push('/user/settings'),
    },
  ];

  const { user, profile, isLoadedProfile } = state;
  if (!isLoadedProfile) {
    return <Wrapper>{null}</Wrapper>;
  }

  const groups = Object.values(profile.groups).map(mapProfileGroupToGroupCard);
  groups.sort((g1, g2) => compareString(g2.startDate, g1.startDate));

  const joinDate = moment(user['custom:created_at'], 'x').format('MMM D, YYYY');
  return (
    <Wrapper>
      <ThemeProvider theme={theme('white')}>
        <Fragment>
          <PathBar pageTitle={messages('pages.user.header.title')} actions={actions} level={0} />
          <Middle>
            <ProfileHeader
              givenName={user.givenName || ''}
              familyName={user.familyName || ''}
              userId={user.sub || ''}
              joinDate={joinDate}
            />
            <Centered>
              <Box margin="50px 0" hidden={groups.length > 0}>
                <Section title={messages('pages.user.no_groups.title')} show={groups.length === 0}>
                  <BigParagraph>
                    {interpolate(messages('pages.user.no_groups.content'), {
                      $1: (
                        <BigParagraphIcon
                          src={settingsPositive}
                          alt={messages('header.action.label.settings')}
                        />
                      ),
                    })}
                  </BigParagraph>
                </Section>
              </Box>
              {groups.length > 0 && <H3Centered>{messages('pages.user.header.groups')}</H3Centered>}
              {groups.map(group => (
                <ThemeProvider theme={theme(group.theme)} key={group.id}>
                  <Link to={`/challenges/${group.challengeId}/group/${group.id.substring(6)}`}>
                    <GroupCard
                      captain={group.captain}
                      challengeId={group.challengeId}
                      challengeName={messages('general.challenge.csn', { csn: group.csn })}
                      chartData={group.chartData}
                      durationDays={group.durationDays}
                      groupName={
                        group.name ||
                        messages('data.group.defaultName', { captainName: getName(group.captain) })
                      }
                      groupReductionKg={group.groupReductionKg}
                      groupYearlyReductionTargetKg={group.groupYearlyReductionTargetKg}
                      memberCount={group.memberCount}
                      personalReductionKg={group.personalReductionKg}
                      startDate={group.startDate}
                      groupId={group.id}
                    />
                  </Link>
                </ThemeProvider>
              ))}
              <Center>
                <Button secondary fatal onClick={signOut}>
                  {messages('pages.user.actions.signout.title')}
                </Button>
              </Center>
            </Centered>
          </Middle>
        </Fragment>
      </ThemeProvider>
    </Wrapper>
  );
};

UserPage.propTypes = {
  history: HistoryShape.isRequired,
  exiting: PropTypes.bool,
  wrapper: PropTypes.func,
};

export default UserPage;

const Centered = styled(CenteredContent)`
  padding: 0 15px;
`;
