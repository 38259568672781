// Converts the API data to proper format. I think this should be done in apis module, but it's not merged yet :).
export default function convertChallenges(data) {
  const newData = data.slice(0);

  newData.forEach(
    instance =>
      (instance.groups = instance.groups
        ? Object.keys(instance.groups).map(key => instance.groups[key])
        : []),
  );

  return newData;
}
