/**
 * Compare strings and return sign of comparison result.
 *
 * @param {string} s1
 * @param {string} s2
 * @return Sign of s1 - s2.
 */
export const compareString = (s1, s2) => {
  if (s1 < s2) {
    return -1;
  } else if (s1 > s2) {
    return 1;
  } else {
    return 0;
  }
};

/**
 * Convert a 3- or 6-digit hex color code into rgba() format.
 *
 * @param {string} hex Hex color code with leading hash character.
 * @param {string} alpha Optional alpha, default 1.0
 */
export const hexToRGBA = (hex, alpha = '1.0') => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')}, ${alpha})`;
  }
  throw new Error('Bad Hex');
};

/**
 * Parse a string with a quantity followed by unit into a tuple with numeric quantity and string unit.
 *
 * @param {string} size
 * @return {[number, string]} Parsed tuple.
 */
export const explodeSizeWithUnit = size => {
  const number = parseFloat(size);
  const unit = size.match(/[\d.\-+]*\s*(.*)/)[1] || '';
  return [number, unit];
};

/**
 * Multiply a string with a quantity followed by unit, without modifying the unit.
 * Spaces before the unit will be removed.
 *
 * @param {string} size
 * @param {number} multiplier
 */
export const multiplySizeWithUnit = (size, multiplier) => {
  const [number, unit] = explodeSizeWithUnit(size);
  return `${number * multiplier}${unit}`;
};

/**
 * Value wether or not app is launched from home screen as standalone app
 */
export const isStandalone = () =>
  !!navigator.standalone || window.matchMedia('(display-mode: standalone)').matches;
