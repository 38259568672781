import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { h2LeftNegative, labelLeftNegative, pNegative } from '../../../styles/text';
import { smallCheckmark } from '../../../assets/images';

const WizardList = ({ title, data = [] }) => {
  if (data.length === 0) {
    // TODO: Maybe in future add a "no-data" row
    return null;
  }

  return (
    <Container>
      <Title>{title}</Title>
      {data.map((rowData, idx) => (
        <WizardListItem key={idx} label={rowData.label} desc={rowData.desc} />
      ))}
    </Container>
  );
};

WizardList.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      desc: PropTypes.string,
    }),
  ),
};

export default WizardList;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 30px;
`;

const Title = styled.h2`
  ${h2LeftNegative};
`;

const WizardListItem = ({ label, desc }) => {
  return (
    <ItemContainer>
      <ItemLabel>{label}</ItemLabel>
      {desc && <ItemDesc>{desc}</ItemDesc>}
    </ItemContainer>
  );
};

WizardListItem.propTypes = {
  label: PropTypes.string.isRequired,
  desc: PropTypes.string,
};

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-image: url(${smallCheckmark});
  background-repeat: no-repeat;
  background-position: top left;
  padding-left: 18px;
  margin-bottom: 15px;
`;

const ItemLabel = styled.label`
  ${labelLeftNegative};
`;

const ItemDesc = styled.p`
  ${pNegative};
  margin-top: 5px;
  margin-bottom: 0;
`;
