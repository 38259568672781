import React from 'react';
import { Router } from 'react-router-dom';
import * as history from 'history';
import {
  HomePage,
  Error404Page,
  Error500Page,
  PostPage,
  PostReactionsPage,
  UserPage,
  MessagesPage,
} from './pages';
import BlogListPage from './blog/BlogListPage';
import BlogArticleModal from './blog/BlogArticleModal';
import ChallengesPage from './pages/challenges/ChallengesPage';
import GroupPage from './pages/group/GroupPage';
import ChallengeRemindersPage from './pages/messages/challengeReminders/ChallengeRemindersPage';
import ChallengePage from './pages/challenges/_short_name_/ChallengePage';
import FactAndMathPage from './pages/challenges/_short_name_/FactAndMathPage';
import SecondaryLoginOptionWizard from './pages/wizards/SecondaryLoginOptionWizard';
import UserSettingsPage from './pages/user/settings/UserSettingsPage';
import GroupSettingsPage from './pages/group/settings/GroupSettingsPage';
import GroupInvitePage from './pages/group/invite/GroupInvitePage';
import GroupFeedPage from './pages/group/GroupFeedPage';
import InvitationPage from './pages/invitation/InvitationPage';
import GroupMembersPage from './pages/group/members/GroupMembersPage';
import GroupFullStatsPage from './pages/group/full-stats/GroupFullStatsPage';
import MainRoutes from './components/routing/MainRoutes';
import MainRoute from './components/routing/MainRoute';
import TourWizard from './pages/wizards/TourWizard';
import PrivacyPolicyPage from './pages/legal/PrivacyPolicyPage';
import TermsOfServicePage from './pages/legal/TermsOfServicePage';
import UserBaselinePage from './pages/group/baseline/UserBaselinePage';
import JoinGroupWizard from './pages/group/join/JoinGroupWizard';
import ChallengeIntroWizard from './pages/challenges/intro/ChallengeIntroWizard';
import CreateGroupWizard from './pages/challenges/CreateGroupWizard';
import DailyAnswerWizard from './pages/group/answer/DailyAnswerWizard';
import ModalRoutes from './components/routing/ModalRoutes';
import AuthWizard from './pages/wizards/auth/AuthWizard';

const customHistory = history.createBrowserHistory();

const modals = [
  { path: '/blog/:slug', Component: BlogArticleModal, layer: 1, exact: true },
  { path: '/:challengeId/:id/a', Component: DailyAnswerWizard, layer: 1, exact: true },
  {
    path: '/challenges/:challengeId/intro',
    Component: ChallengeIntroWizard,
    layer: 1,
    exact: true,
  },
  { path: '/challenges/:challengeId/create', Component: CreateGroupWizard, layer: 1, exact: true },
  {
    path: '/challenges/:challengeId/group/:groupId/posts',
    Component: GroupFeedPage,
    layer: 1,
    exact: true,
  },
  {
    path: '/challenges/:challengeId/group/:groupId/settings',
    Component: GroupSettingsPage,
    layer: 1,
    exact: true,
  },
  {
    path: '/challenges/:challengeId/group/:groupId/join',
    Component: JoinGroupWizard,
    layer: 1,
    exact: true,
  },
  {
    path: '/challenges/:challengeId/group/:groupId/invite',
    Component: GroupInvitePage,
    layer: 1,
    exact: true,
  },
  {
    path: '/challenges/:challengeId/group/:groupId/members',
    Component: GroupMembersPage,
    layer: 3,
    exact: true,
  },
  {
    path: '/challenges/:challengeId/group/:groupId/stats',
    Component: GroupFullStatsPage,
    layer: 2,
    exact: true,
  },
  {
    path: '/challenges/:challengeId/group/:groupId/posts/:postId',
    Component: PostPage,
    layer: 2,
    exact: true,
  },
  {
    path: '/messages/:challengeId/group/:groupId/posts/:postId',
    Component: PostPage,
    layer: 2,
    exact: true,
  },
  {
    path: '/challenges/:challengeId/group/:groupId/baseline',
    Component: UserBaselinePage,
    layer: 3,
    exact: true,
  },
  {
    path: '/challenges/:challengeId/group/:groupId/posts/:postId/reactions/:reactionsId',
    Component: PostReactionsPage,
    layer: 4,
    exact: true,
  },
  {
    path: '/messages/:challengeId/group/:groupId/posts/:postId/reactions/:reactionsId',
    Component: PostReactionsPage,
    layer: 4,
    exact: true,
  },
  { path: '/j/:inviteHash', Component: InvitationPage, layer: 1, exact: true },
  { path: '/user/settings', Component: UserSettingsPage, layer: 1, exact: true },
  { path: '/user/settings/:type', Component: SecondaryLoginOptionWizard, layer: 2, exact: true },
  { path: '/auth', Component: AuthWizard, layer: 1, exact: true },
  { path: '/auth/privacy-policy', Component: PrivacyPolicyPage, layer: 1, exact: true },
  { path: '/auth/terms-of-service', Component: TermsOfServicePage, layer: 1, exact: true },
  { path: '/privacy-policy', Component: PrivacyPolicyPage, layer: 1, exact: true },
  { path: '/terms-of-service', Component: TermsOfServicePage, layer: 1, exact: true },
  { path: '/404', Component: Error404Page, layer: 1, exact: true },
  { path: '/500', Component: Error500Page, layer: 1, exact: true },
];

const AppRouter = () => {
  return (
    <Router history={customHistory}>
      <MainRoutes>
        <MainRoute path="/challenges" component={ChallengesPage} exact horizontalPosition={1} />
        <MainRoute
          path="/challenges/:challengeId"
          component={ChallengePage}
          exact
          horizontalPosition={2}
        />
        <MainRoute
          path="/challenges/:challengeId/fact-and-math"
          component={FactAndMathPage}
          exact
          horizontalPosition={3}
        />
        <MainRoute
          path="/challenges/:challengeId/group/:id"
          component={GroupPage}
          horizontalPosition={4}
        />
        <MainRoute path="/messages" component={MessagesPage} exact horizontalPosition={5} />
        <MainRoute
          path="/messages/challenge-reminders"
          component={ChallengeRemindersPage}
          exact
          horizontalPosition={6}
        />
        <MainRoute path="/user" component={UserPage} exact horizontalPosition={6} />
        <MainRoute path="/blog" component={BlogListPage} exact horizontalPosition={7} />
        <MainRoute path="/tour" component={TourWizard} exact horizontalPosition={7} />
        <MainRoute path="/" component={HomePage} exact horizontalPosition={0} />
      </MainRoutes>
      <ModalRoutes modals={modals} />
    </Router>
  );
};

export default AppRouter;
