import React, { createContext, useContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { loadUser, registerServiceWorker } from './actions';
import { client } from '../util/wsClient';
import { appReducer, initialState } from './appContextReducer';

const AppStateContext = createContext();
const AppDispatchContext = createContext();

function AppStateProvider({ children }) {
  const [state, dispatch] = useReducer(appReducer, initialState);
  useEffect(() => {
    loadUser(dispatch);
  }, [dispatch]);

  useEffect(() => {
    registerServiceWorker(dispatch);
  }, [dispatch]);

  useEffect(() => {
    async function connect() {
      await client(dispatch);
    }
    connect();
  }, [dispatch]);

  return (
    <AppStateContext.Provider value={state}>
      <AppStateContext.Consumer>
        {values => {
          // Enable Chrome plugin "React Context DevTool" as context consumer
          if (
            process &&
            process.env &&
            process.env.REACT_APP_ENV === 'dev' &&
            window._REACT_CONTEXT_DEVTOOL
          ) {
            window._REACT_CONTEXT_DEVTOOL({
              id: 'appStateContext',
              displayName: 'Tideal App Context',
              values,
            });
          }
          return null;
        }}
      </AppStateContext.Consumer>
      <AppDispatchContext.Provider value={dispatch}>{children}</AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
}

AppStateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function useAppState() {
  const context = useContext(AppStateContext);
  if (context === undefined) {
    throw new Error('useAppState must be used within a AppStateProvider');
  }
  return context;
}

function useAppDispatch() {
  const context = useContext(AppDispatchContext);
  if (context === undefined) {
    throw new Error('useAppDispatch must be used within a AppStateProvider');
  }
  return context;
}

function useApp() {
  return [useAppState(), useAppDispatch()];
}

export { AppStateProvider, useAppState, useAppDispatch, useApp };
