import styled from 'styled-components';
import { pCenter } from '../../../styles/text';

const ButtonSplitter = styled.div`
  ${pCenter};
  margin-top: 20px;
  margin-bottom: 20px;
`;

export default ButtonSplitter;
