import { useEffect, useRef } from 'react';

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

/**
 * Hook for keeping track of the most recent value that was not `null` or `undefined`.
 */
export function useMostRecentValue(value) {
  const ref = useRef(value);
  if (value != null) ref.current = value;
  return ref.current;
}
