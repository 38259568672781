import React, { useCallback } from 'react';
import { getCreateGroupConfig } from '../../functionalities/apis';
import { useTrackPageView } from '../../hooks/useTrackPageView';
import { HistoryShape, MatchShapeOfParams } from '../../util/shapes';
import Wizard from '../wizards/Wizard';

const CreateGroupWizard = ({ match, history }) => {
  useTrackPageView();

  const { challengeId } = match.params;
  const loadConfig = useCallback(async () => await getCreateGroupConfig(challengeId), [
    challengeId,
  ]);
  return (
    <Wizard
      history={history}
      match={match}
      loadConfig={loadConfig}
      onClose={() => history.push(`/challenges/${challengeId}`)}
    />
  );
};

CreateGroupWizard.propTypes = {
  match: MatchShapeOfParams(['challengeId']),
  history: HistoryShape.isRequired,
};

export default CreateGroupWizard;
