import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { Constants } from 'isomorphic';

import CombinedProgressCard from './CombinedProgressCard';
import GroupProgressCard from './GroupProgressCard';
import {
  GroupGoalShape,
  GroupProgressShape,
  PersonalGoalShape,
  UserShape,
} from '../../../util/shapes';

export default function GroupPageCard(props) {
  const { personalGoalData } = props;
  const CardComponent = personalGoalData ? CombinedProgressCard : GroupProgressCard;
  return (
    <Container>
      <CardComponent {...props} />
    </Container>
  );
}

GroupPageCard.propTypes = {
  groupGoalData: GroupGoalShape.isRequired,
  groupProgressData: GroupProgressShape,
  personalGoalData: PersonalGoalShape,
  groupDurationDays: PropTypes.number.isRequired,
  groupStartDate: PropTypes.string.isRequired,
  messages: PropTypes.func.isRequired,
  status: PropTypes.oneOf(Object.values(Constants.GroupStates)),
  reductionLeader: UserShape,
  footprintLeader: UserShape,
  fullStatsLink: PropTypes.string,
};

const Container = styled.div`
  transform: translateY(-50px);
  margin: 0 0 -75px 0;
`;
