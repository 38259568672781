// https://dev.to/somedood/bitmasks-a-very-esoteric-and-impractical-way-of-managing-booleans-1hlf

/**
 * Extracts the "truthiness" of a bit given a position
 * @param {number} binaryNum - The number to query from
 * @param {number} position - This is the zero-indexed position of the bit from the right
 * @returns {boolean} - "Truthiness" of the bit we're interested in
 */

export const getBooleanFrom = (binaryNum, position) => {
  // Bit-shifts according to zero-indexed position
  const mask = 1 << position;
  const query = binaryNum & mask;
  return Boolean(query);
}

/**
 * Returns the new number as a result of setting the selected bits
 * @param {number} binaryNum - The number to query from
 * @param {number} mask - This is the bitmask that selects the bits to be set
 * @returns {number} - New number as a result of setting the selected bits
 */
export const setBoolean = (binaryNum, position, value) => {
  // Bit-shifts according to zero-indexed position
  const mask = 1 << position;
  return value ? binaryNum | mask : binaryNum & ~mask;
}
