import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { h2Center, pCenter } from '../../styles/text';
import Modal from './Modal';
import { useKeyPress } from '../../hooks/useKeyPress';

const ModalConfirm = ({ title, description, options = [], x = 0, y = 0, onCancel = () => {} }) => {
  useKeyPress('Escape', onCancel);

  return (
    <Modal x={x} y={y}>
      <Content>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Content>
      {options.length > 0 && (
        <Actions>
          {options.map(option => (
            <Action key={option.key} onClick={option.onClick}>
              {option.label}
            </Action>
          ))}
        </Actions>
      )}
    </Modal>
  );
};

ModalConfirm.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  ),
  onCancel: PropTypes.func,
  /** In views wider than 100%, adds way to move the modal to view */
  x: PropTypes.number,
  /** In views taller than 100%, adds way to move the modal to view */
  y: PropTypes.number,
};

export default ModalConfirm;

const Content = styled.div`
  flex: 1;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  white-space: pre-line;
`;

const Title = styled.h2`
  ${h2Center};
`;

const Description = styled.p`
  ${pCenter};
`;

const Actions = styled.div`
  height: 40px;
  border-top: 1px solid #d8d8d8; /* XXX: What color is this? */
  display: flex;
  flex-direction: row;
`;

const Action = styled.button`
  ${h2Center};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background-color: transparent;
  &:not(:first-child) {
    border-left: 1px solid #d8d8d8; /* XXX: What color is this? */
    border-bottom-right-radius: 4px;
  }
  &:first-child {
    border-bottom-left-radius: 4px;
  }
  &:hover {
    cursor: pointer;
  }
`;
