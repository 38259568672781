import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { Groups } from 'isomorphic';
import ModalFullscreen, { ModalContent } from '../../components/modal/ModalFullscreen';
import { logoColor } from '../../assets/images';
import { theme } from '../../theme';
import { useApp } from '../../context/appContext';
import { loadGroupByInviteHash } from '../../context/actions';
import ModalPageTitle from '../../components/modal/ModalPageTitle';
import ModalIllustration from '../../components/modal/ModalIllustration';
import ModalPageDescription from '../../components/modal/ModalPageDescription';
import RoundMemberList from '../../components/groups/member-list/RoundMemberList';
import Button from '../../components/button/Button';
import GroupInfoCondensed from '../../components/groups/group-info/GroupInfoCondensed';
import { useMessageGetter } from 'react-message-context';
import SimpleFeedItem from '../../components/feeds/SimpleFeedItem';
import PageSegment from '../../components/container/PageSegment';
import Box from '../../components/container/Box';
import { useTrackPageView } from '../../hooks/useTrackPageView';
import { HistoryShape, MatchShapeOfParams } from '../../util/shapes';
import { assureNoPrefix } from 'isomorphic/util';
import { storeInvitation } from '../../functionalities/invitations';

const InvitationModal = ({ onClose, actions, children }) => (
  <ThemeProvider theme={theme('white')}>
    <ModalFullscreen title="" titleIcon={logoColor} onClose={onClose} actions={actions}>
      {children}
    </ModalFullscreen>
  </ThemeProvider>
);

InvitationModal.propTypes = {
  onClose: PropTypes.func,
  actions: PropTypes.arrayOf(PropTypes.node),
  children: PropTypes.node,
};

const InvitationPage = ({ match, history }) => {
  const { inviteHash } = match.params;
  const [groupId, setGroupId] = useState();
  const [app, dispatch] = useApp();
  const user = app.user;
  const messages = useMessageGetter();

  const onClose = () => {
    history.push('/');
  };

  useTrackPageView();

  useEffect(() => {
    async function load() {
      const groupId = await loadGroupByInviteHash(dispatch, inviteHash);
      setGroupId(groupId);
    }
    load();
  }, [dispatch, inviteHash]);

  if (!groupId) {
    return <InvitationModal onClose={onClose} />;
  }

  const group = app[groupId];
  const illustration = require(`../../assets/images/${group.challenge.short_name}/invitation.svg`)
    .default;

  storeInvitation(group, inviteHash);

  const challengeId = assureNoPrefix('challenge_', group.challenge.id);
  const onJoin = () => history.push(`/challenges/${challengeId}/group/${groupId}/join`);

  const actions = [
    <Button secondary key={'back'} onClick={onClose} disabled={false}>
      {messages('pages.invite.actions.back')}
    </Button>,
    <Button primary key={'save'} onClick={onJoin} disabled={false}>
      {messages('pages.invite.actions.join')}
    </Button>,
  ];

  const status = Groups.getGroupStatus(group.startDate, group.durationDays);
  const isUserMember = user.isLoggedIn && group.members[`user_${user.sub}`];

  const challengeName = messages(`data.challenge.${challengeId}.title`);

  return (
    <InvitationModal onClose={onClose} actions={actions}>
      <ModalContent style={{ textAlign: 'center', paddingBottom: '170px' }}>
        <ModalIllustration alt="" src={illustration} />
        <ModalPageTitle>
          {messages('pages.invite.header.title', { captainName: group.captain.givenName })}
        </ModalPageTitle>
        <Box margin="0 0 15px 0">
          <RoundMemberList
            captain={group.captain}
            members={Object.values(group.members)}
            isUserMember={isUserMember}
          />
        </Box>
        <ModalPageDescription style={{ padding: '0 15px' }}>
          {messages('pages.invite.header.subtitle')}
        </ModalPageDescription>
        {group.welcomeMessage && (
          <PageSegment
            title={messages('pages.invite.segment.message')}
            style={{ marginBottom: '50px' }}
          >
            <SimpleFeedItem user={group.captain} text={group.welcomeMessage} />
          </PageSegment>
        )}
        <GroupInfoCondensed
          challengeId={group.challenge.id}
          challengeName={challengeName}
          challengeDesc={group.challenge.short_desc || ''}
          status={status}
          startDate={group.startDate}
          language={group.discussionLocale}
          region={group.dataLocale}
          inviteOnly={group.access === 'invite'}
        />
      </ModalContent>
    </InvitationModal>
  );
};

InvitationPage.propTypes = {
  history: HistoryShape.isRequired,
  match: MatchShapeOfParams(['inviteHash']),
};

export default InvitationPage;
