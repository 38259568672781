import commutingWalk from './commuting/walk.svg';
import commutingBicycle from './commuting/bicycle.svg';
import commutingTram from './commuting/tram.svg';
import commutingMetro from './commuting/metro.svg';
import commutingTrain from './commuting/train.svg';
import commutingEScooter from './commuting/escooter.svg';
import commutingLDBus from './commuting/bus-long-distance.svg';
import commutingSDBus from './commuting/bus-short-distance.svg';
import commutingScooter from './commuting/scooter.svg';
import commutingMotorcycle from './commuting/motorcycle.svg';
import commutingMicrocar from './commuting/microcar.svg';
import commutingCarElectric from './commuting/car-electric.svg';
import commutingCarElectricZero from './commuting/car-electric-zero.svg';
import commutingCarHybrid from './commuting/car-hybrid.svg';
import commutingCarHybridZero from './commuting/car-hybrid-zero.svg';
import commutingCarDiesel from './commuting/car-diesel.svg';
import commutingCarGasoline from './commuting/car-gasoline.svg';
import commutingCarCustom from './commuting/car-general.svg';
import equation from './equation.svg';
import co2e from './co-2-e.svg';
import commuting from './commuting-icon.svg';

import military from './hot_water/military.svg';
import efficient from './hot_water/efficient.svg';
import relaxed from './hot_water/relaxed.svg';
import lengthy from './hot_water/lengthy.svg';
import monsoon from './hot_water/monsoon.svg';
import manual from './hot_water/manual.svg';
import wood from './hot_water/wood.svg';
import geothermal from './hot_water/geothermal.svg';
import naturalgas from './hot_water/naturalgas.svg';
import oil from './hot_water/oil.svg';
import districtheating from './hot_water/districtheating.svg';
import electricity from './hot_water/electricity.svg';
import dunno from './hot_water/dunno.svg';

import shower from './hot_water/shower.svg';
import timer from './hot_water/manual.svg';
import minus from './minus.svg';
import goal from './goal.svg';
import concreteAction from './concrete-action.svg';
import { electricityZero, geothermalZero } from './hot_water';

export { default as activeIcon } from './active-icon.svg';
export { default as alumniIcon } from './alumni-icon.svg';
export { default as arrowLeft } from './arrow-left.svg';
export { default as arrowLeftDisabled } from './arrow-left-disabled.svg';
export { default as arrowLeftPositive } from './arrow-left-positive.svg';
export { default as arrowRight } from './arrow-right.svg';
export { default as arrowRightSmallNegative } from './arrow-right-small-negative.svg';
export { default as arrowRightDisabled } from './arrow-right-disabled.svg';
export { default as arrowRightPositive } from './arrow-right-positive.svg';
export { default as arrowUp } from './icons-arrow-up.svg';
export { default as blueBg } from './1-bg.svg';
export { default as challengeIcon } from './challenge-icon.svg';
export { default as challengeIconPositive } from './challenge-icon-positive.svg';
export { default as checkMark } from './check.svg';
export { default as comment } from './comment.svg';
export { default as copyIconPositive } from './copy-positive.svg';
export { default as dataLocaleIcon } from './data-locale-icon.svg';
export { default as emailPositive } from './email-positive.svg';
export { default as generalLightBgBottom } from './general-bg-light-bottom.svg';
export { default as generalLightBgTop } from './general-bg-light-top.svg';
export { default as groupLanguageIcon } from './group-language-icon.svg';
export { default as hatchingIcon } from './hatching-icon.svg';
export { default as hatchingIconPositive } from './hatching-icon-positive.svg';
export { default as imgChallenges } from './img-challenges.svg';
export { default as imgHifiveColor } from './img-hifive-color.svg';
export { default as imgSignup } from './img-signup.svg';
export { default as imgSignupGroup } from './img-signup-group.svg';
export { default as imgSmiley } from './img-smiley.svg';
export { default as imgSmileyColor } from './img-smiley-color.svg';
export { default as imgWinkColor } from './img-wink-color.svg';
export { default as largeCheckmark } from './large-checkmark.svg';
export { default as lightBg } from './light-bg.svg';
export { default as lightBgHeader } from './light-bg-header.svg';
export { default as logoNegative } from './logo-negative.svg';
export { default as logoPositive } from './logo-positive.svg';
export { default as logoColor } from './logo-color.svg';
export { default as manualAdjustmentIcon } from './manual-adjustment-icon.svg';
export { default as memberCountIcon } from './member-count.svg';
export { minus };
export { default as notReacted } from './not-reacted.svg';
export { default as placeholderGroup } from './placeholder-group.svg';
export { default as profilePicturePlaceholder } from './placeholder-user.svg';
export { default as settings } from './settings.svg';
export { default as settingsPositive } from './icons-settings-positive.svg';
export { default as shareApplePositive } from './share-apple-positive.svg';
export { default as sharePositive } from './share-positive.svg';
export { default as signIn } from './sign-in.svg';
export { default as smallCheckmark } from './small-checkmark.svg';
export { default as badgeCaptain } from './badge-captain.svg';
export { default as badgeCaptainLine } from './badge-captain-line.svg';
export { default as badgeIsMember } from './badge-is-member.svg';
export { default as group } from './group.svg';
export { default as trophy } from './trophy.svg';
export { default as person } from './person.svg';
export { default as postIcon } from './post.svg';
export { default as respectIcon } from './reaction-1.svg';
export { default as allReadIcon } from './empty-messages-icon.svg';
export { default as lock } from './lock.svg';
export { default as openLock } from './open-lock.svg';
export { default as lockColour } from './lock-colour.svg';
export { default as openLockColour } from './lock-open-colour.svg';
export { default as plus } from './plus.svg';
export { default as concreteAction } from './concrete-action.svg';
export { default as colorProfilePlaceholder } from './1-icons-person-color.svg';
export { default as orbitBg } from './orbit.svg';
export { default as iconPlus } from './1-icons-plus-positive.svg';
export { default as iconPlusNegative } from './1-icons-plus-negative.svg';

export function getChallengeIconSrc(iconName) {
  switch (iconName) {
    case 'walk':
      return commutingWalk;
    case 'bicycle':
      return commutingBicycle;
    case 'tram':
      return commutingTram;
    case 'metro':
      return commutingMetro;
    case 'train':
      return commutingTrain;
    case 'escooter':
      return commutingEScooter;
    case 'ldbus':
      return commutingLDBus;
    case 'sdbus':
      return commutingSDBus;
    case 'scooter':
      return commutingScooter;
    case 'motorcycle':
      return commutingMotorcycle;
    case 'microcar':
      return commutingMicrocar;
    case 'car-electric':
      return commutingCarElectric;
    case 'car-electric-zero':
      return commutingCarElectricZero;
    case 'car-hybrid':
      return commutingCarHybrid;
    case 'car-hybrid-zero':
      return commutingCarHybridZero;
    case 'car-diesel':
      return commutingCarDiesel;
    case 'car-gasoline':
      return commutingCarGasoline;
    case 'car-custom':
      return commutingCarCustom;
    case 'equation':
      return equation;
    case 'co2e':
      return co2e;
    case 'commuting':
      return commuting;
    case 'shower':
      return shower;
    case 'timer':
      return timer;
    case 'minus':
      return minus;
    case 'goal':
      return goal;
    case 'concrete-action':
      return concreteAction;
    case 'military':
      return military;
    case 'efficient':
      return efficient;
    case 'relaxed':
      return relaxed;
    case 'lengthy':
      return lengthy;
    case 'monsoon':
      return monsoon;
    case 'manual':
      return manual;
    case 'wood':
      return wood;
    case 'geothermal':
      return geothermal;
    case 'geothermal_zero':
      return geothermalZero;
    case 'naturalgas':
      return naturalgas;
    case 'oil':
      return oil;
    case 'districtheating':
      return districtheating;
    case 'electricity':
      return electricity;
    case 'electricity_zero':
      return electricityZero;
    case 'dunno':
      return dunno;
    default:
      try {
        const icon = require(`./${iconName}`).default;
        return icon;
      } catch (error) {
        return undefined;
      }
  }
}
