import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Message from './Message';

const NewMessage = props => (
  <LinkedContainer href={getUrl(props.message)} noBackground={props.noBackground}>
    <Message {...props} />
  </LinkedContainer>
);

NewMessage.propTypes = {
  message: PropTypes.string.isRequired,
  noBackground: PropTypes.bool,
};

function getUrl(message) {
  const urls = message.match(/(https?:\/\/[^\s]+)/g);
  if (urls !== null && urls[0]) {
    if (urls[0].endsWith('.')) {
      return urls[0].substring(0, urls[0].length - 1);
    } else {
      return urls[0];
    }
  } else {
    return '';
  }
}

const LinkedContainer = styled.a.attrs(({ noBackground, theme }) => ({
  style: noBackground
    ? undefined
    : {
        backgroundColor: theme.colors.soft1,
      },
}))`
  display: block;
`;

export default NewMessage;
