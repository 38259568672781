export const GET_ARTICLES = `
  query ListPosts($locale: String!) {
    listBlogPosts(locale: $locale) {
      data {
        id
        slug
        title
        ingress
        category
        theme
        image
        appHighlight
        published
      }
    }
  }
`;

export const GET_ARTICLE = `
  query GetPost($slug: String!, $locale: String!) {
    getBlogPost(where: { slug: $slug }, locale: $locale) {
      data {
        slug
        title
        ingress
        category
        theme
        body
        published
        image
        mainImageDescription
        inlineImages
        imageDescriptions
        slug
      }
    }
  }
`;

export const GET_FACT_AND_MATH = `
  query GetFactAndMath($locale: String!, $challengeId: String!) {
    getFactAndMath1(locale: $locale, where: { challengeId: $challengeId }) {
      data {
        id
        challengeId
        challengeName
        content
      }
    }
  }
`;

export const GET_PRIVACY_POLICY = `
  query GetPrivacyPolicy($locale: String!) {
    getSimpleDocument(locale: $locale, where: { kind: "privacyPolicy" }) {
      data {
        header
        heading
        content
      }
    }
  }
`;

export const GET_TERMS_OF_SERVICE = `
  query GetPrivacyPolicy($locale: String!) {
    getSimpleDocument(locale: $locale, where: { kind: "termsOfService" }) {
      data {
        header
        heading
        content
      }
    }
  }
`;

export const sortArticles = (a, b) => {
  if (!a || !a.published) return 1;
  if (!b || !b.published) return -1;
  return a.published > b.published ? -1 : 1;
};
