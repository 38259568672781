import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getBackground } from '../../styles/util';
import { SpacingShape } from '../../util/shapes';

const Card = ({
  bgImage,
  bgGradient,
  bgSize = 'auto',
  bgPosition = 'top left',
  children,
  flat = false,
  spacing = 'default',
  smallShadow = false,
}) => {
  return (
    <Container
      bgImage={bgImage}
      bgGradient={bgGradient}
      bgSize={bgSize}
      bgPosition={bgPosition}
      flat={flat}
      spacing={spacing}
      smallShadow={smallShadow}
    >
      {children}
    </Container>
  );
};

Card.propTypes = {
  bgImage: PropTypes.any,
  children: PropTypes.node,
  flat: PropTypes.bool,
  smallShadow: PropTypes.bool,
  spacing: SpacingShape,
  bgGradient: PropTypes.string,
  bgSize: PropTypes.string,
  bgPosition: PropTypes.string,
};

export default Card;

const paddings = {
  none: '0px',
  dense: '15px',
  default: '15px 20px 20px 20px',
  loose: '30px',
};

const Container = styled.div`
  width: 100%;
  border-radius: 4px;
  background-color: ${props => props.theme.card.bg};
  background-image: ${props =>
    getBackground(
      props.bgImage && `url(${props.bgImage.default || props.bgImage})`,
      props.bgGradient || props.theme.card.bgGradient,
    )};
  background-size: ${props => props.bgSize};
  background-position: ${props => props.bgPosition};
  box-shadow: ${props =>
    props.flat
      ? 'none'
      : props.smallShadow
      ? props.theme.card.shadowSmall
      : props.theme.card.shadow};
  padding: ${({ spacing }) => paddings[spacing]};
  box-sizing: border-box;
`;
