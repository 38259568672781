import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PreventBodyScroll from './PreventBodyScroll';

const Modal = ({ children, x = 0, y = 0 }) => {
  return (
    <Background x={x} y={y}>
      <PreventBodyScroll />
      <ModalContainer>{children}</ModalContainer>
    </Background>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  /** In views wider than 100%, adds way to move the modal to view */
  x: PropTypes.number,
  /** In views taller than 100%, adds way to move the modal to view */
  y: PropTypes.number,
};

export default Modal;

const Background = styled.div.attrs(props => ({
  style: {
    left: `${props.x * 100}%`,
    top: `${props.y * 100}%`,
  },
}))`
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 0;
  padding: 0;
  z-index: 253;
  background-color: rgba(20, 20, 20, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;

const ModalContainer = styled.div`
  width: 100%;
  margin: 30px;
  border-radius: 4px;
  box-shadow: 0 35px 35px -15px ${props => props.theme.colors.seachangeTextAlpha30};
  background-color: ${props => props.theme.colors.seachangeStandout};
  min-height: 150px;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  z-index: 4;
`;
