export const mapBody = (shape, values) => {
  const body = {};
  const keys = Object.keys(shape);
  for (let key of keys) {
    if (typeof shape[key] === 'object') {
      body[key] = mapBody(shape[key], values);
    } else {
      body[key] = values[shape[key]];
    }
  }
  return body;
};
