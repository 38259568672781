import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useMessageGetter } from 'react-message-context';
import navigation, { navigationParents } from '../../functionalities/navigation';
import Middle from '../../components/middle/Middle';
import PathBar from '../../components/top/pathBar/PathBar';
import ChallengeReminderBox from '../../components/messages/ChallengeReminderBox';
import NotificationsList from '../../components/notifications/NotificationsList';
import { useApp } from '../../context/appContext';
import { useTrackPageView } from '../../hooks/useTrackPageView';
import { ModalCenteredContent } from '../../components/container/CenteredContent';
import AllReadMessage from '../../components/messages/AllReadMessage';
import { getRemindersSummary } from '../../functionalities/apis';
import { loadNotifications, markNotificationRead, updateUserInfo } from '../../context/actions';
import { HistoryShape } from '../../util/shapes';

const thisPage = navigationParents.messages.page;

const MessagesPage = ({ history, exiting, wrapper: Wrapper }) => {
  if (!exiting) navigation.setPage(thisPage);

  const messages = useMessageGetter('pages.messages');

  useTrackPageView();

  const [state, setState] = useState({
    loading: true,
    remindersSummary: null,
  });

  useEffect(() => {
    async function load() {
      try {
        const remindersSummary = await getRemindersSummary();
        setState(state => ({
          ...state,
          loading: false,
          remindersSummary,
        }));
      } catch (err) {
        history.replace('/404');
      }
    }
    load();
  }, [history]);

  const [
    { notifications, nextNotificationOffset, isLoadingMoreNotifications, hasMoreNotifications },
    dispatch,
  ] = useApp();

  /**
   * Update `lastVisitedMessages` timestamp when user enters and leaves this page.
   */
  useEffect(() => {
    updateUserInfo(dispatch, { lastVisitedMessages: Date.now() });
    return () => {
      updateUserInfo(dispatch, { lastVisitedMessages: Date.now() });
    };
  }, [dispatch]);

  const loadMoreNotifications = useCallback(() => {
    if (!isLoadingMoreNotifications) {
      loadNotifications(dispatch, nextNotificationOffset);
    }
  }, [dispatch, isLoadingMoreNotifications, nextNotificationOffset]);

  const markAllRead = () => markNotificationRead('ALL', dispatch);

  const { loading, remindersSummary } = state;

  if (loading) return <Wrapper>{null}</Wrapper>;

  const unreadNotificationCount = notifications.filter(notification => !notification.read).length;
  const hasNoMessages = (notifications || []).length === 0 && remindersSummary.count === 0;
  const allRead = remindersSummary.unread === 0 && unreadNotificationCount === 0;

  return (
    <Wrapper>
      <PathBar pageTitle={messages('title')} level={1} bwHeader={true} posAbsolute={false} />
      <ModalCenteredContent>
        <Middle>
          {allRead && <AllReadMessage messagesEmpty={hasNoMessages} />}
          <ChallengeReminderBox
            unreadCount={remindersSummary.unread}
            readCount={remindersSummary.count}
            firstUnreadMsg={remindersSummary.mostRecent}
          />
          <NotificationsList
            loadMore={loadMoreNotifications}
            hasMore={hasMoreNotifications}
            notifications={notifications}
            markAllRead={markAllRead}
          />
        </Middle>
      </ModalCenteredContent>
    </Wrapper>
  );
};

MessagesPage.propTypes = {
  history: HistoryShape.isRequired,
  exiting: PropTypes.bool,
  wrapper: PropTypes.func,
};

export default MessagesPage;
