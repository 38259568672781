import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMessageGetter } from 'react-message-context';
import { Groups, Constants } from 'isomorphic';
import { format, parseISO } from 'date-fns';
import {
  challengeIcon,
  groupLanguageIcon,
  dataLocaleIcon,
  hatchingIcon,
  lock,
  openLock,
} from '../../../assets/images';
import { pNegative, h2LeftNegative, labelLeftNegative } from '../../../styles/text';
import CenteredContent from '../../container/CenteredContent';
import { assureNoPrefix } from 'isomorphic/util';

const images = require.context('../../../assets/images', true);

// TODO: Merge common contents with "./GroupInfoCondensed.jsx"
const GroupInfo = ({
  challengeId,
  challengeName,
  challengeDesc,
  status,
  language,
  region,
  startDate,
  children,
  inviteOnly,
}) => {
  const messages = useMessageGetter();
  challengeId = assureNoPrefix('challenge_', challengeId);

  return (
    <Container bgImage={images(`./${challengeId}-bg.svg`).default}>
      <Centered>
        <H2>{messages('pages.group.info.title')}</H2>
        <Item
          iconSrc={challengeIcon}
          title={messages('pages.group.info.challenge.label')}
          titleHighlight={challengeName}
          content={challengeDesc}
        />
        {status === Constants.GroupStates.hatching && (
          <Item
            iconSrc={hatchingIcon}
            title={messages('pages.group.info.status.label')}
            titleHighlight={messages('constants.group_status', {
              STATUS: status,
            })}
            content={messages('pages.group.info.status.hatching.desc', {
              startInDays: Math.abs(Groups.getGroupDay(startDate)),
              startDateString: format(parseISO(startDate), 'd MMMM'),
            })}
          />
        )}
        <Item
          iconSrc={groupLanguageIcon}
          title={messages('pages.group.info.discussion.title')}
          titleHighlight={messages('pages.group.info.discussion.label', { language })}
          content={messages('pages.group.info.discussion.desc', { language })}
        />
        <Item
          iconSrc={dataLocaleIcon}
          title={messages('pages.group.info.region.title')}
          titleHighlight={messages('pages.group.info.region.label', { region })}
          content={messages('pages.group.info.region.desc', { region })}
        />
        <Item
          iconSrc={inviteOnly ? lock : openLock}
          title={messages('pages.group.info.access.title')}
          titleHighlight={messages(
            `pages.group.info.access.label.${inviteOnly ? 'inviteOnly' : 'open'}`,
          )}
          content={messages(
            `pages.group.settings.input.access.modal.${
              inviteOnly ? 'invitation' : 'open'
            }.description`,
          )}
        />
        {children}
      </Centered>
    </Container>
  );
};

GroupInfo.propTypes = {
  challengeId: PropTypes.string.isRequired,
  challengeName: PropTypes.string.isRequired,
  challengeDesc: PropTypes.string.isRequired,
  children: PropTypes.node,
  status: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  inviteOnly: PropTypes.bool.isRequired,
};

export default GroupInfo;

const Item = ({ title, titleHighlight, iconSrc, content }) => (
  <ListItem bg={iconSrc}>
    <TITLE>{`${title} — `}</TITLE>
    <LABEL>{titleHighlight}</LABEL>
    <P>{content}</P>
  </ListItem>
);

Item.propTypes = {
  title: PropTypes.string.isRequired,
  titleHighlight: PropTypes.string.isRequired,
  iconSrc: PropTypes.string.isRequired,
  content: PropTypes.string,
};

export const Container = styled.div.attrs(props => ({
  style: {
    backgroundImage: `url(${props.bgImage})`,
  },
}))`
  background-color: ${props => props.theme.groupInfo.bgColor};
  background-position: ${props => props.theme.groupInfo.bgPosition};
  color: ${props => props.theme.colors.dull7};
`;

const Centered = styled(CenteredContent)`
  padding: 30px;
`;

const ListItem = styled.div.attrs(props => ({
  style: {
    backgroundImage: `url(${props.bg})`,
  },
}))`
  padding-left: 18px;
  position: relative;
  background-repeat: no-repeat;
  background-size: 11px 11px;
  background-position: left 4px;
`;

const P = styled.p`
  ${pNegative}
  margin: 5px 0 15px 0;
`;
const TITLE = styled.span`
  ${pNegative};
  text-transform: capitalize;
`;
const LABEL = styled.span`
  ${labelLeftNegative}
`;
const H2 = styled.h2`
  ${h2LeftNegative}
`;
