import React, { useMemo, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  bigNumberCenterNegative,
  bigNumberUnitNegative,
  labelLeftNegative,
} from '../../../../styles/text';
import mask from '../../../../assets/images/picker-wheel-mask.svg';
import { manualAdjustmentIcon, getChallengeIconSrc } from '../../../../assets/images';
import ButtonIcon from '../../../button-icon/ButtonIcon';

const STEP_HEIGHT = 70;
const STEPS_SHOWN = 3;
const WHEEL_WIDTH = 130;
const ICON_SIZE = 34;

const GigaSelect = ({
  initialValue,
  min,
  max,
  step = 1,
  onMounted,
  name,
  unit = '',
  unitSecondary = '',
  icon,
}) => {
  const ref = useRef(null);
  const values = useMemo(() => {
    const amount = (max - min) / step + 1;
    const valuesArray = new Array(amount);
    for (let i = 0; i < valuesArray.length; i++) {
      valuesArray[i] = min + step * i;
    }
    return valuesArray;
  }, [min, max, step]);
  const [atMin, setAtMin] = useState(false);
  const [atMax, setAtMax] = useState(false);

  useLayoutEffect(() => {
    if (ref.current) {
      const initialValueIndex = values.indexOf(initialValue);
      ref.current.scrollTop = initialValueIndex * STEP_HEIGHT;

      if (ref.current.scrollTop === 0) {
        setAtMin(true);
      }

      // Not ideal but used to bypass heavy triggering of onChange on scroll events
      if (onMounted) {
        onMounted(ref, name);
      }
    }
  }, [initialValue, values, onMounted, name]);

  const scroll = deltaY => {
    ref.current.scroll({
      left: 0,
      top: ref.current.scrollTop + deltaY,
      behavior: 'smooth',
    });
  };

  const onMinus = () => {
    scroll(STEP_HEIGHT * -1);
  };

  const onPlus = () => {
    scroll(STEP_HEIGHT);
  };

  const onWheelClick = evt => {
    const { top, height } = ref.current.getBoundingClientRect();
    const y = evt.clientY - top; //y position within the element.
    if (y <= height / 3) {
      onMinus();
    } else if (y >= (height / 3) * 2) {
      onPlus();
    }
  };

  const onPickerScroll = evt => {
    if (evt.target.scrollTop === 0) {
      setAtMin(true);
    } else if (atMin) {
      setAtMin(false);
    }

    if (evt.target.scrollTop === evt.target.scrollHeight - STEP_HEIGHT * 3) {
      setAtMax(true);
    } else if (atMax) {
      setAtMax(false);
    }
  };

  return (
    <Container>
      <PickerWheelContainer>
        <PickerWheel ref={ref} tabIndex="0" onClick={onWheelClick} onScroll={onPickerScroll}>
          <PickerValue />
          {values.map(value => (
            <PickerValue key={`pv-${value}`}>{value}</PickerValue>
          ))}
          <PickerValue />
        </PickerWheel>
        <PickerWheelCage>
          <PickerWheelHelperContainer>
            <ButtonIcon type="minus" label="Minus" disabled={atMin} onClick={onMinus} />
          </PickerWheelHelperContainer>
          <PickerWheelSelectionIndicator>
            <PickerIcon src={getChallengeIconSrc(icon) || manualAdjustmentIcon} />
            <PickerWheelPlaceholder />
            <PickerUnitText>{unit}</PickerUnitText>
          </PickerWheelSelectionIndicator>
          <PickerWheelHelperContainer>
            <ButtonIcon type="plus" label="Plus" disabled={atMax} onClick={onPlus} />
          </PickerWheelHelperContainer>
        </PickerWheelCage>
      </PickerWheelContainer>
      {unitSecondary && <PickerSecondaryUnitText>{unitSecondary}</PickerSecondaryUnitText>}
    </Container>
  );
};

GigaSelect.propTypes = {
  initialValue: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  onMounted: PropTypes.func,
  name: PropTypes.string,
  unit: PropTypes.string,
  unitSecondary: PropTypes.string,
  icon: PropTypes.string,
};

export default GigaSelect;

export const getValue = selectRef => {
  const index = Math.round(selectRef.current.scrollTop / STEP_HEIGHT) + 1;
  return parseInt(selectRef.current.children[index].innerText);
};

const Container = styled.div`
  margin-bottom: 30px;
`;

const PickerWheelContainer = styled.div`
  height: ${STEPS_SHOWN * STEP_HEIGHT}px;
  max-height: ${STEPS_SHOWN * STEP_HEIGHT}px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
`;

const PickerWheel = styled.div`
  width: ${WHEEL_WIDTH}px;
  height: ${STEPS_SHOWN * STEP_HEIGHT}px;
  overflow: scroll;
  scroll-snap-type: mandatory;
  scroll-snap-type: y mandatory;
  mask: url(${mask});

  /* Enable iOS "momentum scroll" */
  -webkit-overflow-scrolling: touch;

  /* Hide scrollbars */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    /* Webkit */
    width: 0;
    height: 0;
    display: none;
  }
`;

const PickerValue = styled.div`
  ${bigNumberCenterNegative};
  height: ${STEP_HEIGHT}px;
  max-height: ${STEP_HEIGHT}px;
  min-height: ${STEP_HEIGHT}px;
  scroll-snap-align: start;
`;

const PickerWheelCage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

const PickerWheelHelperContainer = styled.div`
  height: ${STEP_HEIGHT}px;
  max-height: ${STEP_HEIGHT}px;
  overflow: hidden;
  width: 100%;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  &:before {
    content: ' ';
    width: ${WHEEL_WIDTH + ICON_SIZE}px;
  }

  * {
    pointer-events: all;
  }

  @media (min-width: 500px) {
    display: flex;
  }
`;

const PickerWheelSelectionIndicator = styled.div`
  border-top: solid 1px ${props => props.theme.colors.dull7_15};
  border-bottom: solid 1px ${props => props.theme.colors.dull7_15};
  height: ${STEP_HEIGHT}px;
  max-height: ${STEP_HEIGHT}px;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const PickerIcon = styled.img`
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
`;

const PickerWheelPlaceholder = styled.div`
  width: ${WHEEL_WIDTH}px;
`;

const PickerUnitText = styled.div`
  ${bigNumberUnitNegative};
  width: ${ICON_SIZE}px;
`;

const PickerSecondaryUnitText = styled.div`
  ${labelLeftNegative};
  text-align: center;
  margin-bottom: 40px;
`;
