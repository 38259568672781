import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { useMostRecentValue } from '../hooks/values';
import { theme } from '../theme';
import {
  articleTitle,
  articleIngress,
  articleImageDescription,
  articleH1,
  articleH2,
  articleH3,
  articleP,
  articleOL,
  articleUL,
  articleQuote,
} from '../styles/text';
import ModalFullscreen from '../components/modal/ModalFullscreen';
import escapeHtml from 'escape-html';
import elements from '../styles/elements';
import { HistoryShape, MatchShapeOfParams } from '../util/shapes';
import { useTrackPageView } from '../hooks/useTrackPageView';
import { getCMSArticle } from '../functionalities/apis';

const BlogArticleModal = ({ match: matchProp, history }) => {
  useTrackPageView();

  const locale = 'en-US';
  const match = useMostRecentValue(matchProp);
  const { slug } = match.params;

  const [state, setState] = useState({
    loading: true,
    error: null,
    data: null,
  });

  useEffect(() => {
    async function load() {
      const response = await getCMSArticle(slug, locale);
      if (response.data) {
        setState(state => ({
          ...state,
          loading: false,
          data: response.data,
        }));
      } else {
        setState(state => ({
          ...state,
          loading: false,
          error: true,
        }));
      }
    }
    load();
  }, [setState, slug, locale]);

  const { loading, error, data } = state;

  if (loading) {
    return (
      <ThemeProvider theme={theme('white')}>
        <ModalFullscreen onClose={history.goBack} />
      </ThemeProvider>
    );
  }
  if (error) {
    return (
      <ThemeProvider theme={theme('white')}>
        <ModalFullscreen onClose={history.goBack} title={'Error with the article'}>
          <ArticleContainer>
            <ArticleTitle>Oops! An error occurred.</ArticleTitle>
          </ArticleContainer>
        </ModalFullscreen>
      </ThemeProvider>
    );
  }
  if (!data || !data.getBlogPost || !data.getBlogPost.data) {
    // not found
    return (
      <ThemeProvider theme={theme('white')}>
        <ModalFullscreen onClose={history.goBack} title={'Article not found'}>
          <ArticleContainer>
            <ArticleTitle>Oops! Article was not found.</ArticleTitle>
          </ArticleContainer>
        </ModalFullscreen>
      </ThemeProvider>
    );
  }
  const article = data.getBlogPost.data;

  return (
    <ThemeProvider theme={theme('white')}>
      <ModalFullscreen title={article.category} onClose={history.goBack}>
        <ArticleTitle>{article.title}</ArticleTitle>
        <ImageDiv src={article.image} alt="" />
        <Ingress>{article.ingress}</Ingress>
        <ArticleContainer>
          <BlogArticleBody
            body={article.body}
            images={article.inlineImages}
            descriptions={article.imageDescriptions}
          />
        </ArticleContainer>
      </ModalFullscreen>
    </ThemeProvider>
  );
};

BlogArticleModal.propTypes = {
  match: MatchShapeOfParams(['slug']),
  history: HistoryShape.isRequired,
};

export default BlogArticleModal;

const BlogArticleBody = ({ body, images, descriptions }) => {
  let idx = 0;

  const render = node => {
    const bold = text => {
      return node && node.bold ? `<b>${text}</b>` : text;
    };
    const italic = text => {
      return node && node.italic ? `<i>${text}</i>` : text;
    };
    const underline = text => {
      return node && node.underline ? `<u>${text}</u>` : text;
    };

    if (node.text !== undefined) {
      if (node.code && node.text.startsWith('<')) {
        // special case for printing markup directly from the blog contents
        // mark the text as 'code' in CMS and must start with a <
        return <span key={idx++} dangerouslySetInnerHTML={{ __html: node.text }} />;
      }
      if (node.text.startsWith('<image')) {
        // Image placeholder format is: <image [index]>
        // Search for matching image and description
        // Index in the placeholder is 1-based, reduce 1 to access the array
        const match = /<image\s*(\d{1,})/.exec(node.text);
        if (!match || parseInt(match[1]) < 0) {
          return null;
        }
        const imageIdx = parseInt(match[1]) - 1;
        const image = images.length > imageIdx ? images[imageIdx] : null;
        const description = descriptions.length > imageIdx ? descriptions[imageIdx] : null;
        return <Image key={idx++} image={image} description={description} />;
      }
      // Render basic text. Pass through bold, italic and underline checks
      return (
        <span
          key={idx++}
          dangerouslySetInnerHTML={{ __html: bold(italic(underline(escapeHtml(node.text)))) }}
        />
      );
    }

    if (node.type) {
      const children = { children: node.children };
      switch (node.type) {
        case 'h1':
          return <H1 key={idx++}>{render(children)}</H1>;
        case 'h2':
          return <H2 key={idx++}>{render(children)}</H2>;
        case 'h3':
          return <H3 key={idx++}>{render(children)}</H3>;
        case 'paragraph':
          return <P key={idx++}>{render(children)}</P>;
        case 'description':
          return <QUOTE key={idx++}>{render(children)}</QUOTE>;
        // case 'primaryColorText':
        //     return <COLORTEXT>${children}</COLORTEXT>;
        case 'link':
          const linkAttrs = {};
          if (node.notfollow) linkAttrs.rel = 'nofollow';
          if (node.newTab) linkAttrs.target = '_blank';
          return (
            <Link key={idx++} href={node.href} {...linkAttrs}>
              {render(children)}
            </Link>
          );
        case 'ordered-list':
          return <OL key={idx++}>{render(children)}</OL>;
        case 'unordered-list':
          return <UL key={idx++}>{render(children)}</UL>;
        case 'list-item':
          return <LI key={idx++}>{render(children)}</LI>;
        default:
          return null;
      }
    }
    if (node.children) {
      return node.children.map(n => render(n));
    } else {
      return null;
    }
  };

  return render({ children: body });
};

BlogArticleBody.propTypes = {
  body: PropTypes.arrayOf(PropTypes.object).isRequired,
  images: PropTypes.array,
  descriptions: PropTypes.array,
};

const ArticleContainer = styled.div`
  border-top: 1px solid ${props => props.theme.colors.seachangeTextAlpha15};
  margin: 0 15px;
  padding-bottom: 130px;
`;

const ArticleTitle = styled.div`
  ${articleTitle};
  margin-bottom: 30px;
`;

const Ingress = styled.div`
  ${articleIngress};
`;

const ImageDiv = styled.img`
  max-width: 100%;
`;

const ImageContainer = styled.div`
  margin: 60px -15px 0 -15px;
`;

const Image = ({ image, description }) => {
  if (!image) return null;
  return (
    <ImageContainer>
      <ImageDiv src={image + '?width=' + elements.containerMaxWidthPx} alt="" />
      <ImageDescription>{description}</ImageDescription>
    </ImageContainer>
  );
};

Image.propTypes = {
  image: PropTypes.string,
  description: PropTypes.string,
};

const ImageDescription = styled.div`
  ${articleImageDescription}
`;

// const Published = styled.div`
//     ${articlePublished};
// `;

const Link = styled.a`
  color: ${({ theme }) => theme.text.link};
  &:visited {
    color: ${({ theme }) => theme.text.link};
  }
`;

const H1 = styled.div`
  ${articleH1};
`;

const H2 = styled.div`
  ${articleH2};
`;

const H3 = styled.div`
  ${articleH3};
`;

const P = styled.div`
  ${articleP};
`;

const OL = styled.ol`
  ${articleOL};
`;

const UL = styled.ul`
  ${articleUL};
`;

const LI = styled.li``;

const QUOTE = styled.div`
  ${articleQuote};
`;
