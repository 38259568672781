import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Card, CardValue, EqualWidth } from '../../components/card';
import { h1LeftNegative, h2LeftNegative } from '../../styles/text';
import { LineChart } from '../chart';
import { UserShape, LineChartShape } from '../../util/shapes';
import { useMessageGetter } from 'react-message-context';
import Co2 from '../../functionalities/Co2';
import { capitalizeFirstLetter } from '../../util/text';
import GroupCardHeaderRow from './GroupCardHeaderRow';
import { BoldText, PreSpaceBoldText, Text, getGroupInfo } from './DataCardCommons';
import TextDotSeparator from '../divider/TextDotSeparator';

const images = require.context('../../assets/images', true);

const GroupCard = ({
  challengeId,
  challengeName,
  groupId,
  groupName,
  startDate,
  durationDays,
  groupReductionKg,
  groupYearlyReductionTargetKg,
  personalReductionKg,
  chartData,
}) => {
  const personalReductionKgCo2 = new Co2(personalReductionKg);
  const messages = useMessageGetter('components.group_card');
  const { value, unitAbbr } = personalReductionKgCo2.getScaledValue();
  return (
    <Wrapper>
      <Card bgImage={images(`./${challengeId}-bg.svg`)} smallShadow>
        <Header>{capitalizeFirstLetter(challengeName)}</Header>
        <Subheader>{messages('subheader')}</Subheader>
        <Row>
          <EqualWidth>
            <CardValue value={value} valueUnit={unitAbbr} desc={messages('value_desc')} />
            <LineChart
              data={chartData}
              refLegend={messages('ref_label')}
              refType="dashed"
              showTarget={false}
            />
          </EqualWidth>
        </Row>
        <GroupInfo
          groupId={groupId}
          name={groupName}
          reductionKg={groupReductionKg}
          groupYearlyReductionTargetKg={groupYearlyReductionTargetKg}
          startDate={startDate}
          durationDays={durationDays}
        />
      </Card>
    </Wrapper>
  );
};

GroupCard.propTypes = {
  challengeId: PropTypes.string.isRequired,
  challengeName: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  durationDays: PropTypes.number.isRequired,
  captain: UserShape.isRequired,
  memberCount: PropTypes.number.isRequired,
  groupName: PropTypes.string.isRequired,
  groupReductionKg: PropTypes.number.isRequired,
  groupYearlyReductionTargetKg: PropTypes.number.isRequired,
  personalReductionKg: PropTypes.number.isRequired,
  chartData: LineChartShape.isRequired,
  groupId: PropTypes.string.isRequired,
};

export default GroupCard;

const Wrapper = styled.div`
  margin-bottom: 15px;
`;

const Header = styled.h1`
  ${h1LeftNegative};
  color: ${props => props.theme.card.textSecondary};
  margin: 0;
`;

const GroupInfo = ({
  groupId,
  name,
  reductionKg,
  startDate,
  durationDays,
  groupYearlyReductionTargetKg,
}) => {
  const messages = useMessageGetter('components.group_card');
  const [stateLabel, progressLabel, progressKgCo2] = getGroupInfo(
    messages,
    startDate,
    durationDays,
    groupYearlyReductionTargetKg,
    reductionKg,
  );

  return (
    <Row style={{ marginTop: '20px' }}>
      <GroupCardHeaderRow
        groupId={groupId}
        groupName={name}
        inviteOnly={false}
        subheaderRow={
          <Fragment>
            <BoldText>{stateLabel}</BoldText>
            <TextDotSeparator />
            <Text>
              {progressLabel}
              <PreSpaceBoldText>{progressKgCo2.getFullScaledText()}</PreSpaceBoldText>
            </Text>
          </Fragment>
        }
      />
    </Row>
  );
};

GroupInfo.propTypes = {
  groupId: PropTypes.string.isRequired,
  groupYearlyReductionTargetKg: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  durationDays: PropTypes.number.isRequired,
  reductionKg: PropTypes.number.isRequired,
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Subheader = styled.h2`
  ${h2LeftNegative};
  color: ${props => props.theme.card.textSecondary};
  margin: 13px 0 0 0;
`;
