import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Groups } from 'isomorphic';
import { pNegative } from '../../styles/text';
import { badgeCaptainLine } from '../../assets/images';
import { useMessageGetter } from 'react-message-context';
import { UserShape } from '../../util/shapes';
import { getName } from '../../util/user';
import Box from '../container/Box';
import { getBackground } from '../../styles/util';
import GroupCardHeaderRow from './GroupCardHeaderRow';
import { BoldText } from './DataCardCommons';
import { assureNoPrefix } from 'isomorphic/util';
import TextDotSeparator from '../divider/TextDotSeparator';
import ProfilePicture, { SHAPES, SIZES } from '../picture/ProfilePicture';

const SmallGroupInviteCard = ({ group }) => {
  const messages = useMessageGetter();
  const groupDefaultName = messages('data.group.defaultName', {
    captainName: getName(group.captain),
  });
  const groupName = group.name || groupDefaultName;
  const captainName = `${group.captain.givenName} ${group.captain.familyName}`;
  const challengeId = assureNoPrefix('challenge_', group.challenge.id);
  const days = Math.abs(Groups.getGroupDay(group.startDate));

  return (
    <Container>
      <Link to={`/j/${group.invitationId}`}>
        <GroupCardHeaderRow
          groupId={group.id}
          groupName={groupName}
          inviteOnly={group.access === 'inviteOnly'}
          subheaderRow={
            <Fragment>
              <BoldText>{messages(`data.challenge.${challengeId}.title`)}</BoldText>
              <TextDotSeparator />
              <BoldText>
                {messages('components.small_group_invite_card.starts_in', { days })}
              </BoldText>
            </Fragment>
          }
        />
        <Box row centered margin="10px 0 0 0">
          <ProfilePicture
            alt={captainName}
            size={SIZES.MICRO}
            shape={SHAPES.ROUND}
            flat={true}
            userId={group.captain.userId}
          />
          <Badge src={badgeCaptainLine} />
          <Text>{messages('components.small_group_invite_card.invited_by', { captainName })}</Text>
        </Box>
      </Link>
    </Container>
  );
};

SmallGroupInviteCard.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string.isRequired,
    invitationId: PropTypes.string.isRequired,
    name: PropTypes.string,
    captain: UserShape.isRequired,
    access: PropTypes.string.isRequired,
    challenge: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    startDate: PropTypes.string.isRequired,
  }),
};

export default SmallGroupInviteCard;

const Badge = styled.img`
  width: 14px;
  height: 14px;
  margin-left: 5px;
  margin-right: 3px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled(Column)`
  padding: 14px 13px 13px 15px;
  background-image: ${({ theme: { smallGroupCard } }) =>
    getBackground(`url(${smallGroupCard.bgImage})`, smallGroupCard.bgGradient)};
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.card.shadowSmall};
  :not(:last-child) {
    margin-bottom: 10px;
  }
`;

const Text = styled.span`
  ${pNegative};
`;
