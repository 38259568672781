import { Groups } from 'isomorphic';
import { storage } from '../util/storage';

const KEY = 'invites';

/**
 * Store invitation to a group in local storage.
 * @param {*} group Group
 */
export function storeInvitation(group, invitationId) {
  let invites = storage.get(KEY);
  if (invites === null) {
    invites = [];
  }

  if (!invites.some(invite => invite.id === group.id)) {
    invites.push({
      invitationId,
      id: group.id,
      access: group.access,
      captain: group.captain,
      challenge: group.challenge,
      name: group.name,
      startDate: group.startDate,
      theme: group.theme,
    });
  }

  storage.add(KEY, invites);
}

/**
 * Loads invitations user has. Filters out groups the potential user is already member of
 * and groups where change period has already started based on the initial start date. NOTE:
 * If start date changes after the invitation got stored, it will not affect this store.
 *
 * @param {*} groups Groups the user is member of
 */
export function getPendingInvitations(groups = []) {
  return (storage.get(KEY) || [])
    .filter(group => {
      const groupDay = Groups.getGroupDay(group.startDate);
      return groupDay < 0;
    })
    .filter(group => !groups.some(({ id }) => group.id === id));
}
