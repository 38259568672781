module.exports.assurePrefix = function (prefix, id) {
  if (!id.startsWith(prefix)) {
    return `${prefix}${id}`;
  } else {
    return id;
  }
}

module.exports.assureNoPrefix = function (prefix, id) {
  if (id.startsWith(prefix)) {
    return id.replace(prefix, '');
  } else {
    return id;
  }
};
