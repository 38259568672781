import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { labelLeftNegative, labelRightNegative } from '../../../../styles/text';
import WizardRangeInput from './WizardRangeInput';
import WizardValueText from '../../text/WizardValueText';

const WizardSliderControl = ({
  onChange,
  minValue = 0,
  maxValue = 100,
  step = 1,
  value,
  valueDescription,
  labelMin,
  labelMax,
  name,
}) => {
  return (
    <Container>
      <WizardValueText value={value} valueDescription={valueDescription} />
      <WizardRangeInput
        name={name}
        onChange={onChange}
        min={minValue}
        max={maxValue}
        step={step}
        value={value}
      />
      {(labelMin || labelMax) && (
        <LabelsContainer>
          <LabelMin>{labelMin}</LabelMin>
          <LabelMax>{labelMax}</LabelMax>
        </LabelsContainer>
      )}
    </Container>
  );
};

WizardSliderControl.propTypes = {
  labelMin: PropTypes.string,
  labelMax: PropTypes.string,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  value: PropTypes.number,
  valueDescription: PropTypes.string,
  name: PropTypes.string,
  step: PropTypes.number,
  onChange: PropTypes.func,
};

export default WizardSliderControl;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 30px 0 30px;
  box-sizing: border-box;
  align-self: center;
  flex: 1 0 auto;
  justify-content: center;
  margin-bottom: 40px;
`;

const LabelsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-self: center;
  margin-top: 7px;
`;

const Label = styled.label`
  flex: 1;
`;

const LabelMin = styled(Label)`
  ${labelLeftNegative};
`;

const LabelMax = styled(Label)`
  ${labelRightNegative};
`;
