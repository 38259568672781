export const getDataPath = (data, dimensions, margin) => {
  if (!data) {
    return {
      dataPath: '',
      markerCoords: {},
    };
  }
  const { values, valuesAmount } = data;
  const width = dimensions.w - margin.left - margin.right;
  const height = dimensions.h - margin.top - margin.bottom;
  let dataPath = '',
    maxIndex = -1;
  const markerCoords = {};
  for (let i = 0; i < values.length; i++) {
    const value = values[i];
    const x = margin.left + (width * value.index) / valuesAmount;
    const y = margin.top + height - height * value.value;

    dataPath += i === 0 ? 'M' : 'L';
    dataPath += `${x},${y}`;

    if (value.index > maxIndex) {
      markerCoords.x = x;
      markerCoords.y = y;
      maxIndex = value.index;
    }
  }

  return {
    dataPath,
    markerCoords,
  };
};

export const getDataPathBezier = (values = [], valuesAmount, dimensions, margin, maxValue) => {
  if (!values) {
    return {
      dataPath: '',
      markerCoords: {},
    };
  }
  const width = dimensions.w - margin.left - margin.right;
  const height = dimensions.h - margin.top - margin.bottom;

  let maxIndex = -1;
  const points = [],
    markerCoords = {};
  for (let i = 0; i < values.length; i++) {
    const value = values[i];
    const x = margin.left + (width * value.index) / valuesAmount;
    const y = margin.top + height - (height * value.value) / maxValue;

    points.push([x, y]);

    if (value.index > maxIndex) {
      markerCoords.x = x;
      markerCoords.y = y;
      maxIndex = value.index;
    }
  }

  const dataPath = points.reduce(
    (acc, point, i, a) =>
      i === 0 ? `M ${point[0]},${point[1]}` : `${acc} ${bezierCommand(point, i, a)}`,
    '',
  );

  return {
    dataPath,
    markerCoords,
  };
};

// The smoothing ratio
const smoothing = 0.05;

const line = (pointA, pointB) => {
  const lengthX = pointB[0] - pointA[0];
  const lengthY = pointB[1] - pointA[1];
  return {
    length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
    angle: Math.atan2(lengthY, lengthX),
  };
};

const controlPoint = (current, previous, next, reverse) => {
  // When 'current' is the first or last point of the array
  // 'previous' or 'next' don't exist.
  // Replace with 'current'
  const p = previous || current;
  const n = next || current;

  // Properties of the opposed-line
  const o = line(p, n);

  // If is end-control-point, add PI to the angle to go backward
  const angle = o.angle + (reverse ? Math.PI : 0);
  const length = o.length * smoothing;

  // The control point position is relative to the current point
  const x = current[0] + Math.cos(angle) * length;
  const y = current[1] + Math.sin(angle) * length;
  return [x, y];
};

const bezierCommand = (point, i, a) => {
  // start control point
  const cps = controlPoint(a[i - 1], a[i - 2], point);

  // end control point
  const cpe = controlPoint(point, a[i - 1], a[i + 1], true);
  return `C ${cps[0]},${cps[1]} ${cpe[0]},${cpe[1]} ${point[0]},${point[1]}`;
};
