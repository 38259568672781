import React from 'react';
import styled from 'styled-components';
import { pCenterNegative } from '../../../styles/text';

export const WizardSubtitle = ({ children, ...props }) => {
  if (typeof children === 'string') {
    return children.split('\n').map((paragraph, i) => (
      <Paragraph key={`p-${i}`} {...props}>
        {paragraph}
      </Paragraph>
    ));
  } else {
    return <Paragraph {...props}>{children}</Paragraph>;
  }
};

const Paragraph = styled.p`
  ${pCenterNegative};
  margin: 20px 0 0 0;
  padding: 0 15px;
  &:not(:first-of-type) {
    margin: 5px 0 0 0;
  }
`;
