import styled from 'styled-components';
import elements from '../../styles/elements';
import { HeroHeaderVariant } from './HeroHeader';

export const container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding:${elements.appPaddingPx}px;
  padding-top:${elements.topBarHeightPx + elements.appSecondaryPaddingPx}px;
  color ${props => props.theme.text.negative};
`;

export const HeroHeaderContainer = styled(container)`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: ${elements.topBarHeightPx}px;
  padding: ${elements.appPaddingPx}px;
  padding-top: ${props => (props.hasTopPadding ? elements.topBarHeightPx + 10 : 0)}px;
  color ${props => props.theme.text.negative};
  background-image:
    ${props => `url(${props.bgImage || props.theme.header.bgImage}),`}
    ${props => props.theme.header.bgGradient};
  background-position: ${props => props.theme.header.bgPosition || 'left top'};
  background-repeat: ${props => props.theme.header.bgRepeat || 'repeat'};
  padding-bottom: 13px;
  height: ${props => (props.height ? `${props.height}px` : 'auto')};
  border-radius: ${props =>
    props.variant === HeroHeaderVariant.ROUNDED ? '0 0 50% 50% / 0 0 13px 13px' : 'none'};
`;
