import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { badgeCaptainLine, badgeIsMember } from '../../assets/images';
import { useMessage, useMessageGetter } from 'react-message-context';
import { GroupShape } from '../../util/shapes';
import { getName } from '../../util/user';
import Box from '../container/Box';
import RoundMemberList from '../groups/member-list/RoundMemberList';
import { useAppState } from '../../context/appContext';
import { getBackground } from '../../styles/util';
import { assureNoPrefix } from 'isomorphic/util';
import GroupCardHeaderRow from './GroupCardHeaderRow';
import { BoldText, PreSpaceBoldText, Text, getGroupInfo } from './DataCardCommons';
import TextDotSeparator from '../divider/TextDotSeparator';

const SmallGroupCard = ({ group, isMember }) => {
  const { user } = useAppState();
  const messages = useMessageGetter('components.small_group_card');
  const groupDefaultName = useMessage('data.group.defaultName')({
    captainName: getName(group.captain),
  });
  const groupName = group.name || groupDefaultName;
  const captainName = `${group.captain.givenName} ${group.captain.familyName}`;
  const members = [];
  const userIsCaptain = user.userId === group.captain.userId;

  const memberInfoRow = [
    <Badge key="1" src={badgeCaptainLine} />,
    <Text key="2">{captainName}</Text>,
  ];

  if (isMember && !userIsCaptain) {
    memberInfoRow.push(<Text key="3">,</Text>);
    memberInfoRow.push(<Badge key="4" src={badgeIsMember} />);
    memberInfoRow.push(<Text key="5">{messages('you')}</Text>);
    members.push(user);
  }

  if (
    (isMember && group.memberCount > 1) ||
    ((!isMember || userIsCaptain) && group.memberCount > 0)
  ) {
    const restMemberCount = isMember && !userIsCaptain ? group.memberCount - 1 : group.memberCount;
    memberInfoRow.push(
      <Text key="6">&nbsp;{messages('rest_members', { amount: restMemberCount })}</Text>,
    );
  }

  const challengeId = assureNoPrefix('challenge_', group.challenge.id);
  const groupId = assureNoPrefix('group_', group.id);

  const groupInfoMessages = useMessageGetter('components.group_card');
  const [stateLabel, progressLabel, progressKgCo2] = getGroupInfo(
    groupInfoMessages,
    group.startDate,
    group.durationDays,
    group.targetReductionCo2Kg,
    group.reduction || 0,
  );

  return (
    <Container>
      <Link to={`/challenges/${challengeId}/group/${groupId}`}>
        <GroupCardHeaderRow
          groupId={group.id}
          groupName={groupName}
          inviteOnly={group.access === 'invite'}
          subheaderRow={
            <Fragment>
              <BoldText>{stateLabel}</BoldText>
              <TextDotSeparator />
              <Text>
                {progressLabel}
                <PreSpaceBoldText>{progressKgCo2.getFullScaledText()}</PreSpaceBoldText>
              </Text>
            </Fragment>
          }
        />
        <Box row centered margin="10px 0 0 0">
          <RoundMemberList
            mini
            style={{ justifyContent: 'flex-start', width: 'auto' }}
            captain={group.captain}
            showBadges={false}
            isShowProspect={false}
            isUserMember={false}
            members={members}
          />
          {memberInfoRow}
        </Box>
      </Link>
    </Container>
  );
};

SmallGroupCard.propTypes = {
  group: GroupShape.isRequired,
  short: PropTypes.bool,
  isMember: PropTypes.bool.isRequired,
};

export default SmallGroupCard;

const Badge = styled.img`
  width: 14px;
  height: 14px;
  margin-left: 5px;
  margin-right: 3px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled(Column)`
  padding: 14px 13px 13px 15px;
  background-image: ${({ theme: { smallGroupCard } }) =>
    getBackground(`url(${smallGroupCard.bgImage})`, smallGroupCard.bgGradient)};
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.card.shadowSmall};
  :not(:last-child) {
    margin-bottom: 10px;
  }
`;
