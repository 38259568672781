import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMessageGetter } from 'react-message-context';
import { pWeak } from '../../../../styles/text';
import AnimatedModalFullscreen from '../../../modal/AnimatedModalFullscreen';
import FlagIcon from '../../../icons/FlagIcon';

// Country list
const WizardCountrySelection = ({ show, onClose, onChange }) => {
  const messages = useMessageGetter();
  const [countries, setCountries] = useState({});
  useEffect(() => {
    const load = async function () {
      const countriesModule = await import('../../../../util/countriesList');
      setCountries(countriesModule.default);
    };
    load();
  });

  return (
    <AnimatedModalFullscreen
      show={show}
      onClose={onClose}
      title={messages('pages.wizards.select.country.title')}
    >
      <CountryPickerContainer>
        {Object.entries(countries).length > 0 &&
          Object.entries(countries)
            .sort(([, { name: countryNameA }], [, { name: countryNameB }]) =>
              countryNameA.localeCompare(countryNameB),
            )
            .map(([countryCode, { name, phone }]) => ({
              countryCode,
              name,
              phone: phone.includes(',') ? phone.charAt() : phone,
            }))
            .map(({ countryCode, name, phone }) => (
              <CountryPicker key={countryCode} onClick={() => onChange(countryCode, phone)}>
                <CountryListLabel dark>
                  <FlagIcon countryCode={countryCode} />
                  {name}
                </CountryListLabel>
                <CountryListLabel>+{phone}</CountryListLabel>
              </CountryPicker>
            ))}
      </CountryPickerContainer>
    </AnimatedModalFullscreen>
  );
};

WizardCountrySelection.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default WizardCountrySelection;

const CountryListLabel = styled.div`
  ${pWeak};
  color: ${props => (props.dark ? props.theme.modal.select.option.color : 'none')};
  margin: auto;
  display: flex;
  align-items: center;
`;

const CountryPickerContainer = styled.div`
  padding: 0 30px;
`;
const CountryPicker = styled.div`
  cursor: pointer;
  border-bottom: ${props => `solid 1px ${props.theme.colors.dull3_15}`};
  display: flex;
  width: 100%;
  padding: 15px 0;
  & > *:last-child {
    flex: 1;
    justify-content: flex-end;
  }
`;
