import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Auth from '@aws-amplify/auth';
import API from '@aws-amplify/api-rest';
import config from './config';

// Configure Amplify modules
API.configure(config[process.env.REACT_APP_ENV].apiGateway);
Auth.configure(config[process.env.REACT_APP_ENV].auth);

ReactDOM.render(<App />, document.getElementById('root'));
