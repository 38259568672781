import React from 'react';
import Icon from './Icon';

// eslint-disable-next-line max-len
const path =
  'M19.059 10.72l5.721 5.75A.75.75 0 0 1 25 17a.776.776 0 0 1-.219.54l-5.713 5.738a.748.748 0 0 1-1.061.002.755.755 0 0 1-.001-1.063l4.445-4.457H8.75A.758.758 0 0 1 8 17c0-.416.336-.76.75-.76h13.681l-4.432-4.455a.755.755 0 0 1 0-1.065.749.749 0 0 1 1.06 0z';

const ArrowRightIcon = props => <Icon {...props} path={path} />;

export default ArrowRightIcon;
