import styled from 'styled-components';
import elements from '../../../styles/elements';

export const RelativeWizardStepContent = styled.div`
  position: relative;
`;

export const AbsoluteWizardHeaderContainer = styled.div`
  position: absolute;
  top: ${75 - 64}px;
  width: 100%;
`;

export const AbsoluteWizardContentContainer = styled.div`
  position: absolute;
  top: ${230 - 64}px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-bottom: ${elements.wizardFooterHeightPx + 64}px;
`;
