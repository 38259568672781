import React, { useCallback } from 'react';
import { getTourConfig } from '../../functionalities/apis';
import { LocationShape, HistoryShape } from '../../util/shapes';
import { storage } from '../../util/storage';
import { useTrackPageView } from '../../hooks/useTrackPageView';
import Wizard from './Wizard';

const TourWizard = ({ location, history }) => {
  useTrackPageView();

  const isFirstTimer = !!new URLSearchParams(location.search).get('first_timer');
  const loadConfig = useCallback(async () => {
    storage.add('hasSeenTour', true);
    return await getTourConfig(isFirstTimer);
  }, [isFirstTimer]);

  return (
    <Wizard
      match={{ params: {} }}
      history={history}
      loadConfig={loadConfig}
      onClose={() => history.push('/')}
    />
  );
};

TourWizard.propTypes = {
  history: HistoryShape.isRequired,
  location: LocationShape.isRequired,
};

export default TourWizard;
