import React from 'react';
import { PropTypes } from 'prop-types';
import { useMessageGetter } from 'react-message-context';

import SuccessCalendarCard from '../../data-cards/SuccessCalendarCard';
import { SpacingShape } from '../../../util/shapes';

export default function PersonalSuccessCard({
  groupStartDate,
  groupDurationDays,
  dailyAnswers,
  spacing,
  flat,
}) {
  const messages = useMessageGetter();
  const mappedDailyAnswers = Object.keys(dailyAnswers).map(dayValue => ({
    day: parseInt(dayValue),
    successValue: dailyAnswers[dayValue].successValue,
  }));

  return (
    <SuccessCalendarCard
      key="calendar"
      startDate={groupStartDate}
      daysAmount={groupDurationDays}
      dailyAnswers={mappedDailyAnswers}
      messages={messages}
      spacing={spacing}
      flat={flat}
    />
  );
}

PersonalSuccessCard.propTypes = {
  groupStartDate: PropTypes.string.isRequired,
  groupDurationDays: PropTypes.number.isRequired,
  dailyAnswers: PropTypes.object,
  spacing: SpacingShape,
  flat: PropTypes.bool,
};
