import styled from 'styled-components';
import { h1LeftPositive } from '../../styles/text';

const ModalPageTitle = styled.h1`
  ${h1LeftPositive};
  text-align: center;
  margin: 11px 0 20px 0;
`;

export default ModalPageTitle;
