import React, { useEffect } from 'react';
import styled from 'styled-components';
import { theme } from '../../theme';
import ProfilePicture, { SIZES } from '../../components/picture/ProfilePicture';
import { relativeTimeText } from '../../util/text';
import { pWeak, pLeft } from '../../styles/text';
import { useMessageGetter } from 'react-message-context';
import { useApp } from '../../context/appContext';
import { loadPost, loadGroup } from '../../context/actions';
import ModalFullscreen, { ModalContent } from '../../components/modal/ModalFullscreen';
import { useTrackPageView } from '../../hooks/useTrackPageView';
import { HistoryShape, MatchShapeOfParams } from '../../util/shapes';
import ModalGroupSubtitle from '../../components/modal/ModalGroupSubtitle';

const images = require.context('../../assets/images', true);

const PostReactionsPage = ({ match, history }) => {
  const messages = useMessageGetter();
  const [state, dispatch] = useApp();
  const { postId, reactionsId, challengeId, groupId } = match.params;

  const { posts } = state;
  const group = state[groupId];

  let post = posts.find(post => post.aspect === `feed_${postId}`);
  if (postId !== reactionsId) {
    if (!post || !post.replies) {
      post = null;
    } else {
      post = post.replies.find(reply => reply.aspect.endsWith(`_${reactionsId}`));
    }
  }

  useTrackPageView();

  useEffect(() => {
    async function load() {
      try {
        await loadPost(dispatch, postId);
      } catch (err) {
        console.error('Error loading post', err);
        history.push('/404');
      }
    }
    load();
  }, [dispatch, postId, history]);

  useEffect(() => {
    loadGroup(dispatch, groupId);
  }, [dispatch, groupId]);

  if (!post) {
    return (
      <ModalFullscreen
        title={messages('components.feed.reactions.title', { givenName: '' })}
        onClose={history.goBack}
        theme={theme('white')}
      >
        {null}
      </ModalFullscreen>
    );
  }

  const goToGroupPage = () => {
    history.push(`/challenges/${challengeId}/group/${groupId}`);
  };

  const { reactions } = post;
  const sortedReactions = Object.keys(reactions)
    .map((reactionType, i) =>
      Object.keys(reactions[reactionType]).map(reactedUserId => ({
        reactionType,
        reactedUserId,
        ...reactions[reactionType][reactedUserId],
      })),
    )
    .flat()
    .sort((a, b) => b.created_at - a.created_at);

  return (
    <ModalFullscreen
      title={messages('components.feed.reactions.title', { givenName: post.user.givenName })}
      onClose={history.goBack}
      theme={theme('white')}
      subtitle={
        <ModalGroupSubtitle group={group} onClick={goToGroupPage} inGroup marginBottom="15px" />
      }
      border={!!group}
    >
      <ModalContent>
        {sortedReactions.map((reaction, i) => (
          <Container key={`reaction-${i}`}>
            <Left>
              <ProfilePicture userId={reaction.reactedUserId} size={SIZES.MINI} flat />
            </Left>
            <Main>
              <Text>{`${reaction.givenName} ${reaction.familyName}`}</Text>
              <Time>{relativeTimeText(reaction.created_at, messages)}</Time>
            </Main>
            <Right>
              <ReactIcon
                src={images(`./given-${reaction.reactionType}.svg`).default}
                alt={reaction.reactionType}
              />
            </Right>
          </Container>
        ))}
      </ModalContent>
    </ModalFullscreen>
  );
};

PostReactionsPage.propTypes = {
  match: MatchShapeOfParams(['challengeId', 'groupId', 'postId', 'reactionsId']),
  history: HistoryShape.isRequired,
};

export default PostReactionsPage;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 10.5px;
  padding-bottom: 9.5px;
  &:not(:last-child) {
    border-bottom: solid 1px rgba(87, 87, 87, 0.15);
  }
`;

const Left = styled.div`
  width: 40px;
  min-width: 40px;
`;

const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Text = styled.div`
  ${pLeft};
  flex: 1;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-left: 8px;
  margin-right: 8px;
`;

const Time = styled.div`
  ${pWeak};
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: top;
`;

const ReactIcon = styled.img`
  width: 16px;
  height: 16px;
`;
