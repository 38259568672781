import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMessageGetter } from 'react-message-context';
import { Link } from 'react-router-dom';

import Card from '../../card/Card';
import { CardLabelWithIcon } from '../../card/CardLabel';
import { trophy } from '../../../assets/images';
import { labelFormElementPositive, pWeak } from '../../../styles/text';
import Button from '../../button/Button';
import { GroupMemberShape, SpacingShape } from '../../../util/shapes';
import MemberList from '../member-list/MemberList';
import sortingOptions from '../../../pages/group/members/sortingOptions';
import { seachangeTextAlpha15 } from '../../../styles/colors';

export function LeaderboardCard({ groupMembersPath, members, groupStatus, spacing, flat }) {
  const messages = useMessageGetter('components.leaderboard_card');
  const rootMessages = useMessageGetter();
  const reductionLeaders = members
    .sort(sortingOptions(rootMessages).biggestReduction.compareFn)
    .slice(0, 3);
  const footprintLeaders = members
    .sort(sortingOptions(rootMessages).smallestFootprint.compareFn)
    .slice(0, 3);
  return (
    <Card spacing={spacing} flat={flat}>
      <CardLabelWithIcon icon={<img src={trophy} alt="" />}>{messages('title')}</CardLabelWithIcon>
      <Header>
        {messages('reduction_header')}
        <Legend>{messages('reduction_legend')}</Legend>
      </Header>
      <MemberList
        showStandings
        members={reductionLeaders}
        scoreType="totalReductionCo2Kg"
        groupStatus={groupStatus}
        showScore
      />
      <StyledLink to={`${groupMembersPath}?initialSortBy=biggestReduction`}>
        <Button secondary>{messages('show_more')}</Button>
      </StyledLink>

      <Header>
        {messages('footprint_header')}
        <Legend>{messages('footprint_legend')}</Legend>
      </Header>

      <MemberList
        showStandings
        members={footprintLeaders}
        scoreType="yearlyFootPrintCo2Kg"
        groupStatus={groupStatus}
        showScore
      />
      <StyledLink to={`${groupMembersPath}?initialSortBy=smallestFootprint`}>
        <Button secondary>{messages('show_more')}</Button>
      </StyledLink>
    </Card>
  );
}

LeaderboardCard.propTypes = {
  groupMembersPath: PropTypes.string.isRequired,
  members: PropTypes.arrayOf(GroupMemberShape).isRequired,
  groupStatus: PropTypes.string.isRequired,
  spacing: SpacingShape,
  flat: PropTypes.bool,
};

const Header = styled.div`
  ${labelFormElementPositive};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px ${seachangeTextAlpha15};
  padding: 15px 0 10px;
`;

const Legend = styled.div`
  ${pWeak}
`;

const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  padding: 10px 0 25px 0;
`;
