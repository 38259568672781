import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import navigation, { navigationParents } from '../../../functionalities/navigation';
import Middle from '../../../components/middle/Middle';
import { ThemeProvider } from 'styled-components';
import PathBar from '../../../components/top/pathBar/PathBar';
import { PropTypes } from 'prop-types';
import { theme } from '../../../theme';
import {
  pLeftWizardTitlePositive,
  h2LeftPositive,
  h1LeftPositive,
  labelLeftPositive,
  pLeft,
  pCenter,
} from '../../../styles/text';
import FixedBack from '../../../components/top/FixedBack';
import CenteredContent from '../../../components/container/CenteredContent';
import { useMostRecentValue } from '../../../hooks/values';
import { useTrackPageView } from '../../../hooks/useTrackPageView';
import { MatchShapeOfParams } from '../../../util/shapes';
import { getCMSFactAndMath } from '../../../functionalities/apis';

const thisPage = 'FactAndMath';

const FactAndMathPage = ({ match: matchProp, wrapper: Wrapper, exiting }) => {
  const match = useMostRecentValue(matchProp);
  const { challengeId } = match.params;

  useTrackPageView();

  const [data, setData] = useState(null);

  useEffect(() => {
    async function load() {
      const response = await getCMSFactAndMath(challengeId, 'en-US');
      setData(response.data.getFactAndMath1.data);
    }
    load();
  }, [challengeId, setData]);

  if (!exiting) {
    navigation.setParent(navigationParents.challenges);
    navigation.setPage(thisPage);
  }

  // Is loading?
  if (!data) {
    return <Wrapper>{null}</Wrapper>;
  }

  const content = mapContent(data);

  return (
    <Wrapper>
      <ThemeProvider theme={theme('blue')}>
        <PathBar level={2} pageTitle={data.challengeName} bwHeader={true} posAbsolute={false} />
        <CenteredContent>
          <FixedBack />
          <Middle>
            <Container>{content}</Container>
          </Middle>
        </CenteredContent>
      </ThemeProvider>
    </Wrapper>
  );
};

FactAndMathPage.propTypes = {
  match: MatchShapeOfParams(['challengeId']),
  exiting: PropTypes.bool,
  wrapper: PropTypes.func,
};

export default FactAndMathPage;

const CONTENT = {
  H1: 'h1',
  H3: 'h3',
  PARAGRAPH: 'paragraph',
  LINK: 'link',
  LIST: 'unordered-list',
  LIST_ITEM: 'list-item',
  TABLE: 'ordered-list',
  TABLE_ROW: 'ordered-list-row',
  TEXT: 'text',
  REFERENCE: 'description',
  REF_ICON: 'ref-icon',
};

const mapContent = ({ content }) => {
  return content.map(mapItem);
};

const mapChildren = children => {
  return children.map(({ text, code, type, ...rest }) => {
    if (code !== undefined) {
      return { type: CONTENT.REF_ICON, children: [{ text }] };
    } else if (type === CONTENT.LINK) {
      return {
        type,
        ...rest,
      };
    } else {
      return { type: CONTENT.TEXT, children: [{ text }] };
    }
  });
};

const mapItem = ({ type, children, href }, index) => {
  switch (type) {
    case CONTENT.H1:
      return <MainHeader key={index}>{children[0].text}</MainHeader>;
    case CONTENT.H3:
      return <SubHeader key={index}>{children[0].text}</SubHeader>;
    case CONTENT.PARAGRAPH:
      return <Paragraph key={index}>{mapContent({ content: mapChildren(children) })}</Paragraph>;
    case CONTENT.LINK:
      return (
        <Link key={index} target="_blank" href={href}>
          {children[0].text}
        </Link>
      );
    case CONTENT.LIST:
      return <List key={index}>{mapContent({ content: children })}</List>;
    case CONTENT.LIST_ITEM:
      return <ListItem key={index}>{mapContent({ content: mapChildren(children) })}</ListItem>;
    case CONTENT.TABLE:
      return (
        <Table key={index}>
          <thead>
            <TableHeader>
              {children[0].children[0].text.split('|').map((head, i) => (
                <TableHeaderCell key={i}>{head}</TableHeaderCell>
              ))}
            </TableHeader>
          </thead>
          <tbody>
            {children.slice(1).map((item, i) =>
              mapItem(
                {
                  ...item,
                  type: CONTENT.TABLE_ROW,
                },
                i,
              ),
            )}
          </tbody>
        </Table>
      );
    case CONTENT.TABLE_ROW:
      const cells = children[0].text.split('|');
      return (
        <tr key={`r${index}`}>
          {cells.map((cell, ci) => (
            <TableCell key={ci}>
              {cell}
              {
                // XXX: A hack to be able to show ref icons at last column of table row if such is present
                children.length > 1 &&
                  ci === cells.length - 1 &&
                  mapContent({ content: mapChildren([children[1]]) })
              }
            </TableCell>
          ))}
        </tr>
      );
    case CONTENT.TEXT:
      return <span key={index}>{children[0].text}</span>;
    case CONTENT.REF_ICON:
      return <RefIcon key={index}>{children[0].text}</RefIcon>;
    case CONTENT.REFERENCE:
      return <Reference key={index}>{mapContent({ content: mapChildren(children) })}</Reference>;
    default:
      console.log('Unknown type', type);
      return null;
  }
};

const Container = styled.div`
  padding: 0 15px 30px 15px;
  display: flex;
  flex-direction: column;
`;

const MainHeader = styled.h1`
  ${h1LeftPositive}
  margin-bottom: 15px;
`;

const SubHeader = styled.h2`
  ${h2LeftPositive}
  margin-bottom: 15px;
  margin-top: 15px;
`;

const Paragraph = styled.p`
  ${pLeftWizardTitlePositive}
  margin-bottom: 15px;
  margin-top: 0;
`;

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding-inline-start: 15px;
`;

const ListItem = styled.li`
  ${pLeftWizardTitlePositive}
  margin-bottom: 10px;
  margin-top: 0;
  text-indent: -15px;
  :before {
    content: '— ';
    text-indent: -15px;
  }
`;

const TableHeader = styled.tr`
  background-image: ${props => props.theme.challenge.factAndMathTableHdr};
  ${labelLeftPositive};
  border-radius: 4px 4px 0 0;
`;

const TableHeaderCell = styled.td`
  ${labelLeftPositive};
  padding: 9px 15px 8px 15px;
  :first-child {
    border-radius: 4px 0 0 0;
  }
  :last-child {
    border-radius: 0 4px 0 0;
  }
`;

const TableCell = styled.td`
  ${pLeft};
  padding: 5px 15px 5px 15px;
`;

const Table = styled.table`
  border-spacing: 0;
  border-radius: 4px;
  box-shadow: 0 15px 40px -15px rgba(87, 87, 87, 0.35);
  margin-top: 23px;
  margin-bottom: 28px;
  padding-bottom: 10px;
`;

const Link = styled.a`
  ${pLeft}
  color: ${props => props.theme.colors.brand2Hue2};
  text-decoration: underline;
  :not(:first-child) {
    margin-left: 5px;
  }
`;

export const RefIcon = styled.span`
  ${pCenter}
  width: 12px;
  height: 15px;
  border-radius: 3px;
  background-color: ${props => props.theme.colors.soft2};
  display: inline-block;
  line-height: 15px;
  margin-left: 2px;
  margin-right: 2px;
  text-indent: 0;
  vertical-align: text-top;
`;

const Reference = styled.div`
  ${pLeft};
  margin-top: 10px;
`;
