import styled from 'styled-components';
import { labelLeftPositive } from '../../styles/text';

const UltraSmallButton = styled.button`
  border-radius: 2px;
  background-color: ${props => props.theme.buttonIcon.bg};
  ${labelLeftPositive}
  padding: 3px 5px;
  border: none;
  cursor: pointer;
`;

export default UltraSmallButton;
