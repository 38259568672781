import React, { memo } from 'react';
import { PropTypes } from 'prop-types';
import { Constants, Groups } from 'isomorphic';
import { useMessageGetter } from 'react-message-context';

import Co2 from '../../../functionalities/Co2';
import ProgressCard, {
  mapPersonalDailyAnswersToCumulativeActualProgress,
} from '../../data-cards/ProgressCard';
import { person } from '../../../assets/images';
import { SpacingShape } from '../../../util/shapes';

function PersonalProgressCard({
  status,
  initialCo2Kg = 0,
  targetCo2Kg = 0,
  dailyAnswers = {},
  groupDurationDays,
  groupStartDate,
  spacing,
  flat,
  challengeShortName = '',
}) {
  const messages = useMessageGetter();
  const { GroupStates } = Constants;
  const label = messages(`pages.group.charts.${status}.personal.title`);
  const totalReductionDesc = messages(`pages.group.charts.${status}.group.valueDesc`);
  let targetReductionPrc, totalReductionKg, sheetData;
  if (status === GroupStates.hatching) {
    targetReductionPrc = targetCo2Kg / initialCo2Kg;
    totalReductionKg = initialCo2Kg - targetCo2Kg;
    const totalTargetReductionKgCo2 = new Co2((totalReductionKg * groupDurationDays) / 365);
    const [equiValue, equiText] = new Co2(totalReductionKg).getRandomEquivalence(messages);
    sheetData = [
      {
        type: 'even',
        valueText: `${equiValue}`,
        desc: equiText,
      },
      {
        type: 'odd',
        valueText: totalTargetReductionKgCo2.getFullScaledText(),
        desc: messages('pages.group.charts.hatching.personal.infoText', {
          weeks: groupDurationDays / 7,
        }),
      },
    ];
  } else if (status === GroupStates.active) {
    targetReductionPrc = targetCo2Kg / initialCo2Kg;
    totalReductionKg = Groups.getTotalReductionKg(dailyAnswers);
    const totalProjectedReductionKg =
      (Math.abs(totalReductionKg) / Math.max(1, Object.keys(dailyAnswers).length)) * 365;
    const totalProjectedReductionKgCo2 = new Co2(totalProjectedReductionKg);
    if (totalReductionKg < 0) {
      sheetData = [
        {
          type: 'warning',
          valueText: `${new Co2(Math.abs(totalReductionKg)).getFullScaledText()}`,
          desc: messages('pages.group.charts.active.personal.warning.infoText', {
            csn: challengeShortName,
          }),
        },
        {
          type: 'warning',
          valueText: totalProjectedReductionKgCo2.getFullScaledText(),
          desc: messages('pages.group.charts.active.personal.warning.yearly'),
        },
      ];
    } else {
      const [equiValue, equiText] = new Co2(totalReductionKg).getRandomEquivalence(messages);
      sheetData = [
        {
          type: 'even',
          valueText: `${equiValue}`,
          desc: equiText,
        },
        {
          type: 'odd',
          valueText: totalProjectedReductionKgCo2.getFullScaledText(),
          desc: messages('pages.group.charts.active.personal.infoText'),
        },
      ];
    }
  } else if (status === GroupStates.alumni) {
    targetReductionPrc = targetCo2Kg / initialCo2Kg;
    totalReductionKg = Groups.getTotalReductionKg(dailyAnswers);
    const isIncrease = totalReductionKg < 0;
    const daysSinceStart = Groups.getGroupDay(groupStartDate, groupDurationDays);
    const reductionSinceStart = (totalReductionKg / groupDurationDays) * daysSinceStart;
    const [equiValue, equiText] = new Co2(totalReductionKg).getRandomEquivalence(messages);
    sheetData = [
      {
        type: isIncrease ? 'warning' : 'even',
        valueText: isIncrease
          ? `${new Co2(Math.abs(totalReductionKg)).getFullScaledText()}`
          : `${equiValue}`,
        desc: isIncrease
          ? messages('pages.group.charts.alumni.personal.warning.infoText', {
              csn: challengeShortName,
            })
          : equiText,
      },
      {
        type: isIncrease ? 'warning' : 'odd',
        valueText: isIncrease
          ? new Co2(Math.abs(reductionSinceStart)).getFullScaledText()
          : new Co2(reductionSinceStart).getFullScaledText(),
        desc: isIncrease
          ? messages('pages.group.charts.alumni.personal.warning.yearly', {
              csn: challengeShortName,
            })
          : messages('pages.group.charts.alumni.personal.infoText'),
      },
    ];
  }

  const values = mapPersonalDailyAnswersToCumulativeActualProgress(dailyAnswers) || [
    { index: 0, value: 0 },
  ];

  return (
    <ProgressCard
      key="personal"
      label={label}
      daysAmount={groupDurationDays}
      targetReductionPrc={targetReductionPrc}
      totalReductionKg={totalReductionKg}
      totalReductionDesc={totalReductionDesc}
      dailyAnswers={values}
      targetLegend={messages('pages.group.charts.personal.targetLegend')}
      refLegend={messages('pages.group.charts.personal.refLegend')}
      refValue={(initialCo2Kg / 365) * groupDurationDays}
      sheetData={sheetData}
      labelIcon={<img src={person} alt="" />}
      spacing={spacing}
      flat={flat}
    />
  );
}

PersonalProgressCard.propTypes = {
  status: PropTypes.oneOf(Object.values(Constants.GroupStates)),
  initialCo2Kg: PropTypes.number,
  targetCo2Kg: PropTypes.number,
  dailyAnswers: PropTypes.object,
  groupDurationDays: PropTypes.number.isRequired,
  groupStartDate: PropTypes.string.isRequired,
  challengeShortName: PropTypes.string,
  spacing: SpacingShape,
  flat: PropTypes.bool,
};

export default memo(PersonalProgressCard);
