import React, { useCallback, useEffect, useState, useMemo, Suspense } from 'react';
import { ThemeProvider } from 'styled-components';
import { useMessageGetter } from 'react-message-context';
import PropTypes from 'prop-types';
import { theme } from '../../theme';
import {
  FullscreenWizard,
  WizardStep,
  WizardStepContent,
  WizardHeading,
  FixedWizardFooter,
  WizardSelectOne,
  WizardValueText,
  WizardLinkText,
  WizardSelectMany,
  WizardList,
  HEADING_SIZES,
  WizardInputText,
  WizardRadioInline,
  WizardDivider,
  WizardInputSecretCode,
} from '../../components/wizard';
import WizardIllustration from '../../components/wizard/illustration/WizardIllustration';
import Button from '../../components/button/Button';
import WizardActionButtons from '../../components/wizard/footer/WizardActionButtons';
import WizardSliderControl from '../../components/wizard/form/slider/WizardSliderControl';
import WizardParagraph from '../../components/wizard/text/WizardParagraph';
import {
  RelativeWizardStepContent,
  AbsoluteWizardContentContainer,
  AbsoluteWizardHeaderContainer,
} from '../../components/wizard/container/PositionedWizardContainers';
import { Card, CardValuesRow, CardValue, CardDataSheet } from '../../components/card';
import WizardCardContainer from '../../components/wizard/container/WizardCardContainer';
import { mapBody } from '../../util/wizard';
import { useAppState } from '../../context/appContext';
import { storage } from '../../util/storage';
import API from '@aws-amplify/api-rest';
import ModalConfirm from '../../components/modal/ModalConfirm';
import * as namedImages from '../../assets/images/illustrations';
import { pages } from '../../functionalities/navigation';
import ModalFullscreen, { ModalContent } from '../../components/modal/ModalFullscreen';
import Box from '../../components/container/Box';
import GigaSelect, { getValue } from '../../components/wizard/form/select/GigaSelect';
import { Errors } from 'isomorphic';
import WizardIconText from '../../components/wizard/text/WizardIconText';
import WizardSectionHeading from '../../components/wizard/text/WizardSectionHeading';
import Co2 from '../../functionalities/Co2';
import ProgressCard, {
  mapPersonalDailyAnswersToCumulativeActualProgress,
} from '../../components/data-cards/ProgressCard';
import { ReactComponent as PersonSvg } from '../../assets/images/person.svg';
import SuccessCalendarCard from '../../components/data-cards/SuccessCalendarCard';
import { Actions, WizardComponents } from 'isomorphic/wizard';
import Center from '../../components/container/Center';
import { MatchShapeOfParams } from '../../util/shapes';
import { sum } from 'isomorphic/reducers';
import ModalGroupSubtitle from '../../components/modal/ModalGroupSubtitle';
import CalculusTable, { CalculusTableRow } from '../../components/table/CalculusTable';
import CalculusCell from '../../components/table/CalculusCell';
import CalculusRow from '../../components/table/CalculusRow';
import ButtonsAsContentContainer from '../../components/wizard/container/ButtonsAsContentContainer';
import SmallButton from '../../components/button/SmallButton';
import PreventBodyScroll from '../../components/modal/PreventBodyScroll';
import { Redirect, useHistory } from 'react-router';

const images = require.context('../../assets/images', true);

const Wizard = ({ loadConfig, onClose, match, modal = false }) => {
  const history = useHistory();
  const { user } = useAppState();
  const [config, setConfig] = useState(null);
  const [state, setState] = useState({
    x: 0,
    y: 0,
    prevX: 0,
    prevY: 0,
    maxX: 0,
    maxY: 0,
  });
  const [postPending, setPostPending] = useState(false);
  const [cache, setCache] = useState({});
  const [error, setError] = useState(null);
  const [evaluate, setEvaluate] = useState(null);
  const [validateClose, setValidateClose] = useState(false);
  const messages = useMessageGetter();

  // Picker wheel (GigaSelect) works weird way so we reference them in weird way as well
  const [pickerWheelGetters, setPickerWheelGetters] = useState({});
  const onPickerWheelMounted = useMemo(
    () => (ref, name) => {
      setPickerWheelGetters(getters => ({
        ...getters,
        [name]: () => getValue(ref),
      }));
    },
    [],
  );

  useEffect(() => {
    async function doLoad() {
      const config = await loadConfig();
      // Ensure config has steps, direct redirects do not have these
      if (config.steps) {
        setState(state => ({
          ...state,
          maxX: config.steps
            .map(step => (step[0].type === 'repeat' ? Object.values(step[0].options).length : 1))
            .reduce(sum, 0),
          maxY: config.steps.map(step => step.length).reduce((acc, val) => Math.max(acc, val), 1),
          ...config.defaultValues,
        }));
      }
      setConfig(config);
    }

    doLoad();
  }, [loadConfig, match.params.id]);

  useEffect(() => {
    async function load() {
      const evaluateModule = await import('../../util/mathjsEvaluate');
      setEvaluate(evaluateModule);
    }
    load();
  }, []);

  /// History handling to move back in wizard when pressing back
  const [addFakeHistory, setAddFakeHistory] = useState(!modal);
  const [onHistoryOnCloseCalled, setOnHistoryOnCloseCalled] = useState(false);
  const onHistoryChange = useCallback(
    event => {
      if (event.type === 'popstate') {
        event.stopPropagation();
        if (state.y > 0) {
          setState(state => ({
            ...state,
            y: state.y - 1,
          }));
          setAddFakeHistory(true);
        } else if (state.x > 0) {
          setState(state => ({
            ...state,
            x: state.x - 1,
          }));
          setAddFakeHistory(true);
        } else if (!onHistoryOnCloseCalled) {
          setOnHistoryOnCloseCalled(true);
          onClose();
        }
      }
    },
    [state.x, state.y, onClose, onHistoryOnCloseCalled],
  );

  useEffect(() => {
    if (addFakeHistory) {
      window.history.pushState({ type: 'wizard' }, null, null);
      setAddFakeHistory(false);
    }
    window.addEventListener('popstate', onHistoryChange);
    return () => {
      window.removeEventListener('popstate', onHistoryChange);
    };
  }, [onHistoryChange, addFakeHistory]);

  if (!config || !evaluate) {
    return (
      <ThemeProvider theme={theme('white')}>
        <ModalFullscreen onClose={history.goBack}>{null}</ModalFullscreen>
      </ThemeProvider>
    );
  }

  // Check if config is just a redirect
  if (config.action === Actions.Redirect) {
    return <Redirect to={config.redirectTo} push={config.redirectType === 'push'} />;
  }

  const { x, y, prevX, prevY, maxX, maxY } = state;
  const { steps, style } = config;
  const flattenedSteps = steps
    .map((step, i) => {
      if ((state.skippedSteps || []).includes(i)) {
        return [];
      }

      if (step[0].type === 'repeat') {
        const repeat = step[0].value
          .map(name => {
            if (Array.isArray(state[name])) {
              return state[name] || [];
            } else {
              return state[name] ? [state[name]] : [];
            }
          })
          .reduce((acc, val) => [...acc, ...val], []);
        // Attach parent info to all options
        const repeatedSteps = repeat.map(repeatValue =>
          step[0].options[repeatValue].map(repeatStep => ({
            ...repeatStep,
            parent: step[0].name,
          })),
        );
        return repeatedSteps;
      } else {
        return [step];
      }
    })
    .reduce((acc, val) => [...acc, ...val], []);

  async function handlePost(action, localStateValues) {
    if (postPending) {
      console.log('Skipping post handle, one is already pending.');
      return {};
    }
    setPostPending(true);

    const postState = {
      ...state,
      ...localStateValues,
    };
    const apiName = 'apigateway';
    const body = mapBody(action.body, postState);
    const init = { body };
    const path = action.endpoint;
    // If post requires login but user is not logged in, add the action as deferred action
    if (!user.isLoggedIn && action.validate === 'loggedIn') {
      const redirectTo = pages[action.onSuccessRedirectTo]
        ? pages[action.onSuccessRedirectTo](postState)
        : undefined;
      const deferredAction = {
        action: 'apiAction',
        apiName,
        path,
        init,
        onSuccessRedirectTo: redirectTo,
      };

      let expiry = new Date();
      expiry.setTime(expiry.getTime() + 15 * 60 * 1000);
      storage.add('deferredAction', deferredAction, expiry);

      history.push(action.loginUrl);
    } else {
      try {
        const response = await API.post(apiName, path, init);
        setPostPending(false);
        if (pages[action.onSuccessRedirectTo]) {
          const redirectTo = pages[action.onSuccessRedirectTo]({
            ...postState,
            ...response,
          });
          history.replace(redirectTo);
        } else if (action.onSuccess) {
          handleActions(action.onSuccess);
        } else {
          return response;
        }
      } catch (error) {
        if (error?.response?.data?.code === Errors.JOIN_BANNED) {
          setError({
            title: messages('pages.wizards.join.banned.title'),
            description: messages('pages.wizards.join.banned.description'),
            type: 'banned',
          });
        } else if (action.onFail) {
          // Let the handling of actions end before handling onFail actions
          setTimeout(() => {
            handleActions(action.onFail, error?.response?.data);
          }, 0);
        } else if (error.response && error.response.data) {
          setError({
            title: 'Error',
            description: error.response.data.error,
          });
        } else {
          setError({
            title: 'Error',
            description: 'Unknown error occured',
          });
        }
      }
    }
    setPostPending(false);
  }

  async function handleActions(actions, ...params) {
    let value, nextY, nextX, redirectTo, redirectType;
    const cacheValues = {};
    let localStateValues = {};

    const checkConditions = (valueName, equals) => {
      const value = { ...state, ...localStateValues, ...(params.length > 0 ? params[0] : {}) }[
        valueName
      ];

      if (equals === true || equals === false) {
        if (Array.isArray(value)) {
          if ((value.length === 0 && !equals) || (value.length > 0 && equals)) {
            return true;
          }
        } else {
          if ((value && equals) || (!value && !equals)) {
            return true;
          }
        }
      } else {
        return Array.isArray(value) ? value.every(val => val === equals) : value === equals;
      }

      return false;
    };

    for (let action of actions) {
      switch (action.action) {
        // Run action if condition is met
        case Actions.Conditional:
          const runAction = action.values.every(valueName =>
            checkConditions(valueName, action.equals),
          );

          if (runAction) {
            if (Array.isArray(action.then)) {
              actions.push(...action.then);
            } else {
              actions.push(action.then);
            }
          } else if (action.else) {
            if (Array.isArray(action.else)) {
              actions.push(...action.else);
            } else {
              actions.push(action.else);
            }
          }
          break;
        case Actions.SetRadioValue:
          localStateValues[action.name] = action.value;
          localStateValues[`${action.name}Select`] = action.value;
          localStateValues[`${action.name}SelectTitle`] = messages(action.title, state);
          break;
        case Actions.RecordPickerValue:
          value = pickerWheelGetters[action.pickerName]();
          localStateValues[action.valueName] = value;
          break;
        // Cache a value for safekeeping, can be restored to another variable using 'restoreCacheValue'
        case Actions.CacheValue:
          if (Array.isArray(state[action.valueName])) {
            cacheValues[action.valueName] = [...state[action.valueName]];
          } else if (typeof state[action.valueName] === 'object') {
            cacheValues[action.valueName] = {
              ...state[action.valueName],
            };
          } else {
            cacheValues[action.valueName] = state[action.valueName];
          }
          break;
        // Restores a value from cache and puts it into STATE
        case Actions.RestoreCache:
          localStateValues[action.valueName] = cache[action.valueName];
          break;
        // Add non null values to a list
        case Actions.SetListValue:
          const listValueParams = { ...state, ...localStateValues };
          value = action.values
            .map(valueName => {
              if (Array.isArray(listValueParams[valueName])) {
                return listValueParams[valueName];
              } else {
                return [listValueParams[valueName]];
              }
            })
            .reduce((acc, val) => acc.concat(val), [])
            .filter(v => !!v);
          localStateValues[action.name] = value;
          break;
        // Evaluates a formula with state, values, localStateValues as params and puts that eventually to STATE
        case Actions.SetValue:
          params = { ...state, ...localStateValues };

          value =
            action.precision !== undefined
              ? Math.round(evaluate.default(action.formula, params) * 10 ** action.precision) /
                10 ** action.precision
              : evaluate.default(action.formula, params);

          if (action.text) {
            value = messages(action.text, { value });
          }

          localStateValues[action.name] = value;
          break;
        // Pick an array from state and save its length to a value in STATE
        case Actions.SetListLengthValue:
          localStateValues[action.name] = (
            { ...state, ...localStateValues }[action.value] || []
          ).length;
          break;
        case Actions.SetValueFromList:
          params = { ...state, ...localStateValues };
          value =
            params[action.value] && params[action.value].length >= action.index + 1
              ? params[action.value][action.index]
              : action.defaultValue;

          if (action.text) {
            value = messages(action.text, { value });
          }

          localStateValues[action.name] = value;
          break;
        case Actions.ToggleValue:
          let [isOn] = params;
          if (isOn) {
            if (action.onFormula) {
              value = evaluate.default(action.onFormula, { ...state, ...localStateValues });
            } else if (action.on !== undefined) {
              value = action.on;
            }
          } else {
            if (action.offFormula) {
              value = evaluate.default(action.offFormula, { ...state, ...localStateValues });
            } else if (action.off !== undefined) {
              value = action.off;
            }
          }
          localStateValues[action.name] = value;
          break;
        case Actions.Post:
          value = await handlePost(action, localStateValues);
          if (value) {
            if (Array.isArray(value)) {
              actions.push(...value);
            } else {
              for (let key in value) {
                localStateValues[key] = value[key];
              }
            }
          }
          break;
        case Actions.Close:
          onClose();
          break;
        case Actions.Redirect:
          redirectTo = pages[action.redirectTo]?.() || action.redirectTo;
          redirectType = action.redirectType;
          break;
        case Actions.Forward:
          if (flattenedSteps[x][y].onExit) {
            actions.push(...flattenedSteps[x][y].onExit);
          }

          if (!!flattenedSteps[x][y].parent) {
            const { parent } = flattenedSteps[x][y];
            // Step has a parent, which means it was generated from repeat:
            // - Check if this is last generated one and if there is onExit on parent
            const isLast =
              flattenedSteps.findIndex((step, idx) => step[0].parent === parent && idx > x) === -1;
            // NOTE: onExit happens only when exiting forward
            if (isLast) {
              const parentStep = steps.find(step => step[0].name === parent);
              if (parentStep && parentStep[0].onExit) {
                actions.push(...parentStep[0].onExit);
              }
            }
          }

          nextX = state.x + 1;

          if (flattenedSteps[nextX][y].onEnter) {
            actions.push(...flattenedSteps[nextX][y].onEnter);
          }

          localStateValues.x = nextX;
          localStateValues.prevX = state.x;
          break;
        case Actions.Down:
          if (flattenedSteps[x][y].onExit) {
            actions.push(...flattenedSteps[x][y].onExit);
          }

          nextY = state.y + 1;

          if (flattenedSteps[x][nextY].onEnter) {
            actions.push(...flattenedSteps[x][nextY].onEnter);
          }

          localStateValues.y = nextY;
          localStateValues.prevY = state.y;
          break;
        case Actions.Up:
          if (flattenedSteps[x][y].onExit) {
            actions.push(...flattenedSteps[x][y].onExit);
          }

          nextY = state.y - 1;

          if (flattenedSteps[x][nextY].onEnter) {
            actions.push(...flattenedSteps[x][nextY].onEnter);
          }

          localStateValues.y = nextY;
          localStateValues.prevY = state.y;
          break;
        case Actions.Back:
          nextX = state.x - 1;
          localStateValues.x = nextX;
          localStateValues.prevX = state.x;
          break;
        case Actions.OnChange:
          localStateValues[action.name] = action.value;
          break;
        case Actions.DoChanges:
          localStateValues = {
            ...localStateValues,
            ...action.changes,
          };
          break;
        case Actions.SkipStep:
          localStateValues.skippedSteps = [
            ...(state.skippedSteps || []),
            ...(localStateValues.skippedSteps || []),
            action.index,
          ];
          break;
        case Actions.DisplayCodeError:
          localStateValues.codeErrorText = messages('pages.wizards.join.wrongCode');
          break;
        default:
          console.log('Unknown action', action);
      }
    }

    setCache(cache => ({
      ...cache,
      ...cacheValues,
    }));

    setState(state => ({
      ...state,
      ...localStateValues,
    }));

    if (redirectTo) {
      if (redirectType === 'push') {
        history.push(redirectTo);
      } else {
        history.replace(redirectTo);
      }
    }

    return {};
  }

  // Predefined actions
  const defaultActions = {
    onChange: e => {
      const { name, value } = e.target;
      handleActions([
        {
          action: Actions.OnChange,
          name,
          value,
        },
      ]);
    },
    onSelect: (value, name) => {
      const selectedInput = flattenedSteps[x][y].content.find(input => input.name === name);
      const selectedOption = selectedInput.options.find(option => option.name === value);
      const actions = [
        {
          action: Actions.DoChanges,
          changes: {
            ...(selectedOption.value !== undefined ? { [name]: selectedOption.value } : {}),
            [`${name}Select`]: value,
            [`${name}SelectTitle`]: messages(selectedOption.title, state),
          },
        },
      ];

      if (selectedOption.action && typeof selectedOption.action === 'string') {
        actions.push({ action: selectedOption.action });
      } else if (selectedOption.action) {
        actions.push(selectedOption.action);
      }

      handleActions(actions);
    },
    onSelectMany: (index, value, name) => {
      const selectedInput = flattenedSteps[x][y].content.find(
        input => input.name === name && input.options.some(opt => opt.value === value),
      );
      const selectedOption = selectedInput.options.find(option => option.value === value);
      const valueIndex = (state[name] || []).indexOf(value);
      const actions = [
        {
          action: Actions.DoChanges,
          changes: {},
        },
      ];
      // Remove
      if (valueIndex > -1) {
        actions[0].changes = {
          [name]: (state[name] || [])
            .slice(0, valueIndex)
            .concat((state[name] || []).slice(valueIndex + 1)),
        };
      }
      // Add
      else {
        actions[0].changes = {
          [name]: [...(state[name] || []), value],
        };
      }

      if (selectedOption.actions) {
        actions.push(...selectedOption.actions);
      }

      handleActions(actions, [valueIndex !== -1]);
    },
  };

  const onClickButton = button => {
    if (button.action) {
      handleActions([button]);
    } else if (button.actions) {
      handleActions(button.actions);
    }
  };

  const mapListData = content => {
    const stateValues = state[content.valuesRef] || [];
    const params = { ...state };
    return stateValues.map(value => {
      const item = content.items.find(item => item.value === value);
      return {
        label: item.title,
        desc: messages(item.description, params),
      };
    });
  };

  const doValidate = validate => {
    if (!validate) {
      return false;
    }

    if (!Array.isArray(validate)) {
      validate = [validate];
    }

    return validate.every(val => {
      if (!state[val]) {
        return true;
      } else if (Array.isArray(state[val]) && state[val].length === 0) {
        return true;
      } else {
        return false;
      }
    });
  };

  const mapButton = (button, i) => {
    switch (button.type) {
      case 'primary':
      case 'secondary':
        const buttonDisabled = doValidate(button.validate);
        return (
          <Button
            key={`button-${i}`}
            loading={postPending}
            primary={button.type === 'primary'}
            secondary={button.type === 'secondary'}
            disabled={buttonDisabled}
            onClick={() => onClickButton(button)}
          >
            {messages(button.text, state)}
          </Button>
        );
      case 'small':
        return (
          <SmallButton
            key={`button-${i}`}
            loading={postPending}
            text={messages(button.text, state)}
            onClick={() => onClickButton(button)}
          />
        );
      case 'arrows':
        const onForthDisabled = x + 1 > maxX || doValidate(button.validate);
        const onBackDisabled = x === 0;

        const onBackText = button.onBackText ? messages(button.onBackText, state) : undefined;
        const onBackActions = button.onBackActions || [{ action: 'back' }];

        const onForthText = button.onForthText ? messages(button.onForthText, state) : undefined;
        const onForthActions = button.onForthActions || [{ action: 'forward' }];

        return (
          <WizardActionButtons
            key={`button-${i}`}
            onBack={async () => handleActions(onBackActions)}
            onBackDisabled={onBackDisabled}
            onBackVisibility={button.onBackVisibility}
            onBackText={onBackText}
            onForth={async () => await handleActions(onForthActions)}
            onForthDisabled={onForthDisabled}
            onForthArrow={button.onForthArrow}
            onForthText={onForthText}
            arrowLeftBg={button.arrowLeftBg}
          />
        );
      default:
        console.log('Unknown button type ', button);
        return null;
    }
  };

  const mapCardDataSheetValue = (data, i) => {
    switch (data.type) {
      case 'equivalence':
        const equiCo2 = new Co2(state[data.valueName]);
        const [equiValue, equiDesc] = equiCo2.getRandomEquivalence(messages);
        return {
          type: i % 2 === 0 ? 'even' : 'odd',
          valueText: `${equiValue}`,
          desc: equiDesc,
        };
      case 'co2':
        const co2e = new Co2(state[data.valueName]);
        return {
          type: i % 2 === 0 ? 'even' : 'odd',
          valueText: co2e.getFullScaledText(),
          desc: messages(data.desc, state),
        };
      case 'warning':
        const warningCo2e = new Co2(Math.abs(state[data.valueName]));
        return {
          type: 'warning',
          valueText: warningCo2e.getFullScaledText(),
          desc: messages(data.desc, state),
        };
      case 'warningText':
        return {
          type: 'warning',
          valueText: messages(data.valueName, state),
          desc: messages(data.desc, state),
        };
      case 'text':
        return {
          type: i % 2 === 0 ? 'even' : 'odd',
          valueText: messages(data.valueName, state),
          desc: messages(data.desc, state),
        };
      default:
        console.log('Unknown progress card sheet value type', data.type);
        return null;
    }
  };

  const mapCalculusContent = calculus => ({
    ...calculus,
    formula: calculus.formula ? messages(calculus.formula, state) : undefined,
    value: calculus.value ? messages(calculus.value, state) : undefined,
    valueUnit: calculus.valueUnit ? messages(calculus.valueUnit, state) : undefined,
    description: calculus.description ? messages(calculus.description, state) : undefined,
  });

  const mapContent = (content, i) => {
    let message, options;

    if (content.show) {
      if (!state[content.show]) {
        return null;
      }
    }

    switch (content.type) {
      case WizardComponents.BigValue:
        message = messages(content.value, state);
        return (
          <WizardValueText
            key={`content-${i}`}
            value={message}
            valueUnit={content.unit ? messages(content.unit) : ''}
            valueDescription={content.description ? messages(content.description) : ''}
          />
        );
      case WizardComponents.CalculusTable:
        const colSpan = content.items[0].length;
        return (
          <CalculusTable
            key={`content-${i}`}
            title={messages(content.title, state)}
            subtitle={content.subtitle && messages(content.subtitle, state)}
            cellCount={colSpan}
          >
            {content.items.map((calculusRow, idx) => (
              <CalculusTableRow key={idx}>
                {calculusRow
                  .map(cellProps => ({
                    ...cellProps,
                    label: messages(cellProps.label, cellProps.params || state),
                    description: cellProps.description
                      ? messages(cellProps.description, cellProps.params || state)
                      : undefined,
                  }))
                  .map((cellProps, idx) => (
                    <CalculusCell key={idx} {...cellProps} />
                  ))}
              </CalculusTableRow>
            ))}
            {content.calculus && (
              <CalculusRow {...mapCalculusContent(content.calculus)} colSpan={colSpan} />
            )}
          </CalculusTable>
        );
      case WizardComponents.Calculation:
        message = messages(content.text, state);
        const icon = content.variableIcon
          ? `${content.iconPath || ''}${state[content.variableIcon]}.svg`
          : content.icon;
        return <WizardIconText key={`content-${i}`} icon={icon} text={message} />;
      case WizardComponents.Conditional:
        if (state[content.condition]) {
          return mapContent(content.props, i);
        }
        return undefined;
      case WizardComponents.Input:
        return (
          <ModalContent key={`content-${i}`}>
            <WizardInputText
              name={content.name}
              label={messages(content.title)}
              hint={messages(content.verifiedLabel)}
              onChange={defaultActions.onChange}
              value={state[content.name] || ''}
              placeholder={messages(content.placeholder, state)}
            />
          </ModalContent>
        );
      case WizardComponents.Heading:
        message = messages(content.text, state);
        return <WizardSectionHeading key={`content-${i}`}>{message}</WizardSectionHeading>;
      case WizardComponents.Paragraph:
        message = messages(content.text, state);
        return (
          <WizardParagraph key={`content-${i}`} centered={content.centered || false}>
            {message}
          </WizardParagraph>
        );
      case WizardComponents.Slider:
        let maxValue;
        if (content.maxValue !== undefined) {
          maxValue = content.maxValue;
        } else if (content.maxValueRef !== undefined) {
          maxValue = state[content.maxValueRef];
        }
        return (
          <WizardSliderControl
            key={`content-${i}`}
            name={content.name}
            value={state[content.name]}
            minValue={content.minValue}
            maxValue={maxValue}
            labelMin={messages(content.minDesc, state) || ''}
            labelMax={messages(content.maxDesc, state) || ''}
            valueDescription={content.unit}
            step={content.step}
            onChange={defaultActions.onChange}
          />
        );
      case WizardComponents.Radio:
        options = content.options.map((option, i) => {
          const optMsgParams = { ...state, ...(option.params || {}) };
          if (option.type === 'subtitle') {
            return {
              key: `subtitle-${i}`,
              title: messages(option.title, optMsgParams),
              type: 'subtitle',
            };
          } else {
            return {
              ...option,
              key: option.name,
              realValue: option.value,
              value: option.name,
              icon: option.icon && images(`./${option.icon}`).default,
              iconText: option.iconText ? messages(option.iconText, optMsgParams) : undefined,
              title: messages(option.title, optMsgParams),
              subtitle: option.description ? messages(option.description, optMsgParams) : '',
            };
          }
        });
        return (
          <WizardSelectOne
            key={`input-${i}`}
            name={content.name}
            options={options}
            title={content.title ? messages(content.title, state) : undefined}
            value={state[`${content.name}Select`]}
            onSelect={defaultActions.onSelect}
          />
        );
      case WizardComponents.RadioInline:
        options = content.options.map(option => ({
          ...option,
          value: option.name,
          realValue: option.value,
          title: messages(option.title, option.optionParams || state),
          subtitle: option.subtitle
            ? messages(option.subtitle, option.optionParams || state)
            : undefined,
        }));
        return (
          <WizardRadioInline
            key={`input-${i}`}
            name={content.name}
            options={options}
            title={content.title ? messages(content.title, state) : ''}
            subtitle={content.subtitle ? messages(content.subtitle, state) : ''}
            value={state[`${content.name}Select`]}
            onSelect={defaultActions.onSelect}
          />
        );
      case WizardComponents.Checkbox:
        options = content.options
          .map((option, i) => ({
            ...option,
            key: `${content.name}-${i}`,
            title: messages(option.title, state),
            subtitle: messages(option.description, option.params || state),
          }))
          .filter(option => {
            if (content.optionsFilterValue) {
              return (state[content.optionsFilterValue] || []).includes(option.value);
            }
            return true;
          });
        return (
          <WizardSelectMany
            key={`input-${i}`}
            name={content.name}
            options={options}
            value={state[content.name] || []}
            label={content.title ? messages(content.title, state) : undefined}
            onSelect={defaultActions.onSelectMany}
          />
        );
      case WizardComponents.Link:
        return (
          <WizardLinkText
            key={`content-${i}`}
            onClick={() => handleActions([{ action: content.action }])}
          >
            {messages(content.text, state)}
          </WizardLinkText>
        );
      case WizardComponents.Card:
        return (
          <ThemeProvider key={`content-${i}`} theme={theme(content.theme)}>
            <WizardCardContainer>
              <Card>
                {mapCardValuesRow(content)}
                {mapCardDataSheet(content)}
              </Card>
            </WizardCardContainer>
          </ThemeProvider>
        );
      case WizardComponents.ValueList:
        return (
          <WizardList key={`content-${i}`} title={content.title} data={mapListData(content)} />
        );
      case WizardComponents.GigaSelect:
        const roundByStep = (value, step = 1) => {
          return Math.floor(value / step) * step;
        };

        const getInitialValue = (currentValue, initialValue) => {
          if (currentValue !== undefined) {
            return roundByStep(currentValue, content.step);
          } else if (Number.isInteger(initialValue)) {
            return initialValue;
          } else if (initialValue !== undefined) {
            return roundByStep(state[initialValue], content.step);
          }

          return undefined;
        };
        const gigaSelectInititalValue = getInitialValue(state[content.name], content.initialValue);
        let max;

        if (content.maxValue !== undefined) {
          max = roundByStep(content.maxValue, content.step);
        } else if (content.maxValueRef !== undefined) {
          max = roundByStep(state[content.maxValueRef], content.step);
        }

        return (
          <GigaSelect
            key={`content-${i}`}
            name={content.name}
            onMounted={onPickerWheelMounted}
            initialValue={gigaSelectInititalValue}
            icon={content.icon}
            unit={content.unit ? messages(content.unit) : ''}
            unitSecondary={content.unitSecondary ? messages(content.unitSecondary) : ''}
            step={content.step}
            max={max}
            min={content.minValue}
          />
        );
      case WizardComponents.ProgressCard:
        const sheetData = content.sheetData
          .filter(({ condition }) => evaluate.default(condition, state))
          .map(mapCardDataSheetValue)
          .filter(val => val !== null);

        const progressDailyAnswers = mapPersonalDailyAnswersToCumulativeActualProgress(
          state[content.dailyAnswersName],
        ) || [{ index: 0, value: 0 }];

        return (
          <ThemeProvider theme={theme('white')} key={`content-${i}`}>
            <WizardCardContainer>
              <ProgressCard
                labelIcon={<PersonSvg />}
                label={messages(content.label, state)}
                daysAmount={content.daysAmount}
                targetReductionPrc={content.targetReductionPrc}
                targetLegend={
                  content.targetLegend ? messages(content.targetLegend, state) : undefined
                }
                refLegend={content.refLegend ? messages(content.refLegend, state) : undefined}
                refValue={content.refValue}
                totalReductionKg={state[content.totalReductionKgName]}
                totalReductionDesc={
                  content.totalReductionDesc
                    ? messages(content.totalReductionDesc, state)
                    : undefined
                }
                dailyAnswers={progressDailyAnswers}
                sheetData={sheetData}
              />
            </WizardCardContainer>
            {
              /* Show success calendar only if user has picked some actions to perform in first place */
              content.plannedActions?.length && (
                <WizardCardContainer>
                  <SuccessCalendarCard
                    startDate={state.startDateTime}
                    daysAmount={content.durationDays}
                    dailyAnswers={Object.keys(state.dailyAnswers).map(dayValue => ({
                      day: parseInt(dayValue),
                      successValue: state.dailyAnswers[dayValue].successValue,
                    }))}
                    messages={messages}
                  />
                </WizardCardContainer>
              )
            }
          </ThemeProvider>
        );
      case WizardComponents.Code:
        return (
          <Center key={`content-${i}`}>
            <WizardInputSecretCode
              placeholder={messages('pages.wizards.login.sms.confirm.placeholder')}
              value={state[content.name]}
              onChange={value => {
                setState({
                  ...state,
                  [content.name]: value,
                });
              }}
            />
          </Center>
        );
      case 'divider':
        return <WizardDivider key={`content-${i}`} />;
      case WizardComponents.Button:
        return (
          <ButtonsAsContentContainer key={`content-${i}`}>
            {mapButton(content.content)}
          </ButtonsAsContentContainer>
        );
      default:
        console.log('Unknown content type', content);
        return null;
    }
  };

  const mapCardValue = (content, i) => {
    switch (content.type) {
      case 'bigValue':
        return (
          <CardValue
            key={`bigValue${i}`}
            label={messages(content.title)}
            value={messages(content.value, state)}
            valueUnit={messages(content.unit)}
            desc={messages(content.description)}
          />
        );
      default:
        console.log('Unknown card value', content);
        return null;
    }
  };

  const mapCardValuesRow = content => {
    if (!content.numbers || content.numbers.length === 0) {
      return null;
    }

    return <CardValuesRow>{content.numbers.map(mapCardValue)}</CardValuesRow>;
  };

  const mapCardDataSheet = content => {
    if (!content.values || content.values.length === 0) {
      return null;
    }

    const data = content.values
      .filter(({ condition }) => !condition || evaluate.default(condition, state))
      .map(mapCardDataSheetValue);

    return <CardDataSheet columns={1} data={data} />;
  };

  const mapFooter = () => {
    const data = flattenedSteps[x][y];
    const buttons = data.buttons;

    if (!buttons || buttons.length === 0) {
      return null;
    }

    const align = buttons.some(({ type }) => type === 'arrows') ? 'flex-end' : 'center';
    const buttonElements = buttons.map(mapButton);

    if (align === 'center') {
      return <FixedWizardFooter align={align}>{buttonElements}</FixedWizardFooter>;
    } else {
      return <FixedWizardFooter align={align}>{buttonElements}</FixedWizardFooter>;
    }
  };

  const mapModalSubtitle = function (data) {
    switch (data.component) {
      case 'ModalGroupSubtitle':
        return (
          <ModalGroupSubtitle
            {...data.props}
            onClick={() => history.push(data.onClickRedirectTo)}
          />
        );
      default:
        console.error('Unknown modal subtitle component:', data);
        return undefined;
    }
  };

  const getStep = (stepX, stepY, data) => {
    // Show current step and previous step so that the content nicely slides away
    if ((x !== stepX && prevX !== stepX) || (y !== stepY && prevY !== stepY)) {
      return undefined;
    }

    const title = data.title && messages(data.title, state);

    let subtitle;
    if (data.description && Array.isArray(data.description)) {
      subtitle = data.description.map(desc => messages(desc, state));
    } else if (data.description) {
      subtitle = messages(data.description, state);
    }

    if (data.type === 'input') {
      return (
        <RelativeWizardStepContent>
          <AbsoluteWizardHeaderContainer>
            <WizardHeading title={title} subtitle={subtitle ? subtitle.split('\n') : undefined} />
          </AbsoluteWizardHeaderContainer>
          <AbsoluteWizardContentContainer>
            {(data.content || []).map(mapContent)}
          </AbsoluteWizardContentContainer>
        </RelativeWizardStepContent>
      );
    } else if (data.type === 'modal') {
      const modalSubtitle = mapModalSubtitle(data.subtitle);
      return (
        <ModalFullscreen
          title={title}
          subtitle={modalSubtitle}
          border={true}
          onClose={history.goBack}
          style={{ paddingBottom: '90px' }} // Bit iffy but needed to make space for fixed footer
        >
          <ModalContent style={{ paddingTop: '30px' }}>
            {(data.content || []).map(mapContent)}
          </ModalContent>
        </ModalFullscreen>
      );
    }

    return (
      <WizardStepContent>
        {data.icon && (
          <Box margin="0 0 20px 0" style={{ display: 'block', textAlign: 'center' }}>
            <WizardIllustration src={namedImages[data.icon]} />
          </Box>
        )}
        <WizardHeading
          type={HEADING_SIZES.HUGE}
          title={title}
          subtitle={subtitle ? subtitle.split('\n') : undefined}
        />
        {(data.content || []).map(mapContent)}
      </WizardStepContent>
    );
  };

  return (
    <ThemeProvider theme={theme(style.theme)}>
      <PreventBodyScroll />
      <Suspense>
        <FullscreenWizard
          x={x}
          y={y}
          maxX={maxX}
          maxY={maxY}
          parallaxBg={style.bgImage && namedImages[style.bgImage]}
          footer={mapFooter()}
        >
          {flattenedSteps.map((step, xIdx) =>
            step.map((data, yIdx) => (
              <WizardStep
                key={`step-${xIdx}-${yIdx}`}
                x={xIdx}
                y={yIdx}
                maxY={maxY}
                title={data.heading ? messages(data.heading, state) : ''}
                onClose={
                  config.closeValidation
                    ? () => setValidateClose(true)
                    : () => handleActions([{ action: Actions.Close }])
                }
              >
                {getStep(xIdx, yIdx, data)}
              </WizardStep>
            )),
          )}
        </FullscreenWizard>
        {validateClose && (
          <ModalConfirm
            x={0}
            y={0}
            title={messages(config.closeValidation.title, state)}
            description={messages(config.closeValidation.description, state)}
            onCancel={() => setValidateClose(false)}
            options={[
              {
                label: messages(config.closeValidation.confirm, state),
                key: 'ok',
                onClick: () => handleActions([{ action: Actions.Close }]),
              },
              {
                label: messages(config.closeValidation.cancel, state),
                key: 'cancel',
                onClick: () => setValidateClose(false),
              },
            ]}
          />
        )}
        {error && (
          <ModalConfirm
            x={0}
            y={0}
            title={error.title}
            description={error.description}
            onCancel={() => setError(null)}
            options={[
              {
                label: 'Ok',
                key: 'ok',
                onClick: () => {
                  if (error.type === 'banned') {
                    handleActions([{ action: Actions.Close }]);
                  }
                  setError(null);
                },
              },
            ]}
          />
        )}
      </Suspense>
    </ThemeProvider>
  );
};

Wizard.propTypes = {
  //history: HistoryShape.isRequired,
  loadConfig: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  match: MatchShapeOfParams([]),
  modal: PropTypes.bool,
};

export default Wizard;
