import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { labelFormElementPositive } from '../../styles/text';
import { LineChart } from '../chart';
import CardValue from './CardValue';
import { LineChartShape } from '../../util/shapes';

const CardItem = ({ label, value, valueUnit, description, chartData, isIncrease }) => (
  <CardItemContainer>
    <CardLabel>{label}</CardLabel>
    <SideBySide>
      <CardValue value={value} valueUnit={valueUnit} desc={description} small />
      {chartData && (
        <LineChartContainer>
          <LineChart data={chartData} refType="solid" isIncrease={isIncrease} />
        </LineChartContainer>
      )}
    </SideBySide>
  </CardItemContainer>
);

CardItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  valueUnit: PropTypes.string,
  description: PropTypes.string,
  chartData: LineChartShape,
  isIncrease: PropTypes.bool,
};

export default CardItem;

export const CardItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 0;
`;

const CardLabel = styled.div`
  ${labelFormElementPositive};
  max-height: 13px;
`;

const SideBySide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const LineChartContainer = styled.div`
  max-width: 54px;
`;
