import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Message } from 'react-message-context';
import elements from '../../styles/elements';
import { h1CenterNegative, pNegative } from '../../styles/text';
import { PropTypes } from 'prop-types';
import ProfilePicture, { SIZES, SHAPES, VARIANT } from '../picture/ProfilePicture';
import HeroHeader, { HeroHeaderVariant } from './HeroHeader';

const ProfileHeader = ({ userId, givenName, familyName, joinDate }) => {
  return (
    <Fragment>
      <HeroHeader variant={HeroHeaderVariant.ROUNDED}>
        <Container>
          <Title>{`${givenName} ${familyName}`}</Title>
          <Subtitle>
            {joinDate && <Message id="pages.user.header.memberText" since={joinDate} />}
          </Subtitle>
          <ProfilePicture
            userId={userId}
            size={SIZES.LARGE}
            shape={SHAPES.SQUARE}
            variant={VARIANT.POSITIVE}
            centered
          />
        </Container>
      </HeroHeader>
      <WhiteSpace />
    </Fragment>
  );
};
ProfileHeader.propTypes = {
  givenName: PropTypes.string.isRequired,
  familyName: PropTypes.string.isRequired,
  joinDate: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};

const Container = styled.div`
  transform: translateY(${elements.topBarHeightPx}px);
  height: ${elements.headerHeightPx}px;
  text-align: center;
`;

const WhiteSpace = styled.div`
  min-height: ${elements.topBarHeightPx + 20}px;
`;

const Title = styled.h1`
  ${h1CenterNegative};
  color: ${props => props.theme.header.text};
  margin-top: 0;
  margin-bottom: 2px;
`;

const Subtitle = styled.p`
  ${pNegative};
  margin-top: 0;
  margin-bottom: 20px;
  color: ${props => props.theme.header.text};
  text-align: center;
`;

export default ProfileHeader;
