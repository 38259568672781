import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import LoadingDotsIndicator from '../indicator/LoadingDotsIndicator';
import { navItemNegative } from '../../styles/text';
import { arrowRightSmallNegative } from '../../assets/images';

const SmallButton = React.forwardRef(
  (
    { disabled = false, loading = false, onClick = () => {}, type = 'primary', text, iconSrc },
    ref,
  ) => {
    return (
      <Container
        disabled={disabled}
        type={type}
        onClick={!disabled ? onClick : undefined}
        ref={ref}
      >
        {text}
        <OverflowContainer>
          <ButtonContentContainer isLoading={loading}>
            <LoadingDotsContainer>
              <LoadingDotsIndicator />
            </LoadingDotsContainer>
            <Icon src={iconSrc ? iconSrc : arrowRightSmallNegative} type={type} alt="" />
          </ButtonContentContainer>
        </OverflowContainer>
      </Container>
    );
  },
);

SmallButton.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['primary', 'secondary', 'positive']),
  text: PropTypes.string.isRequired,
  iconSrc: PropTypes.string,
};

export default SmallButton;

const Container = styled.button.attrs(props => ({
  style: {
    ...(props.disabled
      ? {
          backgroundColor: props.theme.smallButton.bg.disabled,
          backgroundImage: 'none', // Override the gradient
        }
      : {}),
  },
}))`
  ${navItemNegative};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 15px 0 15px;
  height: 34px;
  max-height: 34px;
  border-radius: 17px;
  background-color: ${({ theme }) => theme.smallButton.bgColor};
  background-image: ${({ type, theme }) => theme.smallButton.bg[type]};
  color: ${({ type, theme }) => theme.smallButton.color[type]};
  border: 0;
  text-transform: uppercase;
  transition: 100ms;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transform: scale(1);
  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
  &:active {
    transform: scale(0.9);
  }
`;

const OverflowContainer = styled.div`
  height: 34px;
  max-height: 34px;
  overflow-y: hidden;
  position: relative;
  margin-left: 4px;
`;

const ButtonContentContainer = styled.div`
  transition-delay: 100ms;
  transition: 300ms;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transform: translateY(-${props => (props.isLoading ? '0' : '34px')});
`;

const Icon = styled.img.attrs(() => ({
  width: '15',
  height: '15',
}))`
  width: 15px;
  height: 15px;
  margin-top: 10px;
  path {
    fill: ${({ type, theme }) => theme.smallButton.color[type]};
  }
`;

const LoadingDotsContainer = styled.div`
  width: 16px;
  height: 34px;
  min-width: 16px;
  max-width: 16px;
  min-height: 34px;
  max-height: 34px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
