import { Util } from 'isomorphic';

export function getLinkUrl() {
  return window.location.pathname + window.location.hash;
}

export const navigationParents = {
  home: {
    page: 'home',
    url: '/',
  },
  blog: {
    page: 'blog',
    url: '/blog',
  },
  challenges: {
    page: 'challenges',
    url: '/challenges',
  },
  messages: {
    page: 'messages',
    url: '/messages',
  },
  user: {
    page: 'user',
    url: '/user',
  },
  signup: {
    page: 'signup',
    url: '/auth',
  },
  login: {
    page: 'login',
    url: '/auth',
  },
  auth: {
    page: 'auth',
    url: '/auth',
  },
  tour: {
    url: '/tour',
  },
};

export const pages = {
  groupView: ({ challengeId, groupId }) => {
    challengeId = Util.assureNoPrefix('challenge_', challengeId);
    groupId = Util.assureNoPrefix('group_', groupId);
    return `/challenges/${challengeId}/group/${groupId}`;
  },
  baselineView: ({ challengeId, groupId }) => {
    challengeId = Util.assureNoPrefix('challenge_', challengeId);
    groupId = Util.assureNoPrefix('group_', groupId);
    return `/challenges/${challengeId}/group/${groupId}/baseline`;
  },
  signup: () => navigationParents.signup.url,
  login: () => navigationParents.login.url,
  auth: () => navigationParents.auth.url,
};

/* Class handles object structure:
  {
    page: [name of the page e.g. 'group', 'challenge'],
    url: [the URL that the page represents with path and hash-parts, e.g. /group/123#abc]
  }
*/
class Navigation {
  history = [];

  setParent(parent) {
    /* If the page is accessed through direct or external link. We can add a parent for it.
     */
    if (!this.getCurrent()) {
      this.setPage(parent.page, parent.url);
    }
  }

  setPage(page, url = getLinkUrl()) {
    const current = this.getCurrent();
    if (current && current.page !== page) {
      this.history.push({ page, url });
      window.scrollTo(0, 0);
    }
  }
  /* We must remove 2 elements from the array, because one gets added on each page load. If we remove only one
    it will not work correctly */
  back() {
    return this.history.splice(-2, 2);
  }
  clear() {
    this.history = [];
  }
  getCurrent() {
    if (this.history.length > 0) {
      return this.history[this.history.length - 1];
    } else {
      return {
        page: '',
      };
    }
  }
  getPrevious(count = 2) {
    return this.history[this.history.length - count];
  }
  getFirst() {
    return this.history[0];
  }
}
const navigation = new Navigation();
export default navigation;
