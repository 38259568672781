import styled from 'styled-components';

const height = '6px';
const borderRadius = '3px';
const WizardRangeInput = styled.input.attrs(props => ({
  type: 'range',
  name: props.name,
}))`
  -webkit-appearance: none;
  width: 100%;
  margin: 7px 0;
  height: ${height};
  border-radius: ${borderRadius};

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: ${height};
    cursor: pointer;
    box-shadow: none;
    background: ${props => props.theme.colors.seachangeTextAlpha15};
    border-radius: ${borderRadius};
    border: 0;
  }

  &::-webkit-slider-thumb {
    box-shadow: 0 0 5px 0 ${props => props.theme.colors.seachangeTextAlpha30},
      0 6px 6px -2px ${props => props.theme.colors.seachangeTextAlpha30};
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background: ${props => props.theme.slider.bgTrack};
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
  }

  &:focus::-webkit-slider-runnable-track {
    background: ${props => props.theme.colors.seachangeTextAlpha15};
  }

  &::-moz-range-progress {
    background-color: ${props => props.theme.slider.bgTrack};
    height: ${height};
    border-radius: ${borderRadius};
  }

  &::-moz-range-track {
    width: 100%;
    height: ${height};
    cursor: pointer;
    background: ${props => props.theme.colors.seachangeTextAlpha15};
    border-radius: ${borderRadius};
  }

  &::-moz-range-thumb {
    box-shadow: 0 0 5px 0 ${props => props.theme.colors.seachangeTextAlpha30},
      0 6px 6px -2px ${props => props.theme.colors.seachangeTextAlpha30};
    height: 20px;
    width: 20px;
    border-radius: 10px;
    border: 0;
    background: ${props => props.theme.slider.bgTrack};
    cursor: pointer;
  }

  &::-ms-track {
    width: 100%;
    height: ${height};
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  &::-ms-fill-lower {
    background: #2a6495;
    border: 0px solid #010101;
    border-radius: 4px;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  }

  &::-ms-fill-upper {
    background: ${props => props.theme.colors.seachangeTextAlpha15};
    border: 0px solid #010101;
    border-radius: 4px;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  }

  &::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 0px solid #000000;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background: #ffffff;
    cursor: pointer;
    height: ${height};
  }

  &:focus::-ms-fill-lower {
    background: ${props => props.theme.colors.seachangeTextAlpha15};
  }

  &:focus::-ms-fill-upper {
    background: #367ebd;
  }
`;

export default WizardRangeInput;
