import React, { useEffect, useState } from 'react';
import { sortArticles } from './queries';
import BlogArticleCard from './BlogArticleCard';
import Box from '../components/container/Box';
import CenteredContent from '../components/container/CenteredContent';
import { useMessageGetter } from 'react-message-context';
import PathBar from '../components/top/pathBar/PathBar';
import ContentWithSpinner from '../components/container/ContentWithSpinner';
import { fullscreenModalSlideDuration, waitBeforeSpinnerDuration } from '../styles/durations';
import { getCMSArticles } from '../functionalities/apis';

const BlogListPage = () => {
  const locale = 'en-US';
  const [state, setState] = useState({
    loading: true,
    error: null,
    data: null,
  });

  useEffect(() => {
    async function load() {
      const response = await getCMSArticles(locale);
      if (response.data) {
        setState(state => ({
          ...state,
          loading: false,
          data: response.data,
        }));
      } else {
        setState(state => ({
          ...state,
          loading: false,
          error: true,
        }));
      }
    }
    load();
  }, [setState, locale]);

  const { loading, error, data } = state;
  const messages = useMessageGetter('pages.blog');
  if (loading)
    return (
      <ContentWithSpinner spinnerDelay={fullscreenModalSlideDuration + waitBeforeSpinnerDuration} />
    );
  if (error || !data) {
    return <p>Error fetching articles</p>;
  }
  const articles = data.listBlogPosts.data.sort(sortArticles);
  return (
    <CenteredContent>
      <PathBar pageTitle={messages('title')} level={0} bwHeader={true} posAbsolute={false} />
      <ContentWithSpinner spinnerDelay={fullscreenModalSlideDuration + waitBeforeSpinnerDuration}>
        {articles.map(article => (
          <Box margin="0 0 12px 0" key={`article-${article.id}`}>
            <BlogArticleCard article={article} />
          </Box>
        ))}
      </ContentWithSpinner>
    </CenteredContent>
  );
};

export default BlogListPage;
