import React, { Fragment } from 'react';
import moment from 'moment';

export const convertLineBreaks = text => {
  const spanElements = text
    .split('\n')
    .map((t, ind) => [<span key={`span-${ind}`}>{t}</span>, <br key={`br-${ind}`} />])
    .reduce((acc, arr) => [...acc, ...arr]);
  spanElements.pop();
  return spanElements;
};

export const relativeTimeText = (millis, messages) => {
  const millisDiff = Date.now() - millis;
  // Within last minute
  if (millisDiff <= 60 * 1000) {
    return messages('general.time.now');
  } else if (millisDiff <= 60 * 60 * 1000) {
    return messages('general.time.minutes', {
      minutes: Math.round(moment.duration(millisDiff).asMinutes()),
    });
  } else if (millisDiff <= 60 * 60 * 24 * 1000) {
    return messages('general.time.hours', {
      hours: Math.round(moment.duration(millisDiff).asHours()),
    });
  } else if (millisDiff <= 60 * 60 * 24 * 7 * 1000) {
    return messages('general.time.days', {
      days: Math.round(moment.duration(millisDiff).asDays()),
    });
  } else {
    return moment(millis, 'x').format('MMM D');
  }
};

export const capitalizeFirstLetter = str => str.toLowerCase().replace(/^\w/, c => c.toUpperCase());

export const interpolate = (text, values) => {
  const pattern = /(\$[0-9])/g;
  const matches = text.match(pattern);
  const parts = text.split(pattern);

  if (!matches) {
    return text;
  }

  return parts.map((part, index) => (
    <Fragment key={part + index}>{matches.includes(part) ? values[part] : part}</Fragment>
  ));
};
