import React from 'react';
import { useMessageGetter } from 'react-message-context';
import { ThemeProvider } from 'styled-components';
import { sadDark } from '../../assets/images/illustrations';
import Button from '../../components/button/Button';
import ModalFullscreen, { ModalContent } from '../../components/modal/ModalFullscreen';
import ModalIllustration from '../../components/modal/ModalIllustration';
import { HEADING_SIZES, WizardHeading } from '../../components/wizard';
import { useTrackPageView } from '../../hooks/useTrackPageView';
import { theme } from '../../theme';
import { HistoryShape } from '../../util/shapes';

const Error500Page = ({ history }) => {
  useTrackPageView();

  const messages = useMessageGetter();
  const onClose = () => history.push('/');
  const actions = [
    <Button key="ok" primary onClick={onClose}>
      {messages('pages.error.500.button.ok')}
    </Button>,
  ];

  return (
    <ThemeProvider theme={theme('white')}>
      <ModalFullscreen onClose={onClose} actions={actions}>
        <ModalContent style={{ textAlign: 'center', paddingBottom: '170px' }}>
          <ModalIllustration alt="" src={sadDark} />
          <WizardHeading
            title={messages('pages.error.500.title')}
            subtitle={messages('pages.error.500.description').split('\n')}
            type={HEADING_SIZES.HUGE}
          />
        </ModalContent>
      </ModalFullscreen>
    </ThemeProvider>
  );
};

Error500Page.propTypes = {
  history: HistoryShape,
};

export default Error500Page;
