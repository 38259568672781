const KEYS = ['signupState'];

class Session {
  add(key, data) {
    const item = { data };
    if (!KEYS.includes(key)) {
      console.error('Invalid key provided for session, when adding: ', key);
    }
    window.sessionStorage[key] = JSON.stringify(item);
  }
  remove(key) {
    if (!KEYS.includes(key)) {
      console.error('Invalid key provided for session, when removing: ', key);
    }
    delete window.sessionStorage[key];
  }
  get(key) {
    if (!KEYS.includes(key)) {
      console.error('Invalid key provided for session, when getting: ', key);
    }
    const item = window.sessionStorage[key] ? JSON.parse(window.sessionStorage[key]) : null;

    if (!item) {
      return null;
    } else {
      return item.data;
    }
  }
}

export const session = new Session();
