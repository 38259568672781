import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useMessageGetter } from 'react-message-context';
import P from '../../texts/paragraphs/P';
import { h2Left, labelLeftPositive } from '../../../styles/text';
import FeedItem from '../FeedItem';
import NewCommentModal from '../NewCommentModal';
import { comment, arrowRightPositive } from '../../../assets/images';
import { h2Center } from '../../../styles/text';
import InfiniteScroll from '../../InfiniteScroll';
import { assureNoPrefix } from 'isomorphic/util';

const MiniFeed = ({
  items = [],
  isLoggedIn = false,
  onNewPost,
  currentUserId,
  groupId,
  challengeId,
  linkToFullFeed,
  noTopPadding,
  loadMore,
  hasMore,
  endMessage,
  scrollableTarget,
}) => {
  const messages = useMessageGetter();
  const [newPost, setNewPost] = useState(false);

  if (isLoggedIn && items.length === 0) {
    return null;
  }

  const onWriteNewPost = () => {
    setNewPost(true);
  };

  const onCloseNewPost = () => {
    setNewPost(false);
  };

  if (!isLoggedIn) {
    return (
      <Container>
        <HeaderRow>
          <H2>{messages('components.feed.title')}</H2>
        </HeaderRow>
        <P>{messages('components.feed.notLoggedIn')}</P>
      </Container>
    );
  }

  const displayedItems = linkToFullFeed ? items.slice(0, 10) : items;

  const FeedItemWrapper = linkToFullFeed ? Fragment : InfiniteScroll;

  const wrapperProps = linkToFullFeed
    ? {}
    : {
        dataLength: displayedItems.length,
        loadMore: loadMore,
        hasMore: hasMore,
        endMessage: endMessage,
        scrollableTarget: scrollableTarget,
      };

  return (
    <Container noTopPadding={noTopPadding}>
      <HeaderRow>
        <H2>{messages('components.feed.title')}</H2>
        <NewPost onClick={onWriteNewPost}>
          <PostText>{messages('components.feed.newpost.action')}</PostText>
          <CommentImg src={comment} alt={messages('components.feed.newpost.action')} />
        </NewPost>
      </HeaderRow>
      <NewCommentModal
        show={newPost}
        currentUserId={currentUserId}
        groupId={groupId}
        onClose={onCloseNewPost}
        onNewPost={onNewPost}
      />
      <FeedItemWrapper {...wrapperProps}>
        {displayedItems.map(item => (
          <FeedItemContainer key={item.aspect}>
            <FeedItem feedItem={item} currentUserId={currentUserId} challengeId={challengeId} />
          </FeedItemContainer>
        ))}
      </FeedItemWrapper>
      <Center>
        {linkToFullFeed && items.length > displayedItems.length && (
          <LinkButton
            to={`/challenges/${assureNoPrefix('challenge_', challengeId)}/group/${groupId}/posts`}
          >
            <LinkText>{messages('components.feed.load')}</LinkText>
            <ButtonIconImg src={arrowRightPositive} />
          </LinkButton>
        )}
      </Center>
    </Container>
  );
};

MiniFeed.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      text: PropTypes.string.isRequired,
    }),
  ),
  groupId: PropTypes.string.isRequired,
  isLoggedIn: PropTypes.bool,
  onNewPost: PropTypes.func.isRequired,
  currentUserId: PropTypes.any,
  challengeId: PropTypes.string.isRequired,
  linkToFullFeed: PropTypes.bool,
  noTopPadding: PropTypes.bool,
  loadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  endMessage: PropTypes.string,
  scrollableTarget: PropTypes.string,
};

export default MiniFeed;

const LinkButton = styled(Link)`
  ${h2Center}
  display: flex;
  justify-content: center;
  border-radius: 20px;
  border: 0;
  text-transform: uppercase;
  overflow-y: hidden;
  position: relative;
  transition: 100ms;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transform: scale(1);
  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
  &:active {
    transform: scale(0.9);
  }
`;

const LinkText = styled.span`
  font-size: 12px;
  padding: 9px 0;
`;

const ButtonIconImg = styled.img`
  height: 23px;
  margin: auto 0;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const Container = styled.div.attrs(({ noTopPadding }) => ({
  style: {
    paddingTop: noTopPadding ? 0 : '30px',
  },
}))`
  padding: 30px 15px 30px 15px;
`;

const Center = styled.div`
  text-align: center;
`;

const FeedItemContainer = styled.div`
  margin-bottom: 30px;
`;

const H2 = styled.h2`
  ${h2Left}
`;

const NewPost = styled.div`
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

const PostText = styled.span`
  ${labelLeftPositive};
  color: ${props => props.theme.colors.brand2Hue3};
  letter-spacing: normal;
`;

const CommentImg = styled.img`
  margin-left: 5px;
  width: 16px;
  height: 16px;
  max-width: 16px;
  max-height: 16px;
`;
