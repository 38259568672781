import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { paragraphBase, weak, negative, positive, fatal } from '../../../styles/text';

const P = ({ children, isCenter = false, textColor = 'positive' }) => (
  <Paragraph isCenter={isCenter} textColor={textColor}>
    {children}
  </Paragraph>
);
P.propTypes = {
  children: PropTypes.node.isRequired,
  isCenter: PropTypes.bool,
  textColor: PropTypes.oneOf(['positive', 'negative', 'weak', 'fatal']),
};
export default P;

const getTextColor = textColor => {
  if (textColor === 'weak') return weak;
  if (textColor === 'negative') return negative;
  if (textColor === 'fatal') return fatal;
  return positive;
};

const Paragraph = styled.p`
  ${paragraphBase};
  text-align: ${({ isCenter }) => (isCenter ? 'center' : 'left')};
  ${({ textColor }) => getTextColor(textColor)};
`;
