import styled from 'styled-components';

const VerticalDivider = styled.hr`
  width: 1px;
  border: none;
  margin: 0 6px;
  background-color: rgba(255, 255, 255, 0.15);
`;

export default VerticalDivider;
