import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { Input, InputContainer } from './InputPrimitives';

const WizardInputSecretCode = forwardRef(({ value, onChange, disabled = false }, ref) => {
  const inputRef = useRef(null);
  useImperativeHandle(ref, () => inputRef.current);

  const onInputChange = evt => {
    const val = evt.target.value;
    const valueStr = `${val}`;
    if (valueStr.length >= 4) {
      const elements = Array.from(document.querySelectorAll('input, button'));
      const inputIndex = elements.indexOf(document.activeElement);

      // Move cursor to beginning
      inputRef.current.setSelectionRange(0, 0);

      // Move focus to next button if it exists or just remove focus altogether
      if (inputIndex > -1 && elements.length > inputIndex + 1) {
        elements[inputIndex + 1].focus();
      } else {
        inputRef.current.blur();
      }
    }
    onChange(val);
  };

  return (
    <SecretInputContainer>
      <SecretInput
        maxLength={4}
        size="big"
        value={value}
        onChange={onInputChange}
        placeholder="∙∙∙∙"
        type="tel"
        ref={inputRef}
        disabled={disabled}
      />
    </SecretInputContainer>
  );
});

const SecretInputContainer = styled(InputContainer)`
  width: 180px;
  height: 75px;
  display: flex;
  align-items: center;
`;

const SecretInput = styled(Input)`
  letter-spacing: 28px;
  line-height: 32px;

  ::placeholder {
    text-align: center;
    color: ${({ theme }) => theme.inputSecret.placeholderColor};
  }
`;

WizardInputSecretCode.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default WizardInputSecretCode;
