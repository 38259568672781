import styled from 'styled-components';

const WizardCardContainer = styled.div`
  padding: 0 15px;

  /* Adjacent sibling of itself */
  & + & {
    margin-top: 30px;
  }
`;

export default WizardCardContainer;
