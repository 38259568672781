import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import bg from '../../assets/images/banner-bg.svg';
import { useMessageGetter } from 'react-message-context';
import { Card } from '../card';
import { h3LeftNegative } from '../../styles/text';
import bannerImage from '../../assets/images/add-to-homescreen.svg';

const Add2HSBanner = ({ onClick }) => {
  const messages = useMessageGetter('components.banner.add2hs');
  return (
    <Link onClick={onClick} to="">
      <Card bgImage={bg} spacing="dense">
        <Content>
          <Texts>
            <Title>{messages('title')}</Title>
          </Texts>
          <Image src={bannerImage} />
        </Content>
      </Card>
    </Link>
  );
};

Add2HSBanner.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default Add2HSBanner;

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 150px;
`;

const Texts = styled.div``;

const Title = styled.h1`
  ${h3LeftNegative};
  color: ${({ theme }) => theme.loginBanner.color};
  margin: 0;
`;

const Image = styled.img.attrs({
  alt: '',
})`
  position: absolute;
  bottom: 0;
  right: 0;
`;
