import React, { useCallback } from 'react';
import { MatchShapeOfParams, HistoryShape } from '../../../util/shapes';
import Wizard from '../../wizards/Wizard';
import { getBaselineConfig } from '../../../functionalities/apis';

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const UserBaselinePage = ({ history, match }) => {
  const { groupId } = match.params;
  const loadConfig = useCallback(async () => {
    await sleep(500);
    return await getBaselineConfig(groupId);
  }, [groupId]);
  return (
    <Wizard match={match} history={history} modal loadConfig={loadConfig} onClose={() => {}} />
  );
};

UserBaselinePage.propTypes = {
  match: MatchShapeOfParams(['challengeId', 'groupId']),
  history: HistoryShape.isRequired,
};

export default UserBaselinePage;
