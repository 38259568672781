import React, { useEffect } from 'react';
import { useMessageGetter } from 'react-message-context';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { Groups } from 'isomorphic';
import { GroupStates } from 'isomorphic/constants';
import ModalFullscreen from '../../../components/modal/ModalFullscreen';
import { useApp } from '../../../context/appContext';
import { theme } from '../../../theme';
import PersonalProgressCard from '../../../components/groups/group-cards/PersonalProgressCard';
import PersonalSuccessCard from '../../../components/groups/group-cards/PersonalSuccessCard';
import GroupProgressCard from '../../../components/groups/group-cards/GroupProgressCard';
import { seachangeTextAlpha15 } from '../../../styles/colors';
import ActionsCard from '../../../components/data-cards/ActionsCard';
import { HatchingLeaderBoardCard } from '../../../components/groups/group-cards/HatchingLeaderboardCard';
import { LeaderboardCard } from '../../../components/groups/group-cards/LeaderboardCard';
import { MatchShapeOfParams, HistoryShape } from '../../../util/shapes';
import ModalGroupSubtitle from '../../../components/modal/ModalGroupSubtitle';
import { loadGroup } from '../../../context/actions';
import InfoBox from '../../../components/container/InfoBox';
import TextButton from '../../../components/button/TextButton';
import Box from '../../../components/container/Box';
import { useTrackPageView } from '../../../hooks/useTrackPageView';

export default function GroupFullStatsPage({ match, history }) {
  const { challengeId, groupId } = match.params;
  const [{ user, [groupId]: group }, dispatch] = useApp();
  const messages = useMessageGetter('pages.group.fullStats');

  useTrackPageView();

  useEffect(() => {
    if (!group) loadGroup(dispatch, groupId);
  }, [dispatch, groupId, group]);

  if (!group) {
    return (
      <ModalFullscreen title={messages('title')} onClose={history.goBack} theme={theme('white')}>
        {null}
      </ModalFullscreen>
    );
  }

  const groupPath = `/challenges/${challengeId}/group/${groupId}`;
  const loggedInUserInGroup = Object.values(group.members).some(m => m.userId === user.userId);
  if (!loggedInUserInGroup) return <Redirect to={groupPath} />;
  const { challenge, durationDays, goal, members, personal, progress, startDate, captain } = group;
  const groupStatus = Groups.getGroupStatus(startDate, durationDays);
  const { initialCo2Kg, targetCo2Kg, dailyAnswers, actions } = personal;
  const membersArray = Object.values(members).map(member => ({
    ...member,
    isCaptain: member.userId === captain.userId,
    isLoggedIn: member.userId === user.userId,
  }));

  return (
    <ModalFullscreen
      title={messages('title')}
      onClose={history.goBack}
      theme={theme('white')}
      showBgImage={false}
      denseTitle
      subtitle={<ModalGroupSubtitle group={group} onClick={history.goBack} marginBottom="15px" />}
      border={!!group}
    >
      {Groups.isBaselineUpdateAllowed(startDate) && (
        <Section>
          <InfoBox>
            {messages('baseline.description')}
            <Box margin="15px 0 0 0">
              <TextButton
                onClick={() => history.push(`/challenges/${challengeId}/group/${groupId}/baseline`)}
              >
                {messages('baseline.link')}
              </TextButton>
            </Box>
          </InfoBox>
        </Section>
      )}
      <Section>
        <PersonalProgressCard
          status={groupStatus}
          initialCo2Kg={initialCo2Kg}
          targetCo2Kg={targetCo2Kg}
          dailyAnswers={dailyAnswers}
          groupDurationDays={durationDays}
          groupStartDate={startDate}
          spacing="loose"
          flat={true}
          challengeShortName={challenge.short_name}
        />
      </Section>
      {actions && actions.length > 0 && (
        <Section>
          {groupStatus === GroupStates.hatching ? (
            <ActionsCard
              actions={actions}
              challengeShortName={challenge.short_name}
              spacing="loose"
              flat={true}
            />
          ) : (
            <PersonalSuccessCard
              groupStartDate={startDate}
              groupDurationDays={durationDays}
              dailyAnswers={dailyAnswers}
              spacing="loose"
              flat={true}
            />
          )}
        </Section>
      )}
      <Section>
        <GroupProgressCard
          status={groupStatus}
          groupGoalData={goal}
          groupProgressData={progress}
          groupDurationDays={durationDays}
          groupStartDate={startDate}
          spacing="loose"
          flat={true}
          challengeShortName={challenge.short_name}
        />
      </Section>
      <Section>
        {groupStatus === GroupStates.hatching ? (
          <HatchingLeaderBoardCard
            groupMembersPath={`${groupPath}/members`}
            spacing="loose"
            flat={true}
          />
        ) : (
          <LeaderboardCard
            groupMembersPath={`${groupPath}/members`}
            members={membersArray}
            groupStatus={groupStatus}
            spacing="loose"
            flat={true}
          />
        )}
      </Section>
    </ModalFullscreen>
  );
}

GroupFullStatsPage.propTypes = {
  match: MatchShapeOfParams(['challengeId', 'groupId']),
  history: HistoryShape.isRequired,
};

const Section = styled.section`
  &:not(:first-child) {
    border-top: 1px solid ${seachangeTextAlpha15};
  }
`;
