import Auth from '@aws-amplify/auth';
import API from '@aws-amplify/api-rest';
import {
  getAppStats,
  getChallenges,
  getProfileData,
  getLatestActions,
  getGroupFeed,
  getPost,
  postReply,
  postReaction,
  getGroupData,
  getGroupInfoByInviteHash,
  getNotifications,
  postNotificationStatus,
  getMessages,
  getUserInfo,
  putUserInfo,
} from '../functionalities/apis';
import Co2 from '../functionalities/Co2';
import * as serviceWorker from '../serviceWorkerRegistration';
import { assureNoPrefix } from 'isomorphic/util';

const GROUP_PREFIX = 'group_';
const POST_PREFIX = 'feed_';

export const ACTIONS = {
  LOAD_CHALLENGES: 'LOAD_CHALLENGES',
  LOAD_GROUP: 'LOAD_GROUP',
  LOAD_PROFILE: 'LOAD_PROFILE',
  LOAD_STATS: 'LOAD_STATS',
  LOAD_MORE_POSTS: 'LOAD_MORE_POSTS',
  POST_REPLY: 'POST_REPLY',
  POST_REACTION: 'POST_REACTION',
  LOAD_MORE_NOTIFICATIONS: 'LOAD_MORE_NOTIFICATIONS',
  LOAD_MORE_REMINDERS: 'LOAD_MORE_REMINDERS',
};

export const EVENTS = {
  LOADED_CHALLENGES: 'LOADED_CHALLENGES',
  LOADED_GROUP: 'LOADED_GROUP',
  LOADED_PROFILE: 'LOADED_PROFILE',
  LOADED_STATS: 'LOADED_STATS',
  LOADED_MESSAGES: 'LOADED_MESSAGES',
  LOADED_NOTIFICATIONS: 'LOADED_NOTIFICATIONS',
  LOADED_LATEST_ACTIONS: 'LOADED_LATEST_ACTIONS',
  LOADED_POSTS: 'LOADED_POSTS',
  LOADED_POST: 'LOADED_POST',
  LOADED_USER_INFO: 'LOADED_USER_INFO',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  UI_UPDATE_PROFILE_IMAGE: 'UI_UPDATE_PROFILE_IMAGE',
  UI_UPDATE_GROUP_IMAGE: 'UI_UPDATE_GROUP_IMAGE',
  POSTED_REPLY: 'POSTED_REPLY',
  POST_REPLY_FAILED: 'POST_REPLY_FAILED',
  FAILED_LOAD_GROUP: 'FAILED_LOAD_GROUP',
  SERVICE_WORKER_UPDATE_READY: 'SERVICE_WORKER_UPDATE_READY',
  INPUT_FOCUS_CHANGED: 'INPUT_FOCUS_CHANGED',
  UPDATED_NOTIFICATION_STATUS: 'UPDATED_NOTIFICATION_STATUS',
  MARKING_NOTIFICATIONS_READ: 'MARKING_NOTIFICATIONS_READ',
  MARKING_NOTIFICATIONS_READ_ENDED: 'MARKING_NOTIFICATIONS_READ_ENDED',
  SAVE_SCROLL_POSITION: 'SAVE_SCROLL_POSITION',
  SET_MAIN_MODAL_INFO: 'SET_MAIN_MODAL_INFO',
  REMOVE_SCROLL_POSITION: 'REMOVE_SCROLL_POSITION',
  BEFORE_INSTALL: 'BEFORE_INSTALL',
  APP_INSTALLED: 'APP_INSTALLED',
};

export function registerA2HSFunctionality(dispatch) {
  const onBeforeInstall = event => {
    // for this event preventDefault only works in Chrome 67 and before
    event.preventDefault();
    dispatch([EVENTS.BEFORE_INSTALL, { event }]);
  };

  const appInstalled = () => {
    dispatch([EVENTS.APP_INSTALLED]);
  };

  window.addEventListener('beforeinstallprompt', onBeforeInstall);
  window.addEventListener('appinstalled', appInstalled);

  return () => {
    window.removeEventListener('beforeinstallprompt', onBeforeInstall);
    window.removeEventListener('appinstalled', appInstalled);
  };
}

export async function registerServiceWorker(dispatch) {
  serviceWorker.register({
    onLoaded: registration => {
      if (registration.waiting) {
        dispatch([
          EVENTS.SERVICE_WORKER_UPDATE_READY,
          {
            registration,
          },
        ]);
      }
    },
    onUpdate: async registration => {
      await registration.update();

      if (!registration.waiting) {
        return;
      }

      dispatch([
        EVENTS.SERVICE_WORKER_UPDATE_READY,
        {
          registration,
        },
      ]);
    },
  });
}

export async function loadStats(dispatch) {
  try {
    dispatch([ACTIONS.LOAD_STATS]);
    const { activeUsers24h, reductionKg24h, totalReductionKg } = await getAppStats();

    dispatch([
      EVENTS.LOADED_STATS,
      {
        activeUsers24h,
        reductionKg24h: new Co2(reductionKg24h),
        totalReductionKg: new Co2(totalReductionKg || 0),
      },
    ]);
  } catch (err) {
    console.error('Error loading app stats', err);
  }
}

export async function loadChallenges(dispatch) {
  try {
    dispatch([ACTIONS.LOAD_CHALLENGES]);
    const challenges = await getChallenges();
    dispatch([EVENTS.LOADED_CHALLENGES, challenges]);
  } catch (err) {
    console.error('Error loading challenges', err);
  }
}

export async function loadGroupByInviteHash(dispatch, inviteHash) {
  try {
    dispatch([ACTIONS.LOAD_GROUP]);
    const group = await getGroupInfoByInviteHash(inviteHash);
    const groupId = assureNoPrefix('group_', group.id);
    dispatch([EVENTS.LOADED_GROUP, { groupId, group }]);
    return groupId;
  } catch (err) {
    console.error('Error loading group', err);
    dispatch([EVENTS.FAILED_LOAD_GROUP, { err }]);
  }
}

export async function loadGroup(dispatch, groupId) {
  try {
    dispatch([ACTIONS.LOAD_GROUP]);
    const { group, user } = await getGroupData(groupId);
    group.personal = user;
    dispatch([EVENTS.LOADED_GROUP, { groupId, group }]);
  } catch (err) {
    console.error('Error loading group', err);
    dispatch([EVENTS.FAILED_LOAD_GROUP, { err }]);
  }
}

export async function loadProfileData(dispatch) {
  try {
    dispatch([ACTIONS.LOAD_PROFILE]);
    const profileData = await getProfileData();
    dispatch([EVENTS.LOADED_PROFILE, profileData]);
    return profileData;
  } catch (err) {
    console.error('Error loading profile data', err.response);
    throw err.response;
  }
}

export async function loadLatestActions(dispatch) {
  try {
    const data = await getLatestActions();
    dispatch([EVENTS.LOADED_LATEST_ACTIONS, data]);
  } catch (err) {
    console.error('Error loading latest actions data', err);
  }
}

export async function loadUser(dispatch, bypassCache = false) {
  try {
    const user = await Auth.currentAuthenticatedUser({
      bypassCache,
    });
    dispatch([
      EVENTS.LOGIN,
      {
        ...user.attributes,
        userId: user.attributes.sub,
      },
    ]);
  } catch (err) {
    console.log('No logged in user found', err);
    dispatch([EVENTS.LOGOUT]);
  }
}

export async function saveUser(dispatch, profileData) {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, profileData);
    await API.post('apigateway', '/profile', { body: profileData });
    dispatch([
      EVENTS.LOGIN,
      {
        ...user.attributes,
        ...profileData,
        userId: user.attributes.sub,
      },
    ]);
  } catch (error) {
    console.error('Error saving user', error);
    throw error;
  }
}

export async function updateGroup(dispatch, groupId, groupData) {
  try {
    const group = await API.put('apigateway', `/group/${groupId}`, { body: groupData });
    dispatch([EVENTS.LOADED_GROUP, { groupId, group }]);
  } catch (error) {
    console.log('Error updating group', error);
    throw error;
  }
}

export async function loadGroupFeed(dispatch, groupId, offsetKey) {
  try {
    if (offsetKey) {
      dispatch([ACTIONS.LOAD_MORE_POSTS]);
    }
    const feedItems = await getGroupFeed(groupId, offsetKey);
    dispatch([EVENTS.LOADED_POSTS, feedItems]);
  } catch (err) {
    console.log('Error loading group feed', err);
  }
}

export async function loadPost(dispatch, postId) {
  try {
    const post = await getPost(postId);
    dispatch([EVENTS.LOADED_POST, post]);
  } catch (err) {
    throw err.response;
  }
}

export async function loadReminders(dispatch, offset) {
  try {
    dispatch([ACTIONS.LOAD_MORE_REMINDERS]);
    const response = await getMessages(offset);
    dispatch([EVENTS.LOADED_MESSAGES, response]);
  } catch (err) {
    console.log('Error loading reminders');
  }
}

export async function loadNotifications(dispatch, offset) {
  try {
    dispatch([ACTIONS.LOAD_MORE_NOTIFICATIONS]);
    const notifications = await getNotifications(offset);
    dispatch([EVENTS.LOADED_NOTIFICATIONS, notifications]);
  } catch (err) {
    console.log('Error loading notifications');
    throw err.response;
  }
}

export async function markNotificationRead(id, dispatch) {
  try {
    dispatch([EVENTS.MARKING_NOTIFICATIONS_READ]);
    await postNotificationStatus(id);
    dispatch([EVENTS.UPDATED_NOTIFICATION_STATUS, id]);
  } catch (err) {
    console.log('Error updating notification status');
    dispatch([EVENTS.MARKING_NOTIFICATIONS_READ_ENDED]);
  }
}

export async function postNewReply(dispatch, groupId, replyToId, newComment) {
  if (groupId.startsWith(GROUP_PREFIX)) {
    groupId = groupId.substring(GROUP_PREFIX.length);
  }

  if (replyToId.startsWith(POST_PREFIX)) {
    replyToId = replyToId.substring(POST_PREFIX.length);
  }

  try {
    dispatch([ACTIONS.POST_REPLY]);
    const reply = await postReply(groupId, replyToId, { text: newComment });
    dispatch([
      EVENTS.POSTED_REPLY,
      {
        reply,
        replyToId,
      },
    ]);
  } catch (error) {
    console.error('Error posting comment', error);
    dispatch([EVENTS.POST_REPLY_FAILED]);
  }
}

export async function postNewReaction(dispatch, origGroupId, postId, { reactionType }) {
  let groupId = origGroupId;
  if (groupId.startsWith(GROUP_PREFIX)) {
    groupId = groupId.substring(GROUP_PREFIX.length);
  }

  try {
    dispatch([
      ACTIONS.POST_REACTION,
      {
        groupId: origGroupId,
        postId,
        reactionType,
      },
    ]);
    await postReaction(groupId, postId, { reactionType });
  } catch (error) {
    console.error('Error posting reaction', error);
    // Post same reaction again as it will remove the reaction added at client side.
    dispatch([
      ACTIONS.POST_REACTION,
      {
        groupId: origGroupId,
        postId,
        reactionType,
      },
    ]);
  }
}

export async function changeInputFocus(dispatch, isInputFocused) {
  dispatch([EVENTS.INPUT_FOCUS_CHANGED, { isInputFocused }]);
}

export async function setScrollPosition(dispatch, scrollPosition) {
  dispatch([EVENTS.SAVE_SCROLL_POSITION, scrollPosition]);
}

export async function setMainModalInfo(dispatch, mainModalInfo) {
  dispatch([EVENTS.SET_MAIN_MODAL_INFO, mainModalInfo]);
}

export async function removeScrollPosition(dispatch, pathName) {
  dispatch([EVENTS.REMOVE_SCROLL_POSITION, pathName]);
}

export async function loadUserInfo(dispatch) {
  try {
    const userInfo = await getUserInfo();
    dispatch([EVENTS.LOADED_USER_INFO, userInfo]);
  } catch (err) {
    throw err.response;
  }
}

export async function updateUserInfo(dispatch, data) {
  try {
    const userInfo = await putUserInfo(data);
    dispatch([EVENTS.LOADED_USER_INFO, userInfo]);
  } catch (err) {
    throw err.response;
  }
}
