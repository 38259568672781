import React from 'react';
import styled from 'styled-components';
import { useMessageGetter } from 'react-message-context';
import PropTypes from 'prop-types';
import Message from '../../components/messages/Message';
import moment from 'moment';
import Card from '../../components/card/Card';
import SmallButton from '../../components/button/SmallButton';
import { h3LeftPositive, labelAlert, labelLeftPositive } from '../../styles/text';
import { seachangeTextAlpha15 } from '../../styles/colors';
import { useHistory } from 'react-router-dom';

const ChallengeReminderBox = ({ unreadCount, readCount, firstUnreadMsg }) => {
  const messages = useMessageGetter('components.messages');
  const history = useHistory();

  if (unreadCount === 0 && readCount === 0) {
    return null;
  }

  return (
    <Container onClick={() => history.push('/messages/challenge-reminders')}>
      <Card spacing="none">
        <HeaderWrapper>
          <SectionHeader>{messages('reminders.title')}</SectionHeader>
          <CountWrapper>
            {firstUnreadMsg && (
              <UnansweredCount>
                {' '}
                {messages('reminders.unanswered', { count: unreadCount })}
              </UnansweredCount>
            )}
            {!firstUnreadMsg && (
              <AnsweredCount> {messages('reminders.earlier', { count: readCount })}</AnsweredCount>
            )}
          </CountWrapper>
        </HeaderWrapper>
        {firstUnreadMsg && (
          <>
            <Message
              read={firstUnreadMsg.readAt !== null}
              message={firstUnreadMsg.content}
              sendDate={moment(firstUnreadMsg.createdAt).format('MMM D')}
              from={firstUnreadMsg.from}
              footerTexts={[
                messages('reminders.day', { number: firstUnreadMsg.challengeDay }),
                messages('reminders.challenge', {
                  csn: firstUnreadMsg.csn,
                }) || firstUnreadMsg.challenge,
              ]}
            />
            <Action>
              <SmallButton type="positive" text={messages('reminders.show')} />
            </Action>
          </>
        )}
      </Card>
    </Container>
  );
};

ChallengeReminderBox.propTypes = {
  unreadCount: PropTypes.number,
  readCount: PropTypes.number,
  firstUnreadMsg: PropTypes.object,
};

const Container = styled.div`
  padding: 20px 15px;
  &:hover {
    cursor: pointer;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px ${seachangeTextAlpha15};
  padding: 20px 15px;
`;

const SectionHeader = styled.h3`
  ${h3LeftPositive};
  margin: 0;
  flex: 1;
`;

const CountWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const UnansweredCount = styled.span`
  ${labelAlert};
`;

const AnsweredCount = styled.span`
  ${labelLeftPositive};
`;

const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: solid 1px ${seachangeTextAlpha15};
  padding: 10px;
`;

export default ChallengeReminderBox;
