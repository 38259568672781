const { Commuting } = require('./challenges');
const HEATING = {
  fi: {
    districtheating: 121,
    dunno: 104,
    electricity: 70,
    electricity_zero: 0,
    geothermal: 23,
    geothermal_zero: 0,
    naturalgas: 120,
    oil: 159,
    wood: 209,
  },
};

const COMMUTING_METHODS = {
  fi: {
    [Commuting.Methods.Walk]: 0,
    [Commuting.Methods.Bicycle]: 0,
    [Commuting.Methods.Tram]: 0,
    [Commuting.Methods.Metro]: 0,
    [Commuting.Methods.Train]: 0,
    [Commuting.Methods.EScooter]: 15,
    [Commuting.Methods.LongDistanceBus]: 53,
    [Commuting.Methods.ShortDistanceBus]: 67,
    [Commuting.Methods.Scooter]: 82,
    [Commuting.Methods.Motorcycle]: 134,
    [Commuting.Methods.Microcar]: 154,
    [Commuting.Methods.ElectricCarZero]: 0,
    [Commuting.Methods.ElectricCar]: 35,
    [Commuting.Methods.HybridCarZero]: 68,
    [Commuting.Methods.HybridCar]: 89,
    [Commuting.Methods.SmallDieselCar]: 169,
    [Commuting.Methods.MediumDieselCar]: 194,
    [Commuting.Methods.LargeDieselCar]: 241,
    [Commuting.Methods.SmallGasolineCar]: 201,
    [Commuting.Methods.MediumGasolineCar]: 228,
    [Commuting.Methods.LargeGasolineCar]: 262,
  },
};

export const getHeatingEmissions = (locale = 'fi', value) => {
  return HEATING[locale][value];
};

export const getCommutingMethodEmissions = (locale = 'fi', value) => {
  return COMMUTING_METHODS[locale][value];
};

export const getCommutingMethodEmissionsKg = (locale = 'fi', value) => {
  return getCommutingMethodEmissions(locale, value) / 1000;
};
