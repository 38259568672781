import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { theme } from '../../theme';
import { useMessageGetter } from 'react-message-context';
import ModalConfirm from '../modal/ModalConfirm';
import AnimatedModalFullscreen from '../modal/AnimatedModalFullscreen';
import { ModalContent } from '../modal/ModalFullscreen';
import PostInput from '../../components/feeds/PostInput';
import { useAppState } from '../../context/appContext';
import ModalGroupSubtitle from '../modal/ModalGroupSubtitle';

const NewCommentModal = ({ show, groupId, currentUserId, onClose, onNewPost }) => {
  const messages = useMessageGetter('components.feed.newpost');
  const [newComment, setNewComment] = useState('');
  const [loading, setLoading] = useState(false);
  const [showExitConfirm, setShowExitConfirm] = useState(false);
  const appState = useAppState();
  const group = appState[groupId];

  const exitModal = () => {
    const hideCloseModal = () => {
      setShowExitConfirm(false);
    };

    return (
      <ModalConfirm
        title={messages('exit.confirm.title')}
        description={messages('exit.confirm.desc')}
        onCancel={hideCloseModal}
        options={[
          {
            label: messages('exit.confirm.yes'),
            key: 'yes',
            onClick: () => {
              setNewComment('');
              onClose();
              hideCloseModal();
            },
          },
          {
            label: messages('exit.confirm.no'),
            key: 'no',
            onClick: hideCloseModal,
          },
        ]}
      />
    );
  };

  const submitNewPost = async () => {
    setLoading(true);
    try {
      await onNewPost(groupId, newComment);
      setLoading(false);
      setNewComment('');
      onClose();
    } catch (err) {
      console.log('Failed to post comment', err);
      setLoading(false);
    }
  };

  const onCloseClick = () => (newComment.length > 0 ? setShowExitConfirm(true) : onClose());

  return (
    <ThemeProvider theme={theme('white')}>
      {showExitConfirm && exitModal()}
      <AnimatedModalFullscreen
        border={!!group}
        onClose={onCloseClick}
        show={show}
        title={messages('title')}
        subtitle={<ModalGroupSubtitle group={group} onClick={onCloseClick} marginBottom="15px" />}
      >
        <ModalContent>
          <PostInput
            loading={loading}
            onChange={e => setNewComment(e.target.value)}
            onSubmit={() => submitNewPost()}
            placeholder={messages('input.placeholder')}
            submitText={messages('submit')}
            userId={currentUserId}
            value={newComment}
          />
        </ModalContent>
      </AnimatedModalFullscreen>
    </ThemeProvider>
  );
};

NewCommentModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onNewPost: PropTypes.func.isRequired,
  currentUserId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
};

export default NewCommentModal;
