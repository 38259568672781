import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { h2Center, h2CenterNegative, pCenterNegative } from '../../styles/text';
import LoadingDotsIndicator from '../indicator/LoadingDotsIndicator';
import { largeCheckmark } from '../../assets/images';

const Button = props => {
  const content = (
    <ButtonContentContainer isLoading={props.loading || false}>
      <ButtonContent visible={props.loading}>
        {props.loadingText}
        {props.loading && (
          <LoadingDotsIndicator
            withText
            round
            variant={props.secondary ? 'positive' : 'negative'}
          />
        )}
      </ButtonContent>
      <ButtonContent visible={!props.loading}>
        {props.success && <Icon src={largeCheckmark} />}
        {!props.success && props.children}
      </ButtonContent>
    </ButtonContentContainer>
  );
  if (!props.explanation) {
    const { loading, ...propsToPass } = props;
    return <StyledButton {...propsToPass}>{content}</StyledButton>;
  } else {
    return (
      <ExplanationButtonContainer>
        {props.preface && <Preface>{props.preface}</Preface>}
        <StyledButton {...props}>{content}</StyledButton>
        <Explanation>{props.explanation}</Explanation>
      </ExplanationButtonContainer>
    );
  }
};

Button.propTypes = {
  explanation: PropTypes.string,
  preface: PropTypes.string,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  disabled: PropTypes.bool,
  fatal: PropTypes.bool,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
};

export default Button;

/**
 * Styles
 */
const width = '240px';
const height = '40px';

export const StyledButton = styled.button`
  ${props => (!props.disabled ? h2Center : h2CenterNegative)}
  width: ${width};
  height: ${height};
  max-height: ${height};
  box-sizing: border-box;
  padding: 0 15px;
  border-radius: 20px;
  background: ${buttonBackground};
  color: ${buttonColor};
  border: 0;
  text-transform: uppercase;
  overflow-y: hidden;
  position: relative;
  transition: 100ms;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transform: scale(1);
  ${({ disabled }) =>
    !disabled &&
    `&:hover {
      transform: scale(1.05);
      cursor: pointer;
    }
    &:active {
      transform: scale(0.9);
    }`}
`;

const ButtonContentContainer = styled.div`
  transition-delay: 100ms;
  transition: 300ms;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transform: translateY(-${props => (props.isLoading ? '0' : height)});
`;

const ButtonContent = styled.div`
  height: ${height};
  min-height: ${height};
  display: flex;
  align-items: center;
  justify-content: center;
  transition-delay: 100ms;
  transition: 300ms;
  opacity: ${props => (props.visible ? '1.0' : '0.0')};
  display: flex;
  flex-dirextion: row;
  align-content: baseline;
`;

function buttonColor(props) {
  if (props.primary) {
    return props.theme.button.textPrimary;
  } else if (props.fatal) {
    return props.theme.button.textFatal;
  } else if (props.secondary) {
    return props.theme.button.textSecondary;
  } else {
    return props.theme.button.text;
  }
}

function buttonBackground(props) {
  if (props.disabled) {
    return props.theme.button.bgDisabled;
  } else if (props.success) {
    return props.theme.colors.brand3Hue2;
  } else if (props.secondary) {
    return props.theme.button.bgSecondary;
  } else if (props.primary) {
    return props.theme.button.bgPrimary;
  } else {
    return props.theme.button.bg;
  }
}

const ExplanationButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${width};
`;

const Explanation = styled.div`
  ${pCenterNegative};
  margin-top: 5px;
`;

const Preface = styled.p`
  ${pCenterNegative};
  margin-bottom: 15px;
`;

const Icon = styled.img``;
