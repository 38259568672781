import styled from 'styled-components';

const Hr = styled.hr`
  color: ${props => props.theme.colors.dull3_15};
  background-color: ${props => props.theme.colors.dull3_15};
  height: 1px;
  border: none;
  width: 100%;
`;

export default Hr;
