import { useState, useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router';

/**
 * Tracks a page view if page is exactly this one
 */
export const useTrackPageView = () => {
  const [prevPathname, setPrevPathname] = useState(null);
  const location = useLocation();
  const match = useRouteMatch();
  const path = location.pathname + location.search + location.hash;
  useEffect(() => {
    if (match && match.isExact && prevPathname !== path) {
      setPrevPathname(path);
      if (window.goatcounter && window.goatcounter.count) {
        window.goatcounter.count({
          path,
        });
      }
    }
  }, [path, match, prevPathname]);
};
