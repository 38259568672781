import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMessageGetter } from 'react-message-context';
import AnimatedModalFullscreen from '../../../components/modal/AnimatedModalFullscreen';
import { ModalContent } from '../../../components/modal/ModalFullscreen';
import { WizardSelectOne } from '../../../components/wizard';
import Button from '../../../components/button/Button';

const EmailNotificationSettingsModal = ({ show, onClose, onSave, initialValue }) => {
  const messages = useMessageGetter('wizards.account_settings.email_opt_out_modal');
  const [value, setValue] = useState(initialValue);

  const options = [
    {
      key: 'on',
      value: false,
      title: messages('options.false'),
    },
    {
      key: 'off',
      value: true,
      title: messages('options.true'),
    },
  ];

  const actions = [
    <Button
      primary
      key={'save'}
      disabled={false}
      onClick={() => {
        onSave(value);
      }}
    >
      {messages('save')}
    </Button>,
  ];

  return (
    <AnimatedModalFullscreen
      show={show}
      title={messages('title')}
      onClose={onClose}
      actions={actions}
      border
    >
      <ModalContent style={{ paddingBottom: '130px' }}>
        <WizardSelectOne onSelect={setValue} options={options} value={value} name="select" />
      </ModalContent>
    </AnimatedModalFullscreen>
  );
};

EmailNotificationSettingsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  initialValue: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EmailNotificationSettingsModal;
