import React, { useState, useEffect, useRef } from 'react';
import { ThemeProvider } from 'styled-components';
import { useMessageGetter } from 'react-message-context';
import {
  FullscreenWizard,
  WizardInputText,
  WizardStep,
  WizardHeading,
  HEADING_SIZES,
  WizardInputSecretCode,
  WizardStepContent,
  FixedWizardFooter,
  WizardCountrySelection,
} from '../../components/wizard';
import FlagIcon from '../../components/icons/FlagIcon';
import Button from '../../components/button/Button';
import Box from '../../components/container/Box';
import { theme } from '../../theme';
import SetupErrorModal from '../../components/modal/ModalError';
import { imgWinkColor } from '../../assets/images';
import WizardIllustration from '../../components/wizard/illustration/WizardIllustration';
import Center from '../../components/container/Center';
import { ModalContent } from '../../components/modal/ModalFullscreen';
import {
  postInitSecondarySignUp,
  postVerifySecondarySignUp,
  postSecondarySignUp,
} from '../../functionalities/apis';
import { HistoryShape, MatchShapeOfParams } from '../../util/shapes';
import {
  AbsoluteWizardHeaderContainer,
  AbsoluteWizardContentContainer,
  RelativeWizardStepContent,
} from '../../components/wizard/container/PositionedWizardContainers';
import { composePhoneNumber } from '../../util/user';
import { Redirect } from 'react-router';
import { useAppDispatch } from '../../context/appContext';
import { loadUser } from '../../context/actions';
import { useTrackPageView } from '../../hooks/useTrackPageView';
import WizardContentButton from '../../components/wizard/form/WizardContentButton';
import TextButton from '../../components/button/TextButton';
import P from '../../components/texts/paragraphs/P';

const DEFAULT_COUNTRY = 'FI';

const SecondaryLoginOptionWizard = ({ history, match }) => {
  useTrackPageView();
  const dispatch = useAppDispatch();
  const [state, setState] = useState({
    x: 0,
    value: '',
    secretCode: '',
    showErrorModal: false,
    errorText: undefined,
    errorCode: null,
    loading: false,
    showValidation: false,
    countryCode: DEFAULT_COUNTRY,
    countryCallCode: '358',
    showCountryListModal: false,
  });

  const type =
    match.params.type === 'phone' ? 'phoneNumber' : match.params.type === 'email' ? 'email' : null;

  const secretInputRef = useRef(null);
  const submitButtonRef = useRef(null);

  useEffect(() => {
    if (state.x === 2) {
      loadUser(dispatch, true);
    }
  }, [dispatch, state.x]);

  useEffect(() => {
    if (state.secretCode === '') {
      if (secretInputRef.current) {
        secretInputRef.current.focus();
      }
    }

    if (state.secretCode.length >= 4 && submitButtonRef.current) {
      submitButtonRef.current.click();
    }
  }, [state.secretCode]);

  const messages = useMessageGetter();

  if (!type) {
    return <Redirect to={'/user/settings'} replace />;
  }

  const changeValue = event => {
    setState(Object.assign({}, state, { value: event.target.value }));
  };

  const changeSecretCode = value => {
    setState(Object.assign({}, state, { secretCode: value }));
  };

  const formattedValue = () => {
    const { countryCallCode, value } = state;
    return type === 'email' ? value : composePhoneNumber(countryCallCode, value);
  };

  const sendVerification = async () => {
    const { value } = state;
    if (!value) {
      setState(state => ({
        ...state,
        loading: false,
        showValidation: true,
      }));
      return;
    }

    try {
      setState(Object.assign({}, state, { loading: true }));
      const { authToken } = await postInitSecondarySignUp({ type, value: formattedValue() });
      setState(Object.assign({}, state, { x: 1, authToken, loading: false }));
    } catch (err) {
      console.log('Error initing sign up', err);
      if (err?.response?.data?.code === 'INVALID_EMAIL') {
        setState(
          Object.assign({}, state, {
            errorText: messages('pages.wizards.secondary.email.invalidEmail'),
            loading: false,
          }),
        );
      } else if (err?.response?.data?.code === 'INVALID_NUMBER') {
        setState(
          Object.assign({}, state, {
            errorText: messages('pages.wizards.secondary.phone.invalidNumber'),
            loading: false,
          }),
        );
      } else {
        setState(Object.assign({}, state, { showErrorModal: 'unknown', loading: false }));
      }
    }
  };

  const confirmAuthChallenge = async () => {
    const { authToken, secretCode } = state;
    try {
      setState(Object.assign({}, state, { loading: true }));

      await postVerifySecondarySignUp({
        authToken,
        secret: secretCode,
      });

      await postSecondarySignUp({
        authToken,
      });

      setState(Object.assign({}, state, { x: 2, loading: false }));
    } catch (err) {
      console.log('Error verifying', err);
      const errorCode = (err.response.data && err.response.data.code) || 'UNKNOWN';
      switch (errorCode) {
        case 'INCORRECT_SECRET':
          setState(
            Object.assign({}, state, {
              errorCode,
              errorText: messages('pages.wizards.secondary.confirm.error'),
              loading: false,
            }),
          );
          break;
        case 'TOO_MANY_TRIES':
          setState(Object.assign({}, state, { showErrorModal: 'tooManyTries', loading: false }));
          break;
        case 'NO_SIGN_UP':
        case 'INVALID_TOKEN':
          setState(Object.assign({}, state, { showErrorModal: 'resetAuth', loading: false }));
          break;
        default:
          setState(Object.assign({}, state, { showErrorModal: 'unknown', loading: false }));
      }
    }
  };

  const gotoInitPhase = () => {
    setState(Object.assign({}, state, { showErrorModal: false, x: 0 }));
  };

  const closeErrorModal = () => {
    setState(Object.assign({}, state, { showErrorModal: false }));
  };

  const getSignupStep = () => {
    const { value, loading, errorText, countryCode, countryCallCode } = state;
    return (
      <RelativeWizardStepContent>
        <AbsoluteWizardHeaderContainer>
          <WizardHeading
            title={messages(`pages.wizards.secondary.${type}.title`)}
            subtitle={messages(`pages.wizards.secondary.${type}.subtitle`)}
          />
        </AbsoluteWizardHeaderContainer>
        <AbsoluteWizardContentContainer>
          <ModalContent>
            {type === 'email' && (
              <WizardInputText
                name="email"
                type="email"
                label={messages('pages.wizards.secondary.email.form.email.label')}
                placeholder={messages('pages.wizards.secondary.email.form.email.placeholder')}
                value={value}
                onChange={changeValue}
                onSubmit={sendVerification}
                required={state.showValidation}
                errorText={errorText}
              />
            )}
            {type === 'phoneNumber' && (
              <WizardInputText
                icon={<FlagIcon countryCode={countryCode} />}
                iconText={`+${countryCallCode}`}
                iconAction={() =>
                  setState(Object.assign({}, state, { showCountryListModal: true }))
                }
                name="phonenumber"
                type="tel"
                label={messages('pages.wizards.secondary.phoneNumber.form.phoneNumber.label')}
                placeholder={messages(
                  'pages.wizards.secondary.phoneNumber.form.phoneNumber.placeholder',
                )}
                value={value}
                onChange={changeValue}
                onSubmit={sendVerification}
                required={state.showValidation}
                errorText={errorText}
              />
            )}
            <WizardContentButton
              onClick={sendVerification}
              loading={loading}
              text={messages(`pages.wizards.secondary.${type}.continue`)}
            />
          </ModalContent>
        </AbsoluteWizardContentContainer>
      </RelativeWizardStepContent>
    );
  };

  const { x, secretCode, showErrorModal, showCountryListModal } = state;
  const ErrorModal = SetupErrorModal(closeErrorModal, x, 0);
  const maxY = 1;

  const reset = () => {
    setState(state => ({
      ...state,
      secretCode: '',
    }));
  };

  const getVerificationStep = () => {
    const { loading, errorCode, errorText } = state;
    return (
      <RelativeWizardStepContent>
        <AbsoluteWizardHeaderContainer>
          <WizardHeading
            title={messages(`pages.wizards.secondary.${type}.confirm.title`)}
            subtitle={messages(`pages.wizards.secondary.${type}.confirm.subtitle`, {
              target: formattedValue(),
            })}
          />
        </AbsoluteWizardHeaderContainer>
        <AbsoluteWizardContentContainer>
          <ModalContent>
            <Center>
              <WizardInputSecretCode
                placeholder={messages(`pages.wizards.secondary.${type}.confirm.placeholder`)}
                value={secretCode}
                onChange={changeSecretCode}
                ref={secretInputRef}
              />
            </Center>
            <WizardContentButton
              ref={submitButtonRef}
              onClick={confirmAuthChallenge}
              loading={loading}
              text={messages(`pages.wizards.secondary.${type}.confirm.continue`)}
            />
            {errorCode && errorCode === 'INCORRECT_SECRET' && (
              <P isCenter>
                {errorText}{' '}
                {
                  <TextButton onClick={reset}>
                    {messages('pages.wizards.secondary.confirm.try_again')}
                  </TextButton>
                }
              </P>
            )}
          </ModalContent>
        </AbsoluteWizardContentContainer>
      </RelativeWizardStepContent>
    );
  };

  const getFeedbackStep = () => {
    return (
      <WizardStepContent>
        <Box margin="0 0 20px 0" style={{ display: 'block', textAlign: 'center' }}>
          <WizardIllustration src={imgWinkColor} />
        </Box>
        <WizardHeading
          title={messages(`pages.wizards.secondary.${type}.verified.title`)}
          subtitle={[
            messages(`pages.wizards.secondary.${type}.verified.subtitle`),
            messages(`pages.wizards.secondary.${type}.verified.subtitle2`),
          ]}
          type={HEADING_SIZES.HUGE}
        />
      </WizardStepContent>
    );
  };

  const mapFooter = () => {
    if (x === 2) {
      return (
        <FixedWizardFooter>
          <Button onClick={() => history.replace('/user/settings')} primary>
            {messages('pages.wizards.signup.welcome.continue.toProfile')}
          </Button>
        </FixedWizardFooter>
      );
    }
  };

  return (
    <ThemeProvider theme={theme('white')}>
      <FullscreenWizard x={x} y={0} maxX={3} maxY={maxY} footer={mapFooter()}>
        <WizardStep x={0} y={0} maxY={maxY} onClose={() => history.replace('/user/settings')}>
          {x === 0 && getSignupStep()}
        </WizardStep>
        <WizardStep x={1} y={0} maxY={maxY} onClose={() => history.replace('/user/settings')}>
          {x === 1 && getVerificationStep()}
        </WizardStep>
        <WizardStep x={2} y={0} maxY={maxY} onClose={() => history.replace('/user/settings')}>
          {x === 2 && getFeedbackStep()}
        </WizardStep>
        {showErrorModal === 'resetAuth' && (
          <ErrorModal
            title={messages('pages.wizards.secondary.error.resetAuth.title')}
            description={messages('pages.wizards.secondary.error.resetAuth.content')}
            options={[
              {
                label: messages('pages.wizards.secondary.error.resetAuth.restart'),
                key: 'restart',
                onClick: gotoInitPhase,
              },
            ]}
          />
        )}
        {showErrorModal === 'tooManyTries' && (
          <ErrorModal
            title={messages('pages.wizards.secondary.error.tooManyTries.title')}
            description={messages('pages.wizards.secondary.error.tooManyTries.content')}
            options={[
              {
                label: messages('pages.wizards.secondary.error.tooManyTries.restart'),
                key: 'restart',
                onClick: gotoInitPhase,
              },
            ]}
          />
        )}
        {showErrorModal === 'unknown' && (
          <ErrorModal
            title={messages('pages.wizards.secondary.error.unknown.title')}
            description={messages('pages.wizards.secondary.error.unknown.content')}
            options={[
              {
                label: messages('pages.wizards.secondary.error.unknown.close'),
                key: 1,
                onClick: closeErrorModal,
              },
            ]}
          />
        )}
      </FullscreenWizard>
      <WizardCountrySelection
        show={showCountryListModal}
        onClose={() => setState(Object.assign({}, state, { showCountryListModal: false }))}
        onChange={(countryCode, countryCallCode) =>
          setState(
            Object.assign({}, state, {
              countryCode,
              countryCallCode,
              showCountryListModal: false,
            }),
          )
        }
      />
    </ThemeProvider>
  );
};

SecondaryLoginOptionWizard.propTypes = {
  history: HistoryShape.isRequired,
  match: MatchShapeOfParams(['type']),
};

export default SecondaryLoginOptionWizard;
