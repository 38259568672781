import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import bg from '../../assets/images/banner-bg.svg';
import { navigationParents } from '../../functionalities/navigation';
import { useMessageGetter } from 'react-message-context';
import { Card } from '../card';
import { h3LeftNegative, labelLeftNegative } from '../../styles/text';
import SmallButton from '../button/SmallButton';

const LoginBanner = () => {
  const messages = useMessageGetter('components.banner.login');
  return (
    <Link to={navigationParents.login.url}>
      <Card bgImage={bg} spacing="dense" bgPosition="bottom left">
        <Content>
          <Texts>
            <Title>{messages('title')}</Title>
            <Subtitle>{messages('subtitle')}</Subtitle>
          </Texts>
          <SmallButton text={messages('button')} type="primary" />
        </Content>
      </Card>
    </Link>
  );
};

export default LoginBanner;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 150px;
  > button {
    width: fit-content;
  }
`;

const Texts = styled.div``;

const Title = styled.h1`
  ${h3LeftNegative};
  color: ${({ theme }) => theme.loginBanner.color};
  margin: 0;
`;

const Subtitle = styled.span`
  ${labelLeftNegative};
  color: ${({ theme }) => theme.loginBanner.color};
  margin-top: 5px;
`;
