import React, { useRef, createContext, Children } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { matchPath, useLocation } from 'react-router-dom';

import MainRoute from './MainRoute';
import { usePrevious } from '../../hooks/values';
import NavigationBar from '../../fragments/navigation/NavigationBar';

export const MainRouteContext = createContext({ transitionDirection: 'right' });

export const Navigating = {
  Inside: 'inside',
  In: 'in',
  Out: 'out',
};

export const Direction = {
  Right: 'right',
  Left: 'left',
};

export default function MainRoutes({ children }) {
  const location = useLocation();
  const previousLocation = usePrevious(location);
  const transitionDirection = useRef(Direction.Right);
  const mainRoutes = Children.map(children, ({ props: { path, exact, horizontalPosition } }) => ({
    path,
    exact,
    horizontalPosition,
  }));
  const newMainRoute = mainRoutes.find(r => matchPath(location.pathname, r));
  const previousMainRoute =
    previousLocation && mainRoutes.find(r => matchPath(previousLocation.pathname, r));
  const navigatingFromMainRouteToMainRoute = newMainRoute && previousMainRoute;
  if (navigatingFromMainRouteToMainRoute) {
    transitionDirection.current =
      previousMainRoute.horizontalPosition > newMainRoute.horizontalPosition
        ? Direction.Left
        : Direction.Right;
  }
  const navigating = navigatingFromMainRouteToMainRoute
    ? Navigating.Inside
    : newMainRoute
    ? Navigating.In
    : Navigating.Out;

  return (
    <MainRouteContext.Provider
      value={{ transitionDirection: transitionDirection.current, navigating }}
    >
      <NavigationBar />
      <Relative>{children}</Relative>
    </MainRouteContext.Provider>
  );
}

const mainRouteType = PropTypes.shape({
  type: PropTypes.oneOf([MainRoute]),
});

MainRoutes.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(mainRouteType), mainRouteType]).isRequired,
};

const Relative = styled.div`
  position: relative;
`;
