import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ButtonIcon from '../../button-icon/ButtonIcon';
import { h2CenterNegative } from '../../../styles/text';
import { ModalCenteredContent } from '../../container/CenteredContent';

const WizardStep = ({ children = [], x, y, maxY, onClose = () => {}, title }) => {
  const childrenArray = React.Children.toArray(children);
  let footer;
  // XXX: Move footer out of children to different DOM root to achieve smooth scroll overflow with
  //      static header and footer.
  if (
    childrenArray.length > 0 &&
    childrenArray[childrenArray.length - 1].type.name === 'FixedWizardFooter'
  ) {
    footer = childrenArray.splice(-1, 1);
  }

  const fixedHeader = !title;

  return (
    <WizardStepContainer x={x} y={y} maxY={maxY}>
      <FixedWizardHeader fixedHeader={fixedHeader}>
        <CenteredHeader>
          <CloseButtonContainer>
            <ButtonIcon label="Close" type="close" disabled={false} onClick={onClose} />
          </CloseButtonContainer>
          {title && <WizardHeaderText>{title}</WizardHeaderText>}
        </CenteredHeader>
      </FixedWizardHeader>
      <WizardContentWrapper fixedHeader={fixedHeader}>
        <Centered>{childrenArray}</Centered>
      </WizardContentWrapper>
      {footer}
    </WizardStepContainer>
  );
};

WizardStep.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  maxY: PropTypes.number.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.node,
  title: PropTypes.string,
};

export default WizardStep;

const WizardStepContainer = styled.div`
  top: ${props => props.y * 100}%;
  left: ${props => props.x * 100}%;
  width: 100vw;
  height: ${props => 100 / props.maxY}%;
  position: absolute;
  z-index: 2;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const Centered = styled(ModalCenteredContent)`
  height: 100%;
`;

const CenteredHeader = styled(ModalCenteredContent)`
  position: relative;
`;

export const FixedWizardHeader = styled.div`
  height: 64px;
  padding: 15px;
  box-sizing: border-box;
  width: 100vw;
  position: ${props => (props.fixedHeader ? 'fixed' : 'relative')};
  text-align: center;
  z-index: 2;
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  left: 15px;
`;

export const WizardHeaderText = styled.span`
  ${h2CenterNegative};
  color: ${props => props.theme.wizard.stepHeaderTextColor};
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WizardContentWrapper = styled.div`
  overflow: auto;
  padding-top: ${props => (props.fixedHeader ? '64px' : '0')};
  height: 100%;
`;
