import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMessageGetter } from 'react-message-context';

import UltraSmallButton from '../button/UltraSmallButton';
import ModalSelect from '../modal/ModalSelect';
import { labelLeftWeak } from '../../styles/text';

const SortingOptionsSelector = ({ selectedOption, onSelect, options }) => {
  const [open, setOpen] = useState(false);
  const messages = useMessageGetter('components.sorting_options_selector');
  const sortingMessages = useMessageGetter('pages.group.members.sorting');

  return (
    <Container>
      <Label>{messages('label')}</Label>
      <Button onClick={() => setOpen(true)}>
        {sortingMessages(`${selectedOption.id}_short`) || sortingMessages(selectedOption.id)}
      </Button>
      {open && (
        <ModalSelect
          title={messages('select_title')}
          options={options}
          onChange={selectedOption => {
            setOpen(false);
            onSelect(selectedOption);
          }}
          onCancel={() => setOpen(false)}
        />
      )}
    </Container>
  );
};

const OptionShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
});

SortingOptionsSelector.propTypes = {
  selectedOption: OptionShape.isRequired,
  onSelect: PropTypes.func,
  options: PropTypes.arrayOf(OptionShape),
};

export default SortingOptionsSelector;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.div`
  ${labelLeftWeak}
`;

const Button = styled(UltraSmallButton)`
  margin-left: 10px;
`;
