import PropTypes from 'prop-types';

const userFields = {
  userId: PropTypes.string.isRequired,
  givenName: PropTypes.string.isRequired,
  familyName: PropTypes.string.isRequired,
};

export const UserShape = PropTypes.shape(userFields);

export const GroupMemberShape = PropTypes.shape({
  ...userFields,
  initialCo2Kg: PropTypes.number,
  yearlyFootPrintCo2Kg: PropTypes.number,
  totalReductionCo2Kg: PropTypes.number,
  isCaptain: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
});

export const LineChartValueShape = PropTypes.shape({
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
});

export const LineChartShape = PropTypes.shape({
  valuesAmount: PropTypes.number.isRequired,
  targetStartValue: PropTypes.number,
  targetEndValue: PropTypes.number,
  refStartValue: PropTypes.number,
  refEndValue: PropTypes.number,
  values: PropTypes.arrayOf(LineChartValueShape).isRequired,
});

export const MatchShapeOfParams = params =>
  PropTypes.shape({
    params: PropTypes.shape(
      params.reduce(
        (acc, param) => ({
          ...acc,
          [param]: PropTypes.string.isRequired,
        }),
        {},
      ),
    ).isRequired,
    isExact: PropTypes.bool,
  });

export const HistoryShape = PropTypes.shape({
  push: PropTypes.func.isRequired,
});

export const LocationShape = PropTypes.shape({
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string,
});

export const FeedItemShape = PropTypes.shape({
  info: PropTypes.string,
  isCaptain: PropTypes.bool,
  user: UserShape.isRequired,
  createdAt: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  aspect: PropTypes.string.isRequired,
  replyCount: PropTypes.number.isRequired,
  reactions: PropTypes.shape({
    like: PropTypes.any,
  }),
});

export const GroupGoalShape = PropTypes.shape({
  totalTargetCo2Kg: PropTypes.number.isRequired,
  totalInitialCo2Kg: PropTypes.number.isRequired,
});

export const PersonalGoalShape = PropTypes.shape({
  targetCo2Kg: PropTypes.number.isRequired,
  initialCo2Kg: PropTypes.number.isRequired,
  actions: PropTypes.array.isRequired,
});

export const GroupProgressShape = PropTypes.shape({
  answers: PropTypes.any,
  co2Kg: PropTypes.any,
  cumulative: PropTypes.any,
});

export const SimpleGroupShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  captain: UserShape.isRequired,
});

export const GroupShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  challenge: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  captain: UserShape.isRequired,
  memberCount: PropTypes.number,
  startDate: PropTypes.string,
  reduction: PropTypes.number,
  targetReductionCo2Kg: PropTypes.number,
  durationDays: PropTypes.number,
  access: PropTypes.string,
});

export const ChallengeShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  groups: PropTypes.arrayOf(SimpleGroupShape),
  co2FootprintPros: PropTypes.number.isRequired,
  theme: PropTypes.string.isRequired,
  reduction: PropTypes.number,
  active: PropTypes.bool,
});

export const SpacingShape = PropTypes.oneOf(['none', 'dense', 'default', 'loose']);
