import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import ProfilePicture, { SIZES } from '../../components/picture/ProfilePicture';
import { theme } from '../../theme';
import { useMessageGetter } from 'react-message-context';
import { pLeft } from '../../styles/text';
import { FooterContainer, Left } from '../../components/feeds/FeedModalComponents';
import FeedItem from '../../components/feeds/FeedItem';
import ButtonIcon from '../../components/button-icon/ButtonIcon';
import TextareaAutosize from 'react-autosize-textarea';
import ModalConfirm from '../../components/modal/ModalConfirm';
import { useApp } from '../../context/appContext';
import { loadGroup, loadPost, postNewReply } from '../../context/actions';
import ModalFullscreen from '../../components/modal/ModalFullscreen';
import { useTrackPageView } from '../../hooks/useTrackPageView';
import { Transition } from 'react-transition-group';
import elements from '../../styles/elements';
import ModalGroupSubtitle from '../../components/modal/ModalGroupSubtitle';
import { MatchShapeOfParams, HistoryShape } from '../../util/shapes';

const modalProps = {
  border: true,
  theme: theme('white'),
};

const PostPage = ({ match, history }) => {
  const { postId, groupId, challengeId } = match.params;
  const messages = useMessageGetter();
  const [state, dispatch] = useApp();
  const [newComment, setNewComment] = useState('');
  const [loading, setLoading] = useState(false);
  const [showExitConfirm, setShowExitConfirm] = useState(false);
  const lastReplyEl = React.createRef();
  const group = state[groupId];

  useTrackPageView();

  useEffect(() => {
    async function load() {
      try {
        await loadPost(dispatch, postId);
      } catch (err) {
        console.error('Error loading post', err);
        history.push('/404');
      }
    }
    load();
  }, [dispatch, postId, history]);

  useEffect(() => {
    if (!group) loadGroup(dispatch, groupId);
  }, [dispatch, groupId, group]);

  const { user } = state;
  const post = state.posts.find(({ aspect }) => aspect === `feed_${postId}`);
  const title = messages(
    `components.feed.comments.${post && post.userWritten ? 'post' : 'event'}_title`,
  );

  const contentNotReady = (
    <ModalFullscreen title={title} onClose={history.goBack} {...modalProps}>
      {null}
    </ModalFullscreen>
  );

  if (!post) {
    return contentNotReady;
  }

  const onShowReactions = () => {};
  const doPostReply = async () => {
    setLoading(true);
    await postNewReply(dispatch, post.id, post.aspect, newComment);
    setNewComment('');
    setLoading(false);
  };

  const onClose = () => {
    if (newComment.length > 0) {
      setShowExitConfirm(true);
    } else {
      history.goBack();
    }
  };

  const exitModal = () => {
    const hideCloseModal = () => {
      setShowExitConfirm(false);
    };

    return (
      <ModalConfirm
        title={messages('components.feed.comments.exit.confirm.title')}
        description={messages('components.feed.comments.exit.confirm.desc')}
        onCancel={hideCloseModal}
        options={[
          {
            label: messages('components.feed.comments.exit.confirm.yes'),
            key: 'yes',
            onClick: history.goBack,
          },
          {
            label: messages('components.feed.comments.exit.confirm.no'),
            key: 'no',
            onClick: hideCloseModal,
          },
        ]}
      />
    );
  };

  const gotoGroup = () => history.push(`/challenges/${challengeId}/group/${groupId}`);

  const currentUserId = user.isLoggedIn && user.sub;
  const { replies = [] } = post;

  if (post.replyCount > 0 && replies.length < 1) {
    return contentNotReady;
  }

  return (
    <ModalFullscreen
      title={title}
      onClose={onClose}
      subtitle={
        <ModalGroupSubtitle group={group} onClick={gotoGroup} inGroup marginBottom="15px" />
      }
      border={!!group}
      {...modalProps}
    >
      {showExitConfirm && exitModal()}

      <ScrolledArea>
        <OriginalItem>
          <ItemContainer>
            <FeedItem
              feedItem={post}
              currentUserId={currentUserId}
              onShowReactions={onShowReactions}
              hideCommentAction={post.replyCount === 0}
              linkToComments={false}
            />
          </ItemContainer>
        </OriginalItem>
        <ReplyContainer>
          {replies
            .sort((a, b) => a.createdAt - b.createdAt)
            .map((reply, i) => (
              <ItemContainer
                key={`reply-${i}`}
                ref={i === replies.length - 1 ? lastReplyEl : undefined}
              >
                <FeedItem
                  feedItem={reply}
                  currentUserId={currentUserId}
                  onShowReactions={onShowReactions}
                  hideCommentAction
                />
              </ItemContainer>
            ))}
        </ReplyContainer>
      </ScrolledArea>

      <Transition in timeout={300} appear>
        {state => (
          <ThemeProvider theme={theme('blue')}>
            <CommentFooter show={state === 'entering' || state === 'entered'}>
              <FooterContainer>
                <Left>
                  <ProfilePicture userId={currentUserId} size={SIZES.MINI} flat />
                </Left>
                <CustomTextArea
                  maxRows={20}
                  maxLength={1000}
                  name={'new-comment'}
                  type={'text'}
                  value={newComment}
                  onChange={e => setNewComment(e.target.value)}
                  placeholder={messages('components.feed.comments.placeholder')}
                  disabled={loading}
                />
                <Right>
                  <ButtonIcon
                    type="arrowRight"
                    onClick={() => doPostReply(post, newComment)}
                    label={messages('general.button.forward')}
                    disabled={newComment.length === 0}
                    loading={loading}
                  />
                </Right>
              </FooterContainer>
            </CommentFooter>
          </ThemeProvider>
        )}
      </Transition>
    </ModalFullscreen>
  );
};

PostPage.propTypes = {
  match: MatchShapeOfParams(['challengeId', 'groupId', 'postId']),
  history: HistoryShape.isRequired,
};

export default PostPage;

const ScrolledArea = styled.div`
  padding-bottom: 64px;
`;

const CustomTextArea = styled(TextareaAutosize)`
  ${pLeft};
  width: 100%;
  border: solid 1px ${props => props.theme.colors.soft1};
  background-color: ${props => props.theme.colors.soft1_20};
  border-radius: 8px;
  border-top-left-radius: 0px;
  padding: 7px 10px 8px 10px;
  resize: none;
  font-size: 16px;
  -webkit-appearance: none;
`;

const Right = styled.div`
  width: 45px;
  min-width: 45px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const ReplyContainer = styled.div`
  padding-top: 8px;
`;

const OriginalItem = styled.div`
  border-bottom: solid 1px rgba(87, 87, 87, 0.15);
`;

const ItemContainer = styled.div`
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding: 12px 30px 12px 30px;
`;

const CommentFooter = styled.div`
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 300ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-delay: 300ms;
  background: ${props => props.theme.colors.dull7};
  border-top: solid 1px rgba(87, 87, 87, 0.15);
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: ${elements.containerMaxWidthPx}px;
  z-index: 250;
  pointer-events: none;
  & > * {
    pointer-events: auto;
  }
`;
