import styled from 'styled-components';
import { pNegative } from '../../../styles/text';
import { Link } from 'react-router-dom';

const WizardInlineLink = styled(Link)`
  ${pNegative};
  color: ${props => props.theme.text.link};
  font-weight: bold;
`;

export default WizardInlineLink;
