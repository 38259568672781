import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { GroupStates } from 'isomorphic/constants';

import Co2 from '../../../functionalities/Co2';
import ProfilePicture, { SIZES } from '../../picture/ProfilePicture';
import { h3Alert, h3Standout, labelAlert, labelStandout, pLeft, pWeak } from '../../../styles/text';
import { ReactComponent as CaptainBadgeSvg } from '../../../assets/images/badge-captain-line.svg';
import { ReactComponent as CheckCircleSvg } from '../../../assets/images/check-circle.svg';
import { seachangeTextAlpha15 } from '../../../styles/colors';
import { GroupMemberShape } from '../../../util/shapes';
import StandingNumber from '../../number/StandingNumber';

const MemberList = ({ members, showStandings, scoreType, groupStatus, showScore, className }) => (
  <Container className={className}>
    {members.map((member, i) => (
      <Member key={member.userId}>
        <Left>
          {showStandings && <StandingNumber standout={i < 3}>{i + 1}</StandingNumber>}
          <ProfilePicture userId={member.userId} size={SIZES.MINI} flat />
          <Name>
            {member.givenName} {member.familyName}
          </Name>
          {member.isCaptain && <CaptainIcon />}
          {member.isLoggedIn && !member.isCaptain && <CheckIcon />}
        </Left>
        {showScore && (
          <Score score={member[scoreType]} rich={groupStatus !== GroupStates.hatching} />
        )}
      </Member>
    ))}
  </Container>
);

MemberList.propTypes = {
  members: PropTypes.arrayOf(GroupMemberShape).isRequired,
  showStandings: PropTypes.bool,
  scoreType: PropTypes.oneOf(['initialCo2Kg', 'yearlyFootPrintCo2Kg', 'totalReductionCo2Kg'])
    .isRequired,
  groupStatus: PropTypes.string.isRequired,
  showScore: PropTypes.bool,
  className: PropTypes.string,
};

export default MemberList;

const Score = ({ score, rich }) => {
  const roundedScore = new Co2().round(score);
  const isNegative = roundedScore < 0;
  let valueStr = `${Math.abs(roundedScore)}`;
  if (valueStr.length === 1) {
    valueStr = Math.abs(roundedScore).toFixed(1);
  }

  return (
    <ScoreContainer>
      {rich ? (
        <BigScore>
          {isNegative && <BigPlus>+</BigPlus>}
          <BigNumber negative={isNegative}>{valueStr}</BigNumber>
          <BigKg negative={isNegative}>kg</BigKg>
        </BigScore>
      ) : (
        <BasicScore>{roundedScore} kg</BasicScore>
      )}
      <Unit rich={rich}>CO2e</Unit>
    </ScoreContainer>
  );
};

Score.propTypes = {
  score: PropTypes.number,
  rich: PropTypes.bool,
};

const Container = styled.ul`
  padding: 0;
  margin: 0;
`;

const Member = styled.li`
  display: flex;
  padding-top: 10.5px;
  padding-bottom: 8.5px;
  border-bottom: solid 1px ${seachangeTextAlpha15};
`;

const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
  flex: 1;
`;

const Name = styled.div`
  ${pLeft};
  margin-left: 10px;
`;

const BasicScore = styled.div`
  ${pLeft};
  margin-bottom: 1px;
`;

const BigNumber = styled.div`
  ${props => (props.negative ? h3Alert : h3Standout)};
  margin: 0 3px 1px 2px;
`;

const BigKg = styled.div`
  ${props => (props.negative ? labelAlert : labelStandout)};
`;

const BigPlus = styled.div`
  ${labelAlert};
  align-self: center;
`;

const BigScore = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
`;

const ScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-left: 8px;
  margin-right: 8px;
`;

const CaptainIcon = styled(CaptainBadgeSvg)`
  margin-left: 3px;
  g {
    fill: #031937;
  }
`;

const CheckIcon = styled(CheckCircleSvg)`
  margin-left: 3px;
`;

const Unit = styled.div`
  ${pWeak}
  ${props => props.rich && 'margin-right: 16px;'}
`;
