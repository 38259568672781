import styled from 'styled-components';

const ModalIllustration = styled.img.attrs(props => ({
  alt: 'illustration',
}))`
  margin-top: 40px;
  margin-bottom: 9px;
  text-align: center;
`;

export default ModalIllustration;
