import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import elements from '../../styles/elements';
import config from '../../config';
import LoadingDotsIndicator from '../indicator/LoadingDotsIndicator';
import { pCenterNegative } from '../../styles/text';
import { placeholderGroup, colorProfilePlaceholder } from '../../assets/images';
import { useAppState } from '../../context/appContext';

export const SIZES = {
  LARGE: 'LARGE',
  MEDIUM: 'MEDIUM',
  SMALL: 'SMALL',
  MINI2: 'MINI2',
  MINI: 'MINI',
  MICRO: 'MICRO',
};

export const SHAPES = {
  SQUARE: 'SQUARE',
  ROUND: 'ROUND',
};

export const TYPES = {
  USER: 'USER',
  GROUP: 'GROUP',
};

export const VARIANT = {
  NEGATIVE: 'NEGATIVE',
  POSITIVE: 'POSITIVE',
};

const MAX_RETRIES = 3;
const RETRY_TIMEOUT = 500;

const ProfilePicture = ({
  alt = '',
  userId,
  badge,
  size = SIZES.LARGE,
  shape = SHAPES.SQUARE,
  type = TYPES.USER,
  variant = VARIANT.NEGATIVE,
  file,
  flat = false,
  isUploading = false,
  overlayText,
  style,
  centered,
}) => {
  // Retrieve from state if this is picture of logged in user and append the cache breaking
  // mechanism if this happens to be the case
  let cacheBreak = '';
  const { profilePictureUpdated, groupProfilePictureUpdated, user } = useAppState();
  if (user.isLoggedIn && user.userId === userId) {
    cacheBreak = profilePictureUpdated;
  } else if (type === TYPES.GROUP && groupProfilePictureUpdated[userId]) {
    cacheBreak = groupProfilePictureUpdated[userId];
  }

  const [retryCount, setRetryCount] = useState(0);
  const side = getWidth(size);
  const bucketUrl = config[process.env.REACT_APP_ENV].profileBucket.url;
  const src = `${bucketUrl}/${side}x${side}/${userId}${cacheBreak}`;
  const onLoad = ev => {
    ev.target.style.padding = '0px'; // XXX: Don't shoot me
  };
  const onError = ev => {
    if (retryCount <= MAX_RETRIES && cacheBreak !== '') {
      setTimeout(() => setRetryCount(retryCount + 1), RETRY_TIMEOUT);
    } else {
      switch (type) {
        case TYPES.GROUP:
          ev.target.src = placeholderGroup;
          break;
        default:
          ev.target.src = colorProfilePlaceholder;
      }
      if (size !== SIZES.MINI) {
        ev.target.style.padding = '8px'; // XXX: Don't shoot me
      }
    }
  };

  return (
    <PictureWrapper alt={alt} centered={centered}>
      <PictureContainer size={side}>
        {isUploading && (
          <UploadOverlay width={side} shape={shape}>
            <LoadingDotsIndicator />
          </UploadOverlay>
        )}
        {overlayText && (
          <UploadOverlay width={side} shape={shape}>
            <OverlayText>{overlayText}</OverlayText>
          </UploadOverlay>
        )}
        <ProfilePictureEl
          flat={flat}
          variant={variant}
          src={file || src}
          shape={shape}
          width={side}
          height={side}
          onLoad={onLoad}
          onError={onError}
          style={style}
        />
      </PictureContainer>
      {badge && <BadgeContainer>{badge}</BadgeContainer>}
    </PictureWrapper>
  );
};

ProfilePicture.propTypes = {
  alt: PropTypes.string,
  badge: PropTypes.node,
  userId: PropTypes.string,
  size: PropTypes.oneOf(Object.values(SIZES)),
  shape: PropTypes.oneOf(Object.values(SHAPES)),
  type: PropTypes.oneOf(Object.values(TYPES)),
  variant: PropTypes.oneOf(Object.values(VARIANT)),
  cacheBreak: PropTypes.string,
  flat: PropTypes.bool,
  file: PropTypes.any,
  isUploading: PropTypes.bool,
  overlayText: PropTypes.string,
  centered: PropTypes.bool,
  style: PropTypes.object,
};

export default ProfilePicture;

function getWidth(size) {
  switch (size) {
    case SIZES.MICRO:
      return 20;
    case SIZES.MINI:
      return 30;
    case SIZES.MINI2:
      return 34;
    case SIZES.SMALL:
      return 40;
    case SIZES.MEDIUM:
      return 50;
    case SIZES.LARGE:
    default:
      return 140;
  }
}

export const PictureWrapper = styled.div.attrs(({ centered }) => ({
  style: centered
    ? {
        display: 'flex',
        justifyContent: 'center',
      }
    : undefined,
}))`
  position: relative;
`;

export const PictureContainer = styled.div`
  position: relative;
  height: ${props => props.size}px;
  width: ${props => props.size}px;
  text-align: center;
`;

export const ProfilePictureEl = styled.img.attrs(props => ({
  style: {
    backgroundColor:
      props.variant === VARIANT.NEGATIVE ? props.theme.colors.soft1 : props.theme.colors.dull7,
    borderRadius: props.shape === SHAPES.SQUARE ? '4px' : `${props.width}px`,
    boxShadow: !props.flat ? elements.boxShadow2 : 'none',
  },
  ariaLabel: 'test',
}))``;

const BadgeContainer = styled.div`
  position: absolute;
  left: -2px;
  top: -2px;
`;

const UploadOverlay = styled.div`
  border-radius: ${props => (props.shape === SHAPES.SQUARE ? '4' : props.width)}px;
  width: 100%;
  height: 100%;
  max-height: ${props => props.width}px;
  max-width: ${props => props.width}px;
  position: absolute;
  left: calc(50% - ${props => props.width / 2}px);
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OverlayText = styled.span`
  ${pCenterNegative};
  color: ${props => props.theme.colors.dull7};
`;
