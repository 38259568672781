import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { h2LeftNegative } from '../../styles/text';

const PageSegment = ({ title, children, style }) => (
  <Container style={style}>
    <Heading>{title}</Heading>
    {children}
  </Container>
);

PageSegment.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  style: PropTypes.object,
};

export default PageSegment;

export const Container = styled.div`
  text-align: left;
`;

const Heading = styled.h2`
  ${h2LeftNegative}
`;
