import React, { useCallback, useMemo } from 'react';
import { getJoinGroupConfig } from '../../../functionalities/apis';
import { useTrackPageView } from '../../../hooks/useTrackPageView';
import { HistoryShape, LocationShape, MatchShapeOfParams } from '../../../util/shapes';
import Wizard from '../../wizards/Wizard';

const JoinGroupWizard = ({ match, history, location }) => {
  useTrackPageView(location, match);
  const { challengeId, groupId } = match.params;
  const loadConfig = useCallback(async () => await getJoinGroupConfig(challengeId, groupId), [
    challengeId,
    groupId,
  ]);
  const onClose = useMemo(() => {
    return () => {
      history.push(`/challenges/${challengeId}/group/${groupId}`);
    };
  }, [history, challengeId, groupId]);
  return <Wizard match={match} loadConfig={loadConfig} onClose={onClose} />;
};

JoinGroupWizard.propTypes = {
  match: MatchShapeOfParams(['challengeId', 'groupId']),
  history: HistoryShape.isRequired,
  location: LocationShape.isRequired,
};

export default JoinGroupWizard;
