import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import ProfilePicture, { SIZES } from '../picture/ProfilePicture';
import { FeedItemShape } from '../../util/shapes';
import { pWeak } from '../../styles/text';
import { relativeTimeText } from '../../util/text';
import { useMessageGetter } from 'react-message-context';
import { Constants, Reducers } from 'isomorphic';
import { notReacted } from '../../assets/images';
import { ReactionPhase, ReactIcon, ReactionCount, Reactions } from './ReactionComponents';
import { postNewReaction } from '../../context/actions';
import { useAppDispatch } from '../../context/appContext';
import Post, { POST_TYPE } from './Post';
import { FeedItemContainer, Left, Main, Right } from './FeedItemPrimitives';
import { assureNoPrefix } from 'isomorphic/util';

const images = require.context('../../assets/images', true);
const { sum } = Reducers;

const getReactionsCount = reactions => {
  return Object.values(reactions)
    .map(reactions => Object.keys(reactions).length)
    .reduce(sum, 0);
};

const FeedItem = ({
  feedItem,
  currentUserId,
  hideCommentAction,
  linkToComments = true,
  challengeId,
}) => {
  const {
    aspect,
    user,
    createdAt,
    replyCount,
    text,
    info,
    params,
    reactions,
    userWritten,
    isCaptain,
  } = feedItem;
  const messages = useMessageGetter();
  const [reactionAnimation, setReactionAnimation] = useState();
  const [countAnimation, setCountAnimation] = useState();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const doneReactionType = Object.keys(reactions).find(reactionType =>
    Object.keys(reactions[reactionType]).some(userId => userId === currentUserId),
  );

  const reactionCount = getReactionsCount(reactions);

  const onReact = async reactionType => {
    // adding a reaction
    if (!doneReactionType) {
      setReactionAnimation(ReactionPhase.adding);
      setCountAnimation(ReactionPhase.adding);
      await postNewReaction(dispatch, feedItem.id, aspect, {
        reactionType: Constants.ReactionTypes.like,
      });
      // removing a reaction
    } else {
      setReactionAnimation(ReactionPhase.removing);
      setCountAnimation(ReactionPhase.removing);
    }
  };

  const onCountAnimationFinished = async () => {
    if (countAnimation === ReactionPhase.removing) {
      postNewReaction(dispatch, feedItem.id, aspect, {
        reactionType: Constants.ReactionTypes.like,
      });
      setCountAnimation(ReactionPhase.removing2);
    } else if (countAnimation === ReactionPhase.removing2) {
      setCountAnimation();
    }
  };

  let unprefixedAspect;
  if (aspect.startsWith('feed_')) {
    unprefixedAspect = assureNoPrefix('feed_', aspect);
  } else if (aspect.startsWith('feedreply_')) {
    unprefixedAspect = assureNoPrefix('feedreply_', aspect).substring(0, aspect.indexOf('_'));
  }

  const reactionsId = aspect.substring(aspect.lastIndexOf('_') + 1);
  const groupId = assureNoPrefix('group_', feedItem.id);
  const basePath = `/challenges/${challengeId}/group/${groupId}/`;

  let postText = text;
  if (!userWritten) {
    postText = messages(text, params) || text; // If no translation found, use the "text" itself
  }

  let infoText = info;
  if (info && typeof info === 'string') {
    infoText = messages(info, params) || info;
  }

  return (
    <FeedItemContainer>
      <Left>
        <ProfilePicture userId={user.userId} size={SIZES.MINI} flat />
      </Left>
      <Main>
        <Post
          type={userWritten ? POST_TYPE.POST : POST_TYPE.ACTION}
          author={userWritten ? user.givenName : ''}
          authorTitle={userWritten && isCaptain ? messages('components.feed.comments.captain') : ''}
          text={postText}
        />
        <Extra>
          {infoText && <Info>{infoText}</Info>}
          {!hideCommentAction && linkToComments && (
            <InfoLink to={`${basePath}posts/${aspect.substring(5)}`}>
              {messages('components.feed.comments.count', { replyCount: replyCount })}
            </InfoLink>
          )}
          {!hideCommentAction && !linkToComments && (
            <Info>{messages('components.feed.comments.count', { replyCount: replyCount })}</Info>
          )}
          <ReactionsLink to={reactionsLink(location, unprefixedAspect, reactionsId)}>
            <Reactions reactionCount={reactionCount}>
              {Object.keys(reactions).map((reactionType, i) => (
                <ReactIcon
                  key={`react-${i}`}
                  src={images(`./given-${reactionType}.svg`).default}
                  animating={reactionAnimation === ReactionPhase.adding}
                />
              ))}
              <ReactionCount
                animating={countAnimation}
                reactionCount={reactionCount}
                onAnimationEnd={() => onCountAnimationFinished()}
              >
                {reactionCount}
              </ReactionCount>
            </Reactions>
          </ReactionsLink>
        </Extra>
      </Main>
      <Right>
        <Time>{relativeTimeText(createdAt, messages)}</Time>
        <ReactIcon
          src={doneReactionType ? images(`./done-${doneReactionType}.svg`).default : notReacted}
          onClick={() => onReact(Constants.ReactionTypes.like)}
          alt={messages('components.feed.reactions.alt')}
          animating={
            reactionAnimation === ReactionPhase.adding ||
            reactionAnimation === ReactionPhase.removing
          }
          onAnimationEnd={() => setReactionAnimation()}
        />
      </Right>
    </FeedItemContainer>
  );
};

function reactionsLink(location, aspect, reactionsId) {
  let basePath = location.pathname;
  if (!location.pathname.endsWith('/')) {
    basePath += '/';
  }

  if (basePath.indexOf(`/posts/${aspect}`) >= 0) {
    return `${basePath}reactions/${reactionsId}`;
  } else if (basePath.indexOf(`/posts/`) >= 0) {
    return `${basePath}${aspect}/reactions/${reactionsId}`;
  } else {
    basePath = basePath.substring(basePath.indexOf(`/posts/${aspect}`));
    return `${basePath}posts/${aspect}/reactions/${reactionsId}`;
  }
}

FeedItem.propTypes = {
  feedItem: FeedItemShape.isRequired,
  currentUserId: PropTypes.string,
  linkToComments: PropTypes.bool,
  hideCommentAction: PropTypes.any,
  challengeId: PropTypes.string,
};

export default FeedItem;

const Time = styled.div`
  ${pWeak};
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: top;
`;

const Extra = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  align-items: center;
  padding-left: 8px;
`;

const Info = styled.div`
  ${pWeak};
  :not(:first-child) {
    margin-left: 15px;
  }
  :hover {
    cursor: ${props => (props.onClick ? 'pointer' : 'cursor')};
  }
`;

const InfoLink = styled(Link)`
  ${pWeak};
  font-weight: bold;
  letter-spacing: normal;

  :not(:first-child) {
    margin-left: 15px;
  }
  :hover {
    cursor: ${props => (props.onClick ? 'pointer' : 'cursor')};
  }
`;

const ReactionsLink = styled(Link)`
  &:not(:first-child) {
    margin-left: 15px;
  }
`;
