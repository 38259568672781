import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMessageGetter } from 'react-message-context';

import { CardLabelWithIcon } from '../card/CardLabel';
import Card from '../card/Card';
import { labelLeftPositive, pLeft, pWeak } from '../../styles/text';
import { ReactComponent as CheckSvg } from '../../assets/images/check.svg';
import { ReactComponent as PersonSvg } from '../../assets/images/person.svg';
import { SpacingShape } from '../../util/shapes';

/**
 * This component relies on challenge wizard translation keys being consistent.
 */
export default function ActionsCard({ actions, challengeShortName, spacing, flat }) {
  const messages = useMessageGetter('components.actions_card');
  const actionMessages = useMessageGetter(`pages.baseline.${challengeShortName}.action`);
  return (
    <Card spacing={spacing} flat={flat}>
      <CardLabelWithIcon icon={<PersonSvg />}>{messages('header')}</CardLabelWithIcon>
      <ActionsList>
        {actions.map(action => (
          <Container key={action}>
            <DoneIcon />
            <Text>
              <Label>{actionMessages(`${action}.label`)}</Label>
              <Description>{actionMessages(`${action}.description`)}</Description>
            </Text>
          </Container>
        ))}
      </ActionsList>
      <Footer>{messages('footer')}</Footer>
    </Card>
  );
}

ActionsCard.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.string),
  challengeShortName: PropTypes.string.isRequired,
  spacing: SpacingShape,
  flat: PropTypes.bool,
};

const ActionsList = styled.ul`
  display: inline-block;
  margin: 15px 0 0 0;
  padding: 0;
`;

const Container = styled.li`
  display: flex;
  margin-bottom: 15px;
`;

const Text = styled.div`
  margin-left: 9px;
`;

const Label = styled.div`
  ${labelLeftPositive};
  margin-bottom: 5px;
`;

const Description = styled.div`
  ${pLeft};
`;

const DoneIcon = styled(CheckSvg)`
  width: 9px;
  height: 9px;
  path {
    fill: ${({ theme }) => theme.text.positive};
  }
`;

const Footer = styled.div`
  ${pWeak};
  margin-top: 15px;
`;
