import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { pLeft, pWeak } from '../../styles/text';
import { interpolate } from '../../util/text';
import RichValue from '../texts/RichValue';

const CalculusRow = ({ colSpan = 1, formula, operations = [], value, valueUnit, description }) => {
  const ops = operations
    .map((op, idx) => ({
      [`$${idx + 1}`]: <HighlightText>{op}</HighlightText>,
    }))
    .reduce((acc, val) => ({ ...acc, ...val }), {});

  return (
    <TableRow>
      <Cell colSpan={colSpan}>
        <Formula>{interpolate(formula, ops)}</Formula>
        <RichValue value={value} unit={valueUnit} negative={false} end />
        <DescriptionText>{description}</DescriptionText>
      </Cell>
    </TableRow>
  );
};

CalculusRow.propTypes = {
  colSpan: PropTypes.number,
  formula: PropTypes.string.isRequired,
  operations: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueUnit: PropTypes.string,
  description: PropTypes.string,
};

export default CalculusRow;

const TableRow = styled.tr``;

const Cell = styled.td`
  ${pLeft};
  border-bottom: none !important;
  padding-top: 15px;
  text-align: right;
`;

const Formula = styled.div`
  margin-bottom: 5px;
`;

const HighlightText = styled.span`
  color: ${({ theme }) => theme.text.standout};
`;

const DescriptionText = styled.div`
  ${pWeak};
`;
