import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { h1Positive } from '../../styles/text';
import { FeedItemContainer } from '../feeds/FeedItemPrimitives';

const Section = ({ children, title, show }) => {
  if (!show) {
    return null;
  } else {
    return (
      <Container>
        <SectionTitle>{title}</SectionTitle>
        {children}
      </Container>
    );
  }
};

Section.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  show: PropTypes.bool,
};

export default Section;

const SectionTitle = styled.h1`
  ${h1Positive};
  text-align: center;
`;

const Container = styled.section`
  > ${SectionTitle} {
    padding: 0 30px;
    margin: 20px 0 20px 0;
  }

  > ${FeedItemContainer} {
    padding: 0 30px;
    margin: 20px 0 14px 0;
  }
`;
