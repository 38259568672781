import styled from 'styled-components';
import { h2LeftNegative } from '../../../styles/text';

const WizardSectionHeading = styled.h2`
  ${h2LeftNegative};
  padding-left: 30px;
  padding-right: 30px;
  margin: 0;
`;

export default WizardSectionHeading;
