import styled from 'styled-components';
import { h2LeftPositive } from '../../styles/text';

const ModalPageSubtitle = styled.h2`
  ${h2LeftPositive};
  text-transform: uppercase;
  margin: 0 0 10px 0;
`;

export default ModalPageSubtitle;
