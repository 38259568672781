import { useEffect, useRef, useState } from 'react';
import { mainViewTransitionDuration } from '../styles/durations';

// We need to wait this long before measuring document height, because there are two pages rendered
// at the same time when a main view transition happens.
const waitBeforeMeasuringDocument = mainViewTransitionDuration + 200;

export function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return width;
}

export function useDocumentHeight() {
  const windowWidth = useWindowWidth();
  const [height, setHeight] = useState(0);
  useEffect(() => {
    const timer = setTimeout(
      () => setHeight(document.body.scrollHeight),
      waitBeforeMeasuringDocument,
    );
    return () => clearTimeout(timer);
  }, [windowWidth]);
  return height;
}

export function useParentHeight() {
  const ref = useRef();
  const parent = ref.current && ref.current.parentElement;
  const [height, setHeight] = useState(null);
  useEffect(() => {
    parent && setHeight(parent.scrollHeight);
  }, [parent]);
  return [height, ref];
}
