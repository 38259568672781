import React, { useState, useEffect } from 'react';
import { useMessageGetter } from 'react-message-context';
import styled, { ThemeProvider } from 'styled-components';
import { theme } from '../../../theme';
import ModalFullscreen, {
  ModalContent,
  ModalDivider,
} from '../../../components/modal/ModalFullscreen';
import ModalPageTitle from '../../../components/modal/ModalPageTitle';
import ModalPageDescription from '../../../components/modal/ModalPageDescription';
import ModalPageSubtitle from '../../../components/modal/ModalPageSubtitle';
import ModalConfirm from '../../../components/modal/ModalConfirm';
import { useApp } from '../../../context/appContext';
import { loadGroup, updateGroup } from '../../../context/actions';
import { getGroupInvite } from '../../../functionalities/apis';
import SimpleFeedItem from '../../../components/feeds/SimpleFeedItem';
import AnimatedModalFullscreen from '../../../components/modal/AnimatedModalFullscreen';
import PostInput from '../../../components/feeds/PostInput';
import ActionListButton, {
  ACTION_LIST_BUTTON_TYPES,
} from '../../../components/button/ActionListButton';
import { useTrackPageView } from '../../../hooks/useTrackPageView';
import P from '../../../components/texts/paragraphs/P';
import ModalGroupSubtitle from '../../../components/modal/ModalGroupSubtitle';
import { HistoryShape, MatchShapeOfParams } from '../../../util/shapes';

const GroupInvitePage = ({ match, history }) => {
  const [app, dispatch] = useApp();
  const { challengeId, groupId } = match.params;
  const group = app[groupId];
  const [showCopySuccess, setCopySuccess] = useState(null);
  const [errorText, setErrorText] = useState(null);
  const [inviteHash, setInviteHash] = useState('');
  const [isModifyWelcomeMsg, setModifyWelcomeMsg] = useState(false);
  const [isWelcomeMsgUpdating, setWelcomeMsgUpdating] = useState(false);
  const [welcomeMessageCopy, setWelcomeMessageCopy] = useState('');
  const messages = useMessageGetter('pages.group.invite');

  useTrackPageView();

  useEffect(() => {
    if (!group) {
      loadGroup(dispatch, groupId);
    }
  }, [dispatch, groupId, group]);

  useEffect(() => {
    async function loadGroupInvite() {
      const inviteData = await getGroupInvite(groupId);
      setInviteHash(inviteData.inviteHash);
    }
    loadGroupInvite();
  }, [groupId]);

  if (!group) {
    return null;
  }

  const value = `${window.location.origin}/j/${inviteHash}`;

  const doCopyText = () => {
    try {
      copyText(value);
      setCopySuccess('link');
    } catch (err) {
      console.log('Error copying', err);
      setErrorText(messages('error.copy.link'));
    }
  };

  const doCopyCode = () => {
    try {
      copyText(group.passcode);
      setCopySuccess('passcode');
    } catch (err) {
      console.log('Error copying', err);
      setErrorText(messages('error.copy.code'));
    }
  };

  const doShareLink = async () => {
    try {
      const body = messages('message.body', {
        captainName: group.captain.givenName,
        isInviteText: !!group.welcomeMessage,
        isPasscode: !!group.passcode,
        passcode: group.passcode,
        url: value,
      });

      await navigator.share({
        title: messages('message.subject'),
        text: body,
        url: value,
      });
    } catch (err) {
      console.log('Error sharing', err);
      if (err.name !== 'AbortError') {
        setErrorText(messages('error.share'));
      }
    }
  };

  const getMailtoLink = () => {
    const body = messages('message.body', {
      captainName: group.captain.givenName,
      isInviteText: !!group.welcomeMessage,
      isPasscode: !!group.passcode,
      passcode: group.passcode,
      url: value,
    });

    const subject = encodeURIComponent(messages('message.subject'));
    return `mailto:?subject=${subject}&body=${encodeURIComponent(body)}`;
  };

  const updateWelcomeMessage = async () => {
    setWelcomeMsgUpdating(true);
    try {
      await updateGroup(dispatch, groupId, {
        welcomeMessage: welcomeMessageCopy,
      });
      setModifyWelcomeMsg(false);
    } catch (error) {
      setErrorText(messages('error.update'));
    }
    setWelcomeMsgUpdating(false);
  };

  const onModifyWelcomeMessage =
    group.captain.userId === app.user.userId
      ? () => {
          setModifyWelcomeMsg(true);
          setWelcomeMessageCopy(group.welcomeMessage || '');
        }
      : undefined;

  const isWebShareAvailable = !!navigator.share;
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream; // Very simplistic

  return (
    <ThemeProvider theme={theme('white')}>
      <ModalFullscreen
        title={messages('heading')}
        onClose={history.goBack}
        actions={[]}
        subtitle={
          <ModalGroupSubtitle
            group={group}
            onClick={history.goBack}
            inGroup={false}
            marginBottom="15px"
          />
        }
        border={!!group}
      >
        <ModalContent>
          <ModalPageTitle>{messages('title')}</ModalPageTitle>
          <ModalPageDescription>{messages('subtitle')}</ModalPageDescription>
          <ModalPageSubtitle>{messages('message.heading')}</ModalPageSubtitle>
          <SimpleFeedItem
            onClick={onModifyWelcomeMessage}
            user={group.captain}
            text={group.welcomeMessage}
            placeholder={messages('message.placeholder')}
          />
        </ModalContent>
        <ModalDivider style={{ marginTop: '10px', marginBottom: '50px' }} />
        <ModalContent>
          <ModalPageSubtitle>{messages('share.heading')}</ModalPageSubtitle>
          {isWebShareAvailable && (
            <ActionListButton
              icon={isIOS ? ACTION_LIST_BUTTON_TYPES.shareApple : ACTION_LIST_BUTTON_TYPES.share}
              onClick={doShareLink}
            >
              {messages('share.action')}
            </ActionListButton>
          )}
          {!isWebShareAvailable && (
            <ActionListButton icon={ACTION_LIST_BUTTON_TYPES.email} href={getMailtoLink()}>
              {messages('email.action')}
            </ActionListButton>
          )}
          <ActionListButton icon={ACTION_LIST_BUTTON_TYPES.copy} onClick={doCopyText}>
            {messages('copy.link.action')}
          </ActionListButton>

          {group.access === 'invite' ? (
            <>
              <SubtitleWithMargin>{messages('copy.code.title')}</SubtitleWithMargin>
              <ActionListButton onClick={doCopyCode}>
                <PasscodeButtonContents>
                  {messages('copy.code.action')}
                  <Passcode>{group.passcode}</Passcode>
                </PasscodeButtonContents>
              </ActionListButton>
              <P>{messages('copy.code.description')}</P>
            </>
          ) : null}
        </ModalContent>
        {showCopySuccess && (
          <ModalConfirm
            title={messages(`copy.${showCopySuccess}.confirm.title`)}
            description={messages(`copy.${showCopySuccess}.confirm.description`)}
            options={[
              {
                key: 'yes',
                label: messages('copy.confirm.ok'),
                onClick: () => setCopySuccess(null),
              },
            ]}
          />
        )}
        {errorText && (
          <ModalConfirm
            title={messages('copy.error.title')}
            description={errorText}
            options={[
              {
                key: 'yes',
                label: messages('copy.confirm.ok'),
                onClick: () => setErrorText(''),
              },
            ]}
          />
        )}
        <AnimatedModalFullscreen
          border={true}
          onClose={() => setModifyWelcomeMsg(false)}
          show={isModifyWelcomeMsg}
          title={messages('message.edit.title')}
          subtitle={
            <ModalGroupSubtitle
              group={group}
              onClick={() => history.push(`/challenges/${challengeId}/group/${groupId}`)}
              inGroup={false}
              marginBottom="15px"
            />
          }
        >
          <ModalContent>
            <PostInput
              loading={isWelcomeMsgUpdating}
              onChange={e => setWelcomeMessageCopy(e.target.value)}
              onSubmit={updateWelcomeMessage}
              placeholder={messages('message.edit.placeholder')}
              submitText={messages('message.edit.save')}
              userId={app.user.sub}
              value={welcomeMessageCopy}
            />
          </ModalContent>
        </AnimatedModalFullscreen>
      </ModalFullscreen>
    </ThemeProvider>
  );
};

GroupInvitePage.propTypes = {
  history: HistoryShape.isRequired,
  match: MatchShapeOfParams(['challengeId', 'groupId']),
};

export default GroupInvitePage;

const copyText = function (text) {
  const node = document.createElement('span');
  node.textContent = text;
  node.style.position = 'fixed';

  document.body.appendChild(node);

  const selection = window.getSelection();
  const range = document.createRange();
  range.selectNodeContents(node);
  selection.removeAllRanges();
  selection.addRange(range);

  document.execCommand('copy');

  selection.removeAllRanges();
  document.body.removeChild(node);
};

const PasscodeButtonContents = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Passcode = styled.span`
  background-color: ${props => props.theme.colors.soft1};
  padding: 8px;
  font-family: Barlow;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1.5px;
`;

const SubtitleWithMargin = styled(ModalPageSubtitle)`
  margin-top: 30px;
`;
