import styled from 'styled-components';
import { h3vanilla } from '../../styles/text';

const H3Centered = styled.h3`
  ${h3vanilla};
  text-align: center;
  margin: 0 0 30px 0;
`;

export default H3Centered;
