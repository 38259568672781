import React from 'react';
import PropTypes from 'prop-types';
import { OptionCheckbox } from './option/Option';
import { Container, Label } from './WizardSelectCommon';

const WizardSelectMany = ({ label, onSelect, options = [], value = [], name }) => {
  return (
    <Container role="group" aria-label={label}>
      {label && <Label>{label}</Label>}
      {options.map((option, i) => (
        <OptionCheckbox
          key={option.key}
          onClick={onSelect}
          index={i}
          name={name}
          value={option.value}
          previousSelected={i > 0 && value.indexOf(options[i - 1].value) > -1}
          nextSelected={i < options.length - 1 && value.indexOf(options[i + 1].value) > -1}
          selected={value.indexOf(option.value) > -1}
          title={option.title}
          subtitle={option.subtitle}
        />
      ))}
    </Container>
  );
};

WizardSelectMany.propTypes = {
  label: PropTypes.string,
  onSelect: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
    }),
  ),
  value: PropTypes.arrayOf(PropTypes.any),
  name: PropTypes.string,
};

export default WizardSelectMany;
