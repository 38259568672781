import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import ButtonIcon from '../button-icon/ButtonIcon';
import { h2CenterPositive } from '../../styles/text';
import { StyledButton } from '../button/Button';
import ContentWithSpinner from '../container/ContentWithSpinner';
import { fullscreenModalSlideDuration, waitBeforeSpinnerDuration } from '../../styles/durations';
import { ModalCenteredContent } from '../container/CenteredContent';
import ResponsiveStickyContainer from '../top/ResponsiveStickyContainer';
import elements from '../../styles/elements';
import PreventBodyScroll from './PreventBodyScroll';

const ModalFullscreen = ({
  actions = [],
  children,
  title,
  titleIcon,
  border = false,
  onClose,
  style,
  theme,
  showBgImage = true,
  denseTitle = false,
  subtitle,
  id,
  inputFocused = false,
}) => {
  const Wrapper = theme ? ThemeProvider : React.Fragment;
  const wrapperProps = theme ? { theme } : {};

  return (
    <Wrapper {...wrapperProps}>
      <PreventBodyScroll />
      <Fullscreen style={style} showBgImage={showBgImage} id={id}>
        <ModalTitleArea border={border} dense={denseTitle} hasSubtitle={subtitle}>
          <ModalTitle>
            {titleIcon && <TitleIcon alt="" src={titleIcon} />}
            {title}
          </ModalTitle>
          {subtitle}
        </ModalTitleArea>
        <ModalCenteredContent>
          <ResponsiveStickyContainer insideModal={true}>
            <ButtonIcon label="Close" type="close" disabled={false} onClick={onClose} />
          </ResponsiveStickyContainer>
          <ContentWithSpinner
            spinnerDelay={fullscreenModalSlideDuration + waitBeforeSpinnerDuration}
          >
            {children}
          </ContentWithSpinner>
          {actions.length > 0 && <ModalActions inputFocused={inputFocused}>{actions}</ModalActions>}
        </ModalCenteredContent>
      </Fullscreen>
    </Wrapper>
  );
};

ModalFullscreen.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.node),
  children: PropTypes.node,
  id: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.node,
  titleIcon: PropTypes.string,
  border: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  style: PropTypes.object,
  theme: PropTypes.object,
  showBgImage: PropTypes.bool,
  denseTitle: PropTypes.bool,
  inputFocused: PropTypes.bool,
};

export default ModalFullscreen;

const Fullscreen = styled.div.attrs(props => ({
  style: {
    backgroundPosition: props.theme.modal.bgPosition,
    backgroundImage: props.showBgImage ? props.theme.modal.bgImage : 'none',
  },
  className: 'fullscreen',
}))`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 250;
  margin: 0;
  box-sizing: border-box;
  background-color: #fff;
  background-repeat: no-repeat;
  overflow: auto;
`;

const ModalTitleArea = styled.div.attrs(props => ({
  style: {
    ...(props.border ? { borderBottom: `1px solid ${props.theme.colors.dull3_15}` } : {}),
  },
}))`
  ${h2CenterPositive};
  width: 100%;
  height: ${({ hasSubtitle }) => (hasSubtitle ? 78 : 60)}px;
  margin-bottom: ${({ dense }) => (dense ? 0 : 10)}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  z-index: 10000;
`;

const ModalTitle = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`;

const TitleIcon = styled.img`
  max-height: 18px;
  width: auto;
`;

export const ModalContent = styled.div`
  padding: 0 30px;
`;

export const ModalActions = styled.div.attrs(({ children }) => ({
  style: {
    justifyContent: children.length > 1 ? 'space-between' : 'center',
  },
}))`
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: ${elements.containerMaxWidthPx}px;
  display: flex;
  height: ${elements.wizardFooterHeightPx}px;
  flex-direction: row;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  > ${StyledButton} {
    ${({ children }) => (children.length > 1 ? 'width: auto;' : '')}
  }

  @media (max-width: 500px) {
    position: ${({ inputFocused }) => (inputFocused ? 'relative' : 'fixed')};
  }
`;

export const ModalDivider = styled.div`
  height: 1px;
  background-color: rgba(87, 87, 87, 0.15);
`;
