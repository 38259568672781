import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import DailySuccessIndicator from '../indicator/DailySuccessIndicator';
import { h2LeftPositive, pPositive } from '../../styles/text';

const CardDataSheet = ({ data, columns = 1 }) => {
  const columnWidth = Math.floor(100 / columns);
  return (
    <DataRowsContainer>
      {data &&
        data.map((value, idx) => (
          <DataCell
            key={idx}
            type={value.type}
            valueText={value.valueText}
            desc={value.desc}
            icon={value.icon}
            widthPrc={columnWidth}
          />
        ))}
    </DataRowsContainer>
  );
};

const DataRowShape = PropTypes.shape({
  type: PropTypes.oneOf(['even', 'odd', 'warning']),
  valueText: PropTypes.string,
  desc: PropTypes.string,
  icon: PropTypes.string,
});

CardDataSheet.propTypes = {
  data: PropTypes.arrayOf(DataRowShape),
  columns: PropTypes.number,
};

export default CardDataSheet;

const DataCell = ({ type, valueText, desc, widthPrc, icon }) => {
  return (
    <DataRowContainer widthPrc={widthPrc}>
      <DataRowValue type={type}>
        {icon && <DataRowIcon icon={icon} />}
        {valueText}
      </DataRowValue>
      <DataRowDesc>{desc}</DataRowDesc>
    </DataRowContainer>
  );
};

DataCell.propTypes = {
  type: PropTypes.oneOf(['even', 'odd', 'warning']),
  valueText: PropTypes.string,
  desc: PropTypes.string,
  icon: PropTypes.string,
  widthPrc: PropTypes.number,
};

const DataRowsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
`;

const DataRowContainer = styled.div.attrs(props => ({
  style: {
    width: `${props.widthPrc}%`,
  },
}))`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
`;

const getDataRowValueBg = (type, colors) => {
  switch (type) {
    case 'warning':
      return colors.soft3;
    case 'even':
      return colors.soft2;
    case 'odd':
      return colors.soft1;
    default:
      throw new Error('Unknown type', type);
  }
};

const DataRowValue = styled.div.attrs(props => ({
  style: {
    backgroundColor: getDataRowValueBg(props.type, props.theme.colors),
  },
}))`
  ${h2LeftPositive};
  width: 60px;
  min-width: 60px;
  height: 32px;
  min-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
`;

const DataRowDesc = styled.p`
  ${pPositive};
  margin: 0 0 0 8px;
  padding: 0;
  display: flex;
  align-items: center;
`;

const DataRowIcon = ({ icon }) => (
  <DataRowWrapper>
    <DailySuccessIndicator state={icon} />
  </DataRowWrapper>
);

DataRowIcon.propTypes = {
  icon: PropTypes.string.isRequired,
};

const DataRowWrapper = styled.div`
  margin-right: 8px;
`;
