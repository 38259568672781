import React, { memo } from 'react';
import { PropTypes } from 'prop-types';
import { useMessageGetter } from 'react-message-context';
import { Constants, Groups } from 'isomorphic';

import Co2 from '../../../functionalities/Co2';
import ProgressCard, {
  mapGroupProgressDataToCumulativeActualProgress,
} from '../../data-cards/ProgressCard';
import { group } from '../../../assets/images';
import { GroupGoalShape, GroupProgressShape, SpacingShape } from '../../../util/shapes';

function GroupProgressCard({
  status,
  groupGoalData,
  groupProgressData,
  groupDurationDays,
  groupStartDate,
  spacing,
  flat,
  challengeShortName = '',
}) {
  const messages = useMessageGetter();
  const { GroupStates } = Constants;
  const label = messages(`pages.group.charts.${status}.group.title`);
  const totalReductionDesc = messages(`pages.group.charts.${status}.group.valueDesc`);
  const targetReductionPrc = groupGoalData.totalTargetCo2Kg / groupGoalData.totalInitialCo2Kg;
  const totalInitialEmissionsDuringChallenge =
    (groupGoalData.totalInitialCo2Kg / 365) * groupDurationDays;
  const dailyAnswers = mapGroupProgressDataToCumulativeActualProgress(groupProgressData) || [
    { index: 0, value: 0 },
  ];

  let totalReductionKg, infoValue, infoText, groupSheetData;
  if (status === GroupStates.hatching) {
    totalReductionKg = groupGoalData.totalInitialCo2Kg - groupGoalData.totalTargetCo2Kg;
    const totalTargetReductionKgCo2 = new Co2((totalReductionKg * groupDurationDays) / 365);
    infoValue = totalTargetReductionKgCo2.getFullScaledText();
    infoText = messages(`pages.group.charts.${status}.group.infoText`, {
      weeks: groupDurationDays / 7,
    });
  } else if (status === GroupStates.active) {
    totalReductionKg = Groups.getGroupTotalReductionKg(groupProgressData);
    const totalProjectedReductionKgCo2 = new Co2(
      (Math.abs(totalReductionKg) / Math.max(1, dailyAnswers.length - 1)) * 365,
    );
    infoValue = totalProjectedReductionKgCo2.getFullScaledText();
    infoText = messages(`pages.group.charts.${status}.group.infoText`);
  } else if (status === GroupStates.alumni) {
    totalReductionKg = Groups.getGroupTotalReductionKg(groupProgressData);
    const daysSinceStart = Groups.getGroupDay(groupStartDate, groupDurationDays);
    const reductionSinceStart = (Math.abs(totalReductionKg) / groupDurationDays) * daysSinceStart;
    infoValue = new Co2(reductionSinceStart).getFullScaledText();
    infoText = messages(`pages.group.charts.${status}.group.infoText`);
  }

  const [equiValue, equiText] = new Co2(totalReductionKg).getRandomEquivalence(messages);

  if (totalReductionKg < 0) {
    groupSheetData = [
      {
        type: 'warning',
        valueText: `${new Co2(Math.abs(totalReductionKg)).getFullScaledText()}`,
        desc: messages(`pages.group.charts.${status}.group.warning.infoText`, {
          csn: challengeShortName,
        }),
      },
      {
        type: 'warning',
        valueText: infoValue,
        desc: messages(`pages.group.charts.${status}.group.warning.yearly`),
      },
    ];
  } else {
    groupSheetData = [
      {
        type: 'even',
        valueText: `${equiValue}`,
        desc: equiText,
      },
      {
        type: 'odd',
        valueText: infoValue,
        desc: infoText,
      },
    ];
  }

  return (
    <ProgressCard
      key="group"
      label={label}
      daysAmount={groupDurationDays}
      targetReductionPrc={targetReductionPrc}
      totalReductionKg={totalReductionKg}
      totalReductionDesc={totalReductionDesc}
      targetLegend={messages('pages.group.charts.group.targetLegend')}
      refLegend={messages('pages.group.charts.group.refLegend')}
      refValue={totalInitialEmissionsDuringChallenge}
      dailyAnswers={dailyAnswers}
      sheetData={groupSheetData}
      labelIcon={<img src={group} alt="" />}
      spacing={spacing}
      flat={flat}
    />
  );
}

GroupProgressCard.propTypes = {
  status: PropTypes.oneOf(Object.values(Constants.GroupStates)),
  groupGoalData: GroupGoalShape.isRequired,
  groupProgressData: GroupProgressShape,
  groupDurationDays: PropTypes.number.isRequired,
  groupStartDate: PropTypes.string.isRequired,
  challengeShortName: PropTypes.string,
  spacing: SpacingShape,
  flat: PropTypes.bool,
};

export default memo(GroupProgressCard);
