import React from 'react';
import styled from 'styled-components';
import { h1CenterNegative, h1WizardTitle, pWizardTitle } from '../../../styles/text';
import { PropTypes } from 'prop-types';
import { WizardSubtitle } from './WizardHeadingPrimitives';

export const HEADING_SIZES = {
  NORMAL: 'normal',
  HUGE: 'huge',
};

const WizardHeading = ({ title, subtitle, type = HEADING_SIZES.NORMAL, onClick }) => {
  return (
    <WizardHeadingContainer onClick={onClick}>
      {type === HEADING_SIZES.HUGE ? (
        <WizardTitleHuge>{title}</WizardTitleHuge>
      ) : (
        <WizardTitle>{title}</WizardTitle>
      )}
      {subtitle &&
        Array.isArray(subtitle) &&
        subtitle.map((sub, i) =>
          type === HEADING_SIZES.HUGE ? (
            <WizardSubtitleHuge key={`sub-${i}`}>{sub}</WizardSubtitleHuge>
          ) : (
            <WizardSubtitle key={`sub-${i}`}>{sub}</WizardSubtitle>
          ),
        )}
      {subtitle &&
        !Array.isArray(subtitle) &&
        (type === HEADING_SIZES.HUGE ? (
          <WizardSubtitleHuge>{subtitle}</WizardSubtitleHuge>
        ) : (
          <WizardSubtitle>{subtitle}</WizardSubtitle>
        ))}
    </WizardHeadingContainer>
  );
};
WizardHeading.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.node)]),
  type: PropTypes.oneOf([HEADING_SIZES.NORMAL, HEADING_SIZES.HUGE]),
  onClick: PropTypes.func,
};

const WizardHeadingContainer = styled.div`
  width: 100%;
  padding: 0 30px 0 30px;
  box-sizing: border-box;
`;

const WizardTitle = styled.h1`
  ${h1CenterNegative};
  margin: 0;
`;

const WizardTitleHuge = styled.h1`
  ${h1WizardTitle};
  margin: 0;
`;

const WizardSubtitleHuge = styled.p`
  ${pWizardTitle};
  margin: 15px 0 30px 0;
`;

export default WizardHeading;
