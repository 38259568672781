import React from 'react';
import { useMessageGetter } from 'react-message-context';
import { ThemeProvider } from 'styled-components';
import { sadDark } from '../../assets/images/illustrations';
import Button from '../../components/button/Button';
import ModalFullscreen, { ModalContent } from '../../components/modal/ModalFullscreen';
import ModalIllustration from '../../components/modal/ModalIllustration';
import { HEADING_SIZES, WizardHeading } from '../../components/wizard';
import { useAppState } from '../../context/appContext';
import { useTrackPageView } from '../../hooks/useTrackPageView';
import { theme } from '../../theme';
import { HistoryShape } from '../../util/shapes';

const Error404Page = ({ history }) => {
  useTrackPageView();

  const { user } = useAppState();
  const messages = useMessageGetter();
  const msgRoot = user ? 'pages.error.signed_404' : 'pages.error.anon_404';
  const onClose = () => history.push('/');
  const actions = [
    <Button key="ok" primary onClick={onClose}>
      {messages(`${msgRoot}.button.ok`)}
    </Button>,
  ];

  return (
    <ThemeProvider theme={theme('white')}>
      <ModalFullscreen onClose={onClose} actions={actions}>
        <ModalContent style={{ textAlign: 'center', paddingBottom: '170px' }}>
          <ModalIllustration alt="" src={sadDark} />
          <WizardHeading
            title={messages(`${msgRoot}.title`)}
            subtitle={messages(`${msgRoot}.description`).split('\n')}
            type={HEADING_SIZES.HUGE}
          />
        </ModalContent>
      </ModalFullscreen>
    </ThemeProvider>
  );
};

Error404Page.propTypes = {
  history: HistoryShape,
};

export default Error404Page;
