import Auth from '@aws-amplify/auth';
import { Signer } from '@aws-amplify/core';
import config from '../config';
import { loadNotifications } from '../context/actions';

const { apiGatewayWebsocket } = config[process.env.REACT_APP_ENV];
const KEEP_ALIVE_TIME = 5 * 60 * 1000;

let socket = null;
let keepAliveTimeout = null;

export const client = async dispatch => {
  if (socket) return socket;

  if ((await Auth.currentUserInfo()) === null) return socket;

  const credentials = await Auth.currentCredentials();

  const accessInfo = {
    access_key: credentials.accessKeyId,
    secret_key: credentials.secretAccessKey,
    session_token: credentials.sessionToken,
  };

  const signedUrl = Signer.signUrl(apiGatewayWebsocket.endpoint, accessInfo);

  socket = new WebSocket(signedUrl);

  const ping = function ping() {
    socket.send(
      JSON.stringify({
        action: 'ping',
      }),
    );
  };

  socket.onerror = function (e) {
    console.log('Connection Error', e);
  };

  socket.onopen = function () {
    keepAliveTimeout = setTimeout(ping, KEEP_ALIVE_TIME);
  };

  socket.onclose = function (e) {
    clearTimeout(keepAliveTimeout);
  };

  socket.onmessage = function (e) {
    const data = JSON.parse(e.data);
    switch (data.action) {
      case 'new_notification':
        loadNotifications(dispatch);
        break;
      case 'pong':
        keepAliveTimeout = setTimeout(ping, KEEP_ALIVE_TIME);
        break;
      default:
        console.log('Unknown message', data);
    }
  };

  return socket;
};
