import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import DailySuccessIndicator, { SuccessStates } from '../indicator/DailySuccessIndicator';

const DotCalendar = ({ startWeekDay, daysAmount, days = [], activeIndex = -1 }) => {
  const daysArray = [...days, ...Array(Math.max(0, daysAmount - days.length))];

  return (
    <Container>
      <DayOffset length={startWeekDay} />
      {daysArray.map((day, index) => (
        <Day key={index} day={day} weekDay={(index + startWeekDay) % 7}>
          <DailySuccessIndicator state={day} active={activeIndex === index} />
        </Day>
      ))}
    </Container>
  );
};

DotCalendar.propTypes = {
  activeIndex: PropTypes.number,
  startWeekDay: PropTypes.number.isRequired,
  daysAmount: PropTypes.number.isRequired,
  days: PropTypes.arrayOf(
    PropTypes.oneOf([SuccessStates.success, SuccessStates.partly, SuccessStates.fail]),
  ),
};

export default DotCalendar;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

const DayOffset = styled.div.attrs(props => ({
  style: {
    width: `${props.length * (100 / 7)}%`,
  },
}))``;

const Day = styled.div`
  width: ${100 / 7}%;
  display: flex;
  height: 24px;
  justify-content: center;
  align-items: center;
`;
