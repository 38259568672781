import msg from './msg';

const TYPES = {
  grams: {
    id: 'grams',
    conversion: 1000,
    abbr: 'g',
  },
  kilograms: {
    id: 'kilograms',
    conversion: 1,
    abbr: 'kg',
  },
  tonnes: {
    id: 'tonnes',
    conversion: 0.001,
    abbr: 't',
  },
};
Object.freeze(TYPES);

const SPECIAL_TYPES = {
  finnishCitizens: {
    id: 'finnishCitizens',
    conversion: 1 / 10300,
    abbr: 'Finns',
  },
};
Object.freeze(SPECIAL_TYPES);

const unitConversionRatio = 0.999;

const INFOS = [{
  id: 'utils.co2data.avocado',
  co2Kg: 0.4,
}, {
  id: 'utils.co2data.cheese',
  co2Kg: 10,
}, {
  id: 'utils.co2data.beef',
  co2Kg: 15,
}]

export default function(co2eKG) {
  this.kg = co2eKG;
  this.TYPES = TYPES;
  this.SPECIAL_TYPES = SPECIAL_TYPES;

  /** < 1000kg as kilograms, otherwise in tonnes. never in grams **/
  this.getCorrectPhysicalUnit = () => {
    let correctUnit = TYPES.kilograms;
    if (Math.abs(this.kg * TYPES.tonnes.conversion) > unitConversionRatio) {
      correctUnit = TYPES.tonnes;
    }

    return correctUnit;
  }

  this.getScaledValue = () => {
    const unit = this.getCorrectPhysicalUnit();
    return { value: this.convertTo(unit), unitAbbr: unit.abbr};
  }

  this.getFullScaledText = () => {
    const {value, unitAbbr} = this.getScaledValue();
    return `${value} ${unitAbbr}`;
  }

  this.convertTo = (type) => {
    let value;

    switch(type) {
      case TYPES.grams:
        value = this.kg * TYPES.grams.conversion;
        break;
      case TYPES.kilograms:
        value = this.kg * TYPES.kilograms.conversion;
        break;
      case TYPES.tonnes:
        value = this.kg * TYPES.tonnes.conversion;
        break;
      case SPECIAL_TYPES.finnishCitizens:
        value = this.kg * SPECIAL_TYPES.finnishCitizens.conversion;
        break;
      default:
        console.error('Type has to be given for convertTo!', type);
        value = this.kg;
    }

    return this.round(value);
  }

  this.convertToKg = () => {
    return this.convertTo(TYPES.kilograms);
  }

  this.round = (value) => {
    if (value < -10) {
      return Math.round(value);
    } else if (value < 10) {
      return Math.round(value * 10) / 10;
    } else {
      return Math.round(value);
    }
  }

  this.getRandomEquivalence = (locale, textForFeed = false) => {
    // Find all where the internal value is greater than 1 unit of the "thing"
    const things = INFOS.filter(thing => thing.co2Kg <= this.kg);

    let thing;
    // If none found, use the first one which is smallest
    if (things.length === 0) {
      thing = INFOS[0];
    } else {
      thing = things[Math.floor(Math.random() * things.length)];
    }

    return this.getEquivalence(locale, thing, textForFeed);
  }

  this.getPseudoRandomEquivalence = (locale, textForFeed = false) => {
    // Find all where the internal value is greater than 1 unit of the "thing"
    const things = INFOS.filter(thing => thing.co2Kg <= this.kg);

    let thing;
    // If none found, use the first one which is smallest
    if (things.length === 0) {
      thing = INFOS[0];
    } else {
      // Stupid simple "pseudo random" generator used to get always same result for given Co2Kg amount
      // https://stackoverflow.com/a/19303725/1791917
      let seed = this.kg;
      const x = Math.sin(seed++) * 10000
      const pseudoRandom = x - Math.floor(x);
      thing = things[Math.floor(pseudoRandom * things.length)];
    }

    return this.getEquivalence(locale, thing, textForFeed);
  }

  this.getEquivalence = (locale, thing, textForFeed) => {
    const amount = this.round(this.kg / thing.co2Kg);
    return textForFeed
      ? msg(locale, `${thing.id}.feed`, { amount })
      : [
        msg(locale, `${thing.id}.value`, { amount }),
        msg(locale, `${thing.id}.desc`),
      ];
  }
}
