import styled, { css, keyframes } from 'styled-components';
import { labelLeftPositive } from '../../styles/text';

export const ReactionPhase = {
  adding: 'adding',
  removing: 'removing',
  removing2: 'removing2',
};

const pulse = keyframes`
  0% {    transform: scale(1.25); }
  35% {   transform: scale(0.75); }
  65% {   transform: scale(1.5); }
  100% {  transform: scale(1); }
`;

const fadeOut = keyframes`
  0% { opacity: 1; }
  50% {   transform: translateY(-10px); }
  100% {  opacity: 0; }
`;

const fadeIn = keyframes`
  0% {    opacity: 0;
          transform: translateY(10px); }
  50% {   opacity: 1; }
  100% {  transform: translateY(0px); }
`;

const mainAnimation = css`
  ${pulse} 850ms cubic-bezier(0.25, 0.1, 0.25, 1);
`;
const decreaseAnimationStart = css`
  ${fadeOut} 300ms cubic-bezier(0.25, 0.1, 0.25, 1);
`;
const decreaseAnimationEnd = css`
  ${fadeIn} 300ms cubic-bezier(0.25, 0.1, 0.25, 1);
`;

const getReactionCountAnimation = (countAnimationPhase, reactionCount) => {
  if (reactionCount === 0) {
    return '';
  }

  switch (countAnimationPhase) {
    case ReactionPhase.adding:
      return mainAnimation;
    case ReactionPhase.removing:
      return decreaseAnimationStart;
    case ReactionPhase.removing2:
      return decreaseAnimationEnd;
    default:
      return '';
  }
};

export const ReactIcon = styled.img`
  width: 16px;
  height: 16px;
  transform: scale(1);
  animation: ${props => (props.animating ? mainAnimation : '')};

  &:hover {
    transform: scale(1.25);
    cursor: pointer;
  }
`;

export const ReactionCount = styled.div`
  ${labelLeftPositive};
  color: ${props => props.theme.colors.brand2Hue3};
  letter-spacing: normal;
  margin-left: 4px;
  align-self: center;
  transform: scale(1);
  animation: ${props => getReactionCountAnimation(props.animating, props.reactionCount)};
`;

export const Reactions = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  opacity: ${props => (props.reactionCount === 0 ? 0 : 1)};
  transition: 300ms;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);

  > ${ReactIcon}:nth-child(2) {
    transform: translateX(-6px);
  }

  > ${ReactIcon}:nth-child(3) {
    transform: translateX(-12px);
  }

  > ${ReactIcon}:nth-child(4) {
    transform: translateX(-18px);
  }
`;
