import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  arrowLeft,
  arrowLeftPositive,
  arrowLeftDisabled,
  arrowRight,
  arrowRightPositive,
  arrowRightDisabled,
  arrowUp,
  settings,
  minus,
  iconPlusNegative,
} from '../../assets/images';
import CloseIcon from '../icons/CloseIcon';
import LoadingDotsIndicator from '../indicator/LoadingDotsIndicator';

const ButtonIcon = ({
  type,
  disabled = false,
  onClick = () => {},
  bg = true,
  label,
  loading = false,
  alpha = 1.0,
  hidden = false,
}) => (
  <ButtonIconContainer
    onClick={!disabled && !!onClick && !loading ? onClick : undefined}
    bg={bg}
    aria-label={label}
    alpha={alpha}
    hidden={hidden}
  >
    <ButtonContentContainer isLoading={loading}>
      <LoadingDotsContainer>
        <LoadingDotsIndicator />
      </LoadingDotsContainer>
      {getImage(type, disabled)}
    </ButtonContentContainer>
  </ButtonIconContainer>
);

ButtonIcon.propTypes = {
  alpha: PropTypes.number,
  bg: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.oneOf([
    'arrowLeft',
    'arrowLeftPositive',
    'arrowRight',
    'arrowRightPositive',
    'arrowUp',
    'close',
    'settings',
    'minus',
    'plus',
  ]).isRequired,
  hidden: PropTypes.bool,
};

export default ButtonIcon;

const getImage = (type, disabled) => {
  switch (type) {
    case 'arrowLeft':
      return disabled ? (
        <ButtonIconImage src={arrowLeftDisabled} />
      ) : (
        <ButtonIconImage src={arrowLeft} />
      );
    case 'arrowRight':
      return disabled ? (
        <ButtonIconImage src={arrowRightDisabled} />
      ) : (
        <ButtonIconImage src={arrowRight} />
      );
    case 'arrowRightPositive':
      return <ButtonIconImage src={arrowRightPositive} />;
    case 'arrowLeftPositive':
      return <ButtonIconImage src={arrowLeftPositive} />;
    case 'arrowUp':
      return <ButtonIconImage src={arrowUp} />;
    case 'close':
      return <CloseIcon />;
    case 'settings':
      return <ButtonIconImage src={settings} disabled={disabled} />;
    case 'minus':
      return <ButtonIconImage src={minus} disabled={disabled} />;
    case 'plus':
      return <ButtonIconImage src={iconPlusNegative} disabled={disabled} />;
    default:
      throw new Error(`Unknown icon type ${type}`);
  }
};

export const ButtonIconContainer = styled.button.attrs(props => ({
  style: {
    backgroundColor: props.bg ? props.theme.buttonIcon.bgRGB(props.alpha) : 'transparent',
    visibility: props.hidden ? 'hidden' : 'visible',
  },
}))`
  width: 34px;
  height: 34px;
  max-width: 34px;
  max-height: 34px;
  overflow: hidden;
  border-radius: 17px;
  border: none;
  display: flex;
  justify-content: center;
  padding: 0;
  transition: 100ms;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transform: scale(1);
  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
  &:active {
    transform: scale(0.85);
  }
`;

const ButtonContentContainer = styled.div`
  transition-delay: 100ms;
  transition: 300ms;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transform: translateY(-${props => (props.isLoading ? '0' : '34px')});
`;

const ButtonIconImage = styled.img.attrs(props => ({
  style: {
    ...(props.disabled ? { opacity: 0.15 } : {}),
  },
}))`
  width: 34px;
  height: 34px;
  min-width: 34px;
  max-width: 34px;
  min-height: 34px;
  max-height: 34px;
`;

const LoadingDotsContainer = styled.div`
  width: 34px;
  height: 34px;
  min-width: 34px;
  max-width: 34px;
  min-height: 34px;
  max-height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
