import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { labelLeftPositive } from '../../styles/text';

const TextButton = ({ children, onClick, disabled = false }) => {
  if (disabled) {
    onClick = () => {};
  }

  const onKeyDown = evt => {
    if (evt.key === ' ' || evt.key === 'Enter' || evt.key === 'Spacebar') {
      evt.preventDefault();
      onClick();
    }
  };

  return (
    <SpanButton
      disabled
      aria-disabled={disabled}
      role="button"
      tabIndex="0"
      onClick={onClick}
      onKeyDown={onKeyDown}
    >
      {children}
    </SpanButton>
  );
};

TextButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default TextButton;

const SpanButton = styled.span`
  ${labelLeftPositive};
  color: ${props => props.theme.textButton.color};
  cursor: pointer;
`;
