import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMessageGetter } from 'react-message-context';
import Card from '../card/Card';
import Notification from './Notification';
import { h3LeftPositive, pWeak } from '../../styles/text';
import TextButton from '../button/TextButton';
import InfiniteScroll from '../InfiniteScroll';

const NotificationsList = ({ notifications = [], loadMore, hasMore, markAllRead }) => {
  const messages = useMessageGetter('components');

  if (notifications.length === 0) {
    return null;
  }

  return (
    <Container>
      <Card spacing="none" flat={true}>
        <HeaderWrapper>
          <SectionHeader>{messages('messages.notifications.title')}</SectionHeader>
          <VerticalCentre>
            {notifications?.find(n => !n.read) && (
              <TextButton onClick={markAllRead}>
                {messages('messages.notifications.markRead')}
              </TextButton>
            )}
          </VerticalCentre>
        </HeaderWrapper>
        {(!notifications || notifications.length === 0) && (
          <NoNotifications>{messages('messages.notifications.empty')}</NoNotifications>
        )}
        <InfiniteScroll
          dataLength={(notifications || []).length}
          loadMore={loadMore}
          hasMore={hasMore}
          endMessage={messages('messages.notifications.allLoaded')}
        >
          {notifications.map((notification, i) => (
            <Notification key={`${notification.aspect}-${i}`} {...notification} />
          ))}
        </InfiniteScroll>
      </Card>
    </Container>
  );
};

NotificationsList.propTypes = {
  notifications: PropTypes.array.isRequired,
  loadMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  markAllRead: PropTypes.func.isRequired,
};

const Container = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
`;

const SectionHeader = styled.h3`
  ${h3LeftPositive};
  margin: 0;
  flex: 1;
`;

const VerticalCentre = styled.div`
  display: flex;
  align-items: center;
`;

const NoNotifications = styled.div`
  ${pWeak};
  padding: 20px;
`;

export default NotificationsList;
