import React from 'react';
import PropTypes from 'prop-types';
import { useMessageGetter } from 'react-message-context';
import { imgSmileyColor } from '../../../../assets/images';
import Button from '../../../../components/button/Button';
import Box from '../../../../components/container/Box';
import {
  FixedWizardFooter,
  HEADING_SIZES,
  WizardHeading,
  WizardStepContent,
} from '../../../../components/wizard';
import WizardIllustration from '../../../../components/wizard/illustration/WizardIllustration';

const VerifiedStep = ({ authValue, type, onContinue, onExit }) => {
  const messages = useMessageGetter(`pages.wizards.auth.step_4_begin_signup.${type}`);
  return (
    <WizardStepContent>
      <Box margin="0 0 20px 0" style={{ display: 'block', textAlign: 'center' }}>
        <WizardIllustration src={imgSmileyColor} />
      </Box>
      <WizardHeading
        title={messages('title')}
        subtitle={messages('subtitle', { value: authValue })}
        type={HEADING_SIZES.HUGE}
      />
      <FixedWizardFooter>
        <Button onClick={onExit} secondary>
          {messages('exit')}
        </Button>
        <Button onClick={onContinue} primary>
          {messages('continue')}
        </Button>
      </FixedWizardFooter>
    </WizardStepContent>
  );
};

VerifiedStep.propTypes = {
  authValue: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['phoneNumber', 'email']),
  onContinue: PropTypes.func.isRequired,
  onExit: PropTypes.func.isRequired,
};

export default VerifiedStep;
