import React from 'react';
import PropTypes from 'prop-types';

import Modal from './Modal';
import styled from 'styled-components';
import { labelCenterPositive, pCenter, pWeak } from '../../styles/text';
import { useMessageGetter } from 'react-message-context';

const ModalSelect = ({ title, options, x = 0, onChange, onCancel }) => {
  const messages = useMessageGetter('components.modal_select');
  return (
    <Modal x={x}>
      <Title>{title}</Title>
      {options.map(option => (
        <Option
          key={option.name}
          disabled={option.disabled}
          onClick={option.disabled ? undefined : () => onChange(option)}
        >
          {option.name}
        </Option>
      ))}
      <Option cancel onClick={onCancel}>
        {messages('cancel')}
      </Option>
    </Modal>
  );
};

ModalSelect.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  x: PropTypes.number,
};

export default ModalSelect;

const Title = styled.div`
  ${pCenter}
  ${pWeak}
  padding: 21px 13px 20px;
`;

const Option = styled.button.attrs(props => ({
  colors: props.theme.modal.select.option,
}))`
  ${labelCenterPositive}
  color: ${({ cancel, disabled, colors }) =>
    cancel ? colors.cancelColor : disabled ? colors.disabledColor : colors.color};
  background: ${({ colors }) => colors.bgColor};
  border-top: solid 1px ${({ colors }) => colors.borderColor};
  border-left: none;
  border-right: none;
  border-bottom: none;
  padding: 13px 10px;
  cursor: pointer;
  ${props => props.disabled && 'pointer-events: none;'}
  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;
