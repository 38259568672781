import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from '../../../theme';
import { useMessageGetter } from 'react-message-context';
import { useApp } from '../../../context/appContext';
import { loadGroup } from '../../../context/actions';
import ModalFullscreen, { ModalContent } from '../../../components/modal/ModalFullscreen';
import { useTrackPageView } from '../../../hooks/useTrackPageView';
import { HistoryShape, MatchShapeOfParams } from '../../../util/shapes';
import ModalGroupSubtitle from '../../../components/modal/ModalGroupSubtitle';
import WizardInputLink from '../../../components/wizard/form/input/WizardInputLink';

const GroupSettingsPageMember = ({ match, history }) => {
  const messages = useMessageGetter('pages.group.settings.member');
  const [app, dispatch] = useApp();
  const { challengeId, groupId } = match.params;
  const group = app[groupId];

  useTrackPageView();

  useEffect(() => {
    if (!group) {
      loadGroup(dispatch, groupId);
    }
  }, [dispatch, groupId, group]);

  if (!group) {
    return null;
  }

  const closeModal = () => {
    history.goBack();
  };

  const gotoGroup = () => history.push(`/challenges/${challengeId}/group/${groupId}`);
  const gotoBaseline = () => history.push(`/challenges/${challengeId}/group/${groupId}/baseline`);

  return (
    <ThemeProvider theme={theme('white')}>
      <ModalFullscreen
        title={messages('title')}
        onClose={closeModal}
        subtitle={<ModalGroupSubtitle group={group} onClick={gotoGroup} marginBottom="15px" />}
        border={!!group}
      >
        <ModalContent>
          <WizardInputLink
            name="gotobaseline"
            onClick={gotoBaseline}
            label={messages('baseline.label')}
            value={messages('baseline.value')}
            secondary
            description={messages('baseline.description')}
          />
        </ModalContent>
      </ModalFullscreen>
    </ThemeProvider>
  );
};

GroupSettingsPageMember.propTypes = {
  history: HistoryShape.isRequired,
  match: MatchShapeOfParams(['challengeId', 'groupId']),
};

export default GroupSettingsPageMember;
