/**
 * AWS Amplify config for dev and production environments (based on REACT_APP_ENV)
 */
const config = {
  dev: {
    apiGateway: {
      endpoints: [
        {
          name: 'apigateway',
          endpoint: 'https://moy7f0r341.execute-api.eu-central-1.amazonaws.com/dev',
          region: 'eu-central-1',
        },
      ],
    },
    apiGatewayWebsocket: {
      endpoint: 'wss://qn08stcuy7.execute-api.eu-central-1.amazonaws.com/dev',
    },
    auth: {
      identityPoolId: 'eu-central-1:76172a80-154b-4eab-ba2a-2b660f8bc895',
      region: 'eu-central-1',
      identityPoolRegion: 'eu-central-1',
      userPoolId: 'eu-central-1_sLN31IFzO',
      userPoolWebClientId: '3j5h2jgjo8sdi0voi2tqur3off',
      mandatorySignIn: false,
    },
    profileBucket: {
      url: 'https://pictures-dev.tideal.app',
    },
    cms: {
      url: 'https://d1rikuttggzk51.cloudfront.net/cms/read/production',
      accessToken: '23b4d577b483600a4e4ed8821ff328c2ce8f8caedf934346',
    },
  },
  production: {
    apiGateway: {
      endpoints: [
        {
          name: 'apigateway',
          endpoint: 'https://api.tideal.app',
          region: 'eu-central-1',
        },
      ],
    },
    apiGatewayWebsocket: {
      endpoint: 'wss://xljevp010f.execute-api.eu-central-1.amazonaws.com/production',
    },
    auth: {
      identityPoolId: 'eu-central-1:eda590c3-8a34-420d-9ca0-3a15180b538a',
      region: 'eu-central-1',
      identityPoolRegion: 'eu-central-1',
      userPoolId: 'eu-central-1_aa7jm32KH',
      userPoolWebClientId: '218qarrkpp8veas1d65lv8sml3',
      mandatorySignIn: false,
    },
    profileBucket: {
      url: 'https://pictures.tideal.app',
    },
    cms: {
      url: 'https://cms.tideal.app/cms/read/production',
      accessToken: '23b4d577b483600a4e4ed8821ff328c2ce8f8caedf934346',
    },
  },
};

export default config;
