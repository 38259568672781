import styled from 'styled-components';
import { pWeak } from '../../styles/text';

const StandingNumber = styled.div.attrs(({ standout, theme }) => ({
  style: standout ? { background: theme.text.standout, color: 'white' } : {},
}))`
  ${pWeak}
  margin-right: 8px;
  width: 13px;
  height: 13px;
  border-radius: 50vh;
  text-align: center;
  line-height: 1.2;
`;

export default StandingNumber;
