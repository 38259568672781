import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMessageGetter } from 'react-message-context';
import { Link } from 'react-router-dom';
import { postIcon, respectIcon } from '../../assets/images';
import ProfilePicture, { SIZES } from '../picture/ProfilePicture';
import { relativeTimeText } from '../../util/text';
import { labelLeftPositive } from '../../styles/text';
import { NotificationType } from 'isomorphic/notifications';
import { markNotificationRead } from '../../context/actions';
import { useAppDispatch } from '../../context/appContext';
import { assureNoPrefix } from 'isomorphic/util';

const REACTION_TYPES = [
  NotificationType.REACT_TO_ACTION,
  NotificationType.REACT_TO_POST,
  NotificationType.REACT_TO_REPLY,
];
const COMMENT_TYPES = [
  NotificationType.POST_TO_GROUP,
  NotificationType.REPLY_TO_THREAD,
  NotificationType.REPLY_TO_ACTION,
  NotificationType.REPLY_TO_POST,
];
const QUOTED_CONTENT = [...REACTION_TYPES.slice(1), ...COMMENT_TYPES.slice(1)];

const Notification = ({ aspect, type, target, user, createdAt, read, action }) => {
  const messages = useMessageGetter();
  const dispatch = useAppDispatch();
  const notificationId = assureNoPrefix('notification_', aspect);
  return (
    <NotificationLink
      $read={read}
      to={parseLinkUrl(target)}
      onClick={() => !read && markNotificationRead(notificationId, dispatch)}
    >
      <ImageContainer>
        <TypeIcon src={getIcon(type)} />
        <ProfilePicture userId={user.userId} size={SIZES.SMALL} />
      </ImageContainer>
      <ContentContainer>
        <TypeRow>
          <TypeText>
            <BoldText>
              {user.givenName} {user.familyName}
            </BoldText>{' '}
            <NotificationText type={type} target={target} />
          </TypeText>
          <Timestamp>{relativeTimeText(createdAt, messages)}</Timestamp>
        </TypeRow>
        {target && type !== NotificationType.POST_TO_GROUP && (
          <ContentText>
            {QUOTED_CONTENT.includes(type)
              ? `"${target.feedItemText || action.feedItemText}"`
              : messages(target.feedItemText, target.feedItemParams)}
          </ContentText>
        )}
      </ContentContainer>
    </NotificationLink>
  );
};

const getIcon = type => {
  if (REACTION_TYPES.includes(type)) {
    return respectIcon;
  } else if (COMMENT_TYPES.includes(type)) {
    return postIcon;
  }
};

const parseLinkUrl = ({ feedItemId, groupId, challengeId }) => {
  const cid = assureNoPrefix('challenge_', challengeId);
  const gid = assureNoPrefix('group_', groupId);
  const fid = assureNoPrefix('feedreply_', assureNoPrefix('feed_', feedItemId));
  const pid = fid.split('_')[0];
  return `/messages/${cid}/group/${gid}/posts/${pid}`;
};

const NotificationText = ({ type, target }) => {
  const commentMessages = useMessageGetter('components.messages.notifications.commented');
  const reactionMessages = useMessageGetter('components.messages.notifications.respected');
  const dataMessages = useMessageGetter('data.group');

  let message = null;
  switch (type) {
    case NotificationType.REACT_TO_POST:
      message = reactionMessages('post');
      break;
    case NotificationType.REACT_TO_ACTION:
      message = reactionMessages('action');
      break;
    case NotificationType.REACT_TO_REPLY:
      message = reactionMessages('comment');
      break;
    case NotificationType.POST_TO_GROUP:
      const groupName = target.groupName
        ? target.groupName
        : target.captainName
        ? dataMessages('defaultName', { captainName: target.captainName })
        : dataMessages('defaultNameUnknown');
      message = (
        <span>
          {commentMessages('group')} <BoldText>{groupName}</BoldText>
        </span>
      );
      break;
    case NotificationType.REPLY_TO_THREAD:
      message = commentMessages('thread');
      break;
    case NotificationType.REPLY_TO_ACTION:
      message = commentMessages('action');
      break;
    case NotificationType.REPLY_TO_POST:
      message = commentMessages('post');
      break;
    default:
      console.error('Notification type', type, 'is unknown');
      message = null;
  }

  return message;
};

const TypeText = styled.span``;

const BoldText = styled.span`
  ${labelLeftPositive}
`;

const ImageContainer = styled.div`
  display: flex;
  height: 40px;
`;

const TypeIcon = styled.img`
  margin-right: 6px;
`;

const NotificationLink = styled(Link).attrs(({ $read, theme }) => ({
  style: {
    color: theme.text.positive,
    backgroundColor: $read ? theme.notification.bg.read : theme.notification.bg.unread,
    borderBottom: $read ? `1px solid ${theme.notification.border}` : 'none',
  },
}))`
  font-family: ${props => props.theme.secondaryFont};
  display: flex;
  padding: 20px 35px 20px 15px;
`;

const TypeRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const ContentContainer = styled.div`
  width: 100%;
  margin-left: 15px;
  font-size: 12px;
`;

const ContentText = styled.span`
  color: ${props => props.theme.notification.textSecondary};
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 28px;
  line-height: 13px;
`;

const Timestamp = styled.span`
  color: ${props => props.theme.notification.textSecondary};
  white-space: nowrap;
`;

Notification.propTypes = {
  aspect: PropTypes.string,
  type: PropTypes.string,
  target: PropTypes.object,
  action: PropTypes.object,
  user: PropTypes.shape({
    userId: PropTypes.string,
    givenName: PropTypes.string,
    familyName: PropTypes.string,
  }),
  createdAt: PropTypes.number,
  read: PropTypes.bool,
};

export default Notification;
