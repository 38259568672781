export const DistanceValues = {
  Walk: 'commuteWalkKm',
  Bicycle: 'commuteBicycleKm',
  Tram: 'commuteTramKm',
  Metro: 'commuteMetroKm',
  Train: 'commuteTrainKm',
  EScooter: 'commuteEScooterKm',
  LongDistanceBus: 'commuteLDBusKm',
  ShortDistanceBus: 'commuteSDBusKm',
  Scooter: 'commuteScooterKm',
  Motorcycle: 'commuteMotorcycleKm',
  Microcar: 'commuteMicrocarKm',
  ElectricCar: 'commuteElectricCarKm',
  ElectricCarZero: 'commuteElectricCarZeroKm',
  HybridCar: 'commuteHybridCarKm',
  HybridCarZero: 'commuteHybridCarZeroKm',
  SmallDieselCar: 'commuteSDieselCarKm',
  MediumDieselCar: 'commuteMDieselCarKm',
  LargeDieselCar: 'commuteLDieselCarKm',
  SmallGasolineCar: 'commuteSGasolineCarKm',
  MediumGasolineCar: 'commuteMGasolineCarKm',
  LargeGasolineCar: 'commuteLGasolineCarKm',
  CustomCar: 'commuteCustomCarKm',
};

export const TextValues = {
  WalkKmPerYear: 'commuteWalkKmYearText',
  BicycleKmPerYear: 'commuteBicycleKmYearText',
  TramKmPerYear: 'commuteTramKmYearText',
  MetroKmPerYear: 'commuteMetroKmYearText',
  TrainKmPerYear: 'commuteTrainKmYearText',
  EScooterKmPerYear: 'commuteEScooterKmYearText',
  LongDistanceBusKmPerYear: 'commuteLDBusKmYearText',
  ShortDistanceBusKmPerYear: 'commuteSDBusKmYearText',
  ScooterKmPerYear: 'commuteScooterKmYearText',
  MotorcycleKmPerYear: 'commuteMotorcycleKmYearText',
  MicrocarKmPerYear: 'commuteMicrocarKmYearText',
  ElectricCarKmPerYear: 'commuteElectricCarKmYearText',
  ElectricCarZeroKmPerYear: 'commuteElectricCarZeroKmYearText',
  HybridCarKmPerYear: 'commuteHybridCarKmYearText',
  HybridCarZeroKmPerYear: 'commuteHybridCarZeroKmYearText',
  SmallDieselCarKmPerYear: 'commuteSDieselCarKmYearText',
  MediumDieselCarKmPerYear: 'commuteMDieselCarKmYearText',
  LargeDieselCarKmPerYear: 'commuteLDieselCarKmYearText',
  SmallGasolineCarKmPerYear: 'commuteSGasolineCarKmYearText',
  MediumGasolineCarKmPerYear: 'commuteMGasolineCarKmYearText',
  LargeGasolineCarKmPerYear: 'commuteLGasolineCarKmYearText',
  CustomCarKmPerYear: 'commuteCustomCarKmYearText',
  WalkKmPerWeek: 'commuteWalkKmWeekText',
  BicycleKmPerWeek: 'commuteBicycleKmWeekText',
  TramKmPerWeek: 'commuteTramKmWeekText',
  MetroKmPerWeek: 'commuteMetroKmWeekText',
  TrainKmPerWeek: 'commuteTrainKmWeekText',
  EScooterKmPerWeek: 'commuteEScooterKmWeekText',
  LongDistanceBusKmPerWeek: 'commuteLDBusKmWeekText',
  ShortDistanceBusKmPerWeek: 'commuteSDBusKmWeekText',
  ScooterKmPerWeek: 'commuteScooterKmWeekText',
  MotorcycleKmPerWeek: 'commuteMotorcycleKmWeekText',
  MicrocarKmPerWeek: 'commuteMicrocarKmWeekText',
  ElectricCarKmPerWeek: 'commuteElectricCarKmWeekText',
  ElectricCarZeroKmPerWeek: 'commuteElectricCarZeroKmWeekText',
  HybridCarKmPerWeek: 'commuteHybridCarKmWeekText',
  HybridCarZeroKmPerWeek: 'commuteHybridCarZeroKmWeekText',
  SmallDieselCarKmPerWeek: 'commuteSDieselCarKmWeekText',
  MediumDieselCarKmPerWeek: 'commuteMDieselCarKmWeekText',
  LargeDieselCarKmPerWeek: 'commuteLDieselCarKmWeekText',
  SmallGasolineCarKmPerWeek: 'commuteSGasolineCarKmWeekText',
  MediumGasolineCarKmPerWeek: 'commuteMGasolineCarKmWeekText',
  LargeGasolineCarKmPerWeek: 'commuteLGasolineCarKmWeekText',
  CustomCarKmPerWeek: 'commuteCustomCarKmWeekText',
};

export const Methods = {
  Walk: 'walk',
  Bicycle: 'bicycle',
  Tram: 'tram',
  Metro: 'metro',
  Train: 'train',
  EScooter: 'escooter',
  LongDistanceBus: 'ldbus',
  ShortDistanceBus: 'sdbus',
  Scooter: 'scooter',
  Motorcycle: 'motorcycle',
  Microcar: 'microcar',
  ElectricCar: 'car_electric',
  ElectricCarZero: 'car_electric_zero',
  HybridCar: 'car_hybrid',
  HybridCarZero: 'car_hybrid_zero',
  SmallDieselCar: 'car_s_diesel',
  MediumDieselCar: 'car_m_diesel',
  LargeDieselCar: 'car_l_diesel',
  SmallGasolineCar: 'car_s_gasoline',
  MediumGasolineCar: 'car_m_gasoline',
  LargeGasolineCar: 'car_l_gasoline',
  CustomCar: 'car_custom',
};

export const OptionValues = {
  Walk: 'optWalk',
  Bicycle: 'optBicycle',
  Tram: 'optTram',
  Metro: 'optMetro',
  Train: 'optTrain',
  EScooter: 'optEScooter',
  LongDistanceBus: 'optLDBus',
  ShortDistanceBus: 'optSDBus',
  Scooter: 'optScooter',
  Motorcycle: 'optMotorcycle',
  Microcar: 'optMicrocar',
  ElectricCar: 'optElectric',
  ElectricCarZero: 'optElectricZero',
  HybridCar: 'optHybrid',
  HybridCarZero: 'optHybridZero',
  SmallDieselCar: 'optSDiesel',
  MediumDieselCar: 'optMDiesel',
  LargeDieselCar: 'optLDiesel',
  SmallGasolineCar: 'optSGasoline',
  MediumGasolineCar: 'optMGasoline',
  LargeGasolineCar: 'optLGasoline',
  CustomCar: 'optCustom',
};

export const Icon = {
  Walk: 'walk',
  Bicycle: 'bicycle',
  Tram: 'tram',
  Metro: 'metro',
  Train: 'train',
  EScooter: 'escooter',
  LongDistanceBus: 'ldbus',
  ShortDistanceBus: 'sdbus',
  Scooter: 'scooter',
  Motorcycle: 'motorcycle',
  Microcar: 'microcar',
  ElectricCar: 'car-electric',
  ElectricCarZero: 'car-electric-zero',
  HybridCar: 'car-hybrid',
  HybridCarZero: 'car-hybrid-zero',
  SmallDieselCar: 'car-s-diesel',
  MediumDieselCar: 'car-m-diesel',
  LargeDieselCar: 'car-l-diesel',
  SmallGasolineCar: 'car-s-gasoline',
  MediumGasolineCar: 'car-m-gasoline',
  LargeGasolineCar: 'car-l-gasoline',
  CustomCar: 'car-custom',
};
