import React, { useCallback } from 'react';
import { getDailyAnswerConfig } from '../../../functionalities/apis';
import { useTrackPageView } from '../../../hooks/useTrackPageView';
import { HistoryShape, MatchShapeOfParams } from '../../../util/shapes';
import Wizard from '../../wizards/Wizard';

const DailyAnswerWizard = ({ match, history }) => {
  useTrackPageView();

  const { challengeId, id } = match.params;
  const loadConfig = useCallback(async () => await getDailyAnswerConfig(id), [id]);
  return (
    <Wizard
      match={match}
      history={history}
      loadConfig={loadConfig}
      onClose={() => {
        history.push(`/challenges/${challengeId}/group/${id}`);
      }}
    />
  );
};

DailyAnswerWizard.propTypes = {
  match: MatchShapeOfParams(['challengeId', 'id']),
  history: HistoryShape.isRequired,
};

export default DailyAnswerWizard;
