import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { multiplySizeWithUnit } from '../../util/common';

const LoadingDotsIndicator = ({ withText, size = '2px', round, bgImage, variant }) => {
  return (
    <Container withText={withText}>
      <Dot phase={0} size={size} round={round} bgImage={bgImage} variant={variant} />
      <Dot phase={1} size={size} round={round} bgImage={bgImage} variant={variant} />
      <Dot phase={2} size={size} round={round} bgImage={bgImage} variant={variant} />
    </Container>
  );
};

LoadingDotsIndicator.propTypes = {
  withText: PropTypes.bool,
  size: PropTypes.string,
  round: PropTypes.bool,
  bgImage: PropTypes.string,
  variant: PropTypes.oneOf(['positive', 'negative']),
};

export default LoadingDotsIndicator;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  /* XXX: Ugly alignment trick */
  margin-top: ${props => (props.withText ? '7px' : '0')};
`;

const pulse = keyframes`
  0% {
    opacity: 1.0;
  }

  50% {
    opacity: 0.55;
  }

  100% {
    opacity: 0.1;
  }
`;

const Dot = styled.div`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 50%;
  margin: 0 ${({ size }) => multiplySizeWithUnit(size, 0.5)};
  background-color: ${({ theme, variant }) => theme.loadingDots[variant || 'negative']};
  background-image: ${({ bgImage }) => bgImage};
  opacity: 0;
  animation: ${pulse} 1050ms linear infinite;
  animation-delay: ${props => props.phase * 350}ms;
  ${({ round }) => round && 'border-radius: 50vh'};
`;
