import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const FullscreenWizard = ({ children, maxX = 1, maxY = 1, x = 0, y = 0, parallaxBg, footer }) => (
  <FixedRootBackground>
    {parallaxBg && (
      <FixedParallaxBackground maxX={maxX} maxY={maxY} x={x} y={y} parallaxBg={parallaxBg} />
    )}
    {!parallaxBg && <StaticBackground />}
    <FixedOverlayRoot maxX={maxX} maxY={maxY} x={x} y={y}>
      {children}
    </FixedOverlayRoot>
    {footer}
  </FixedRootBackground>
);
FullscreenWizard.propTypes = {
  children: PropTypes.node,
  /** Amount of steps on x-axis */
  maxX: PropTypes.number,
  /** Amount of steps on y-axis */
  maxY: PropTypes.number,
  /** Current step on x-axis */
  x: PropTypes.number,
  /** Current step on y-axis */
  y: PropTypes.number,
  /** Background image for parallax effect */
  parallaxBg: PropTypes.string,
  /** Footer element, placed to work as fixed to bottom. */
  footer: PropTypes.element,
};

export default FullscreenWizard;

const FixedRootBackground = styled.div.attrs(props => ({
  style: {
    backgroundColor: props.theme.wizard.bgColor,
    backgroundImage: props.theme.wizard.bg,
  },
  className: 'fullscreen',
}))`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 200;
  margin: 0;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
`;

const parallaxEffectSizePrc = 5;
const transitionDurationMs = 600;
const transitionDelayMs = 400;
const FixedParallaxBackground = styled.div.attrs(props => ({
  style: {
    /* Set width and height of the wizard to contain all possible steps. */
    width: `${100 + props.maxX * parallaxEffectSizePrc}%`,
    height: `${100 + props.maxY * parallaxEffectSizePrc}%`,

    /* Translate the x and y position of the wizard to correct step based on x and y values. */
    transform: `translateX(${props.x * -parallaxEffectSizePrc}%)
      translateY(${props.y * -parallaxEffectSizePrc}%)`,
    transition: `transform ${transitionDurationMs + transitionDelayMs}ms ease-in-out`,

    backgroundImage: `url(${props.parallaxBg})`,
    backgroundSize: `auto ${100 + props.maxY * parallaxEffectSizePrc}%`,
  },
}))`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 1;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  opacity: ${props => props.theme.wizard.bgOpacity};
`;

const FixedOverlayRoot = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  /* Set width and height of the wizard to contain all possible steps. */
  width: ${props => props.maxX * 100}%;
  height: ${props => props.maxY * 100}%;

  /* Translate the x and y position of the wizard to correct step based on x and y values. */
  transform: translateX(${props => props.x * -100}%) translateY(${props => props.y * -100}%);
  transition: transform ${transitionDurationMs}ms ease-in-out ${transitionDelayMs}ms;

  z-index: 2;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
`;

const StaticBackground = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: 0;
  margin: 0;
  background-image: ${props => props.theme.modal.bgImage};
  background-position: ${props => props.theme.modal.bgPosition};
  background-repeat: no-repeat;
`;
