import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import ModalFullscreen from './ModalFullscreen';

const AnimatedModalFullscreen = ({
  actions = [],
  children,
  title,
  onClose,
  show,
  border = false,
  subtitle,
}) => {
  return (
    <CSSTransition in={show} timeout={500} classNames="slide" unmountOnExit>
      <ModalFullscreen
        actions={actions}
        title={title}
        onClose={onClose}
        children={children}
        border={border}
        subtitle={subtitle}
      />
    </CSSTransition>
  );
};

AnimatedModalFullscreen.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.node),
  children: PropTypes.node,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  border: PropTypes.bool,
  subtitle: PropTypes.node,
};

export default AnimatedModalFullscreen;
