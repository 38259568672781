import API from '@aws-amplify/api-rest';
import { storage } from './storage';

export const runDeferredAction = async (data, history) => {
  if (data.action === 'apiAction') {
    await apiAction(data, history);
  } else {
    console.log('Unknown action:', data);
  }

  // Remove the possible deferred action as we dont want to run it again.
  storage.remove('deferredAction');
};

const apiAction = async (data, history) => {
  const { apiName, path, init, onSuccessRedirectTo } = data;
  const response = await API.post(apiName, path, init);

  console.log('Run deferred API action', data, response);

  history.push(onSuccessRedirectTo);
};
