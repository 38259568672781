import React, { useEffect, useState } from 'react';
import { theme } from '../../theme';
import { useMessageGetter } from 'react-message-context';
import MiniFeed from '../../components/feeds/mini-feed/MiniFeed';
import ModalConfirm from '../../components/modal/ModalConfirm';
import { useApp } from '../../context/appContext';
import { loadGroupFeed, loadGroup } from '../../context/actions';
import ModalFullscreen from '../../components/modal/ModalFullscreen';
import { useTrackPageView } from '../../hooks/useTrackPageView';
import { postComment } from '../../functionalities/apis';
import ModalGroupSubtitle from '../../components/modal/ModalGroupSubtitle';
import { MatchShapeOfParams, HistoryShape } from '../../util/shapes';

const modalProps = {
  border: true,
  theme: theme('white'),
};

const GroupFeedPage = ({ match, history }) => {
  const { challengeId, groupId } = match.params;
  const messages = useMessageGetter();
  const [state, dispatch] = useApp();
  const [showExitConfirm, setShowExitConfirm] = useState(false);
  useTrackPageView();
  const group = state[groupId];

  useEffect(() => {
    if (groupId) {
      loadGroupFeed(dispatch, groupId);
    }
  }, [dispatch, groupId]);

  useEffect(() => {
    if (!group) {
      loadGroup(dispatch, groupId);
    }
  }, [dispatch, groupId, group]);

  const { user, posts } = state;
  const groupPosts = posts.filter(post => post.id === `group_${groupId}`);
  groupPosts.sort((p1, p2) => p2.createdAt - p1.createdAt);
  const title = messages('components.feed.title');

  const doClose = () => {
    history.goBack();
  };

  const onClose = () => {
    doClose();
  };

  const onLoadMorePosts = () => {
    if (groupPosts.length <= 0) {
      return;
    }

    const offsetKey = groupPosts[groupPosts.length - 1].aspect;
    loadGroupFeed(dispatch, groupId, offsetKey);
  };

  const onNewPost = async (groupId, newComment) => {
    const comment = await postComment(groupId, { text: newComment });

    const url = `/challenges/${
      match.params.challengeId
    }/group/${groupId}/posts/${comment.aspect.substring(5)}`;
    history.push(url);
  };

  const exitModal = () => {
    const hideCloseModal = () => {
      setShowExitConfirm(false);
    };

    return (
      <ModalConfirm
        title={messages('components.feed.comments.exit.confirm.title')}
        description={messages('components.feed.comments.exit.confirm.desc')}
        onCancel={hideCloseModal}
        options={[
          {
            label: messages('components.feed.comments.exit.confirm.yes'),
            key: 'yes',
            onClick: doClose,
          },
          {
            label: messages('components.feed.comments.exit.confirm.no'),
            key: 'no',
            onClick: hideCloseModal,
          },
        ]}
      />
    );
  };

  return (
    <ModalFullscreen
      title={title}
      onClose={onClose}
      subtitle={<ModalGroupSubtitle group={group} onClick={onClose} />}
      {...modalProps}
      id="scrollable-content"
    >
      {showExitConfirm && exitModal()}
      <MiniFeed
        items={groupPosts}
        isLoggedIn={user.isLoggedIn}
        currentUserId={user.isLoggedIn && user.sub}
        groupId={groupId}
        onNewPost={onNewPost}
        challengeId={challengeId}
        noTopPadding
        loadMore={onLoadMorePosts}
        hasMore={state.hasMorePosts}
        endMessage={messages('components.feed.allRead')}
        scrollableTarget="scrollable-content"
      />
    </ModalFullscreen>
  );
};

GroupFeedPage.propTypes = {
  match: MatchShapeOfParams(['challengeId', 'groupId']),
  history: HistoryShape.isRequired,
};

export default GroupFeedPage;
